import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class AssetCount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: ['#ccc', '#3c4ccf', '#02a499'],
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.1,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: this.props.periods,
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true
                    }
                },
                legend: {
                    show: false
                },
            },
            series: this.props.series,

        }
    }

    render() {
        return ( <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="350" /> );
    }
}

export default AssetCount;