import React from 'react';
import { Link } from "react-router-dom";
import  { Can, translate  } from 'helpers/helpers';
import { DropdownItem } from "reactstrap";

const ContractButtons = ({ contract, togglemodal }) => (
    <>
    {!contract?.archived && contract?.type === 'framework' &&
      <Can I="create" a="Contract">
        {() => (
          <DropdownItem >
            <Link className="btn btn-success col-lg-12 "  to={`/contracts/${contract?.id}/create-substart`}>
                {translate('contract.create-substart')}
            </Link>
          </DropdownItem>
        )}
      </Can>}
      <Can I="update" a="Contract">
        {() => (
        !contract?.archived &&
          <>
          <DropdownItem >
            <Link to={`/contracts/${contract?.id}/handle-assets`} className="btn btn-success col-lg-12 ">
              {translate('contract.add-assets')}
            </Link>
          </DropdownItem>
          <DropdownItem>
            <div onClick={() => togglemodal('extend')} className="btn btn-info col-lg-12 ">
              {translate('contract.extend')}
            </div>
          </DropdownItem>
          <DropdownItem>
            <div onClick={() => togglemodal('archive')} className=" btn btn-secondary col-lg-12 ">
              {translate('contract.archive')}
            </div>
          </DropdownItem>
          </>
        )}
      </Can>
  </>
    )

export default ContractButtons
