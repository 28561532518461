import React from 'react';

const MySearch = ({ onSearch, handleKeyPress }) => {
    let input;
    return (
          <input
            className="form-control mb-1"
            ref={ n => input = n }
            type="text"
            placeholder="Search"
            onChange={onSearch}
            onKeyUp={handleKeyPress}
            />
    );
  };

export default MySearch