import React from "react";
import { CardTitle, Media, Row, Col } from "reactstrap";
import error from "assets/images/error-img.png";
import { translate } from "helpers/helpers";


const Support = () => (
<>
<CardTitle className="mb-5">Support</CardTitle>

<Media className="faq-box mb-4">
    <div className="faq-icon mr-3">
        <i className="bx bx-email font-size-20 text-success"></i>
    </div>
    <Media body>
        <p className="text-muted text-center">{translate('faq.technical-support')}</p>
            <div className="mt-2 text-center">
                <a href="mailto:development@moreco.se" className="btn btn-primary button-shaddow">
                    <i className="mdi mdi-at mr-2"></i>
                    {translate('faq.email-us')}
                </a>
            </div>
            <Row className="justify-content-center">
                <Col md="8" xl="6">
                    <div>
                        <img src={error} alt="" className="img-fluid" />
                    </div>
                </Col>
            </Row>
    </Media>
</Media>
</>
);

export default Support;
