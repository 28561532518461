import React from "react";

// Redux and actions
import { useSelector } from "react-redux";
import { translate, getContractLogColumns } from 'helpers/helpers';
import { Alerts } from 'Components/Global';
import PageContainer from "Containers/PageContainer";
import { RemotePaginationTable } from "Components/Lists";


const ContractHistory = ({ match }) => {

  const pagination = useSelector(state => state.Model.history)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);

    return (
      <PageContainer
        Model={'Contract'}
        breadcrumbTitle={translate('contract.history')}
        breadcrumbItem={translate('contract.history')}
        listHeaderTitle={translate('contract.history')}
        data={pagination?.total}
        alerts={<Alerts error={error} success={success}/>}
      >
        <RemotePaginationTable
          model={'history'}
          pagination={pagination}
          columns={getContractLogColumns('history')}
          pageUrl={`${process.env.REACT_APP_API_URL}/contracts/logs`}
          path={match.path}
          can={'Contract'}
        />
    </PageContainer>
      );
  }


export default ContractHistory
