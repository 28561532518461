import React from "react";
import { Col, CardHeader, Row, Button } from 'reactstrap'
import Image9001 from 'assets/images/9001.png'
import Image14001 from 'assets/images/14001.png'
import Image27001 from 'assets/images/27001.png'
import Moreco from 'assets/images/logo.png'
import BlanccoGold from 'assets/images/blancco_gold.png'

const SectionContainer = ({ filename, id, exportPdf, title, subTitle, body, optional, bg, report }) =>  (

  <div id={id}>
    <div className="mb-3">
    <CardHeader className={`${bg} p-3 pt-3`} style={{ minHeight: '625px', height: "100%"}}>
      <Row>
        <Col lg={10}><h3>{title}</h3></Col>
        {filename ?
        <Col lg={2}>
          <Button data-html2canvas-ignore="true" color="success" className="mb-1 ml-1 float-right" onClick={() => exportPdf(id, filename)}>
            <i className="mdi mdi-download-outline" style={{ color: 'white'}}></i> PDF
          </Button>
        </Col>
        : null }
      </Row>
      <hr />
        <Col lg={12}>
          <h4 className="mt-3 text-center"> {subTitle} </h4>
          {optional}
        </Col>
        <div className="mt-3">
          {body}
        </div>
    </CardHeader>
      <div className="col-lg-12 d-flex justify-content-between p-0">
        <div>
          <img className="mr-1" alt="9001 icon" src={Image9001} width="50px" height="auto"/>
          <img className="mr-1" alt="14001 icon" src={Image14001} width="50px" height="auto"/>
          <img alt="27001 icon" src={Image27001} width="50px" height="auto"/>
          <img className="mr-1" alt="Blancco icon" src={BlanccoGold} width="50px" height="auto"/>
        </div>
        <div className="d-flex flex-column align-items-end">
          <img  alt="moreco logo" src={Moreco} width="60px" height="auto"/>
          <p className="f-10">{report.results.lot_number ? `Lot report ${report.results.lot_number}` : `Summary ${report.results.year}`}</p>
        </div>
      </div>
    </div>
  </div>
)

export default SectionContainer

