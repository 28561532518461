import React from "react";
import { useDispatch } from "react-redux";
import { getExcelReport } from "store/report/actions";
import { translate } from "helpers/helpers";
import { Button} from 'reactstrap'
import fileDownload from "js-file-download";

const ReportButtons = ({ exportPdf, downloadReport, exportDiploma, downloadDiploma, report }) =>
{
  const dispatch = useDispatch()

  const getExcel = () => {
    dispatch(getExcelReport( { filter: {
      customer_id : report?.results?.customer_id,
      reseller_id: report?.results?.reseller_id,
      year: report?.results?.year,
      lot_number: report?.results?.lot_number,
      start_date: report?.results?.start_date,
      end_date: report?.results?.end_date,
      excel: 'true'
    }}))
    .then(res => {
      if(res.payload) fileDownload(res.payload, `Download.xls`)
    })
  }



  return (
        <div className="mb-3 mr-2">
          <Button color="primary" className="mb-1 ml-1" onClick={getExcel}>
            <i className="mdi mdi-download-outline" style={{ color: 'white'}}></i> 
            {translate('reports.excel-full-report')}
          </Button>
          <Button color="primary" className="mb-1 ml-1" onClick={() => exportPdf('pdf', 'Report')}>
            {downloadReport ?
              <>
                {translate('preparing')} PDF... <i className="bx bx-loader bx-spin text-success ml-2 f-16"></i>
              </>
              :
              <>
                <i className="mdi mdi-download-outline" style={{ color: 'white'}}></i> 
                {translate('reports.pdf-full-report')}
              </>}
          </Button>
        </div>
  )
}

export default ReportButtons

