import React from 'react';
import { Dot } from 'react-animated-dots';


const LoaderOnPage = () => (
  <div className="ecosystem-loader text-center">
    <h1 className="text-success" style={{ height: '10px'}}>
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </h1>
  </div>
)
export default LoaderOnPage;