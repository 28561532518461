import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom"

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { inventoryAsset, clearAssetMessages } from 'store/asset/actions';
import { getById, clearModelState } from 'store/model/actions';
// Components
import { DropdownItem } from "reactstrap";
import { AssetInfo, AssetOtherDetails, AssetContractDetails, AssetSpecs, AssetPrice, AssetTitle} from "./Components/AssetCard/index";
import CardContainer from 'Containers/CardContainer'
import Page404 from '../Page404';

// Functions
import { getAssetLogColumns, translate } from 'helpers/helpers';
import { ObjectLog } from 'Components/Global/ObjectLog';

const AssetCard = ({ location, history }) => {

    const asset = useSelector(state => state.Model.asset)
    const notFound = useSelector(state => state.Model.notFound)
    const isFetching  = useSelector(state => state.Model.isFetching)
    const error = useSelector(state => state.Model.error)
    const errors = useSelector(state => state.Model.errors)
    const success = useSelector(state => state.Model.success)
    const me = useSelector(state => state.Global.me)
    const isCustomer = useSelector(state => state.Global.isCustomer)
    const assetSuccess = useSelector(state => state.Assets.success)

    const { id } = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getById(id, 'asset'))
        return () => {
            location.state = null
            dispatch(clearModelState())
            dispatch(clearAssetMessages())
          }
    },[dispatch, id, location])

    const inventory = () => {
        dispatch(inventoryAsset(id))
    }


    const Tabs =
        [
            {
                activeTab: 'first',
                translate: 'asset.details',
                component: <AssetOtherDetails asset={asset} />
            },
            {
                activeTab: 'price',
                translate: 'asset.price',
                component: <AssetPrice asset={asset} isCustomer={isCustomer} />
            },
            {
                activeTab: 'specs',
                translate: 'asset.specs',
                component: <AssetSpecs asset={asset} features={asset?.product?.features} />
            },
            {
                activeTab: 'log',
                translate: 'history',
                component: <ObjectLog id={id} objectType='asset' columns={getAssetLogColumns('asset')} />
            },
        ]

        if(asset?.contract_id) {
            Tabs.unshift(
                {
                    activeTab: 'contract',
                    translate: 'contract.contract',
                    component: <AssetContractDetails asset={asset} />
                }
            )
        }


        return (
            asset ?
                <CardContainer
                  model={'asset'}
                  can={'Asset'}
                  breadcrumbTitle={translate('asset.serial')}
                  breadcrumbItem={asset?.serial_number}
                  tabs={Tabs}
                  data={asset}
                  isFetching={isFetching}
                  notFound={notFound}
                  error={error}
                  errors={errors}
                  success={success || assetSuccess}
                  history={history}
                  location={location}
                  title={<AssetTitle asset={asset} />}
                  customButtons={
                    <DropdownItem >
                        <Link to="#" onClick={() => inventory()} className="btn btn-success  col-lg-12 mb-2">
                            {translate('asset.take-stock')}
                        </Link>
                    </DropdownItem>
                }>
                    <AssetInfo asset={asset} blanccoReport={null} isCustomer={isCustomer} me={me} />
                </CardContainer>

            :   !isFetching ?  <Page404 model={'Asset'} link={`/assets`}/> : ''
        );
    }

export default AssetCard