const CLEAR_STATE = "CLEAR_STATE"

const INIT_STATE = {
    isFetching: false,
    widget: []
};


const Stats = (state = INIT_STATE, action) => {
	switch (action.type) {
        case `GET_${action.variable}_WIDGET_REQUEST`:
            return {
                ...state,
                isFetching: true
            };
            case `GET_${action.variable}_WIDGET_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                widget: action.payload,
            };
        case `GET_${action.variable}_WIDGET_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message,
            };
        case CLEAR_STATE:
            return {
                ...state,
                isFetching: false,
                error: null,
                success: null
            };
		default:
			return state;
	}
};

export default Stats;
