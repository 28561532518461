import React from "react";
import { Row, Col } from 'reactstrap'
import { translate  } from "helpers/helpers";
import { Table, Tr, Th, Td, Tbody } from 'react-super-responsive-table';
import SectionContainer from "./SectionContainer";
import moment from 'moment'

const SectionReportInfo = ({ report, downloadReport, exportPdf, id }) => {

  var showOverview = report?.customers.length > 1
  var name = report.results.reseller_id ?
  report.reseller.name :
  report.results.customer_id ?
  report.customers[0].name : null

  return (
          <SectionContainer
            report={report}
            downloadReport={downloadReport}
            exportPdf={exportPdf}
            filename={'Overview'}
            id={id}
            title={
                 <span>
                  { report.results.year ?
                  <>
                    {translate('reports.annual')} {report.results.year}
                    <p> {name}</p>
                  </>
                  : report.results.lot_number ?
                   <>
                    {translate('reports.title-lot')}
                    {report.results.lot_number}
                   </>
                  :
                   <>
                    {translate('reports.overview')}
                    <div className="f-12">
                      {report.reseller ? report.reseller.name : null}
                    </div>
                    <ul className="ecosystem-list">
                      {report.customers.map(customer => <li key={customer.id}>{customer.name}</li>)}
                    </ul>
                    {report.results.start_date || report.results.end_date ?
                      <p className="f-10">{translate('from')} {moment(report.results.start_date).format('YYYY-MM-DD')} {translate('to')} {moment(report.results.end_date).format('YY-MM-DD')}</p>
                      : null}
                    </>
                   }
                 </span>
             }
             body={
               <Row >
                {showOverview &&
                  <Col lg={12} className="mt-3">
                      <h5 className="text-success">{translate('customer.customers')}</h5>
                    <Table className="table f-10">
                      <Tbody>
                        <Tr>
                          <Th style={{ width: '100px'}}>{translate('customer.customer')}</Th>
                          <Th>Lots</Th>
                          <Th>{translate('environmental-savings')}</Th>
                          <Th>{translate('reports.savings-comparison')}</Th>
                          <Th>{translate('reports.total-assets')}</Th>
                          <Th>{translate('reports.handled-hdd')}</Th>
                        </Tr>
                        {report?.customers.map(customer => (
                          <Tr key={customer.id}>
                            <Td>{customer.name}</Td>
                            <Td>{customer.lots.toLocaleString()}</Td>
                            <Td>{customer.co2e.toLocaleString()} kg CO2e</Td>
                            <Td>{customer.homes > 0 ? translate('reports.equal-homes', {homes : parseInt(customer.homes)}) : null}</Td>
                            <Td>{customer.assets.toLocaleString()}</Td>
                            <Td>{customer.total_handled_hard_drives.toLocaleString()}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Col>
                }
                  <Col lg={12}>
                      <h5 className="text-success">LOTs</h5>
                    <Table className="table f-10">
                     <Tbody>
                     <Tr>
                       <Th style={{ width: '100px'}}>{translate('reports.lot-number')}</Th>
                       <Th>{translate('customer.customer')}</Th>
                       <Th>{translate('reports.received')}</Th>
                       <Th>{translate('reports.finalized')}</Th>
                       <Th>{translate('reports.reports-day')}</Th>
                     </Tr>
                     {report?.lots?.map(lot => {
                      var a = moment(lot.finalized)
                      var b = moment(lot.received)
                      var diff = a.diff(b, 'days')
                       return (
                       <Tr key={lot.lot_number}>
                         <Td>{lot.lot_number}</Td>
                         <Td>{lot.customer?.name}</Td>
                         <Td>{lot.received}</Td>
                         <Td>{lot.finalized}</Td>
                         <Td>{diff}</Td>
                       </Tr>
                     )})}
                     </Tbody>
                   </Table>
                  </Col>
              </Row>}
            />
  )
}

export default SectionReportInfo


