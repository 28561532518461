import React, { useState } from 'react';

// Components
import { Nav, NavItem, NavLink, TabContent, TabPane,  Row, Col, Card, CardBody} from "reactstrap";
import classnames from 'classnames';
import { MobileCollapse } from '../Global';

const VerticalTabs = ({ Tabs }) => {

    const [ activeTab, setActiveTab ] = useState(Tabs[0].activeTab)
    const toggleTab = (tab) => {
        if(activeTab !== tab) {
            setActiveTab(tab)
            window.scrollTo(0, 0)
        }
    }

        return (
            <>
                <div className="d-block d-lg-none">
                    <MobileCollapse Tabs={Tabs}/>
                </div>
                <div className="checkout-tabs d-none d-sm-none d-md-none d-lg-block">
                    <Row>
                        <Col lg="2">
                            <Nav className="flex-column" pills>
                                {Tabs?.map((tab, key) =>  (
                                        <NavItem key={key}>
                                            <NavLink
                                                className={classnames({ active: activeTab === tab.activeTab })}
                                                onClick={() => { toggleTab(tab.activeTab) }}
                                            >
                                                <div className="mx-auto col-lg-12 text-center mt-3">
                                                    <div className="avatar-md mx-auto mb-4">
                                                        <div className="avatar-title bg-light rounded-circle text-primary h1">
                                                            <i className={`${tab.icon} d-block check-nav-icon text-success`}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="font-weight-bold mb-4 text-success">{tab.title}</p>
                                            </NavLink>
                                        </NavItem>
                                    )
                                )}
                            </Nav>
                        </Col>
                        <Col lg="10">
                            <Card>
                                <CardBody>
                                     <TabContent activeTab={activeTab}>
                                        {Tabs?.map(tab => (
                                            <TabPane tabId={tab.activeTab} className="col-lg-10">
                                                {tab.component}
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
        </>
        );
}

export default VerticalTabs