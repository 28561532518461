import React from "react"
import { Container, Row, Col } from "reactstrap"
import error from "assets/images/error-img.png";
import translate from 'helpers/Translation/translate'

const Page404 = ({ model }) =>  (
        <div className="account-pages my-5">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="text-center mb-5">
                            <h1 className="display-2 font-weight-medium">404</h1>
                            <h4>{translate(`${model}`)} {translate('not-found')}</h4>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8" xl="6">
                        <div>
                            <img src={error} alt="" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )

export default Page404