export const GET_HISTORY_FOR_ASSET_REQUEST = "GET_HISTORY_FOR_ASSET_REQUEST";
export const GET_HISTORY_FOR_ASSET_SUCCESS = "GET_HISTORY_FOR_ASSET_SUCCESS";
export const GET_HISTORY_FOR_ASSET_FAILURE = "GET_HISTORY_FOR_ASSET_FAILURE";

export const DELETE_ASSETS_SUCCESS = "DELETE_ASSETS_SUCCESS"
export const DELETE_ASSETS_REQUEST = "DELETE_ASSETS_REQUEST"
export const DELETE_ASSETS_FAILURE = "DELETE_ASSETS_FAILURE"

export const CLEAR_STATE = "CLEAR_STATE"

export const INVENTORY_ASSET_SUCCESS = "INVENTORY_ASSET_SUCCESS"
export const INVENTORY_ASSET_REQUEST = "INVENTORY_ASSET_REQUEST"
export const INVENTORY_ASSET_FAILURE = "INVENTORY_ASSET_FAILURE"

export const INVENTORY_ASSETS_SUCCESS = "INVENTORY_ASSETS_SUCCESS"
export const INVENTORY_ASSETS_REQUEST = "INVENTORY_ASSETS_REQUEST"
export const INVENTORY_ASSETS_FAILURE = "INVENTORY_ASSETS_FAILURE"

export const GET_ICECAT_DATA_REQUEST = "GET_ICECAT_DATA_REQUEST"
export const GET_ICECAT_DATA_SUCCESS = "GET_ICECAT_DATA_SUCCESS"
export const GET_ICECAT_DATA_FAILURE = "GET_ICECAT_DATA_FAILURE"
export const GET_ICECAT_DATA_NOT_FOUND = "GET_ICECAT_DATA_NOT_FOUND"
