import React from 'react';
import { Link } from "react-router-dom";
import { translate } from 'helpers/helpers'

const ImportExampleFile = () => (
        <Link to="/files/assetimport.csv" target="_blank"  download className="mb-2"> {translate('asset.download-file')} <i className="mdi mdi-file-download-outline align-middle"></i></Link>
    )

export default ImportExampleFile

