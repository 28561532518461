import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import { translate } from "helpers/helpers";

import { useAuth0 } from "@auth0/auth0-react";

const MicrosoftButton = ({ me }) => {
    const { logout } = useAuth0();
    return !!me && <Button color="light" onClick={() => logout()} >
        <i className="bx bx-power-off font-size-16 align-middle mr-1"></i>
        <span>{translate('logout')}</span>
    </Button>
}

export default MicrosoftButton