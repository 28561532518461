import React from "react";
// Redux and actions
import {  useSelector } from "react-redux";
// Functions
import { translate, getUserColumns } from 'helpers/helpers';
import { Alerts } from 'Components/Global';

import PageContainer from "Containers/PageContainer";
import { RemotePaginationTable } from "Components/Lists";


const UserList = ({ match, location }) => {

  const pagination = useSelector(state => state.Model.users)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);
  const me= useSelector((state) => state.Global.me);

    return (
      <PageContainer
        Model={'User'}
        breadcrumbTitle={translate("user.users")}
        breadcrumbItem={translate("user.list")}
        listHeaderTitle={translate("user.list")}
        data={pagination?.total ?? []}
        alerts={<Alerts success={success} error={error} location={location} />}
      >
        <RemotePaginationTable
          model={'users'}
          pagination={pagination}
          columns={getUserColumns(me.type, 'user-list')}
          createLink={'/users/create'}
          buttonText={translate("user.create")}
          pageUrl={`${process.env.REACT_APP_API_URL}/users`}
          path={match.path}
          can={'User'}
        />
    </PageContainer>
  );
}

export default UserList;