import React from "react";
// Redux and actions
import {  useSelector } from "react-redux";
// Functions
import { translate, getCustomerColumns } from 'helpers/helpers';
import { Alerts } from 'Components/Global';

import PageContainer from "Containers/PageContainer";
import { RemotePaginationTable } from "Components/Lists";


const CustomerList = ({ location, match }) => {

  const pagination = useSelector(state => state.Model.customers)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);
  const me= useSelector((state) => state.Global.me);

    return (
      <PageContainer
        Model={'User'}
        breadcrumbTitle={translate("customer.customers")}
        breadcrumbItem={translate("customer.list")}
        listHeaderTitle={translate("customer.list")}
        data={pagination?.total ?? []}
        alerts={<Alerts success={success} error={error} location={location} />}
      >
        <RemotePaginationTable
          model={'customers'}
          pagination={pagination}
          columns={getCustomerColumns(me.type, 'list')}
          createLink={'/customers/create'}
          buttonText={translate("customer.create")}
          pageUrl={`${process.env.REACT_APP_API_URL}/customers`}
          path={match.path}
          can={'Customer'}
        />
    </PageContainer>
  );
}

export default CustomerList;
