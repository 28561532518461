import React from "react";
import { translate, getAssetsOnReportColumns } from "helpers/helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SectionContainer from "./SectionContainer";
import GraphCategories from "./GraphCategories";


const SectionSpecification = ({ report, me }) =>  (

  <SectionContainer
    report={report}
    bg={'bg-white'}
    title={translate('repors.product-specification')}
    optional={report.graph_categories?.length && report.graph_units ? <GraphCategories categories={report.graph_categories} units={report.graph_units}/> : null}
    body={report.categories?.map((category, i) => (
      <div key={i} className="mb-5">
        <h5 className="text-success">{category} <span className="badge badge-pill badge-success">{report?.assets_by_category[category]?.length}</span></h5>
          <BootstrapTable
            id={`${i}_`}
            striped
            bordered={ false }
            condensed
            keyField="id"
            noDataIndication={ () => { return translate('asset.no-rows') }}
            wrapperClasses="table-responsive f-10"
            defaultSorted={[{ dataField: 'id', order: 'asc' }]}
            data={report.assets_by_category[category] ?? []}
            columns={getAssetsOnReportColumns(me.type, 'specification')}
            pagination={paginationFactory()}
            dataField={"id"}
            order={"desc"}
            />
      </div>
    ))
    }
    />

)

export default SectionSpecification


