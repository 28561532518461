import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { translate } from 'helpers/helpers';

const ApexRadial = ({ total, color, text, percent, id }) => {

    const options = {
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: "13px",
                        color: void 0,
                        offsetY: 60
                    },
                    value: {
                        offsetY: 22,
                        fontSize: "16px",
                        color: void 0,
                        formatter: function (e) {
                            return e + "%"
                        }
                    }
                }
            }
        },
        colors: [color],
        stroke: {
            dashArray: 4
        },
        labels: [text]
    }

    const [ series, setSeries ] = useState([percent])

    useEffect(()=>{
        setSeries([percent])
    },[percent])

        return (
            <>
                <ReactApexChart id={id} options={options} series={series} type="radialBar" height="143" />
                {total ? <p className="text-center mt-0">{translate('total')}: {total}</p> : null}
            </>
        );
}

export default ApexRadial;
