import React from "react";
import { translate } from 'helpers/helpers';
import Icons from 'assets/styles/Icons'

const AllAssetsCoveredSection = () => {

    return (
        <div className="text-center">
        <div className="avatar-md mx-auto mb-2">
          <span className="avatar-title rounded-circle bg-soft-success">
            <i className={`text-success f-25 ${Icons.shield}`}></i>
          </span>
        </div>
        <span>{translate('warranty.assets-covered')}</span>
       </div>
    )
}

export default AllAssetsCoveredSection