import React from "react";
import { Col } from "reactstrap";
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { translate } from "helpers/helpers";
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import ecosystemCardHeight100 from 'assets/styles/EcosystemCardHeight100'

const Section = ({ children, title, required }) => (
    <Card style={ecosystemCardHeight100}>
        <CardHeader style={ecosystemCardHeader}>
          <CardTitle>
            {title} {required && <span className="text-success f-10">{translate('required')}</span>}
          </CardTitle>
        </CardHeader>
        <Col lg={8} sm={12} className="mx-auto">
          <CardBody>
            {children}
          </CardBody>
        </Col>
    </Card>
  )

export default Section
