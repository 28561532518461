import React from "react";
import translate from '../Translation/translate';


const DataErasureColumns = [
    {
        text: 'Lot number',
        dataField: "lot_number",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.lot-number'))
        }
    },
    {
        text: 'Disk serial number',
        dataField: "disk_serial_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('reports.disk-serial-number'))
        }
    },
    {
        text: 'Manufacturer',
        dataField: "disk_manufacturer",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.manufacturer'))
        }
    },
    {
        text: 'Model',
        dataField: "disk_model",
        sort: true
        ,
        headerFormatter: () => {
            return (translate('reports.model'))
        }
    },
    {
        text: 'Category',
        dataField: "disk_category",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('asset.category'))
        }
    },
    {
        text: 'Performed security',
        dataField: "performed_security",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('reports.performed-security'))
        }
    },
    {
        text: 'Product serial number',
        dataField: "product_serial_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('reports.product-serial-number'))
        }
    },
    {
        text: 'Technician',
        dataField: "technician",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.technician'))
        }
    },
]


  export default DataErasureColumns