import React from "react";
import { useSelector} from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import { Alerts } from "Components/Global/index"
import PageContainer from 'Containers/PageContainer'
import { RemotePaginationTable } from "Components/Lists";

// Functions
import { translate, getBlanccoColumns } from "helpers/helpers";

// Images
import blanccologo from "assets/images/blancco.png";


const  BlanccoReportList  = ({ match }) => {

  const error = useSelector(state => state.Report.error)
  const success = useSelector(state => state.Report.success)
  const me = useSelector(state => state.Global.me)
  const pagination = useSelector(state => state.Model.blanccoreports)

  const history = useHistory();

    return (
      <PageContainer
        Model={"Morecoreport"}
        data={pagination?.total}
        breadcrumbTitle={'Blancco'}
        breadcrumbItem={'Blancco'}
        listHeaderTitle={
          <>
            <img src={blanccologo} alt="blancco logo" width="30px" />
            {translate("reports.blancco")}
         </>}
        alerts={<Alerts success={success} error={error} location={history?.location} />}
      >
      <RemotePaginationTable
        model={'blanccoreports'}
        pagination={pagination}
        columns={getBlanccoColumns(me?.type, 'all')}
        pageUrl={`${process.env.REACT_APP_API_URL}/moreco-reports/blancco`}
        path={match.path}
        can={'MorecoReport'}
        sort={'lot_number'}
      />
      </PageContainer>

    );
}


export default BlanccoReportList

