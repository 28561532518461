import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";

import HeaderSearch from "./Navigation/Components/HeaderSearch";
import ProfileMenu from "./Components/ProfileMenu";
import logo from "assets/images/ecosystem-green.png";
import o from "assets/images/o-light.png";

import MicrosoftButton from "Components/Global/MicrosoftButton";
import { dashboard } from "store/api";

const Header = ({ toggleMenuCallback }) =>  {

  const [ isSearch, setIsSearch ] = useState(false)
  const [ results, setResults ] = useState([])
  const me = useSelector(state => state.Global.me)

  const toggleMenu = () => {
    toggleMenuCallback()
  }

  const getInfo = (search) => {
    dashboard.get(`/search`,
    { params: { search: search }})
    .then(res => {
      setResults(res.data)
      })
  }

  const handleInputChange = (e) => {
    const search = e.target?.value
    if ((search?.length > 1) && (search?.length % 2 === 0)) getInfo(search)
    if(search?.length === 0) {
      setResults([])
    }
  }


    return (
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={o} alt="" width="100%" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" width="100%" style={{ maxWidth: '200px'}}/>
                  </span>
                </Link>
                <Link to="/" className="logo logo-light">
                  <span className="logo-sm" style={{ marginLeft: '-5px'}}>
                    <img src={o} alt="" width="35px" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" width={"100%"} style={{ maxWidth: '200px'}}/>
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                id="vertical-menu-btn"
                onClick={toggleMenu}
                data-target="#side-menu">
                <i className="fa fa-fw fa-bars"></i>
              </button>

              <div className="d-none d-lg-block ml-4">
                <HeaderSearch handleInputChange={handleInputChange} results={results} />
              </div>
            </div>

            <div className="d-flex align-items-baseline">
              <div className="dropdown d-inline-block d-lg-none">
                <button
                  onClick={() => setIsSearch(!isSearch) }
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown">
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 ${isSearch && "show"}`} aria-labelledby="page-header-search-dropdown">
                  <HeaderSearch handleInputChange={handleInputChange} results={results} />
                </div>
              </div>
              <ProfileMenu user={me}/>
              <MicrosoftButton me={me} />
            </div>
          </div>
        </header>
    );
  }


export default Header;
