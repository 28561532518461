import React from "react";
import { Row, Col, CardBody, Button, Label } from "reactstrap";
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// Functions
import { translate } from 'helpers/helpers';
import TextInputField from 'Components/Form/TextInputField';
import { AvForm } from "availity-reactstrap-validation";
import { PopUpModal } from "Components/Global";
import { getContractColumns, getProductColumns } from 'helpers/helpers';
import ContractsBody from "./ModalContents/ContractsBody";
import CustomersBody from "./ModalContents/CustomersBody";
import ProductsBody from "./ModalContents/ProductsBody";
import TagEditor from "./ModalContents/TagEditor";
import { DesktopTabs } from "Components/Global";
import { UploadFile } from "Components/Form";
import { CSVUploader } from "./CSVUploader";
import { FILE_FIELDS } from "Pages/Asset/Constants";
import { fileWithRenamesToSerialized, toFileWithRenames } from "helpers/Imports/Transformations";

const AssetImportWizard = ({ isFetching, onUpload }) => {

    const [data, setData] = React.useState({});

    const [selectedModel, setSelectedModel] = React.useState(null);

    const onItemClick = React.useCallback((row) => {
        setData((state) => {
            const newState = { ...state };
            if (selectedModel.field === 'customer') {
                delete newState.contract;
                delete newState.product;
            }
            if (!row) {
                delete newState[selectedModel.field];
            } else {
                newState[selectedModel.field] = row;
            }
            return newState;
        });
        setSelectedModel(null);
    }, [selectedModel, setData]);

    const fields = [
        {
            displayName: <span>{translate('customer.customer')} ID</span>,
            field: 'customer',
            lineFormatter (row) {
                return `${row.id} - ${row.name}`;
            },
            display ({ onItemClick }) {
                return <CustomersBody onItemClick={onItemClick} />
            }
        },
        {
            displayName: <span>{translate('contract.contract')} ID</span>,
            field: 'contract',
            columns: getContractColumns('admin', 'all'),
            lineFormatter (row) {
                return `${row.id} - ${row.title}`;
            },
            display ({ onItemClick, context }) {
                return <ContractsBody onItemClick={onItemClick} context={context} />
            },
            showField ({ context }) {
                return !!context?.customer;
            }
        },
        {
            displayName: <span>{translate('product.product')} ID</span>,
            field: 'product',
            columns: getProductColumns('ids'),
            lineFormatter (row) {
                return `${row.supplier} - ${row.supplier_code}`;
            },
            showField ({ context }) {
                return !!context?.customer;
            },
            display({ onItemClick, context }) {
                return <ProductsBody onItemClick={onItemClick} context={context} />;
            },
            toSend({ supplier, supplier_code }) {
                return { supplier, supplier_code };
            }
        }
    ]

    const handleUpload = React.useCallback(() => {
        const toSend = {};
        Object.entries(data).forEach(([key, value]) => {
            const customSend = fields.find(({ field }) => field === key)?.toSend?.(value);
            if (customSend) {
                Object.assign(toSend, customSend);
            } else if (key === 'file' || typeof value !== 'object') {
                toSend[key] = value;
            } else if (key === 'renames') {
                Object.assign(toSend, fileWithRenamesToSerialized(value));
            } else {
                const isArray = Array.isArray(value);
                toSend[isArray ? key : `${key}_id`] = isArray ? value.join(' ') : value?.id;
            }

            
        });
        onUpload(toSend);
    }, [onUpload, data]);

    const onTagsChange = React.useCallback((serialNumbers) => {
        setData((data) => {
            const newState =  { ...data };
            delete newState.file;
            delete newState.renames;
            return Object.assign(newState, { serial_numbers: serialNumbers });
        });
    }, [setData]);

    const handleFileOrRenamesChange = React.useCallback((d) => {
        const newData = toFileWithRenames(d);

        setData((data) => { 
            const newState = { ...data, ...newData };
            delete newState.serial_numbers;
            return newState;
        });
    }, [setData]);

    const tabs = React.useMemo(() => [
        {
            activeTab: 'serialNumbers',
            translate: 'I will key in my serial numbers', // TODO: Translate
            displayBadge: false,
            component: <TagEditor tags={data?.serial_numbers} onTagsChange={onTagsChange} />
        },
        {
            activeTab: 'csv',
            translate: 'I will upload a CSV', // TODO: Translate
            displayBadge: false,
            component: <CSVUploader data={data.renames} onDataChange={handleFileOrRenamesChange} labels={FILE_FIELDS} />
        }
    ], [data?.serial_numbers, setData, data?.renames]);

    const context = data;

    return(
    <CardBody>
        <AvForm>
            <Row>
                <Col lg={12} sm={12}>
                    <Table  className="table mt-3">
                        <Tbody>
                            {fields.map(({ displayName, model, field, lineFormatter, showField }, ix) => 
                                showField?.({ context }) !== false && <Tr key={model}>
                                    <Th><Label className="col-form-label">{displayName}</Label></Th>
                                    <Td>
                                        <Row>
                                            <Col lg={8}>
                                                <TextInputField
                                                    onClick={() => setSelectedModel(fields[ix])}
                                                    name={`${model}_id`}
                                                    type={"text"}
                                                    label={""}
                                                    value={data[field] ? lineFormatter(data[field]) : "It is in the CSV, or I'll leave it empty"} // TODO: Translate
                                                    required
                                                    readonly
                                                    />
                                            </Col>
                                            <Col lg={4}>
                                                <Button color="success" className="col-lg-12" onClick={() => setSelectedModel(fields[ix])}>Change</Button>
                                            </Col>
                                        </Row>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
            {data?.customer && <Row className="justify-content-center mt-1 mb-1">
                <h4>Where do you want to import the assets from?</h4>
            </Row>}
            {data?.customer && <Row>
                <Col lg={12} sm={12}>
                    <DesktopTabs Tabs={tabs} displayTab="serialNumbers" />
                </Col>
                <Col lg={12} sm={12}>
                    {isFetching ?
                        <Button className="btn-primary col-lg-12 mt-1">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            {translate('uploading')}
                        </Button> :
                        <Button className="btn-success col-lg-12 mt-1" onClick={handleUpload}>
                            {translate('upload')}
                        </Button>}
                </Col>
            </Row>}
        </AvForm>
        {selectedModel && 
        <PopUpModal
         title={selectedModel?.displayName}
         className="embedEntityModal"
         isOpen={true}
         togglemodal={() => setSelectedModel(null)}
         size='xl'
        >
            {data[selectedModel.field] && <Row>
                <Col lg={8}>
                    <AvForm>
                        <TextInputField
                            name="current"
                            type={"text"}
                            label={""}
                            value={selectedModel.lineFormatter(data[selectedModel.field])}
                            required
                            readonly
                            />
                    </AvForm>
                </Col>
                <Col lg={4}>
                    <Button color="warning" className="col-lg-12" onClick={() => onItemClick(null)}>Clear</Button>
                </Col>
            </Row>}
            {selectedModel.display({ onItemClick, context })}
        </PopUpModal>}
    </CardBody>
    )
}

export default AssetImportWizard