
export const GENERATE_REPORT_REQUEST = "GENERATE_REPORT_REQUEST"
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS"
export const GENERATE_REPORT_FAILURE = "GENERATE_REPORT_FAILURE"
export const GENERATE_REPORT_WARNING = "GENERATE_REPORT_WARNING"

export const GET_BLANCCO_REPORT_REQUEST = "GET_BLANCCO_REPORT_REQUEST"
export const GET_BLANCCO_REPORT_SUCCESS = "GET_BLANCCO_REPORT_SUCCESS"
export const GET_BLANCCO_REPORT_FAILURE = "GET_BLANCCO_REPORT_FAILURE"

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST"
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS"
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE"

export const DELETE_LOT_REPORT_REQUEST = "DELETE_LOT_REPORT_REQUEST"
export const DELETE_LOT_REPORT_SUCCESS = "DELETE_LOT_REPORT_SUCCESS"
export const DELETE_LOT_REPORT_FAILURE = "DELETE_LOT_REPORT_FAILURE"

export const DOWNLOAD_LOTREPORT_EXCEL_REQUEST = "DOWNLOAD_LOTREPORT_EXCEL_REQUEST"
export const DOWNLOAD_LOTREPORT_EXCEL_SUCCESS = "DOWNLOAD_LOTREPORT_EXCEL_SUCCESS"
export const DOWNLOAD_LOTREPORT_EXCEL_FAILURE = "DOWNLOAD_LOTREPORT_EXCEL_FAILURE"

export const DOWNLOAD_ANNUAL_REPORT_EXCEL_REQUEST = "DOWNLOAD_ANNUAL_REPORT_EXCEL_REQUEST"
export const DOWNLOAD_ANNUAL_REPORT_EXCEL_SUCCESS = "DOWNLOAD_ANNUAL_REPORT_EXCEL_SUCCESS"
export const DOWNLOAD_ANNUAL_REPORT_EXCEL_FAILURE = "DOWNLOAD_ANNUAL_REPORT_EXCEL_FAILURE"

export const NOTIFY_EMAIL_REQUEST = "NOTIFY_EMAIL_REQUEST"
export const NOTIFY_EMAIL_SUCCESS = "NOTIFY_EMAIL_SUCCESS"
export const NOTIFY_EMAIL_FAILURE = "NOTIFY_EMAIL_FAILURE"

export const GET_ASSET_WITH_REPORT_REQUEST = "GET_ASSET_WITH_REPORT_REQUEST"
export const GET_ASSET_WITH_REPORT_SUCCESS = "GET_ASSET_WITH_REPORT_SUCCESS"
export const GET_ASSET_WITH_REPORT_FAILURE = "GET_ASSET_WITH_REPORT_FAILURE"

export const GET_DISK_WITH_REPORT_REQUEST = "GET_DISK_WITH_REPORT_REQUEST"
export const GET_DISK_WITH_REPORT_SUCCESS = "GET_DISK_WITH_REPORT_SUCCESS"
export const GET_DISK_WITH_REPORT_FAILURE = "GET_DISK_WITH_REPORT_FAILURE"

export const  NO_REPORT = "NO_REPORT"

export const CLEAR_STATE = "CLEAR_STATE"
