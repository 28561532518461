import React from "react";
import { Card, CardTitle, CardBody, Col, Row, CardHeader } from "reactstrap";
import { translate } from 'helpers/helpers';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import ecosystemCardHeight100 from 'assets/styles/EcosystemCardHeight100'

const InfoCard = ({ title, total, children }) => {

    return(
      <Card style={ecosystemCardHeight100}>
        <CardHeader style={ecosystemCardHeader}>
            <CardTitle >
                <Row>
                    <Col xs={9}>
                    {title}
                    </Col>
                    <Col xs={3}>
                    {total ?
                    <span className="badge badge-pill bg-light f-14 float-right">
                        {translate('total')} {total}
                    </span> : null}
                    </Col>
                </Row>
            </CardTitle>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
      </Card>
    )
}

export default InfoCard 