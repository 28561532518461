import { translate } from 'helpers/helpers'

const RESET_DEMO_REQUEST = "RESET_DEMO_REQUEST"
const RESET_DEMO_SUCCESS = "RESET_DEMO_SUCCESS"
const RESET_DEMO_FAILURE = "RESET_DEMO_FAILURE"

const GET_BILLING_REQUEST = "GET_BILLING_REQUEST"
const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS"
const GET_BILLING_FAILURE = "GET_BILLING_FAILURE"

const SYNC_ITGLUE_REQUEST = "SYNC_ITGLUE_REQUEST"
const SYNC_ITGLUE_SUCCESS = "SYNC_ITGLUE_SUCCESS"
const SYNC_ITGLUE_FAILURE = "SYNC_ITGLUE_FAILURE"

const SYNC_ICECAT_REQUEST = "SYNC_ICECAT_REQUEST"
const SYNC_ICECAT_SUCCESS = "SYNC_ICECAT_SUCCESS"
const SYNC_ICECAT_FAILURE = "SYNC_ICECAT_FAILURE"

const MONTHLY_PDF_REQUEST = "MONTHLY_PDF_REQUEST"
const MONTHLY_PDF_SUCCESS = "MONTHLY_PDF_SUCCESS"
const MONTHLY_PDF_FAILURE = "MONTHLY_PDF_FAILURE"

const CLEAR_STATE = "CLEAR_STATE"

const GET_DEVICES_REQUEST = "GET_DEVICES_REQUEST"
const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
const GET_DEVICES_FAILURE = "GET_DEVICES_FAILURE"


const INIT_STATE = {
    isResetting: false,
    success: undefined,
    error: false,
	billingInfo: [],
	devices: [],
	isFetching: false,
	isFetchingIcecat: false,
	report: undefined,
	isFetchingReport: false,
	successIcecat: null

 };

const Tool = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CLEAR_STATE:
			return {
				...state,
				error: null,
				success: null,
				successIcecat: null
			};
		case RESET_DEMO_REQUEST:
			return {
				...state,
				isResetting: true
			};
    	case RESET_DEMO_SUCCESS:
			return {
				...state,
				isResetting: false,
                success: action.message
			};
        case RESET_DEMO_FAILURE:
			return {
				...state,
				isResetting: false,
                error: action.message
			};
	    case GET_BILLING_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_BILLING_SUCCESS:
			return {
                ...state,
                isFetching: false,
				billingInfo: action.payload
            };
        case GET_BILLING_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
		case SYNC_ITGLUE_REQUEST:
			return {
			  ...state,
			  isFetching: true,
			};
		case SYNC_ITGLUE_SUCCESS:
			return {
			  ...state,
			  isFetching: false,
			  success: translate(`${SYNC_ITGLUE_SUCCESS}`),
			};
		case SYNC_ITGLUE_FAILURE:
			return {
			  ...state,
			  isFetching: false,
			  error: action.message
			};
		case SYNC_ICECAT_REQUEST:
			return {
			  ...state,
			  isFetchingIcecat: true,
			};
		  case SYNC_ICECAT_SUCCESS:
			return {
			  ...state,
			  isFetchingIcecat: false,
			  successIcecat: action.message,
			};
		  case SYNC_ICECAT_FAILURE:
			return {
			  ...state,
			  isFetchingIcecat: false,
			  errorIcecat: action.message
			};
		case MONTHLY_PDF_SUCCESS:
			return {
			  ...state,
			  success: action.message,
			  report: action.payload
			};
		case GET_DEVICES_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_DEVICES_SUCCESS:
			return {
                ...state,
                isFetching: false,
				devices: action.payload
            };
        case GET_DEVICES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
		default:
			return state;
	}
};

export default Tool;
