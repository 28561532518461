import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Redux
import { getById, uploadImage, clearModelState, getWarrantyInfoFor } from "store/model/actions";
// Components
import { Row, Col } from "reactstrap";
import { NavLinkBadge } from "Components/Global/index";
import TableList from "Components/Lists/TableList";
import { UploadFile } from "Components/Form";
import CardContainer from 'Containers/CardContainer'
import AssetsTable from 'Pages/Asset/Components/AssetList/Table/AssetsTable'
import CustomerInfo from 'Pages/Customer/Components/CustomerInfo'
import WarrantyOverview from "Pages/Dashboard/Components/Warranty/WarrantyOverview";
import CalculatingWarranty from "Pages/Dashboard/Components/Warranty/CalculatingWarranty";

// Functions
import { translate, getContractColumns, getUserColumns, getLotReportListColumns } from "helpers/helpers";


const CustomerCard  = ({ history }) => {

  const me = useSelector(state => state.Global.me)
  const notFound = useSelector(state => state.Model.notFound)
  const success = useSelector(state => state.Model.success)
  const error = useSelector(state => state.Model.error)
  const errors = useSelector(state => state.Model.errors)
  const warrantyInfo = useSelector(state => state.Model.warrantyInfo)

  const [ data, setData] = useState({})
  const { id } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();


  useEffect(() => {
      dispatch(getById(id, 'customer')).then((res) => {
        if(res.payload) {
            dispatch(getWarrantyInfoFor(id, 'customer'))
            setData(res.payload)
        }
    })
  }, [id])


  const uploadLogo = (files) => {
    dispatch(clearModelState())
    if(files.length) dispatch(uploadImage(id, files[0], 'customer'))
  }

    const Tabs = [
          {
            activeTab: 'first',
            translate: 'dashboard.warranty-opportunity',
            component: !warrantyInfo ? <CalculatingWarranty /> : <WarrantyOverview onCustomer warrantySummary={warrantyInfo.warrantySummary} warrantyOverview={warrantyInfo.warrantyOverview} customer_id={data?.id}/>
          },
          {
            activeTab: 'assets',
            translate: 'Assets',
            badge: data?.total_assets ? <NavLinkBadge color={'badge-primary'} total={data?.total_assets?.toLocaleString()} /> : null,
            component: data?.id ?
              <AssetsTable predefinedFilter={{ customer_id: data?.id }} /> : null
          },
          {
            activeTab: 'users',
            translate: 'user.users',
            badge: data?.users?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.users?.length?.toLocaleString()} /> : null,
            component:
            <TableList
              id={'customer-users'}
              data={data?.users ?? []}
              title={translate("user.users")}
              columns={getUserColumns(me?.type, 'on-customer')}
              dataField={'name'}
              />
          },
          {
            activeTab: 'contracts',
            translate: 'contract.contracts',
            badge: data?.contracts?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.contracts?.length?.toLocaleString()} /> : null,
            component:
            <TableList
              id={'customer-contracts'}
              data={data?.contracts ?? []}
              columns={getContractColumns(me?.type, 'all')}
              dataField={'end_date'}
              />
          },
          {
            activeTab: 'reports',
            translate: 'reports.reports',
            badge: data?.project_lots?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.project_lots?.length?.toLocaleString()} /> : null,
            component:
            <TableList
              id={'customer-creports'}
              data={data?.project_lots ?? []}
              columns={getLotReportListColumns(me.type, 'card')}
              dataField={'end_date'}
              keyField={'lot_number'}
              />
          }
      ]


    return (
          <CardContainer
            model={'customer'}
            can={'Customer'}
            breadcrumbTitle={translate(`customer.customers`)}
            breadcrumbItem={translate(`customer.card`)}
            tabs={Tabs}
            data={data}
            image={data.logo ? <img alt="product" src={data.logo} height="auto" width="300px"/> : null}
            history={history}
            notFound={notFound}
            error={error} errors={errors} success={success} location={location}
          >
            <Row>
              <Col lg={data.logo ? 12 : 8}>
                <CustomerInfo customer={data}/>
              </Col>
              {!data.logo &&
              <Col lg={4}>
                <UploadFile getFiles={uploadLogo} title={translate('upload-logo')} />
              </Col>}
            </Row>
          </CardContainer>
    )
  }


export default CustomerCard;