export function getCorruptedHeaders(errors, headers) {
    if (!errors || !headers) {
        return [];
    }
    const errorHeaders = Object.entries(errors).reduce((acc, [idx, error]) => {
        Object.entries(error).forEach(([field, rules]) => {
            acc.add(field);
            rules.forEach((ruleAndDeps) => {
                const [rule, deps] = ruleAndDeps.split('|');
                if (rule.startsWith('required_without')) {
                    deps
                    .replace(new RegExp(`${idx}.`, 'g'), '')
                    .split('/')
                    .forEach((entry) => acc.add(entry.trim()));
                }
            });
        });
        return acc;
    }, new Set());
    return [...errorHeaders].map((h) => h.replace(/\s+/g, '_'));
}

export function toHeaderText(header) {
    return header.split(/_|\s+/g).map((word) => word[0].toLocaleUpperCase() + word.slice(1)).join(' ');
}