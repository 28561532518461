
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
// Components
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SwitchLanguage } from 'Components/Global';
// Functions
import { translate } from "helpers/helpers";
import { Icons } from 'assets/styles'


const ProfileMenu = ({ user }) => {

    const [ menu, setMenu ] = useState(false)

        return (
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" >
                    <DropdownToggle
                        className="btn header-item waves-effect"
                        id="page-header-user-dropdown"
                        tag="button">
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                            <i className={`mdi ${Icons.user} f-24 text-success`}></i>
                            <i className="mdi mdi-chevron-down d-xl-inline-block"></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu dropdown-menu-lg mb-5" >
                        <span className="float-right mr-2">
                            <SwitchLanguage/>
                        </span>
                        <DropdownItem className="d-flex align-items-baseline mt-3">
                            <h5>{user?.name}</h5>
                        </DropdownItem>
                        <hr />
                        <DropdownItem tag="a" className="d-flex align-items-baseline mt-3" href={`/users/me`}>
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                            {translate('user.profile')}
                        </DropdownItem>
                        <DropdownItem tag="a" className="d-flex align-items-baseline" href="/get-started">
                            <i className="mdi mdi-creation font-size-17 align-middle mr-1"></i>
                            {translate('start.title')}
                        </DropdownItem>
                        <DropdownItem tag="a" className="d-flex align-items-baseline" href="/FAQ">
                            <i className="mdi mdi-cloud-question font-size-17 align-middle mr-1"></i>
                            FAQ
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
        );
    }

export default withRouter(ProfileMenu)

