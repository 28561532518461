import React , {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "helpers/Translation/translate";
import { Button, Alert } from "reactstrap";
import { syncIcecat, clearState } from 'store/tool/actions';


const SyncIcecatProducts = ()=> {

    const dispatch = useDispatch();

    const error  = useSelector(state => state.Tool.errorIcecat)
    const success  = useSelector(state => state.Tool.successIcecat)
    const isFetching  = useSelector(state => state.Tool.isFetchingIcecat)

    const syncProductsWithIcecat = () => {
        dispatch(clearState())
        dispatch(syncIcecat())
    }

    useEffect(() => {
        return () => {
          dispatch(clearState())
        }
      },[])

    return (
          <span>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
                {isFetching ?
                  <Button className="btn-success col-lg-12">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {translate('product.syncing-with-it-glue')}
                  </Button> :
                  <Button onClick={syncProductsWithIcecat} className="btn btn-success  col-lg-12">{translate('product.synchronize')} Icecat</Button>}
          </span>
    );
}

export default SyncIcecatProducts;
