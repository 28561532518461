import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button, UncontrolledTooltip } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Can, translate } from 'helpers/helpers';
import LoaderInsideComponent from "../Global/LoaderInsideComponent";


const { SearchBar } = Search;

const TableList = ({
  showCSVButton,
  download,
  downloadCSV,
  // handleOnSelect,
  // handleOnSelectAll,
  // selectedIDs,
  id,
  data,
  columns,
  dataField,
  order,
  model,
  create_link,
  button_text,
  keyField,
}) => (
  <ToolkitProvider
    keyField={keyField ?? "id"}
    data={data}
    columns={columns}
    search
    exportCSV
    columnToggle
  >
    {(props) => (
      <>
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            {model && create_link && (
              <Can I="create" a={model}>
                <Link to={create_link}>
                  <Button color="success" className=" mb-2 mr-2">
                    {button_text}
                  </Button>
                </Link>
              </Can>
            )}
            {showCSVButton && (
              <>
                <button
                  id="csv"
                  className="btn btn-light mr-1 button-shaddow btn-sm"
                  onClick={downloadCSV}
                >
                  {download ? <LoaderInsideComponent /> : (
                    <>
                      <i className="mdi mdi-download "></i> CSV
                    </>
                  )}
                </button>
                <UncontrolledTooltip placement="top" target="csv">
                  {translate("csv-download")}
                </UncontrolledTooltip>
              </>
            )}
          </Col>
          <Col lg={3} className="mb-2">
            <SearchBar {...props.searchProps} className="custom-search-field" />
          </Col>
        </Row>
        <BootstrapTable
          id={id}
          striped
          bordered={false}
          hover
          condensed
          noDataIndication={() => {
            return translate("asset.no-rows");
          }}
          defaultSorted={[
            {
              dataField: dataField ?? 'id',
              order: order ?? 'asc',
            },
          ]}
          wrapperClasses="table-responsive"
          pagination={paginationFactory()}
          {...props.baseProps}
        />
      </>
    )}
  </ToolkitProvider>
);


export default (TableList);


