import React from "react";
import moment from 'moment'
import { FormattedMessage }  from 'react-intl';

const displayWarranty = ( asset ) => {

    const warranty_check = moment()
    const expires_at = asset?.warranty_expires_at && moment(asset.warranty_expires_at)
    const warranty_end = asset?.warranty_end && moment(asset.warranty_end)
    const lookup = asset?.warranty_lookup

        switch(lookup) {
            case 'unavailable': return (asset.warranty_end ?
            <>
              {warranty_check > new Date(asset.warranty_end) ?
                <span className="text-danger"> {asset.warranty_end}</span>
                : asset.warranty_end}
            </>
            :<FormattedMessage id={'lookup.unavailable'}/>)

            case 'pending': return <FormattedMessage id={'lookup.pending'}/>
            
            case 'completed': return (

                expires_at && ( expires_at < warranty_check ) ?
                  <span className="text-danger">
                      {asset.warranty_expires_at}
                        <span className="ml-1"><strong><FormattedMessage id={'asset.warranty-overdue'}/></strong></span>
                  </span>
                : expires_at && ( expires_at > warranty_check ) ? asset.warranty_expires_at 
                : warranty_end && ( warranty_end < warranty_check ) ?
                    <span className="text-danger">
                        {asset.warranty_expires_at}
                      <span className="ml-1"><strong><FormattedMessage id={'asset.warranty-overdue'}/></strong></span>
                    </span>
                : asset.warranty_end
            )
            default : return <FormattedMessage id={'no-info'}/>
        }

}

export default displayWarranty
