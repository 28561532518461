import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from '../Context';

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
		<Route
			{...rest}
			render={props => (
					<Layout>
						<UserContext.Consumer>
							{(me, isAdmin, isCustomer, isReseller)  =>
								<Component
									{...props}
									me={me}
									isAdmin={isAdmin}
									isCustomer={isCustomer}
									isReseller={isReseller}
									/>}
						</UserContext.Consumer>
					</Layout>
				)
			}
		/>
	);

export default AppRoute;

