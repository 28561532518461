import React from "react";
import moment from 'moment'
import { FormattedMessage }  from 'react-intl';
import Can from '../Can/Can'

const calculateAge = asset => {

    const today = moment()
    var years = null
    var months = null

    // Rented assets connected to Contract Use contract date.
    // Rented assets not connected to contract and purchased assets 1. Purchase date from ItGlue. 2. Delivery date
    if(asset.contract_id) {

            const contract_start = moment(asset.contract?.start_date)
            years = today.diff(contract_start, 'years')
            contract_start.add(years, 'years')

            months = today.diff(contract_start, 'months')
            contract_start.add(months, 'months')

            return (
                <>
                     <FormattedMessage id={'years'} values={{years: years}}/>  <FormattedMessage id={'months'} values={{values: months}}/>
                </>
            )

        } else {

            if (asset?.purchased_at ||  asset.delivery_date ) {

                const purchased = moment(asset?.purchased_at ?? asset.delivery_date)

                years = today.diff(purchased, 'years')
                purchased.add(years, 'years')
                months = today.diff(purchased, 'months')
                purchased.add(months, 'months')

                return (
                    <>
                        <FormattedMessage id={'years'} values={{years: years}}/>  <FormattedMessage id={'months'} values={{values: months}}/>
                    </>
                )
            } else { return <>
                <FormattedMessage id={'asset.no-delivery-date'}/>
                <Can I="update" a="Asset">
                    {() => (
                        <a href={`/assets/${asset.id}/edit`}>
                            <i className="mdi mdi-square-edit-outline f-20 ml-1"></i>
                        </a>
                    )}
                </Can>
                </>}
        }

}

export default calculateAge
