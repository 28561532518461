import React from "react";
import {Row, Col} from "reactstrap";
import translate from 'helpers/Translation/translate';


const ResellerInfo = ({ reseller }) => (
        <Row>
          <Col lg={6}>
            <p><strong className="mr-1">{translate('reseller.org-no')}</strong>: {reseller.org_no} </p>
            <p><strong className="mr-1">{translate('reseller.comments')}</strong>: {reseller.comment}</p>
            <p><strong className="mr-1">Ecosystem ID</strong>: {reseller.id} </p>
          </Col>
          <Col lg={6}>
            <p><strong className="mr-1">{translate('address.address')}</strong>: {reseller.address}</p>
            <p><strong className="mr-1">{translate('address.zip')}</strong>: {reseller.zip} </p>
            <p><strong className="mr-1">{translate('address.city')}</strong>: {reseller.city}</p>
            <p><strong className="mr-1">{translate('address.email')}</strong>:  <a href={`mailto:${reseller.email}`}>{reseller.email}</a></p>
            <p><strong className="mr-1">{translate('address.phone')}</strong>: <a href={`tel:${reseller.telephone}`}>{reseller.telephone}</a></p>
          </Col>
        </Row>
  )

export default ResellerInfo;