import React from "react";
// Components
import { Row, Col, CardBody, CardTitle, Label, Button, CardHeader } from "reactstrap";
import { AvForm, AvGroup, AvField, AvInput } from "availity-reactstrap-validation";
import { OptionSelect, UcLookupSection, ContactSection, TextInputField } from 'Components/Form/index';
// Functions
import { Can, translate}  from 'helpers/helpers';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'


const CreateResellerOrCustomer = ({
  resellers,
  data,
  editMode,
  handleValidSubmit,
  allaForetagFetch,
  handleChange,
  removeLogo,
  model,
  options,
  selectThis,
}) => (

    <AvForm onValidSubmit={handleValidSubmit}>
      <Row>
        <Col lg={12}>
          {editMode && (
            <CardHeader style={ecosystemCardHeader}>
              <CardTitle>
                <a href={`/${model}s/${data?.id}`}>
                  <h2 className="text-primary underline">{data?.name}</h2>
                </a>
              </CardTitle>
              {data?.logo ? (
                <Row>
                  <Col lg={9}></Col>
                  <Col lg={3}>
                    <img src={data?.logo} alt="logo" className="img-fluid" />
                    <Button
                      onClick={removeLogo}
                      className="btn-sm btn btn-danger float-right mt-1"
                    >
                      {translate("remove-image")}
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </CardHeader>
          )}
          <CardBody>
            <UcLookupSection
              model={data}
              handleChange={handleChange}
              allaForetagFetch={allaForetagFetch}
            />
            <Row className="mb-4">
              {options?.map((option) => {
                return (
                  <Col lg={12} className="mb-2">
                    <AvGroup
                      check
                      onClick={() => {
                        selectThis(option);
                      }}
                    >
                      <AvInput
                        className="mt-1"
                        type="checkbox"
                        name="option"
                        checked={
                          data?.org_no === option.org_no ? true : false
                        }
                      />
                      <Label className="mt-1" check for="option">
                        <strong>{translate("reseller.name")}</strong> :{" "}
                        {option.name} <br />
                        <strong>{translate("reseller.org-no")}</strong> :
                        {option.org_no}
                      </Label>
                    </AvGroup>
                  </Col>
                );
              })}
            </Row>
            {model === "customer" && (
              <>
                {data?.margins?.map((margin) => (
                  <AvGroup className="mb-4" row>
                    <Label htmlFor="margin" className="col-form-label col-lg-3">
                      {translate("customer.margin")} %
                    </Label>
                    <Col lg={9}>
                      <TextInputField
                        onChange={(e) => handleChange(e)}
                        errorMessage={"form.enter-margin"}
                        type={"text"}
                        name={"margin"}
                        value={margin.margin ?? ""}
                        placeholder={"form.enter-margin"}
                      />
                    </Col>
                  </AvGroup>
                ))}

                {!(data?.margins?.length > 0) ? (
                  <AvGroup className="mb-4" row>
                    <Label htmlFor="margin" className="col-form-label col-lg-3">
                      {translate("customer.margin")} %
                    </Label>
                    <Col lg={9}>
                      <TextInputField
                        onChange={(e) => handleChange(e)}
                        errorMessage={"form.enter-margin"}
                        type={"text"}
                        name={"margin"}
                        value={data?.margin ?? ""}
                        placeholder={"form.enter-margin"}
                      />
                    </Col>
                  </AvGroup>
                ) : null}
              </>
            )}

            {model === "customer" && resellers ? (
              <Can I="read" a="Reseller">
                {() => (
                  <AvGroup className="mb-4" row>
                    <Label
                      htmlFor="reseller_id"
                      className="col-form-label col-lg-3"
                    >
                      {translate("form.connect-reseller")}
                    </Label>
                    <Col lg={9}>
                      <AvField
                        type="select"
                        name="reseller_id"
                        value={data?.reseller_id ?? ""}
                        onChange={(e) => handleChange(e)}
                        className="form-control select2"
                      >
                        <OptionSelect message={"form.select-reseller"} />
                        {resellers.map((reseller) => {
                          return (
                            <option key={reseller.id} value={reseller.id}>
                              {reseller.name}
                            </option>
                          );
                        })}
                      </AvField>
                    </Col>
                  </AvGroup>
                )}
              </Can>
            ) : null}
            <AvGroup className="mb-4" row>
              <Label htmlFor="comment" className="col-form-label col-lg-3">
                {translate("form.comments")}
              </Label>
              <Col lg={9}>
                <TextInputField
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-comment"}
                  type={"text"}
                  name={"comment"}
                  value={data?.comment ?? ""}
                  placeholder={"form.enter-comment"}
                />
              </Col>
            </AvGroup>
          </CardBody>
        </Col>
        <Col lg={12}>
          <CardHeader style={ecosystemCardHeader}>
            <CardTitle> {translate("form.contact")} </CardTitle>
          </CardHeader>
          <CardBody>
            <ContactSection model={data} handleChange={handleChange} />
          </CardBody>
          <CardBody className="d-flex">
            <Button type="submit" color="success" className="col-lg-4  mx-auto">
              {editMode ? translate(`${model}.update`) : translate(`${model}.create`)}
            </Button>
          </CardBody>
        </Col>
      </Row>
    </AvForm>
);



export default CreateResellerOrCustomer;