import React from 'react';
import { Col } from 'reactstrap'

const NonAuthLayout = ({ children }) => (

        <React.Fragment>
            {children}
            <footer className="footer-non-auth">
                <Col lg={12}>
                  <div className="text-sm-right d-sm-block">
                    {new Date().getFullYear()} © Ecosystem Software.
                  </div>
                </Col>
            </footer>
        </React.Fragment>
)

export default NonAuthLayout;