
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE"

export const GET_PREDIFINED_PERMISSIONS_REQUEST = "GET_PREDIFINED_PERMISSIONS_REQUEST"
export const GET_PREDIFINED_PERMISSIONS_SUCCESS = "GET_PREDIFINED_PERMISSIONS_SUCCESS"
export const GET_PREDIFINED_PERMISSIONS_FAILURE = "GET_PREDIFINED_PERMISSIONS_FAILURE"

export const CLEAR_STATE = "CLEAR_STATE"

export const TWOFA_SETUP_REQUEST = "TWOFA_SETUP_REQUEST"
export const TWOFA_SETUP_SUCCESS = "TWOFA_SETUP_SUCCESS"
export const TWOFA_SETUP_FAILURE = "TWOFA_SETUP_FAILURE"

export const TWOFA_ENABLE_REQUEST = "TWOFA_ENABLE_REQUEST"
export const TWOFA_ENABLE_SUCCESS = "TWOFA_ENABLE_SUCCESS"
export const TWOFA_ENABLE_FAILURE = "TWOFA_ENABLE_FAILURE"

export const TWOFA_DISABLE_REQUEST = "TWOFA_DISABLE_REQUEST"
export const TWOFA_DISABLE_SUCCESS = "TWOFA_DISABLE_SUCCESS"
export const TWOFA_DISABLE_FAILURE = "TWOFA_DISABLE_FAILURE"

export const GENERATE_API_TOKEN_REQUEST = "GENERATE_API_TOKEN_REQUEST"
export const GENERATE_API_TOKEN_SUCCESS = "GENERATE_API_TOKEN_SUCCESS"
export const GENERATE_API_TOKEN_FAILURE = "GENERATE_API_TOKEN_FAILURE"

export const GET_API_TOKEN_REQUEST = "GET_API_TOKEN_REQUEST"
export const GET_API_TOKEN_SUCCESS = "GET_API_TOKEN_SUCCESS"
export const GET_API_TOKEN_FAILURE = "GET_API_TOKEN_FAILURE"

export const DELETE_API_TOKEN_REQUEST = "DELETE_API_TOKEN_REQUEST"
export const DELETE_API_TOKEN_SUCCESS = "DELETE_API_TOKEN_SUCCESS"
export const DELETE_API_TOKEN_FAILURE = "DELETE_API_TOKEN_FAILURE"

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE"

export const HANDLE_KEY_ACCOUNT_MANAGER_REQUEST = "HANDLE_KEY_ACCOUNT_MANAGER_REQUEST"
export const HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS = "HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS"
export const HANDLE_KEY_ACCOUNT_MANAGER_FAILURE = "HANDLE_KEY_ACCOUNT_MANAGER_FAILURE"
export const CREATE_KEY_ACCOUNT_MANAGER_SUCCESS = "CREATE_KEY_ACCOUNT_MANAGER_SUCCESS"

export const DELETE_KEY_ACCOUNT_MANAGER_REQUEST = "DELETE_KEY_ACCOUNT_MANAGER_REQUEST"
export const DELETE_KEY_ACCOUNT_MANAGER_SUCCESS = "DELETE_KEY_ACCOUNT_MANAGER_SUCCESS"
export const DELETE_KEY_ACCOUNT_MANAGER_FAILURE = "DELETE_KEY_ACCOUNT_MANAGER_FAILURE"

export const GET_KEY_ACCOUNT_MANAGERS_REQUEST = "GET_KEY_ACCOUNT_MANAGERS_REQUEST"
export const GET_KEY_ACCOUNT_MANAGERS_SUCCESS = "GET_KEY_ACCOUNT_MANAGERS_SUCCESS"
export const GET_KEY_ACCOUNT_MANAGERS_FAILURE = "GET_KEY_ACCOUNT_MANAGERS_FAILURE"

export const DONT_SHOW_TOUR_REQUEST = "DONT_SHOW_TOUR_REQUEST"
export const DONT_SHOW_TOUR_SUCCESS = "DONT_SHOW_TOUR_SUCCESS"
export const DONT_SHOW_TOUR_FAILURE = "DONT_SHOW_TOUR_FAILURE"

export const NOTIFY_USER_ABOUT_REPORT_REQUEST = "NOTIFY_USER_ABOUT_REPORT_REQUEST"
export const NOTIFY_USER_ABOUT_REPORT_SUCCESS = "NOTIFY_USER_ABOUT_REPORT_SUCCESS"
export const NOTIFY_USER_ABOUT_REPORT_FAILURE = "NOTIFY_USER_ABOUT_REPORT_FAILURE"
