import React from 'react';
import { Link } from "react-router-dom";
import SwitchLanguage from "./SwitchLanguage";
import { translate} from "helpers/helpers";
import { Container } from 'reactstrap';

const GdprLanguage = ({ login }) => (

    <Container>
        <div className="mt-3 d-flex justify-content-between col-12" style={{ position: "absolute", top: "0px", right: 0, zIndex: 10}}>
            {login ? <Link to="/gdpr" className="text-muted"><i className="mdi mdi-shield-check mr-1"></i>  GDPR</Link>
            : <Link to="/login" className="text-muted"><i className="mdi mdi-arrow-left mr-1"></i>  {translate('login.back')}</Link> }
            <SwitchLanguage />
        </div>
    </Container>
    )

export default GdprLanguage