import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { AvField } from "availity-reactstrap-validation";
import OptionSelect from './OptionSelect'


class TranslatedSelect extends Component {
  render() {
   const { formatMessage } = this.props.intl;
   const  { name, error, onChange, required, options, optionMessage, value, children} = this.props
   return(
          <AvField
            name={name}
            type="select"
            required={required ?? false}
            className="form-control select2"
            value={value}
            onChange={onChange}
            error={error ? formatMessage({ id: error }) : ''}
            >
            <OptionSelect message={optionMessage} value={''}/>
              {options?.map(option => {
                return <option key={option.id} value={option.id}>{option.name}</option>
              })}
            {children}
          </AvField>
   )
  }
}

 export default injectIntl(TranslatedSelect);