import React from "react";
import { CardTitle, Media } from "reactstrap";


const VerticalTabsInfo = ({ content }) => (
    <>
        <CardTitle className="mb-5 f-20">
            {content?.title}
             <hr />
            {content?.buttons}
        </CardTitle>
        {content?.media?.map((media, key) => (
            <div key={key}>
                <Media className="faq-box mb-4">
                    <div className="faq-icon mr-3">
                        <i className="bx bxs-hand-right font-size-20 text-muted"></i>
                    </div>
                    <Media body>
                        <h5 className="font-size-15">{media.subtitle}</h5>
                        <p className="text-muted">{media.text}</p>
                        <p className="text-muted">{media.text2}</p>
                    </Media>
                </Media>
                {media.component ?
                <Media className="faq-box mb-4">
                    <Media body className="p-5">
                        {media.component}
                    </Media>
                </Media>
                : null}
            </div>
        ))}
    </>
)

export default VerticalTabsInfo