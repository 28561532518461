import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "Pages/Authentication/Login";

import ResellerCustomerCreate from "Pages/reseller-customer-create";

// Reseller
import ResellerList from "Pages/Reseller/reseller-list";
import ResellerCard from "Pages/Reseller/reseller-card";

// Customer
import CustomerList from "Pages/Customer/customer-list";
import KeyAccountManagerList from "Pages/User/key-account-manager-list";
import CreateKeyAccountManager from "Pages/User/key-account-manager-create";
import CustomerCard from "Pages/Customer/customer-card";

// User
import UserProfile from "Pages/User/user-profile";
import UserList from "Pages/User/user-list";
import CreateUser from "Pages/User/user-create-update";

// Contract
import ContractList from "Pages/Contract/contract-list";
import ContractCreateUpdate from "Pages/Contract/contract-create-update";
import Contract from "Pages/Contract/contract-card";
import HandleAssetsOnContract from "Pages/Contract/contract-asset-handle"

// Asset
import AssetList from "Pages/Asset/asset-list";
import AssetCreateUpdate from "Pages/Asset/asset-create-update";
import AssetImport from "Pages/Asset/asset-import";
import AssetCard from "Pages/Asset/asset-card";
import AssetStatuses from "Pages/Asset/asset-statuses";

// Products
import ProductList from "Pages/Product/product-list";
import ProductCard from "Pages/Product/product-card";
import ProductForm  from "Pages/Product/product-form";

// Dashboard
import Dashboard from "Pages/Dashboard/dashboard"
import GetStarted from "Pages/GetStarted/GetStarted"

// Tools
import Blancco from "Pages/Report/blancco-report-list";
import Billing from "Pages/Billing/Billing";
import Azure from "Pages/Azure/list-devices";

import IcecatCategoriesList from "Pages/Product/icecat-categories-list";

import HandleEMV from "Pages/Product/handle-emv";
import ProductEMVImport from "Pages/Product/product-emv-import";

import PagesFaqs from "Pages/FAQ/PagesFaq";
import Gdpr from 'Pages/Gdpr/Gdpr'

// Reports
import CreateLotReport from "Pages/Report/lot-report-create";
import LotReportList from "Pages/Report/lot-report-list";
import Report from "Pages/Report/report";
import BlanccoPDF from "Pages/Report/blancco-report";
import AssetHistory from "Pages/Asset/asset-history";
import ContractHistory from "Pages/Contract/contract-history";

const authProtectedRoutes = [
	{ path: "/assets/:id(\\d+)/edit", component: AssetCreateUpdate},
	{ path: "/assets/:id(\\d+)", component: AssetCard },
	{ path: "/assets/create", component: AssetCreateUpdate },
	{ path: "/assets/import", component: AssetImport },
	{ path: "/assets/status", component: AssetStatuses },
	{ path: "/assets/history", component: AssetHistory },
	{ path: "/assets/warranty-expired", component: AssetList },
	{ path: "/assets/warranty-due-soon", component: AssetList },
	{ path: "/assets", component: AssetList },

	{ path: "/products/:id(\\d+)/edit", component: ProductForm },
	{ path: "/products/:id(\\d+)", component: ProductCard },
	{ path: "/products/emv/import", component: ProductEMVImport },
	{ path: "/products/emv", component: HandleEMV },
	{ path: "/products/categories", component: IcecatCategoriesList },
	// { path: "/products/create", component: ProductForm },
	{ path: "/products", component: ProductList },

	{ path: "/customers/:id(\\d+)/edit", component: ResellerCustomerCreate},
	{ path: "/customers/:id(\\d+)", component: CustomerCard},
	{ path: "/customers/create", component: ResellerCustomerCreate},
	{ path: "/customers", component: CustomerList },

	{ path: "/contracts/:id(\\d+)/edit", component: ContractCreateUpdate},
	{ path: "/contracts/:id(\\d+)/create-substart", component: ContractCreateUpdate},
	{ path: "/contracts/:id(\\d+)/handle-assets", component: HandleAssetsOnContract },
	{ path: "/contracts/:id(\\d+)", component: Contract },
	{ path: "/contracts/create", component: ContractCreateUpdate},
	{ path: "/contracts/history", component: ContractHistory },
	{ path: "/contracts", component: ContractList },

	{ path: "/resellers/:id(\\d+)/edit", component: ResellerCustomerCreate},
	{ path: "/resellers/:id(\\d+)", component: ResellerCard },
	{ path: "/resellers/create", component: ResellerCustomerCreate},
	{ path: "/resellers", component: ResellerList },

	{ path: "/users/:id(\\d+)/key-account-manager/edit", component: CreateKeyAccountManager},
	{ path: "/users/:id(\\d+)/edit", component: CreateUser },
	{ path: "/users/:id(\\d+)", component: UserProfile},
	{ path: "/users/me", component: UserProfile},
	{ path: "/users/create", component: CreateUser },
	{ path: "/users/key-account-managers/create", component: CreateKeyAccountManager},
	{ path: "/users/key-account-managers", component: KeyAccountManagerList},
	{ path: "/users", component: UserList },

	// Tools
	{ path: "/billing", component: Billing },
	{ path: "/azure", component: Azure },

	// Reports
	{ path: "/moreco-reports/blancco/asset/:id", component: BlanccoPDF },
	{ path: "/moreco-reports/blancco/disk/:id", component: BlanccoPDF },
	{ path: "/moreco-reports/blancco", component: Blancco },
	{ path: "/moreco-reports/lots/:id/update", component: CreateLotReport },
	{ path: "/moreco-reports/lots/:id", component: Report },
	{ path: "/moreco-reports/lots", component: LotReportList },
	{ path: "/moreco-reports/generate", component: CreateLotReport },
	{ path: "/moreco-reports/search", component: Report },

	{ path: "/dashboard", component: Dashboard },
	{ path: "/get-started", component: GetStarted },
	{ path: "/faq", component: PagesFaqs },

	{ path: "/release-notes", exact: true, component: () => <Redirect to="/dashboard/release-notes" /> },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/login", component: Login },
	{ path: "/gdpr", component: Gdpr },
];

export { authProtectedRoutes, publicRoutes };
