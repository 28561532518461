import React from 'react';
import { Row } from 'reactstrap';
import { OptionSelect } from 'Components/Form/index'
import { PopUpModal } from "Components/Global";
import { translate } from 'helpers/helpers';

const AssetListModal = ({
  selectedIDs,
  modal,
  contracts,
  setContractId,
  togglemodal,
  display,
  removeAssets,
  deleteSelectedAssets,
  inventory,
  addAssets,
  moveAssets,
  success,
  error,
  errors,
}) => {

  const getTitle = () => {
    switch (display) {
      case "remove":
        return translate("button.assets-remove", {
          assets: selectedIDs.length,
        });
      case "delete":
        return translate("button.delete-assets", {
          assets: selectedIDs.length,
        });
      case "inventory":
        return translate("button.inventory-assets", {
          assets: selectedIDs.length,
        });
      case "add":
        return translate("button.add-assets", { assets: selectedIDs.length });
      case "move":
        return translate("button.move-assets", { assets: selectedIDs.length });
      default:
        return null;
    }
  };

  const getAction = () => {
    switch (display) {
      case "remove":
        return removeAssets;
      case "delete":
        return deleteSelectedAssets;
      case "inventory":
        return inventory;
      case "add":
        return addAssets;
      case "move":
        return moveAssets;
      default:
        return null;
    }
  };

  const getBody = () => {
    switch (display) {
      case "remove":
        return translate("button.assets-remove-text");
      case "delete":
        return translate("button.assets-delete-text");
      case "inventory":
        return translate("button.assets-inventory-text");
      case "add":
        return translate("button.add-assets-text");
      case "move":
        return (
          <Row>
            <select
              className="form-control select2 col-lg-6 mt-2"
              onChange={(e) => setContractId(e.target.value)}
            >
              <OptionSelect message={"form.contracts"} value="" />
              {contracts?.map((contract) => {
                  return (
                    <option key={contract.id} value={contract.id}>
                      ID {contract.id}: {contract.title}
                    </option>
                  );
                })}
            </select>
            <p className="col-lg-6 mt-2">
              {translate("button.move-assets-text", {
                assets: selectedIDs.length,
              })}
            </p>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <PopUpModal
      isOpen={modal}
      togglemodal={togglemodal}
      error={error}
      errors={errors}
      success={success}
      title={getTitle()}
      action={getAction()}
      buttonText={getTitle()}
    >
      {getBody()}
    </PopUpModal>
  );
};

export default AssetListModal