import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Table, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Can, translate  } from 'helpers/helpers';

const AssetContractDetails = ({ asset }) => (

    <Row>
        <Col sm="12">
                <div className="table-responsive">
                    <Table className="table">
                        <Tbody>
                            <Can I="read" a="Contract">
                                {() => (
                                    <Tr>
                                        <Th scope="row">{translate('contract.contract')}</Th>
                                        <Td>{asset.contract_id &&
                                            <Link to={`/contracts/${asset.contract_id}`}>{asset.contract?.title} <i className="mdi mdi-arrow-right"></i>
                                            </Link>}
                                        </Td>
                                    </Tr>
                                )}
                            </Can>
                            <Tr>
                                <Th scope="row" width={'220px'}>{translate('asset.cost-center')}</Th>
                                <Td>{asset?.cost_center}</Td>
                            </Tr>
                            <Tr>
                                <Th scope="row">{translate('asset.installation-address')}</Th>
                                <Td>{asset?.installation_address}</Td>
                            </Tr>
                            <Tr>
                                <Th scope="row">{translate('asset.delivery-date')}</Th>
                                <Td>{asset?.delivery_date}</Td>
                            </Tr>
                            <Tr>
                                <Th scope="row">{translate('asset.delivery-address')}</Th>
                                <Td>{asset?.delivery_address}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </div>
        </Col>
    </Row>
    )

export default AssetContractDetails;
