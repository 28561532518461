import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ContractDates from "./ContractDates";

import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

// Functions
import  { Can, translate } from 'helpers/helpers';
import moment from "moment";

const ConTractDetails = ({ contract, isCustomer }) => (

        <>
          <Row>
            <Col lg={8}>
            </Col>
            <Col lg={4}>
              <ContractDates contract={contract} />
            </Col>
            {contract?.description ?
              <Col lg={6}>
                <strong>{translate("contract.description")}:</strong> {contract?.description}
              </Col>
            : null}
            {contract?.comment ?
            <Col lg={6} className="mt-3">
              <strong>{translate("contract.comment")}:</strong> {contract?.comment}
            </Col>
            : null}
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <Table className="table">
                <Tbody>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.title")}</strong>
                    </Th>
                    <Td>
                      <span className="">{contract?.title}</span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.agreement-no")}</strong>
                    </Th>
                    <Td>
                      {contract?.type === 'substart' ?
                        <Link to={`/contracts/${contract?.parent_id}`}>{contract?.agreement_no}</Link>
                      : <span className="">{contract?.agreement_no}</span>
                      }
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("supplier.supplier")}</strong>
                    </Th>
                    <Td>
                      <span className="">{contract?.supplier}</span>
                    </Td>
                  </Tr>
                  <Can I="read" a="Customer">
                    {() => (
                    <Tr>
                      <Th scope="row">
                        <strong className="mr-2">{translate("customer.customer")}</strong>
                      </Th>
                      <Td>
                      <Link to={`/customers/${contract?.customer?.id ?? null}`} >
                        {contract?.customer?.name ?? null}
                      </Link>
                      </Td>
                    </Tr>
                    )}
                  </Can>
                      <Tr>
                        <Th scope="row">
                          <strong className="mr-2">{translate("reseller.reseller")} </strong>
                        </Th>
                        <Td>
                          <Can I="read" a="Reseller">
                            {() => (
                              <Link to={`/resellers/${contract?.reseller?.id ?? null}`} >
                                {contract?.reseller?.name ?? null}
                              </Link>
                            )}
                          </Can>
                          <Can not  I="read" a="Reseller">
                            {() => ( <span className="">{contract?.reseller?.name ?? null}</span> )}
                          </Can>
                        </Td>
                      </Tr>
                  <Can not I="read" a="Customer">
                      {() => (
                      <Tr>
                        <Th scope="row">
                          <strong className="mr-2">{translate("contract.supplier")} </strong>
                        </Th>
                        <Td>
                          <span className="">
                            {contract?.supplier}
                          </span>
                        </Td>
                      </Tr>
                      )}
                  </Can>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.by")}</strong>
                    </Th>
                    <Td>
                      <span className="">
                        {contract?.user?.name} - {moment(contract?.created_at).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.type")}</strong>
                    </Th>
                    <Td>
                    <span className="">
                      {translate(`${contract?.type}`)}
                    </span>
                    </Td>
                  </Tr>
                  </Tbody>
              </Table>
            </Col>
            <Col lg={6}>
              <Table className="table">
                <Tbody>
                  {!isCustomer &&
                  <>
                  <Tr>
                      <Th scope="row">
                      <strong className="mr-2">{translate("contract.bank")}</strong>
                      </Th>
                    <Td>
                      <span className="">
                        {contract?.financier}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                    <strong className="mr-2">{translate("contract.special-conditions")}</strong>
                    </Th>
                    <Td>
                      <span className="">
                        {contract?.special_conditions}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.object-value")}</strong>
                    </Th>
                      <Td>
                        <span className="">
                          { contract?.object_value?.toLocaleString() + ' kr' ?? null}
                          </span>
                      </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.residual-value")}</strong>
                    </Th>
                    <Td>
                      <span className="">
                        {contract?.residual_value?.toLocaleString() + " kr" ?? null}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.residual-value-guarantor")}</strong>
                    </Th>
                    <Td>
                      <span className="">{contract?.residual_value_guarantor}</span>
                    </Td>
                  </Tr>
                  </>
                    }
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.monthly-cost")}</strong>
                    </Th>
                    <Td>
                    <span className="">
                      {contract?.monthly_cost?.toLocaleString() + ' kr' ?? null}
                      {/* TODO - Show sum_monthly_cost if not the same as monthly_cost. */}
                    </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th scope="row">
                      <strong className="mr-2">{translate("contract.payment-period")}</strong>
                    </Th>
                    <Td>
                    <span className="">
                      {contract?.payment_period && translate(`${contract?.payment_period}`)}
                    </span>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Col>
          </Row>
        </>
  )

export default ConTractDetails;
