import React, { useEffect, useState } from 'react';
import {  useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
// Redux and actions
import { fetchInfoFromAllaForetag, getResellerDropdown } from 'store/form/actions'
import { getById, updateModel, createModel, deleteImage, clearModelState } from "store/model/actions";
import { CreateResellerOrCustomer } from "Components/Form";
import CreateContainer from "Containers/CreateContainer";
// Functions
import { translate } from 'helpers/helpers';


const ResellerCustomerCreate = ({ match }) => {

  const [ data, setData] = useState({})
  const [ model, setModel] = useState("")
  const [ editMode, setEditMode ] = useState(false)
  const [ options, setOptions ] = useState(null)
  const [ can, setCan ] = useState(null)

  const isFetching = useSelector(state =>  state.Model.isFetching)
  const error = useSelector(state =>  state.Model.error)
  const errors = useSelector(state =>  state.Model.errors)
  const success = useSelector(state =>  state.Model.success)
  const ucError = useSelector(state =>  state.Form.error)
  const resellers = useSelector(state =>  state.Form.resellersDropdown)

  const id = match.params.id
  const url = match.path
  let history = useHistory();
	const dispatch = useDispatch();

  const handleChange = e => {
    data[e.target.name] = e.target.value
    setData(data)
  }

  useEffect(() => {

    if(url.indexOf('reseller') > 0) {
      setModel('reseller')
      setCan('Reseller')
      if(url.indexOf('edit') > 0) {
        dispatch(getById(id, 'reseller')).then(res => {
          setEditMode(true)
          setData(res.payload)
        })
      }
    }
    if(url.indexOf('customer') > 0) {
      setModel('customer')
      setCan('Customer')
      dispatch(getResellerDropdown())
      if(url.indexOf('edit') > 0) {
        dispatch(getById(id, 'customer')).then(res => {
          setEditMode(true)
          setData(res.payload)
        })
      }
    }
    return () => {
      dispatch(clearModelState())
    }
  },[])


  const handleValidSubmit = (event, values) => {
    editMode ? dispatch(updateModel(model, data)).then(res => {
        if(res.payload) setData(res.payload)
      })
      :
        dispatch(createModel(model, data))
        .then(res => {
          if(res.id) {
            history.push(`/${model}s/${res.id}`,  `message.create-${model}-success`)
          }
      })
    }

  const allaForetagFetch = (type) => {

      dispatch(clearModelState())
      let request
      if(type === 'name') request = {name : data.name}
      else request = {orgno: data.org_no}

      dispatch(fetchInfoFromAllaForetag(request)).then(res => {
        const options = res.payload
        if(Array.isArray(options)) {
            setOptions(options)
          }
        else if (res?.payload?.name){
          setData(res.payload)
        }
      })
  }

  const removeLogo = () => {
    const id = data.id

    dispatch(deleteImage(id, model)).then(() => {
      dispatch(getById(id, model)).then(res => {
        if(res?.payload) {
          setData(res?.payload)
        }
      })
    })
  }

  const selectThis = option => {
    setData(option)
  }

  return (
    <CreateContainer
        Model={can}
        isFetching={isFetching}
        breadcrumbTitle={translate(`${model}.${model}s`)}
        breadcrumbItem={editMode ? translate(`${model}.edit`) : translate(`${model}.create`)}
        editMode={editMode}
        success={success}
        error={error || ucError}
        errors={errors}
      >
        <CreateResellerOrCustomer
          removeLogo={removeLogo}
          editMode={editMode}
          handleValidSubmit={handleValidSubmit}
          handleChange={handleChange}
          allaForetagFetch={allaForetagFetch}
          model={model}
          data={data}
          options={options}
          resellers={resellers}
          selectThis={selectThis}
          />
    </CreateContainer>
  );
}


export default withRouter(ResellerCustomerCreate)