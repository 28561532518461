import React from "react";
import { FormGroup, Label, Col } from 'reactstrap'
import translate from "helpers/Translation/translate";

const ContractStatus = ({ contract, setActive }) => (

  <FormGroup className="mt-4" row>
    <Label htmlFor="active" className="col-form-label col-lg-3" >
        Status
      </Label>
      <Col lg={9}>
        <div className="custom-control custom-checkbox mt-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="active"
            checked={contract?.active}
            readOnly
          />
          <label
            className="custom-control-label"
            onClick={() => setActive(!contract.active)}
          > {contract?.active ?
            <span className="badge f-14 badge-pill badge-success">{translate('active')}</span> :
            <span className="badge f-14 badge-pill badge-danger">{translate('inactive')}</span>}
            </label>
        </div>
      </Col>
  </FormGroup>
)

export default ContractStatus