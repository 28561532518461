import React,  { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
// Redux and actions
import { getWidgets } from 'store/dashboard/actions'
// Components
import { Row, Col, Card, CardBody } from "reactstrap";
import AssetsTable from '../Asset/Components/AssetList/Table/AssetsTable';
import WarrantyCardCategory from "./Components/Warranty/WarrantyCardCategory";
// Styles
import ListHeader from "Components/Lists/ListHeader";
import CalculatingWarranty from "./Components/Warranty/CalculatingWarranty";

const CustomerDashboard  = () => {

  const warrantyOverview = useSelector(state => state.Dashboard.warrantyOverview)
  const isFetching = useSelector(state => state.Dashboard.isFetching)
  const me = useSelector(state => state.Global.me)
  const [ total , setTotal ] = useState(0)
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWidgets())
  },[])

  return (
    <Col lg={12}>
      {isFetching ?  <CalculatingWarranty />  : null}
      <Row>
          {warrantyOverview?.map((category, key) => {
            return(
              <Col xl={4} md={6} key={key}>
                <WarrantyCardCategory title={category.total.title} warranty={category} customer_id={me.customer_id}/>
              </Col>
            )
          })}
      <Col lg={12}>
        <Card>
          <CardBody>
            <ListHeader title={'Assets'} data={total}/>
            <AssetsTable getTotal={total => setTotal(total)} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Col>
  );
}

export default CustomerDashboard;

