import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const ContractLogColumns = [
    {
        text: 'Time',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD HH:mm'))
        },
        headerFormatter: () => {
            return (translate('asset.time'))
        }
    },
    {
        text: 'ID',
        dataField: "contract_id",
        sort: true,
        formatter: (cell, row) => {
            return (
                ( !row.contract?.deleted_at ? <a href={`/contracts/${cell}`}> {cell} </a> : <i className="text-muted">{cell}</i>)
                )
        },
        headerFormatter: () => {
            return (translate('contract.id'))
        }
    },
    {
        text: 'Contract',
        dataField: "contract.title",
        sort: true,
        formatter: (cell, row) => {
            return (row.contract?.deleted_at ?<i className="text-muted">{ cell}</i> : translate('contract.contract'))
        }
    },
    {
        text: 'Description',
        dataField: "comment",
        sort: true,
        headerFormatter: () => {
            return (translate('asset.description'))
        }
    }
]


const getContractLogColumns = (page) => {

    var notAvailableFor = {
        contract: {},
        history: {}
    };


    notAvailableFor['contract'] = ['contract.id']
    notAvailableFor['history'] = ['']



    var filteredColumns = page ? ContractLogColumns.filter(d => !notAvailableFor[page].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getContractLogColumns