import React, { useEffect, useState  } from "react";
import { Row, Col, Button, Form, Input } from 'reactstrap'
import { translate  } from "helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { PopUpModal } from "Components/Global/index"
import { notifyLotReport, deleteLotReport  } from "store/report/actions";
import { useHistory } from "react-router-dom";


const SectionModal = ({ lot_number, error, success, deleteModal, isOpen, setIsOpen, customer_emails, reseller_emails }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const isMailing = useSelector(state => state.Report.isMailing)
  const [emails, setEmails] = useState([])

  useEffect(() => {
    let newemails = [...emails];
    const users = customer_emails?.concat(reseller_emails)
      users?.map((user, i) => {
        newemails[i] = user.email
      })
      setEmails(newemails);
  },[reseller_emails, customer_emails])

  const handleChange = (i, e) => {
    let newemails = [...emails];
    newemails[i] = e.target.value;
    setEmails(newemails);
  }

  const addFormFields = () => {
      setEmails([...emails, ''])
   }

  const removeFormFields = (i) => {
      let newemails = [...emails];
      newemails.splice(i, 1);
      setEmails(newemails)
  }

  const sendEmails = () => {
    dispatch(notifyLotReport({lot: lot_number, emails: emails}))
  }

  const removeAddressesOnClose = () => {
    setIsOpen(!isOpen)
    setEmails([])
  }

  const deleteTheReport = () => {
    dispatch(deleteLotReport(lot_number))
    .then(res => {
      if(res?.type === `DELETE_LOT_REPORT_SUCCESS`) history.push(`/moreco-reports/lots`, `message.delete-report-success`)
      setIsOpen(false)
    },[])
  }

return (
  <PopUpModal
    error={error}
    success={success}
    isOpen={isOpen}
    togglemodal={removeAddressesOnClose}
    buttonText={deleteModal ? translate('reports.delete') : translate('send-email')}
    action={deleteModal ? deleteTheReport : sendEmails}
    title={deleteModal ? translate('reports.delete') : 'Maila ansvariga att rapport finns tillgänglig i systemet'}
    >
  {deleteModal ?
    <Col lg={12}>{translate('reports.delete-text')}</Col>
    :
    <Form>
      {emails?.map((email, index) => (
        <div className="d-flex col-lg-10 p-1" key={index}>
          <Input className="col-12 mr-2" type="text" name="email" value={email} onChange={e => handleChange(index, e)} />
          {<Button className="btn btn-danger btn-small" onClick={() => removeFormFields(index)}>{translate('remove')}</Button>}
        </div>
      ))}
        <Button className="btn btn-info ml-1" onClick={() => addFormFields()}>{translate('add-address')}</Button>
        <Row>
          <Col lg={12} className="text-center">
              {isMailing ?
              <> {translate('sending')}
              <i className="bx bx-loader bx-spin text-success ml-2 f-16"></i>
              </> : null}
          </Col>
        </Row>
    </Form>}
  </PopUpModal>
  )
}

export default SectionModal


