import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Redux and actions
import { getAll, clearModelState } from "store/model/actions";
import {
  getCustomerDropdown,
  getResellerDropdown,
  getCategories,
  getCustomersForThisReseller,
  getSupplierDropdown,
  getStatusesForThisReseller,
  getStatusesForThisCustomer,
} from "store/form/actions";

// Components
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { OptionSelect, TextAreaField } from "Components/Form/index";

// Functions
import { Can, translate } from "helpers/helpers";

const AssetFilters = ({ onRented, onChange, filter, clearFilter }) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state.Global.isAdmin);
  const isCustomer = useSelector((state) => state.Global.isCustomer);
  const isReseller = useSelector((state) => state.Global.isReseller);
  const statuses = useSelector((state) => state.Model.status);
  const customersDropdown = useSelector((state) => state.Form.customersDropdown);
  const resellersDropdown = useSelector((state) => state.Form.resellersDropdown);
  const categories = useSelector((state) => state.Form.categories);
  const customerStatuses = useSelector((state) => state.Form.customerStatuses);
  const resellerStatuses = useSelector((state) => state.Form.resellerStatuses);
  const suppliersDropdown = useSelector((state) => state.Form.suppliersDropdown);

  useEffect(() => {
    if (isAdmin) dispatch(getResellerDropdown());
    if (!isCustomer) dispatch(getCustomerDropdown());

    dispatch(getSupplierDropdown());
    dispatch(getCategories({ products: true }));
    if (!isAdmin) dispatch(getAll("status"));
    return () => {
      dispatch(clearModelState());
    };
  }, [isAdmin, isCustomer]);

  useEffect(() => {
    if (filter.reseller_id) {
      dispatch(getCustomersForThisReseller(filter.reseller_id));
      dispatch(getStatusesForThisReseller(filter.reseller_id));
    }
  }, [filter.reseller_id]);

  useEffect(() => {
    if (filter.customer_id) {
      dispatch(getStatusesForThisCustomer(filter.customer_id));
    }
  }, [filter.customer_id]);

  return (
      <Row className="mb-5">
        <Col lg={12}>
          <FormGroup>
            <button
              className="btn btn-light btn-sm"
              onClick={() => clearFilter()}
            >
              {translate("clear-filters")}
            </button>
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("asset.multi-search")}
            </Label>
            <TextAreaField
              name="serial_numbers"
              className="form-control"
              rows="1"
              value={filter.serial_numbers}
              placeholder={"asset.multi-placeholder"}
              getChange={onChange}
            />
          </FormGroup>
        </Col>
        {!isCustomer ? (
          <Col lg={3}>
            <Can I="read" a="Customer">
              {() => (
                <FormGroup>
                  <Label className="control-label text-muted">
                    {translate("customer.customer")}
                  </Label>
                  <select
                    className="form-control minimal"
                    name="customer_id"
                    onChange={onChange}
                    value={filter?.customer_id}
                  >
                    <OptionSelect message={"include-all"} value={""} />
                    {customersDropdown?.map((customer) => {
                      return (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              )}
            </Can>
            <Can I="read" a="Reseller">
              {() =>
                !filter?.customer_id && (
                  <FormGroup>
                    <Label className="control-label text-muted">
                      {translate("reseller.reseller")}
                    </Label>
                    <select
                      className="form-control select2"
                      name="reseller_id"
                      onChange={onChange}
                      value={filter?.reseller_id}
                    >
                      <OptionSelect message={"include-all"} value={""} />
                      {resellersDropdown?.map((reseller) => {
                        return (
                          <option key={reseller.id} value={reseller.id}>
                            {reseller.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                )
              }
            </Can>
          </Col>
        ) : null}

        <Col lg={3}>
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("form.categories")}
            </Label>
            <select
              className="form-control select2"
              name="product_category"
              onChange={onChange}
              value={filter?.product_category}
            >
              <OptionSelect message={"include-all"} value={""} />
              {categories?.map((category) => {
                return (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                );
              })}
            </select>
          </FormGroup>
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("product.supplier")}
            </Label>
            <select
              name="product_supplier"
              className="form-control select2"
              onChange={onChange}
              value={filter?.product_supplier}
            >
              <OptionSelect message={"include-all"} value={""} />
              {suppliersDropdown?.map((supplier, i) => {
                return (
                  <option key={i} value={supplier}>
                    {supplier}
                  </option>
                );
              })}
            </select>
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("asset.warranty")}
            </Label>
            <select
              name="warranty"
              className="form-control select2"
              onChange={onChange}
              value={filter?.warranty}
            >
              <OptionSelect message={"include-all"} value={""} />
              <OptionSelect value={"inside"} message={"dashboard.reseller-inside"} />
              <OptionSelect value={"expired"} message={"dashboard.reseller-outside-warranty"}/>
              <OptionSelect value={"not_available"} message={"dashboard.reseller-unknown"} />
              <OptionSelect value={"six_months"} message={"dashboard.reseller-expire-6"} />
            </select>
          </FormGroup>
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("asset.eol")}
            </Label>
            <select
              name="eol_months"
              className="form-control select2"
              onChange={onChange}
              value={filter?.eol_months}
            >
              <OptionSelect message={"include-all"} value={""} />
              <OptionSelect value={3} message={"months"} values={3} />
              <OptionSelect value={6} message={"months"} values={6} />
              <OptionSelect value={9} message={"months"} values={9} />
              <OptionSelect value={12} message={"months"} values={12} />
              <OptionSelect value={24} message={"months"} values={24} />
            </select>
            <FormGroup check className="mt-1">
              <Label>
                <Input
                  type="checkbox"
                  checked={filter.include_expired}
                  name="include_expired"
                  onChange={onChange}
                />
                <span className="badge badge-pill badge-danger">
                  {translate("expired")} Assets
                </span>
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col lg={3}>
          {!onRented && (
            <FormGroup>
              <Label className="control-label text-muted">
                {translate("asset.type")}
              </Label>
              <select
                name="type"
                className="form-control select2"
                onChange={onChange}
                value={filter?.type}
              >
                <OptionSelect message={"include-all"} value={""} />
                <OptionSelect value={"purchased"} message={"purchased"} />
                <OptionSelect value={"rented"} message={"rented"} />
              </select>
            </FormGroup>
          )}
          <FormGroup>
            <Label className="control-label text-muted">
              {translate("asset.in-use")}
            </Label>
            <select
              name="in_use"
              className="form-control select2"
              onChange={onChange}
              value={filter?.in_use}
            >
              <OptionSelect message={"include-all"} value={""} />
              <OptionSelect value={1} message={"yes"} />
              <OptionSelect value={0} message={"no"} />
            </select>
          </FormGroup>
          <Can not I="read" a="Customer">
            {() => (
              <FormGroup>
                <Label className="control-label text-muted">Status</Label>
                <select
                  name="status_customer"
                  className="form-control select2"
                  onChange={onChange}
                  value={filter?.status_customer}
                >
                  {isCustomer && filter?.customer_id && (
                    <OptionSelect message={"include-all"} value={""} />
                  )}
                  {isCustomer && (
                    <OptionSelect message={"include-all"} value={""} />
                  )}
                  {statuses?.map((status) => {
                    return (
                      <option key={status.id} value={status.id}>
                        {status.status}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            )}
          </Can>
          <Can I="read" a="Customer">
            {() =>
              customerStatuses?.length ? (
                <FormGroup>
                  <Label className="control-label text-muted">
                    {translate("customer.customer")} Status
                  </Label>
                  <select
                    className="form-control minimal"
                    name="status_customer"
                    onChange={onChange}
                    value={filter?.status_customer}
                  >
                    <OptionSelect message={"include-all"} value={""} />
                    {customerStatuses?.map((status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.status}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              ) : null
            }
          </Can>
          {isReseller ? (
            <FormGroup>
              <Label className="control-label text-muted"> Status</Label>
              <select
                name="status_reseller"
                className="form-control select2"
                onChange={onChange}
                value={filter?.status_reseller}
              >
                <OptionSelect message={"include-all"} value={""} />
                {statuses?.map((status) => {
                  return (
                    <option key={status.id} value={status.id}>
                      {status.status}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          ) : null}
          <Can I="read" a="Reseller">
            {() => (
              <>
                {!filter?.customer && (
                    resellersDropdown?.length > 0 && resellerStatuses && (
                      <FormGroup>
                        <Label className="control-label text-muted">
                          {translate("reseller.reseller")} Status
                        </Label>
                        <select
                          name="onChange"
                          className="form-control select2"
                          onChange={onChange}
                          value={filter?.status_reseller}
                        >
                          {!filter?.reseller_id && (
                            <OptionSelect
                              message={"form.select-reseller"}
                              value={""}
                            />
                          )}
                          {filter?.reseller_id && (
                            <OptionSelect message={"include-all"} value={""} />
                          )}
                          {resellerStatuses?.map((status) => {
                            return (
                              <option key={status.id} value={status.id}>
                                {status.status}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                    )
                )}
                {statuses?.length ? (
                  <FormGroup>
                    <Label className="control-label text-muted">
                      Admin Status
                    </Label>
                    <select
                      name="status_admin"
                      className="form-control select2"
                      onChange={onChange}
                      value={filter?.status_admin}
                    >
                      {<OptionSelect message={"include-all"} value={""} />}
                      {statuses?.map((status) => {
                        return (
                          <option key={status.id} value={status.id}>
                            {status.status}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                ) : null}
              </>
            )}
          </Can>
        </Col>
      </Row>
  );
};

export default AssetFilters;
