import React, { useEffect, useState} from 'react';
import { withRouter, useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getCustomersMultiSelect, getpredfinedCustomers } from "store/form/actions";
import { handleKeyAccountManager, deleteKeyAccountManager } from "store/user/actions";
import { users } from 'store/api'
import { getAll, getById } from "store/model/actions";

// Components
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  CardTitle,
  CardHeader,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Breadcrumbs from 'Components/Global/Breadcrumb';
import Select from 'react-select'

// Functions
import { Can, translate } from 'helpers/helpers';

// Styles
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import { clearState } from 'store/asset/actions';
import Page403 from '../Page403';
import { OptionSelect } from 'Components/Form';



const  HandleKeyAccountManager = props => {
        const id = props.match.params.id

        const success = useSelector(state => state.Users.success)
        const error = useSelector(state => state.Users.error)
        const user = useSelector(state => state.Model.user)
        const customers = useSelector(state => state.Form.customersMultiselect)
        const me = useSelector(state => state.Global.me)

        const [selected, setSelected] = useState([]);
        const [editMode, setEditMode] = useState(false);
        const [modal, setModal] = useState(false);
        const [reseller, setReseller] = useState(id);
        const [users, setUsers] = useState([]);

        const dispatch = useDispatch()
        let history = useHistory();

        useEffect(() => {
            if(props.match.path === '/users/:id(\\d+)/key-account-manager/edit') {
                setEditMode(true)
                dispatch(getById(id, 'user', users ))
                .then(res => {
                    if(res?.payload?.key_account_manager === false) {
                        history.push('/users/key-account-managers')
                    }
                })
                dispatch(getpredfinedCustomers(id))
                .then(res => { setSelected(res.customers) })
            }
            dispatch(getCustomersMultiSelect())
            dispatch(getAll('users', users ))
            .then(res => {
                if (res?.payload) {
                    const userOptions = res?.payload?.data?.filter(user => {
                        return ((user.key_account_manager === false) && (user.role === "partner" || user.role === "partner_admin")) && (me.id !== user.id)
                    })
                    setUsers(userOptions)
                }
            })
            return () => dispatch(clearState())
          }, [dispatch, setEditMode, history, id, props.match.path])


        const handleManager = () => {
            const customers = []
            selected && selected.map(id => { return customers.push(id.value) })
            if (!selected) {
                setModal(true)
            } else {
                dispatch(handleKeyAccountManager(reseller, customers))
                .then(res => {
                    if(res.type !== 'HANDLE_KEY_ACCOUNT_MANAGER_FAILURE') {
                        history.push( '/users/key-account-managers', 'success')
                    }
                })
            }
        }

        const handleManagerWithoutCustomers = () => {
            setModal(false)
            dispatch(deleteKeyAccountManager(reseller))
            .then(() => {
                history.push('/users/key-account-managers', 'delete-success')

            })
        }

        return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={translate("user.users")} breadcrumbItem={editMode ? translate("user.handle-kam") : translate("user.create-kam")} />
                    <Can I="create" a="Key">
                        {() => (
                            <>
                            {success && <UncontrolledAlert color="success"> {success}</UncontrolledAlert>}
                            {error && <UncontrolledAlert color="danger"> {error}</UncontrolledAlert>}
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardHeader style={ecosystemCardHeader}>
                                            <CardTitle>
                                                {editMode ? translate("user.handle-kam") : translate("user.create-kam")}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            {me.role === 'admin' ? 'Denna sida är ej tillgänglig för Admin ännu'
                                        :
                                        <Container className="col-lg-8">
                                            <FormGroup row>
                                                <Label className="col-3">Key Account Manager</Label>
                                                {editMode ? <Col>{user?.name}</Col>
                                                :
                                                <Col lg={9}>
                                                    {users.filter(user => user.id !== me.id).length > 0 ? 
                                                    <select
                                                        style={{ width: "100%"}}
                                                        value={reseller}
                                                        onChange={(e) => setReseller(e.target.value)}>
                                                        <OptionSelect  message={"form.select-user"}/>
                                                        {users.map(user => (<option key={user?.id} value={user?.id}>{user?.name}</option>))}
                                                    </select>
                                                    : translate('user.no-user-for-kam')}
                                                </Col>
                                                }
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="col-3">{translate('customer.customers')}</Label>
                                                <Col lg={9}>
                                                    <Select
                                                        options={customers}
                                                        value={selected}
                                                        onChange={setSelected}
                                                        isMulti
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <Col lg={12} className="mt-5">
                                                <Row style={{ justifyContent: 'flex-end' }}>
                                                    <Button color="success" className="mr-2 " onClick={handleManager} >
                                                        {editMode ? translate('user.update-kam') : translate('user.create-kam')}
                                                    </Button>
                                                    {editMode &&
                                                    <Button color="danger" className="" onClick={() => setModal(true)} >
                                                        {translate('user.delete-kam')}
                                                    </Button>}
                                                </Row>
                                            </Col>
                                            </Container>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" toggle={() => setModal(!modal)}>
                            <div className="modal-content">
                                <ModalHeader>{translate('user.no-selected-customers')}</ModalHeader >
                                    <ModalBody>{translate('user.no-selected-customers-text')}</ModalBody>
                                    <ModalFooter>
                                        <Col lg={8} >
                                            <Button color="success" className="" onClick={handleManagerWithoutCustomers}>{translate('user.yes-save-without-selected-customers')}</Button>
                                        </Col>
                                        <Col>
                                            <Button color="light" className="float-right " onClick={() => setModal(!modal)}>{translate('cancel')}</Button>
                                        </Col>
                                    </ModalFooter>
                            </div>
                            </Modal>
                            </>
                        )}
                    </Can>
                    <Can not I="create" a="Key">
                        {() => (
                            <Page403 />
                        )}
                    </Can>
                </Container>
            </div>
        )

}


export default withRouter(HandleKeyAccountManager);