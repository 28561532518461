import React, { useEffect, useState, useCallback } from "react";

// Redux and actions
import { useSelector, useDispatch} from 'react-redux'
import { getById, updateModel, createModel, clearModelState } from "store/model/actions";
import { getCategories } from 'store/form/actions'

// Components
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { SelectProduct } from "../Asset/Components/Form/index";
import { AvForm } from 'availity-reactstrap-validation'

// Functions
import translate from "helpers/Translation/translate";
import CreateContainer from "Containers/CreateContainer";


const ProductForm = ({ match, history }) => {

  const dispatch = useDispatch()

  const [ editMode, setEditMode ] = useState(false)
  const [ product, _setProduct] = useState({
    type: undefined,
    supplier: undefined,
    supplier_code: undefined,
    ean: undefined
  })

  const setProduct = React.useCallback((product) => {
    _setProduct({ ...product, update_with_icecat: true });
  }, [_setProduct]);

  const url = match?.path
  const id = match?.params?.id

  const isFetching = useSelector(state => state.Model.isFetching)
  const error = useSelector(state => state.Model.error)
  const errors = useSelector(state => state.Model.errors)
  const success = useSelector(state => state.Model.success)
  const notFound = useSelector(state => state.Assets.notFound)
  const categories = useSelector(state => state.Form.categories)

  useEffect(() => {
    if (url === "/products/:id(\\d+)/edit") {
      dispatch(getById(id, 'product')).then(res => {
        setEditMode(true)
        setProduct(res?.payload)
      })
      dispatch(getCategories({}))
    }
    return () => {
      dispatch(clearModelState())
    }
  }, [])

  const handleChange = e => {
    product[e.target.name] = e.target.value
    setProduct(product)
  }

  const createManualProduct = () => {
    if(editMode) {
      dispatch(updateModel('product', product))
      .then(res => {
          if (res.payload && history) {
            return(
              history.push({
                pathname: `/products/${res.payload.id}`,
                state: "message.update-product-success",
              })
            )
            }
          })
      } else {
        dispatch(createModel('product', product))
        .then(res => {
          if(res?.type === "CREATE_product_SUCCESS") {
            return (
                history.push({
                  pathname: `/products/${res.id}`,
                  state: "message.create-product-success",
                })
            )
          }
        })
        }
      }

  const setImage = image => {
    product['image'] = image
    setProduct(product)
  }

  const getProduct = product => {
    setProduct(product)
  }

    return (
      <CreateContainer
        isFetching={isFetching}
        Model="Asset"
        breadcrumbItem={editMode ? translate("product.edit") : translate("product.create")}
        breadcrumbTitle={translate("product.product")}
        editMode={editMode}
        success={success}
        error={error}
        errors={errors}
      >
        <Card>
          <CardBody >
            <AvForm onValidSubmit={createManualProduct}>
              <Col lg={8} className="mx-auto">
                 <SelectProduct
                    getProduct={getProduct}
                    handleChange={handleChange}
                    product={product}
                    getImage={setImage}
                    categories={categories}
                    editMode={editMode}
                   />
              </Col>
            {(notFound || editMode) &&
              <Row className=" d-flex flex-direction-column">
                <Button
                  color="success"
                  type='submit'
                  className="mx-auto"
                  disabled={
                    product?.product?.title && product?.product?.supplier && product?.product?.supplier_code && product?.product?.customer_id &&
                    product?.product?.category ? true : false}>{editMode ? translate('product.edit') : translate('product.create')}</Button>
              </Row> }
            </AvForm>
          </CardBody>
        </Card>
      </CreateContainer>


    );
  }

export default ProductForm
