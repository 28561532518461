import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"

// Redux
import { getIcecatData } from 'store/asset/actions'
import { getById, clearModelState, updateModel } from "store/model/actions";
import { useSelector, useDispatch} from 'react-redux'

// Components
import {
  Col,
  DropdownItem,
  Row,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NotAvailable from 'assets/images/no-image.jpeg'
import CardContainer from 'Containers/CardContainer'
import { PopUpModal } from "Components/Global";
import ProductInfo from "./Components/ProductInfo";
import classnames from 'classnames';

// Functions
import { translate, Can, getProductLogColumns } from "helpers/helpers";
import ProductAttributes from "./Components/ProductAttributes";
import { ObjectLog } from "Components/Global/ObjectLog";

const ProductCard = ({ history, location, match }) => {

  const isAdmin =  useSelector(state => state.Global.isAdmin)
  const product =  useSelector(state => state.Model.product)
  const notFound =  useSelector(state => state.Model.notFound)
  const isFetching  =  useSelector(state => state.Model.isFetching)
  const error =  useSelector(state => state.Model.error)
  const errors =  useSelector(state => state.Model.errors)
  const success =  useSelector(state => state.Model.success)
  const icecatData = useSelector(state => state.Assets.icecatData)
  const icecatDataMessage = useSelector(state => state.Assets.error)
  const [activeTab, setActiveTab] = useState('info');
  const toggle = tab => { if(activeTab !== tab) setActiveTab(tab)}

  const dispatch = useDispatch()

  const id = match.params.id
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    dispatch(getById(id, 'product'))
    return () => {
      dispatch(clearModelState())
      let state = { ...history?.location?.state };
      state = null
      history.replace({ ...history.location, state });
    }
  }, [dispatch, id, history])

  const checkIcecat = () => {
    dispatch(clearModelState())
    dispatch(getIcecatData({'supplier' : product?.supplier, 'supplier_code' : product?.supplier_code, 'update' : true }))
    .then(res =>
      res.type === "GET_ICECAT_DATA_SUCCESS" ?
        setIsOpen(true)
      : null
    )
  }

  const updateTheProduct = () => {
    const update = {
      supplier_code: icecatData?.id,
      update_with_icecat: true,
      id: product?.id,
      customer_id: null,
      reseller_id: null,
      name: icecatData?.name,
      title: icecatData?.title,
      ean: icecatData?.ean,
      product_url: icecatData?.product_url,
      pdf_url: icecatData?.pdf_url,
      image: icecatData?.image,
      description: icecatData?.summary,
      use_icecat: 1,
      icecat_id: icecatData?.supplier_code,
      supplier: icecatData?.supplier,
    }
    dispatch(updateModel('product', update))
    setIsOpen(false)
  }


  return (
    <CardContainer
      model={'product'}
      can={'Asset'} // Same as Product
      breadcrumbTitle={translate("product.products")}
      breadcrumbItem={translate("product.my-product")}
      data={product}
      subtitle={<p className="text-success" style={{ marginTop: '-10px'}}>{product?.product_category ??  translate('asset.no-category')}</p>}
      isFetching={isFetching}
      notFound={notFound}
      error={error || icecatDataMessage}
      errors={errors}
      success={success}
      history={history}
      location={location}
      image={<img alt="product" src={product?.image ?? NotAvailable} className="img-fluid"  />}
      customButtons={

      !product?.use_icecat && !isAdmin ?
      <UncontrolledDropdown style={{zIndex: 20}}>
        <DropdownToggle className="card-drop float-right btn btn-light btn-sm">
          <span style={{ color: 'black'}}>{translate('handle')} <i className="mdi mdi-dots-horizontal"></i></span>
        </DropdownToggle>
        <DropdownMenu right className="mt-5">
            <DropdownItem >
              <Link className="btn btn-warning col-lg-12 mb-2 " to={`/products/${product?.id}/edit`}>
                  {translate('edit')}
              </Link>
            </DropdownItem>
            <Can I="delete" a="Asset">
              {() => (
                <DropdownItem>
                  <Link to="#" onClick={(e) => {e.preventDefault();setIsOpen(true)}} className="btn btn-danger col-lg-12 mb-2 ">
                    {translate('delete')}
                  </Link>
                </DropdownItem>
              )}
            </Can>
          </DropdownMenu>
        </UncontrolledDropdown>

        : isAdmin ?
          <UncontrolledDropdown style={{zIndex: 20}}>
            <DropdownToggle className="card-drop float-right btn btn-light btn-sm">
              <span style={{ color: 'black'}}>{translate('handle')} <i className="mdi mdi-dots-horizontal"></i></span>
            </DropdownToggle>
            <DropdownMenu right className="mt-5">
              <DropdownItem >
                <Link className="btn btn-warning col-lg-12 mb-2 " to={`/products/${product?.id}/edit`}>
                    {translate('edit')}
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="#" onClick={(e) => {e.preventDefault();setIsOpen(true)}} className="btn btn-danger col-lg-12 mb-2 ">
                  {translate('delete')}
                </Link>
              </DropdownItem>
              {product?.supplier && product?.supplier_code && !product.use_icecat?
                <DropdownItem >
                  <Link to="#" onClick={() => checkIcecat()} className="btn btn-success  col-lg-12 mb-2">
                      {translate('product.check-icecat')}
                  </Link>
              </DropdownItem>
            : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        : null}
    >
      <Col lg={12}>
        {product?.use_icecat ? <span className="badge-purple badge-pill">Icecat</span> : null}
        <p className="mt-2">{product?.description}</p>
      </Col>
      <Nav tabs className="nav-tabs-custom mt-3">
        <NavItem key={'info'}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active:  activeTab === 'info' })}
            onClick={() => toggle('info')}>
             {translate('product.info')}
          </NavLink>
        </NavItem>
        <NavItem key={'attributes'}>
          <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active:  activeTab === 'attributes' })}
              onClick={() => toggle('attributes')}>
             {translate('product.attributes')}
          </NavLink>
        </NavItem>
        <NavItem key='history'>
          <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active:  activeTab === 'history' })}
              onClick={() => toggle('history')}>
             {translate('product.history')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane key={'info-tab'}  tabId={'info'} className="p-3">
          <ProductInfo product={product} />
        </TabPane>
        <TabPane key={'attributes-tab'}  tabId={'attributes'} className="p-3">
          <ProductAttributes features={product?.attributes}/>
        </TabPane>
        <TabPane key={'history-tab'}  tabId={'history'} className="p-3">
          <ObjectLog id={id} columns={getProductLogColumns()} objectType={'products'} />
        </TabPane>
      </TabContent>


      <PopUpModal
          isOpen={isOpen}
          togglemodal={setIsOpen}
          title={translate('product.info-found')}
          success={success}
          error={error}
          action={updateTheProduct}
          buttonText={translate('update')}
          >
          {icecatData &&
            <Row className="justify-content-center">
              <Col sm={6}>
                <img className="product-detail-imgs img-fluid mb-3 " src={icecatData?.image} alt="product" id="expandedImg1" /> 
                <p>{icecatData.summary}</p>
              </Col>
                <Col lg={6}>
                    <FormGroup>
                      <Label htmlFor="title">{translate('asset.title')}</Label>
                      <Input
                        name={"title"}
                        defaultValue={icecatData?.title}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="name">{translate('product.name')} <span className="text-muted ml-1 f-10">({translate('displayed-in-tables')})</span></Label>
                      <Input
                        name={"name"}
                        defaultValue={icecatData?.name}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="category">{translate('asset.category')}</Label>
                      <Input
                        name={"category"}
                        defaultValue={icecatData?.product_category ?? icecatData.category}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    {icecatData?.ean &&
                      <FormGroup>
                        <Label htmlFor="ean">{translate('asset.ean')} </Label>
                        <Input
                          name={"ean"}
                          defaultValue={icecatData?.ean}
                          type={"text"}
                          readonly
                          />
                      </FormGroup>
                    }
                    {icecatData?.product_url &&
                    <FormGroup>
                      <Label htmlFor="product_url">{translate('asset.web')} </Label>
                      <Input
                        name={"title"}
                        defaultValue={icecatData?.product_url}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    }
                    {icecatData?.pdf_url &&
                    <FormGroup>
                      <Label htmlFor="pdf_url">{translate('asset.technical-pdf')} </Label>
                      <Input
                        name={"title"}
                        defaultValue={icecatData?.pdf_url}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    }
                  {icecatData?.weight &&
                    <FormGroup>
                      <Label htmlFor="weight">{translate('asset.weight')} </Label>
                      <Input
                        name={"title"}
                        defaultValue={icecatData?.weight}
                        type={"text"}
                        readonly
                        />
                    </FormGroup>
                    }
                </Col>
            </Row>}
        </PopUpModal>
    </CardContainer>
  );
}

export default ProductCard
