export function toFormData(data) {
    const fData = new FormData();
    Object.entries(data).forEach((entry) => fData.append(...entry));
    return fData;
}

export function toFileWithRenames(d) {
    const newData = {};

    Object.entries(d).forEach(([k, v]) => {
        if (k === 'file') {
            newData.file = v;
        } else if (v) {
            newData.renames = newData.renames ?? {};
            newData.renames[k] = v;
        }
    });
    
    return newData;
}

export function fileWithRenamesToSerialized(value) {
    const toSend = {};
    Object.entries(value).forEach(([label, rename]) => {
        label !== rename && (toSend[`renames[${label}]`] = rename); 
    });
    return toSend;
}
