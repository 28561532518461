import React from "react"
import { Link } from "react-router-dom";
import { translate } from "helpers/helpers";
import { Icons } from 'assets/styles/index';
import { useSelector } from "react-redux";
import NavItem from './NavItem'


const NavProducts= ({ toggleMenu, layoutType }) => {

    const isAdmin = useSelector(state => state.Global.isAdmin)

    return (
        <NavItem
            id={'product'}
            layoutType={layoutType}
            item={
            <>
                <i className={Icons.product}></i>
                <span className="ml-1">{translate('product.products')}</span>
            </>
            }
            menu={
                <ul className="sub-menu ecosystem-list pl-2">
                <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                    <Link to="/products" >{translate('list')}</Link> </li>
                {isAdmin &&
                <>
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link to="/products/categories" className=" waves-effect">
                            <span>{translate('product.categories')}</span>
                        </Link>
                    </li>
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link to="/products/emv" className=" waves-effect">
                            <span>{translate('product.handle-emv')}</span>
                        </Link>
                    </li>
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link to="/products/emv/import" className=" waves-effect">
                            <span>{translate('product.products-fmv-import')}</span>
                        </Link>
                    </li>
                </>
                }
            </ul>
            }
        />
    )
}

export default NavProducts