import React from 'react';
import translate from 'helpers/Translation/translate';
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ContractDeliveryAddress = ({ contract }) => (

       <div className="table-responsive">
            <Table className="table">
                <Tbody>
                    <Tr>
                        <Th scope="row">{translate('address.address')}</Th>
                        <Td>{contract?.delivery_address}</Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.zip')}</Th>
                        <Td>{contract?.delivery_zip}</Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.city')}</Th>
                        <Td>{contract?.delivery_city}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    )

export default ContractDeliveryAddress;
