import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormGroup} from "reactstrap";
import { TextInputField } from 'Components/Form/index'
import DatePicker from "react-datepicker";
import translate from 'helpers/Translation/translate';

const AssetOrder = ({ asset, handleChange, dateChange }) => {
  
  const [ invoiceDate, setInvoiceDate ] = useState(null)
  
  const handleDateChange = (type, date) => {
    setInvoiceDate(date)
    dateChange(type, date)
  }

  useEffect(() => {
    if(asset.invoice_date) {
      setInvoiceDate(new Date(asset.invoice_date))
    }
  },[asset.invoice_date])

  return (
    <Row>
      <Col lg={6}>
        <TextInputField
          name={"additional_info"}
          label={"asset.additional-info"}
          type="text"
          value={asset?.additional_info ?? ''}
          placeholder={"form.enter-additional-info"}
          errorMessage={"form.enter-additional-info"}
          onChange={(e) => handleChange(e)}
        />
      </Col>
      <Col sm={6}>
        <TextInputField
          name={"order_no"}
          label={"asset.order"}
          value={asset?.order_no ?? ''}
          placeholder={"form.enter-order-no"}
          errorMessage={"form.enter-order-no"}
          onChange={(e) => handleChange(e)}
          type={"text"}
        />
      </Col>
      <Col sm={6}>
        <TextInputField
          name={"invoice_no"}
          label={"asset.invoice-no"}
          value={asset?.invoice_no ?? ''}
          placeholder={"form.enter-invoice-no"}
          onChange={(e) => handleChange(e)}
          errorMessage={"form.enter-invoice-no"}
          type={"text"}
          validation={{ pattern: { value: "[0-9]" } }}
        />
      </Col>
      <Col sm={6}>
        <FormGroup>
          <Label htmlFor="invoice_date">
            {translate("asset.invoice-date")}
          </Label>
          <DatePicker
            name="invoice_date"
            className="form-control"
            dateFormat="yyyy-MM-dd"
            required={false}
            selected={invoiceDate}
            onChange={(e) => handleDateChange("invoice_date", e)}
          />
        </FormGroup>
      </Col>
    </Row>
  )
}

export default AssetOrder;
