
import { translate } from 'helpers/helpers';
import axios from 'axios';

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE"
export const CLEAR_STATE = "CLEAR_STATE"

export const clearState = () => { return async dispatch => {dispatch({ type: CLEAR_STATE })}}

// Rename this route
export const updateCategory = ( row, newValue ) => {
  return async dispatch => {
    dispatch({ type: UPDATE_CATEGORY_REQUEST })
  try {
      await axios.put(`${process.env.REACT_APP_API_URL}/products/categories/${row.id}`, {eol_months: parseInt( newValue )});
      return dispatch ({ type: UPDATE_CATEGORY_SUCCESS, message: translate('message.update-eol-success')});
    } catch (error) {
        return dispatch ({ type: UPDATE_CATEGORY_FAILURE,  message: translate('message.update-eol-failure') }, error)
      }
  }
}
