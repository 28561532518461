import React from "react";
import translate from "helpers/Translation/translate";
import { InfoCard } from "Components/Global";
import ApexRadial from "../../Charts/ApexRadial";
import { useSelector } from "react-redux";
import SyncIcecatProducts from "./SyncIcecatProducts";
import { LoaderInsideComponent } from "Components/Global";

const NewProductsCard = ({ productCard })=> {

    const locale = useSelector(state => state.Global.locale)
    const text = locale === 'sv' ? 'Manuella produkter' : 'Manual products'

    return (
        <InfoCard title={translate('product.products')} total={productCard?.total?.toLocaleString()}>
            <p className="text-muted text-center m-0">
                {translate('products.new-this.month')}:
                <span className="text-success">
                    {productCard?.newManual?.toLocaleString()}
                </span>
            </p>
            {!productCard && <LoaderInsideComponent />}
            <ApexRadial
                color="#f7033b"
                total={productCard?.manual ?? 0}
                text={text}
                id={"products"}
                percent={productCard?.manualPercent ?? 0}/>
            <SyncIcecatProducts />
        </InfoCard>
    );
}

export default NewProductsCard;
