import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Button } from "reactstrap";
import TextInputField from "Components/Form/TextInputField";
import axios from "axios";

const hiddenParams = {
    client_id: Array(24).fill('*').join(''),
    client_secret: Array(24).fill('*').join('')
};

// TODO: Translate
const IntuneIntegration = ({ user }) =>  {

    const [intInfo, setIntInfo] = React.useState({});

    const fetchIntegrationInfo = React.useCallback(() => {
        return axios
            .get(`${process.env.REACT_APP_API_URL}/integrate/intune/status`)
            .then(({data}) => setIntInfo({ ...data, ...hiddenParams }))
            .catch(console.error);
    }, []);

    React.useEffect(() => {
        fetchIntegrationInfo();
    }, []);

    const onSubmit = React.useCallback((_, __, values) => {
        const { tenant_id, client_id, client_secret } = values;
        const url = new URL(`https://login.microsoftonline.com/${tenant_id}/adminconsent?client_id=${client_id}&redirect_uri=${process.env.REACT_APP_API_URL.replace('http', 'https')}/integrate/intune`);
        url.searchParams.append('state', `${tenant_id}:${client_id}:${client_secret}`);
        axios
            .post(`${process.env.REACT_APP_API_URL}/integrate/intune/save-integration`, values)
            .then(() => window.location.href = url.toString());
    }, []);

    if (!user?.customer_id) {
        return null;
    }

    return (<AvForm className="form-horizontal pt-4" onSubmit={onSubmit}>
        <Row>
            <Col lg={6}>
                <p>To integrate with Microsoft Intune&reg;:</p>
                <ul>
                    <li>Create a Microsoft Entra App Registration</li>
                    <li>Add <i>Devices.Read</i> permission under <strong>API permissions</strong></li>
                    <li>Create a <strong>Client Secret</strong></li>
                    <li>Add <i>
                        https://api.ecosystemit.com/api/v1/intune_integration
                        </i> as a <strong>redirect_uri</strong></li>
                </ul>
                <FormGroup>
                    <TextInputField
                        name="tenant_id"
                        id="tenantId"
                        value={intInfo.tenant_id ?? ''}
                        placeholder={"Enter Tenant ID (e. g. 5d05f2e9-92d2-4dec-b04f-5db1bb44703e)"}
                        errorMessage={"Enter Tenant ID"}
                        type={"text"}
                        required={true}
                        label="Tenant ID"
                    />
                    <TextInputField
                        name="client_id"
                        id="clientId"
                        value={intInfo.client_id ?? ''}
                        placeholder={"Enter Client ID (e. g. cdfedfcc-6c9c-432b-a73a-70f8884ccb1d)"}
                        errorMessage={"Enter Client ID"}
                        type={"password"}
                        required={true}
                        label="Client ID"
                    />
                    <TextInputField
                        name="client_secret"
                        id="clientSecret"
                        value={intInfo.client_secret ?? ''}
                        placeholder={"Enter Client Secret (e. g. gfe8Q~HWvCz2FnyfU6mg3itxQ-KswkHYqUJfeaG4)"}
                        errorMessage={"Enter Client Secret"}
                        type={"password"}
                        required={true}
                        label="Client Secret"
                    />
                    <Button
                        color="success"
                        className=" w-md waves-effect waves-light col-lg-12"
                        type="submit"
                    >
                        {"Configure integration"}
                    </Button>
                </FormGroup>
            </Col>
            
            <Col lg={6}>
                <Row>
                    <Col style={{ display: 'flex', paddingBottom: '10px' }}>
                        <i className={`fa ${intInfo.hasParams ? 'text-success fa-check-circle' : 'fa-exclamation-circle'}`} style={{ fontSize: "24px" }}></i>
                        <h6 style={{ verticalAlign: 'middle', paddingLeft: '10px' }}>
                            Integration is {!intInfo.hasParams && 'not'} ready.
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Button
                        color="success"
                        className=" w-md waves-effect waves-light col-lg-12"
                        type="button"
                        onClick={fetchIntegrationInfo}
                    >
                        {"Check integration status"}
                    </Button>
                </Row>            
            </Col>
        </Row>
        </AvForm>
    )
}

export default IntuneIntegration;
