import React from "react";

// Redux and actions
import { useSelector } from "react-redux";
import { translate, getLotReportListColumns } from 'helpers//helpers';

// Components
import { Alerts } from "Components/Global/index"
import { RemotePaginationTable} from "Components/Lists";
import PageContainer from 'Containers/PageContainer'
import Moreco from 'assets/images/logo.png'

const LotReportList = ({ location, match }) => {

  const pagination = useSelector(state => state.Model.lot)
  const error = useSelector(state => state.Model.error)
  const success = useSelector(state => state.Model.success)
  const me = useSelector(state => state.Global.me)

    return (
      <PageContainer
        Model={"Morecoreport"}
        data={pagination?.total}
        breadcrumbTitle={<img src={Moreco} height="15px" alt="logo"></img>}
        breadcrumbItem={translate('reports.lot')}
        listHeaderTitle={translate('reports.lot')}
        alerts={<Alerts success={success} error={error} location={location} />}
        >
        <RemotePaginationTable
          model={'lot'}
          pagination={pagination}
          columns={getLotReportListColumns(me?.type, 'all')}
          pageUrl={`${process.env.REACT_APP_API_URL}/moreco-reports/lot`}
          path={match.path}
          can={'MorecoReport'}
          sort={'lot_number'}
        />

      </PageContainer>
    )
  }

export default  LotReportList;
