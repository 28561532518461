import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateModel , clearModelState } from "store/model/actions";
import { getCategories, getProducts } from 'store/form/actions'
// Components
import {  Row, FormGroup, Col } from "reactstrap";
import OptionSelect from 'Components/Form/OptionSelect'
import { Alerts } from 'Components/Global/index';
import PageContainer from 'Containers/PageContainer'
// Functions
import { translate, getProductColumns } from 'helpers/helpers';
// Table
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

const HandleEMV = () => {

  const [ filter, setFilter ] = useState({ product_category: '',  market_value: ''});
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const categories = useSelector(state =>  state.Form.categories)
  const products = useSelector(state =>  state.Form.products)
  const error = useSelector(state =>  state.Model.error)
  const success = useSelector(state =>  state.Model.success)
  const isFetching = useSelector(state =>  state.Model.isFetching)
	const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getProducts(filter))
    dispatch(getCategories({ products: true }))
    return () => {
      dispatch(clearModelState())
    }
  },[])


 const selectChange = (event) => {
    const currentFilter = filter;
    currentFilter[event.target.name] = event.target.value;
    setFilter(currentFilter)
    dispatch(getProducts(filter))
  }


  const saveValue = (row) => {
    dispatch(updateModel('product', {id: row.id, market_value : row.market_value}))
    .then((res => {
      if(res.type === 'UPDATE_product_SUCCESS') dispatch(getProducts(filter))
      }))
  }

  const indication = () => { return translate('asset.no-rows')}

    return (
      <PageContainer
        isFetching={isFetching}
        Model={"Reseller"} // Only admin kan read a Reseller, this page is only for admins. 
        breadcrumbTitle={translate("handle-emv")}
        breadcrumbItem={translate("handle-emv")}
        listHeaderTitle={translate('handle-emv')}
        data={products}
        alerts={ <Alerts success={success} error={error} /> }
      >
          <ToolkitProvider
            keyField='id'
            data={products}
            columns={getProductColumns('showall')}
            >
            {props => (
              <>
                <Col lg={12} className="mb-3">
                    <Row>
                      <FormGroup className="col-lg-4 col-sm-12">
                        <label>EMV</label>
                        <select name="market_value" className="form-control select2" onChange={selectChange}>
                          <OptionSelect message={"include-all"} value={""}/>
                          <OptionSelect value={true} message={"product.with-fmv"} />
                          <OptionSelect value={false} message={"product.no-fmv"} />
                        </select>
                      </FormGroup>
                      <FormGroup className="col-lg-4 col-sm-12">
                      <label>{translate('asset.category')}</label>
                        <select className="form-control select2" name="product_category" onChange={selectChange}>
                          <OptionSelect message={"include-all-categories"} value={""}/>
                            {categories?.map(category=> {
                              return <option key={category.id} value={category.name}>{category.name}</option>
                            }
                            )}
                        </select>
                       </FormGroup>
                          <FormGroup className="col-lg-4 col-sm-12 float-right">
                            <label>{translate('search')}</label>
                            <SearchBar {...props.searchProps} className="custom-search-field" />
                      </FormGroup>
                    </Row>
                </Col>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField="id"
                    striped
                    bordered={false}
                    noDataIndication={ indication }
                    cellEdit={
                      cellEditFactory({ mode: "click",
                      afterSaveCell: (oldValue, newValue, row, column) => { saveValue(row, newValue)} })}
                    autoSelectText={true}
                    loading={ true }
                    defaultSorted={ [{ dataField: 'name', order: 'desc' }]}
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ 
                      page, 
                      sizePerPage: pageSize, 
                      onPageChange: (page) => setPage(page),
                      onSizePerPageChange: (size) => setPageSize(size) 
                    })}
                    {...props.baseProps}
                    />
                </div>
              </>
            )}
          </ToolkitProvider>
        </PageContainer>
    );
  }


export default HandleEMV

