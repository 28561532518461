import React, { useEffect, useState } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getCategories, getCustomerDropdown } from 'store/form/actions'
import { getIcecatData, clearState } from 'store/asset/actions'
// Components
import { Button, CardHeader, FormGroup, Row, Col } from "reactstrap";
import { TextInputField } from "Components/Form";
import ProductFields from './ProductFields';
import { Alerts } from "Components/Global";
// Functions
import translate from 'helpers/Translation/translate';


const SelectProduct = ({ getProduct, handleChange, product, editMode, getImage, customer_id }) => {

  const dispatch = useDispatch()

  const [ supplier, setSupplier ] = useState('')
  const [ supplier_code, setSupplierCode ] = useState('')
  const [ searchIsMade, setSearchIsMade ] = useState(false)

  const isFetching = useSelector(state => state.Assets.isFetchingIcecat)
  const errors = useSelector(state => state.Assets.errors)
  const error = useSelector(state => state.Assets.error)
  const categories = useSelector(state => state.Form.categories)

  useEffect(() => {
    dispatch(getCategories({}))
    dispatch(getCustomerDropdown())
  },[])


  const clear = () => {
    setSupplier('')
    setSupplierCode('')
    setSearchIsMade(false)
    dispatch(clearState())
    getProduct(customer_id ? { customer_id } : {})
  }

  const fetchIcecatData = (e) => {
    e.preventDefault();
    dispatch(clearState())

    const request = ({'supplier' : supplier, 'supplier_code' : supplier_code, 'customer_id' : customer_id })
  
    dispatch(getIcecatData(request)).then(res => {

      const prd = { ...product };
      if(res.type === 'GET_ICECAT_DATA_SUCCESS') {
        prd['type'] = res.payload.type // product for products in DB and Icecat for icecat result
        prd['supplier'] = res.payload.data.supplier
        prd['name'] = res.payload.data.name
        prd['ean'] = res.payload.data.ean
        prd['pdf_url'] = res.payload.data.pdf_url
        prd['product_url'] = res.payload.data.product_url
        prd['weight'] = res.payload.data.weight
        prd['title'] = res.payload.data.title
          if(res.payload.type === 'product') {
            prd['id'] = res.payload.data.id
            prd['supplier_code'] = res.payload.data.supplier_code
            prd['product_category_id'] = res.payload.data.category.id
            prd['description'] = res.payload.data.description
            prd['image_url'] = res.payload.data.image
          }
          if(res.payload.type === 'icecat') {
            const catId = categories?.find((cat) => cat.icecat_id === +res.payload?.data?.category_id)?.id;
            prd['image_url'] = res.payload.data.image
            prd['supplier_code'] = res.payload.data.id
            prd['product_category_id'] = catId;
            prd['category'] = res.payload.data.category
            prd['description'] = res.payload.data.summary
            prd['attributes'] = res.payload.data.attributes
          }
        } else {
          prd['type'] = 'manual'
          prd['supplier'] = supplier
          prd['supplier_code'] = supplier_code
        }
        setSearchIsMade(true)
        getProduct(prd)
      });

  }

      return(
          <>
          {!searchIsMade && !editMode ?
            <Row>
              <CardHeader>
                <h5>{translate('asset.enter-product-info')}</h5>
              </CardHeader>
                <Col lg={6}>
                    <FormGroup >
                      <label htmlFor="example-text-input" className="col-md-12 col-form-label">{translate('asset.supplier/manufacturer')}</label>
                      <TextInputField
                        value={supplier}
                        name="supplier"
                        type="text"
                        errorMessage='form.enter-supplier'
                        onChange={e => setSupplier(e.target.value)}
                        />
                      </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup >
                      <label htmlFor="example-text-input" className="col-md-12 col-form-label">{translate('form.supplier-code')}</label>
                      <TextInputField
                        value={supplier_code}
                        name="supplier_code"
                        type="text"
                        errorMessage='form.enter-supplier-code'
                        onChange={e => setSupplierCode(e.target.value)}
                        />
                      </FormGroup>
                </Col>
              </Row>
          : null}

            {!editMode ?
            <FormGroup row className="mb-5 justify-content-center">
              {searchIsMade ?
                <Button
                  type="submit"
                  className="btn btn-warning  mt-1 mr-1"
                  onClick={() => clear()}>
                  {translate('new-search')}
                </Button>
              : (supplier && supplier_code) ?
                <Button
                  onClick={(e) => fetchIcecatData(e)}
                  className="btn btn-success  mt-1">
                  {isFetching ? <i className="bx bx-loader bx-spin align-middle"></i> : translate('form.fetch')}
                </Button>
                : null
              }
            </FormGroup> : null}
            <Alerts errors={errors} error={error} />
            {(searchIsMade || editMode || product.type === 'manual') &&
            <ProductFields
              handleChange={handleChange}
              product={product}
              getImage={getImage}
              categories={categories}
              editMode={editMode}
              />}
           </>
        )
    }


  export default SelectProduct
