import React from "react";
import { Col, FormGroup, Label} from "reactstrap";
import translate from "helpers/Translation/translate";
import { TextInputField, OptionSelect }from "Components/Form/index";

const ContractFinancialDetails = ({ getChange, contract }) => (

    <>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="financier" className="col-form-label  col-lg-3">
          {translate("contract.bank/financier")}
        </Label>
        <Col lg={9}>
        <TextInputField
          name={"financier"}
          value={contract?.financier}
          placeholder={"form.enter-bank"}
          onChange={getChange}
          errorMessage={"form.enter-bank"}
          type={"text"}
        />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="object_value" className="col-form-label  col-lg-3">
          {translate("contract.object-value")} (kr)
        </Label>
        <Col lg={9}>
          <TextInputField
            name={"object_value"}
            value={contract?.object_value}
            placeholder={"contract.object-value"}
            onChange={getChange}
            errorMessage={"form.enter-object-value"}
            type={"number"}
            validation={{
              pattern: {
                value: "^[0-9]+$",
                error: "Only Numbers",
              },
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="payment_period" className="col-form-label  col-lg-3" >
          {translate("contract.payment-period")}
        </Label>
        <Col lg={9}>
          <select
            name="payment_period"
            className="form-control select2"
            value={contract?.payment_period}
            onChange={getChange}
            >
            <OptionSelect message={"contract.select-payment-period"} />
            <OptionSelect value={"monthly"} message={"contract.monthly"} />
            <OptionSelect value={"quarterly"} message={"contract.quarterly"}/>
          </select>
        </Col>
      </FormGroup>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="surcharge_value" className="col-form-label  col-lg-3">
          {translate("contract.surcharge-value")} (kr)
        </Label>
        <Col lg={9}>
          <TextInputField
            name={"surcharge_value"}
            value={contract?.surcharge_value}
            placeholder={"contract.surcharge-value"}
            onChange={getChange}
            errorMessage={"form.enter-surcharge"}
            type={"number"}
            validation={{
              pattern: {
                value: "^[0-9]+$",
                error: "Only Numbers",
              },
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="residual_value" className="col-form-label  col-lg-3">
          {translate("contract.residual-value")} (kr)
        </Label>
        <Col lg={9}>
          <TextInputField
            name={"residual_value"}
            value={contract?.residual_value}
            placeholder={"form.residual-value"}
            onChange={getChange}
            errorMessage={"form.enter-residual-value"}
            type={"number"}
            validation={{
              pattern: {
                value: "^[0-9]+$",
                error: "Only Numbers",
              },
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="residual_value_guarantor" className="col-form-label  col-lg-3">
          {translate("contract.residual-value-guarantor")}
        </Label>
        <Col lg={9}>
          <TextInputField
            name={"residual_value_guarantor"}
            value={contract?.residual_value_guarantor}
            placeholder={"form.enter-residual-value-guarantor"}
            onChange={getChange}
            errorMessage={"form.enter-residual-value-guarantor"}
            type={"text"}
          />
        </Col>
      </FormGroup>
    </>
)

export default ContractFinancialDetails;
