import React from 'react'
import { Link, withRouter } from "react-router-dom";
import { translate } from "helpers/helpers";

const Suggestions = ({ results }) => {

  const options = results?.map(r => (
    <li key={r.id} >
      <Link to={ `/${r.model}s/${r.id}` } style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div className="pl-4">
            <p className="ecosystem-suggestions-title pb-0 mb-0">{r.title}</p>
            <p className="text-muted">{r.model === 'asset' ? 'Asset' :
                r.model === 'contract' ? translate('contract.contract') :
                r.model === 'reseller' ? translate('reseller.reseller') :
                r.model === 'customer' ? translate('customer.customer') :
                r.model === 'user' ? translate('user.user')
                : null}
            </p>
        </div>
        <i className="mdi mdi-arrow-right-circle float-right font-size-16 ml-4 mr-4"></i>
      </Link>
    </li>
  ))
  return <ul className="ecosystem-suggestions mt-2">{options}</ul>
}

export default withRouter(Suggestions)