import React from "react";
// Components
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import WarrantyCardCustomer from "./WarrantyCardCustomer";
import WarrantyCardCategory from "./WarrantyCardCategory";
// Styles
import ecosystemDashboardCard from 'assets/styles/EcosystemDashboardCard'
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import { translate } from 'helpers/helpers';


const WarrantyOverview = ({ warrantyOverview, warrantySummary, onCustomer, customer_id }) => {

    return (
      <Col lg={12}>
        <Card style={ecosystemDashboardCard}>
          <CardBody>
            <Row>
              <Col lg={12} className="text-center mb-4">
                <CardHeader style={ecosystemCardHeader}>
                  <h1 className="mb-0">{warrantySummary?.opportunity_value?.toLocaleString()} kr</h1>
                  <h5>{translate('warranty.hardware-replacement-title')}</h5>
                  <p className="f-10 text-muted"> {translate('warranty.based-on')} {warrantySummary?.price_based_on?.toLocaleString()} assets.
                  <br/>{translate('warranty.price-missing')} {warrantySummary?.missing_price?.toLocaleString()} assets </p>
                </CardHeader>
              </Col>
              <Col lg={6}>
                <h2 className="mb-0">{warrantySummary?.purchased_assets?.toLocaleString()}</h2>
                <p>{translate('warranty.purchased-assets')}</p>
                <h2 className="mb-0">{warrantySummary?.rented_assets?.toLocaleString()}</h2>
                <p>{translate('warranty.rented-assets')}</p>
              </Col>
              <Col lg={6} style={{ textAlign: 'right'}}>
                <h2 className="mb-0">{warrantySummary?.total_value?.toLocaleString()} kr</h2>
                <p className="mb-0">{translate('warranty.total-value')}</p>
                <p className="f-10 text-muted">{translate('warranty.based-on')} {warrantySummary?.total_value_based_on?.toLocaleString()} assets</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
          <Row>
            {warrantyOverview?.map((category, key) => {
              return(
                <Col xl={4} md={6} key={key}>
                  {onCustomer ?
                  <WarrantyCardCategory title={category.total.title} warranty={category} customer_id={customer_id}/>
                  : <WarrantyCardCustomer title={category.total.title} warranty={category} />}
                </Col>
              )
            })}
          </Row>
      </Col>
    )
}

export default WarrantyOverview