import React, { useState } from 'react';
import { Container } from "reactstrap";
import translate from 'helpers/Translation/translate';

import { Breadcrumbs } from 'Components/Global';
import GeneralQuestions from './Components/GeneralQuestions';
import Support from './Components/Support';
import GdprInfo from '../Gdpr/Gdpr-info';
import VerticalTabs from 'Components/Global/VerticalTabs';


const PagesFaqs = () => {

    const [ nestedActiveTab, setNestedActiveTab ] = useState('assets')

    const toggleNestedTab = (tab) => {
        if (nestedActiveTab !== tab) setNestedActiveTab(tab)
    }

    const Tabs = [
        {
                title: translate('faq.general'),
                activeTab: 'general',
                translate: 'faq.general',
                icon: "bx bx-question-mark",
                component: <GeneralQuestions nestedActiveTab={nestedActiveTab} toggleNestedTab={toggleNestedTab}/>
        },
        {
                title: translate('GDPR'),
                activeTab: 'privacy',
                translate: 'GDPR',
                icon: "bx bx-check-shield",
                component: <GdprInfo />
        },
        {
                title: translate('faq.support'),
                activeTab: 'support',
                translate: 'faq.support',
                icon: "bx bx-support",
                component: <Support />
        }
    ]

    return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={translate('help')} breadcrumbItem="FAQs" />
                    <VerticalTabs Tabs={Tabs} />
                </Container>
            </div>
        );
    }

export default PagesFaqs;