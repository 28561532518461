import React, { useState, useEffect } from 'react';
import { Table } from "reactstrap";
import translate from 'helpers/Translation/translate';


const Permissions = ({ all_permissions, user, selected_permissions, updatePermissionState }) => {

  const [ user_permissions, setUserPermissions ] = useState([])

  useEffect(() => {
    setUserPermissions(selected_permissions)
  },[selected_permissions])

  return (

      <div className="table-responsive">
        <Table className="table">
          <tbody>
            <tr>
              <th scope="row" style={{ width: "40%" }}></th>
              <th style={{ width: "15%" }}>{translate("user.permission-create")}</th>
              <th style={{ width: "15%" }}>{translate("user.permission-read")}</th>
              <th style={{ width: "15%" }}>{translate("user.permission-update")}</th>
              <th style={{ width: "15%" }}>{translate("user.permission-delete")}</th>
            </tr>
          {
            // Map through all the permissions and create a new row on every forth
            all_permissions?.map((p, j) => {
                if(j%4 !== 0) return null;
                p = p.split("_")[1];

                if( (p === 'reseller') && !(user?.role === 'admin') ) return null;
                if( (p === 'customer') && ((user?.role === 'enduser_admin') ||  (user?.role === 'enduser')) ) return null;
                if( (p === 'key') && ((user?.role === 'enduser_admin') ||  (user?.role === 'enduser')) ) return null

                return (
                  <tr key={j/4}>
                    <th>{translate(p+'.'+p)}</th>
                      {all_permissions.length > 0 && all_permissions.slice(j , j+4).map((permission, i) => {

                        if (user?.role === 'enduser' || user?.role === "enduser_admin") {
                          if( permission === 'create_contract' ||
                              permission === 'update_contract' ||
                              permission === 'delete_contract'
                              ) {
                              return(
                                <td key={i}> </td>
                              )
                            }
                        }
                        if (user?.role === 'enduser' || user?.role === "enduser_admin" || user?.role === 'partner' || user?.role === "partner_admin" ) {
                          if( permission === 'create_morecoreport' || permission === 'update_morecoreport' ) {
                              return(
                                <td key={i}> </td>
                              )
                            }
                        }
                        if (user?.role !== "admin") {
                          if (permission === 'delete_morecoreport') {
                              return(
                                <td key={i}> </td>
                              )
                            }
                        }
                        const checked = user_permissions?.includes(permission)

                          return(
                              <td key={i}>
                                <div className="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={permission}
                                    checked={checked ?? false}
                                    readOnly
                                  />

                                  <label
                                    className="custom-control-label"
                                    htmlFor={permission}
                                    onClick={() => updatePermissionState([permission])}
                                  >
                                  </label>
                                </div>
                              </td>
                          )
                      })}
                    </tr>
                  )
            })}
          </tbody>
        </Table>
        </div>
  )
}

export default Permissions;