import React from "react";
import { Container, Card, CardBody } from "reactstrap";
import ListHeader from "Components/Lists/ListHeader";
import { Breadcrumbs, LoaderOnPage } from 'Components/Global/index';
import Page403 from '../Pages/Page403';
import { Can } from "helpers/helpers";

const PageContainer = ({
  isProfilePage,
  isFetching,
  Model,
  can,
  breadcrumbItem,
  breadcrumbTitle,
  alerts,
  children,
  listHeaderTitle,
  data,
}) => (

  <div className="page-content">
    <Container fluid>
      {isFetching ? (
        <LoaderOnPage />
      ) : (
        <>
          <Can I={can ?? "read"} a={Model}>
            {() => (
              <>
                <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbItem} />
                {alerts}
                {isProfilePage ? (
                  children
                ) : (
                  <Card style={{ width: 'fit-content', minWidth: '100%' }}>
                    <ListHeader title={listHeaderTitle} data={data} />
                    <CardBody>{children}</CardBody>
                  </Card>
                )}
              </>
            )}
          </Can>
          <Can not I={can ?? "read"} a={Model}>
            {() => <Page403 />}
          </Can>
        </>
      )}
    </Container>
  </div>
);

export default PageContainer;
