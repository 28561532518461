import React from "react";
import { Col } from "reactstrap";
import translate from "helpers/Translation/translate";
import { Table, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const AssetSpecs = ({ features, asset }) =>  (

    <div className="table-responsive">
      <Col className="mb-4 mt-3">
        {asset?.product?.pdf_url ?
          <a href={asset.product.pdf_url} target="_blank" rel="noopener noreferrer">
              <i className="mdi mdi-file-pdf-box-outline f-14"></i>
              {translate('asset.technical-pdf')}
          </a>
        : null}
      </Col>
      <Table className="table">
        <Tbody>
          {features?.map((feature, i) => (
            <React.Fragment key={i}>
              <Tr style={{ backgroundColor: '#f6f6f6'}}>
                <Th>{feature.name}</Th>
                <Td></Td>
              </Tr>
              {feature.attributes.map((attribute, j) => (
                <Tr key={j}>
                  <Td>{attribute.name}</Td>
                  <Th>{attribute.formatted_value}</Th>
                </Tr>
              ))}
            </React.Fragment>
          )
          )}
          {features?.length < 1 &&
            <Tr key={'no-feature'}>
              <Th>{translate('info-not-avaiable')}</Th>
              <Td></Td>
            </Tr>
          }
        </Tbody>
      </Table>
    </div>
  )

export default AssetSpecs;
