import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Alert } from "reactstrap";
import { syncItGlue } from 'store/tool/actions';
import translate from 'helpers/Translation/translate';

const SyncItGlue = () => {
	const dispatch = useDispatch();

  const error  = useSelector(state => state.Tool.error)
  const success  = useSelector(state => state.Tool.success)
  const isFetching  = useSelector(state => state.Tool.isFetching)

    return (
          <span>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}

                {isFetching ?
                  <Button className="btn-success col-lg-12">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {translate('product.syncing-with-it-glue')}
                  </Button> :
                  <Button onClick={() => dispatch(syncItGlue())} className="btn btn-success  col-lg-12">{translate('product.synchronize')} IT Glue</Button>}
          </span>
    );
  }


export default SyncItGlue;

