export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE"
export const CLEAR_STATE = "CLEAR_STATE"

const INIT_STATE = {
  errors: [],
  error: null,
  success: null,
  isFetching: false,
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        error: null,
        errors: null,
        success: null
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.message,
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };
    default:
      return state;
  }
};

export default Products;
