import React from "react";
import { translate } from "helpers/helpers";
import { Button, Col } from 'reactstrap'

const AdminButtons = ({ deleteReport, setIsOpen, lot_number, isAdmin }) =>  (

    isAdmin && lot_number ?
        <Col>
          <Button color="danger" className="mb-1 mr-1" onClick={deleteReport}>
            {translate('reports.delete')}
          </Button>
          <Button color="success" className="mb-1 mr-1" onClick={() => setIsOpen(true)}>
            {translate('reports.mail')}
          </Button>
        </Col>
      : null
)

export default AdminButtons

