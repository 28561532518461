import React from "react";
import { RemotePaginationTable } from "Components/Lists";
import { Button } from "reactstrap";
import { getCustomerColumns, translate } from 'helpers/helpers';
import { useSelector, useDispatch } from "react-redux";

export default function CustomersBody({ onItemClick, context = null }) {

    const formatter = React.useCallback((cell, row) => <Button onClick={() => onItemClick(row)} color="success">{cell}</Button>, [onItemClick]);

    const columns = React.useMemo(() => {
        const columns = getCustomerColumns('admin', 'ids');
        return columns.map((c) => c.dataField === 'id' ? { ...c, formatter } : c);
    }, [formatter]);

    const pagination = useSelector(state => state.Model.customers);

    return (
    <div>
        <RemotePaginationTable
            model={'customers'}
            pagination={pagination}
            columns={columns}
            pageUrl={`${process.env.REACT_APP_API_URL}/customers`}
            can={'Customer'}
        >
        </RemotePaginationTable>
    </div>);
}
