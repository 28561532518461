import React, { useState  } from "react";
import { translate, DataErasureColumns, getAssetsOnReportColumns } from "helpers/helpers";
import { Link } from "react-router-dom";
import { Col, Alert, Button } from "reactstrap";
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SectionModal from "./SectionModal";
import { useSelector,  } from "react-redux";

const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
        <Button className="mr-2 mb-3 btn-light btn-sm" onClick={handleClick} >
            <span style={{ color: 'black'}}>CSV</span> <i className="mdi mdi-download-outline" style={{ color: 'black'}}></i>
        </Button>
    );
  };


  const GeneratedReport = ({ data, me }) =>  {

    const [isOpen, setIsOpen] = useState(false)
    const error = useSelector(state => state.Report.error)
    const success = useSelector(state => state.Report.success)

    const deleteReport = () => {
      setIsOpen(true)
    }

    return (
        <>
    <Col lg={12}>
      <div className="d-flex align-items-center justify-content-center mb-5">
          <Button color="info" className="mb-1 mr-1" onClick={() => window.location.reload()}>
          {translate('reports.create-new')}
          </Button>
          <Link to={`/moreco-reports/lots/${data?.lot_report?.lot_number}`} className="m-1 btn btn-success ">{translate('reports.read')}</Link>
          <Button color="danger" className="mb-1 mr-1" onClick={deleteReport}>
              {translate('reports.delete')}
          </Button>
      </div>

    {data.hdd_audit_missing.length ?
    <>
        <h5>{translate('reports.missing-hdd-audit')}</h5>
        <ToolkitProvider
          keyField="id"
          data={ data.hdd_audit_missing }
          columns={ DataErasureColumns }
          exportCSV={{
              fileName: 'missing_hdd_audit_report.csv'
          }}
        >
          {
              props => (
                <div>
                  <Alert color="danger">
                    <MyExportCSV  { ...props.csvProps } />
                    <BootstrapTable
                        wrapperClasses="table-responsive f-10"
                        condensed
                        bordered={ false }
                        striped
                        noDataIndication={ () => { return translate('asset.no-rows') }}
                        { ...props.baseProps } 
                    />
                    </Alert>
              </div>
            )
          }
        </ToolkitProvider>
    </> : null} 
    {data.disposition_missing.length ?
    <>
        <h5>{translate('reports.missing-disposition')}</h5>
        <ToolkitProvider
          keyField="id"
          data={ data.disposition_missing }
          columns={ getAssetsOnReportColumns(me.type, 'specification') }
          exportCSV={{
              fileName: 'missing_disposition.csv'
          }}
        >
          {
              props => (
                <div>
                  <Alert color="warning">
                    <MyExportCSV  { ...props.csvProps } />
                    <BootstrapTable
                        wrapperClasses="table-responsive f-10"
                        condensed
                        bordered={ false }
                        striped
                        noDataIndication={ () => { return translate('asset.no-rows') }}
                        { ...props.baseProps }
                    />
                    </Alert>
              </div>
            )
          }
        </ToolkitProvider>
    </> : null}
      <Table className="table mt-5 mb-5">
        <Tbody>
          <Tr>
              <Th>LOT #</Th>
              <Td className="text-muted">{data.lot_report?.lot_number}</Td>
          </Tr>
          <Tr>
              <Th>{translate('customer.customer')}</Th>
              <Td className="text-muted">{data.lot_report?.customer?.name}
              </Td>
          </Tr>
          <Tr>
              <Th>{translate('reports.received')}</Th>
              <Td className="text-muted">{data.lot_report?.received}
              </Td>
          </Tr>
          <Tr>
              <Th>{translate('reports.finalized')}</Th>
              <Td className="text-muted">{data.lot_report?.finalized}
              </Td>
          </Tr>
          <Tr>
              <Th>CO<sub>2</sub>e</Th>
              <Td className="text-muted">{parseInt(data.lot_report?.co2e).toLocaleString()}
              </Td>
          </Tr>
          <Tr>
              <Th>Assets</Th>
              <Td className="text-muted">{data.assets.length.toLocaleString()}
              </Td>
          </Tr>
          <Tr>
              <Th>Hard drives</Th>
              <Td className="text-muted">{data.hard_drives.length.toLocaleString()}
              </Td>
          </Tr>
          <Tr>
              <Th>{translate('reports.blancco')}</Th>
              <Td className="text-muted">{data.blancco.length.toLocaleString()}
              </Td>
          </Tr>
          <Tr>
              <Th>{translate('reports.deleted-assets')}</Th>
              <Td className="text-muted">{data.deleted.toLocaleString()}
              </Td>
          </Tr>
        </Tbody>
      </Table>
    </Col>
    <SectionModal
      lot_number={data?.lot_report?.lot_number}
      error={error}
      success={success}
      deleteModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
    </>
)
        }

export default GeneratedReport

