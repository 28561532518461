import React from "react";
import { CardSubtitle } from 'reactstrap'
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import translate from "helpers/Translation/translate";
import moment from "moment";


const ContractSubstarts = ({ contract }) => (

<CardSubtitle>
    {translate("contract.create-substart-text")}
    <ul className="ecosystem-list mt-3">
      <li>
        <strong>{translate("contract.agreement-no")} </strong>: {contract?.agreement_no}
      </li>
      {contract?.sub_start_contracts?.length > 0 ?
      <li className="mt-3 col-lg-8 col-sm-12">
        <strong >{translate("contract.other-substarts")} </strong> : {contract?.sub_start_contracts.length < 1 ? '-' : null}
        <Table className="mt-3">
          <Tbody>
          <Tr>
            <Th>Status</Th>
            <Th>{translate("contract.title")}</Th>
            <Th>{translate("contract.start")}</Th>
            <Th>{translate("contract.end")}</Th>
          </Tr>
          {contract?.sub_start_contracts?.map(contract =>
          <Tr>
            <Td>{contract.active ? <span className="badge badge-pill badge-success">{translate('active')}</span>:
              <span className="badge badge-pill badge-danger">{translate('inactive')}</span>}
            </Td>
            <Td>{contract.title}</Td>
            <Td>{moment(contract.start_date).format("YYYY-MM-DD")}</Td>
            <Td>{moment(contract.end_date).format("YYYY-MM-DD")}</Td>
          </Tr>
            )}
            </Tbody>
        </Table>
      </li>
      : null}
    </ul>
  </CardSubtitle>
)

export default ContractSubstarts