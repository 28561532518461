import {
    contracts,
    assets,
    customers,
    resellers,
    users,
    products,
    status
} from 'store/api'


const getUrl = model => {

    switch(model) {
        case 'customer': return customers
        case 'customers': return customers
        case 'reseller': return resellers
        case 'resellers': return resellers
        case 'product': return products
        case 'products': return products
        case 'asset': return assets
        case 'assets': return assets
        case 'contract': return contracts
        case 'contracts': return contracts
        case 'status': return status
        case 'statuses': return status
        case 'user': return users
        case 'users': return users
    default: return null
    }

}

export default getUrl