export const FILE_FIELDS = [
    "ID",
    "Serial Number (*)",
    "Supplier Code (*)",
    "Supplier (*)",
    "In Use",
    "User",
    "Computer Name",
    "MAC Address",
    "Installation Address",
    "Inventoried Date",
    "Delivery Date",
    "Invoice Date",
    "Price",
    "Monthly cost",
    "Reference",
    "Order No",
    "Invoice No",
    "Sub Supplier No",
    "Customer ID",
    "Contract ID",
    "Product ID",
    "Cost center",
    "Additional info",
    "Description",
    "Warranty end date",
    "IMEI Number",
    "Status"
];
