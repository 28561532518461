import React from 'react';
import {  useSelector } from "react-redux";
import Suggestions from './Suggestions'

const HeaderSearch = ({ handleInputChange, results }) => {

  const locale = useSelector(state => state.Global.locale)
  
  return (
  <form className="app-search d-block">
    <div className="form-group m-0">
      <div className="input-group">
        <div className="position-relative" style={{ width: '100%' }}>
          <input
            type="text"
            className="form-control"
            placeholder={locale === 'en' ? "Search" : 'Sök'}
            onChange={e => handleInputChange(e)}/>
            <span className="bx bx-search-alt"></span>
        </div>
        {results?.length > 0 ? <Suggestions results={results} />: null}
      </div>
    </div>
  </form>
    )
}



export default HeaderSearch