import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/reducer";

import Global from "./global/reducer";
import Model from './model/reducer'
import Stats from './stats/reducer'

import Assets from './asset/reducer'
import Contracts from './contract/reducer'
import Users from './user/reducer'
import Form from './form/reducer'
import Dashboard from './dashboard/reducer'
import Products from './product/reducer'
import Tool from './tool/reducer'
import Report from './report/reducer'


const rootReducer = combineReducers({
  // public
  // Account,
  Assets,
  Contracts,
  // ForgetPassword,
  Layout,
  Login,
  Global,
  Stats,
  Users,
  Form,
  Dashboard,
  Products,
  Tool,
  Model,
  Report
});

export default rootReducer;
