import React from "react"
import { Link } from "react-router-dom";
import { Can, translate } from "helpers/helpers";
import { Icons } from 'assets/styles/index';
import NavItem from './NavItem'

const NavAssets = ({ toggleMenu, layoutType }) => (

        <NavItem
            id={'asset'}
            layoutType={layoutType}
            item={
            <>
            <i className={Icons.asset}></i>
            <span className="ml-1">Assets</span>
            </>
            }
            menu={
            <ul className="sub-menu ecosystem-list pl-2">
                <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                    <Link to="/assets" >{translate('nav.assets-list')}</Link>
                 </li>
                <Can I="create" a="Asset">
                    {() => (
                        <>
                            <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                                <Link to="/assets/create">{translate('nav.assets-create')}</Link>
                                </li>
                            <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                                <Link to="/assets/import">{translate('nav.assets-import')}</Link>
                                </li>
                        <Can I="create" a="Assetstatus">
                            {() => (
                                <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                                    <Link to="/assets/status">{translate('nav.assets-status')}</Link>
                                </li>
                                )}
                        </Can>
                        </>
                    )}
                </Can>
                <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                    <Link to="/assets/history">{translate('nav.assets-history')}</Link>
                 </li>
            </ul>
            }
        />
    )


export default NavAssets