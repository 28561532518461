import {
    GET_WIDGETS_SUCCESS,
    GET_WIDGETS_REQUEST,
    GET_WIDGETS_FAILURE,
    CLEAR_STATE
} from "./actionTypes";

import { dashboard } from '../api'

export const getWidgets = () => {
  return async dispatch => {
      dispatch({ type: GET_WIDGETS_REQUEST})
      try {
          const dashboard_widgets = await dashboard.get('/widgets');
          return dispatch ({ type: GET_WIDGETS_SUCCESS, payload: dashboard_widgets.data});
        } catch (error) {
            return dispatch ({ type: GET_WIDGETS_FAILURE }) }
      }
}


export const clearState = () => { return async dispatch => {dispatch({ type: CLEAR_STATE})}}