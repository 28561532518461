// @flow
import {
    NOTIFY_USER_ABOUT_REPORT_REQUEST,
    NOTIFY_USER_ABOUT_REPORT_SUCCESS,
    NOTIFY_USER_ABOUT_REPORT_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    CLEAR_STATE,
    GET_PREDIFINED_PERMISSIONS_REQUEST,
    GET_PREDIFINED_PERMISSIONS_SUCCESS,
    GET_PREDIFINED_PERMISSIONS_FAILURE,
    TWOFA_SETUP_REQUEST,
    TWOFA_SETUP_SUCCESS,
    TWOFA_SETUP_FAILURE,
    TWOFA_ENABLE_REQUEST,
    TWOFA_ENABLE_SUCCESS,
    TWOFA_ENABLE_FAILURE,
    TWOFA_DISABLE_REQUEST,
    TWOFA_DISABLE_SUCCESS,
    TWOFA_DISABLE_FAILURE,
    GENERATE_API_TOKEN_REQUEST,
    GENERATE_API_TOKEN_SUCCESS,
    GENERATE_API_TOKEN_FAILURE,
    GET_API_TOKEN_REQUEST,
    GET_API_TOKEN_SUCCESS,
    GET_API_TOKEN_FAILURE,
    DELETE_API_TOKEN_REQUEST,
    DELETE_API_TOKEN_SUCCESS,
    DELETE_API_TOKEN_FAILURE,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    CREATE_KEY_ACCOUNT_MANAGER_SUCCESS,
    HANDLE_KEY_ACCOUNT_MANAGER_REQUEST,
    HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS,
    HANDLE_KEY_ACCOUNT_MANAGER_FAILURE,
    DELETE_KEY_ACCOUNT_MANAGER_REQUEST,
    DELETE_KEY_ACCOUNT_MANAGER_SUCCESS,
    DELETE_KEY_ACCOUNT_MANAGER_FAILURE,
    GET_KEY_ACCOUNT_MANAGERS_REQUEST,
    GET_KEY_ACCOUNT_MANAGERS_SUCCESS,
    GET_KEY_ACCOUNT_MANAGERS_FAILURE,
    DONT_SHOW_TOUR_REQUEST,
    DONT_SHOW_TOUR_SUCCESS,
    DONT_SHOW_TOUR_FAILURE,
} from "./actionTypes";

const INIT_STATE = {
    user: {},
    users: [],
    KeyAccountManagers: [],
    KeyAccountManagerCustomers: [],
    all_permissions: [],
    predifined_permissions: [],
    isFetching: false,
    notFound: false,
    error: null,
    errors: [],
    success: null,
    secret: null,
    qrcode: null,
    apiToken: null,
};

const Users = (state = INIT_STATE, action) => {
	switch (action.type) {
        case GET_PREDIFINED_PERMISSIONS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_PREDIFINED_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                predifined_permissions: action.payload,
                all_permissions: action.all_permissions
            };
        case GET_PREDIFINED_PERMISSIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case NOTIFY_USER_ABOUT_REPORT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case NOTIFY_USER_ABOUT_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
                user: action.payload
            };
        case NOTIFY_USER_ABOUT_REPORT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
                };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
                };
        case CREATE_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case CREATE_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages

                };
        case TWOFA_SETUP_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case TWOFA_SETUP_SUCCESS:
            return {
                ...state,
                isFetching: false,
                secret: action.payload.secret,
                qrcode: action.payload.qrcode
            };
        case TWOFA_SETUP_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case TWOFA_ENABLE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case TWOFA_ENABLE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case TWOFA_ENABLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case TWOFA_DISABLE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case TWOFA_DISABLE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case TWOFA_DISABLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GENERATE_API_TOKEN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GENERATE_API_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
                apiToken: action.payload.data.api_token
            };
        case GENERATE_API_TOKEN_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_API_TOKEN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_API_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                apiToken: action.payload
            };
        case GET_API_TOKEN_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case DELETE_API_TOKEN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_API_TOKEN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case DELETE_API_TOKEN_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
                errors: [],
                success: null
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages
            };
        case HANDLE_KEY_ACCOUNT_MANAGER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case CREATE_KEY_ACCOUNT_MANAGER_SUCCESS:
                return {
                    ...state,
                    isFetching: false,
                    success: action.message
                };
        case HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case HANDLE_KEY_ACCOUNT_MANAGER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case DELETE_KEY_ACCOUNT_MANAGER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_KEY_ACCOUNT_MANAGER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case DELETE_KEY_ACCOUNT_MANAGER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_KEY_ACCOUNT_MANAGERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_KEY_ACCOUNT_MANAGERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                KeyAccountManagers: action.payload
            };
        case GET_KEY_ACCOUNT_MANAGERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case CLEAR_STATE:
            return {
                ...state,
                error: null,
                success: null,
                updateSuccess: null
            };
        case DONT_SHOW_TOUR_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DONT_SHOW_TOUR_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case DONT_SHOW_TOUR_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
		default:
			return state;
	}
};

export default Users;
