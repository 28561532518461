import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// Redux and actions
import { getWithPagination, clearModelState } from 'store/model/actions'
// Components
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import RemotePagination from './RemotePagination';
import RemotePaginationFilters from './RemotePaginationFilters';
import { LoaderInsideComponent } from 'Components/Global';
// Functions
import { translate } from 'helpers/helpers';

import { Button, UncontrolledCollapse } from 'reactstrap';
import { CustomToggleList } from 'Pages/Asset/Components/AssetList/Table';


const RemotePaginationTable= ({
  model,
  pagination,
  columns,
  pageUrl,
  buttonText,
  createLink,
  path,
  can,
  keyField,
  selectRow,
  predefinedFilter,
  getFilter,
  children,
}) => {

  const dispatch = useDispatch();

  const params = {}
  const [filter, setFilter ] = useState(predefinedFilter ?? {})
  const [perPage, setPerPage] = useState(25);
  const [tableSort, setTableSort] = useState(null);
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState(null);
  const [url, setUrl] = useState(pageUrl);

  const isFetching = useSelector(state => state.Model.isFetching);


  const getModelWithPagination = (params) => {
    dispatch(clearModelState());
    dispatch(getWithPagination(model, url, params));
  };

  const perPageChange = (e) => {
    setPerPage(e.target.value);
  };

  const pageChange = (url) => {
    setUrl(url);
  };

  const onTableChange = (type, newState) => {
    if(newState.sortOrder === 'asc') setTableSort(newState.sortField)
    else setTableSort(`-${newState.sortField}`)
    setOrder(newState.sortOrder)
  }


  const handleKeyPress = (e) => {
    if (e.key === "Enter") getModelWithPagination(params);
  };

  const getInputValueForSearch = (e) => {
    setSearch(e.target.value);
  };

  const onChange = (e) => {
    const changeFilter = filter
    if(e.target.name === 'include_expired' && filter.include_expired === 'on') {
      changeFilter[e.target.name] = false
    } else {
      changeFilter[e.target.name] = e.target.value
    }
    setFilter(changeFilter)
    params['filter'] = changeFilter
    getModelWithPagination(params)
  };

  const setDate = (e, name) => {
    const changeFilter = filter
    changeFilter[name] = e
    setFilter(changeFilter)
    params['filter'] = changeFilter
    getModelWithPagination(params)

  };

  const clearFilter = () => {
    setFilter({})
  }

  useEffect(() => {
    params['per_page'] = perPage
    params['sort'] = tableSort
    params['search'] = search
    params['filter'] = predefinedFilter ?? filter
    getModelWithPagination(params)
  }, [perPage, search, tableSort, url, model, filter]);

  useEffect(() => {
    if(getFilter) getFilter(filter);
  }, [filter]);


  return (
      <ToolkitProvider
        keyField="id"
        data={pagination?.data ?? []}
        columns={columns}
        columnToggle
      >
        {(props) => (
          <>
          <RemotePaginationFilters
              filter={filter}
              onChange={onChange}
              clearFilter={clearFilter}
              path={path}
              predefinedFilter={predefinedFilter}
              model={model}
              can={can}
              createLink={createLink}
              buttonText={buttonText}
              children={children}
              handleKeyPress={handleKeyPress}
              getInputValueForSearch={getInputValueForSearch}
              setDate={setDate}
              props={props}
              />
                {model === 'assets' ?
                  <div className="d-flex">
                    <Button id={"select"} color="light" className="mr-1 mb-2 btn-sm">
                      <i className="mdi mdi-format-columns"></i>
                    </Button>
                    <UncontrolledCollapse toggler={`#select`} className="ecosystem-column-toggler-dropdown">
                      <CustomToggleList {...props.columnToggleProps} />
                    </UncontrolledCollapse>
                    {children}
                  </div>
                : null}
            {isFetching ?  <LoaderInsideComponent /> :
            <RemotePagination
              dataField={tableSort}
              keyFiled={keyField}
              order={order}
              data={pagination ?? []}
              sizePerPage={params?.per_page}
              page={params?.page}
              columns={columns}
              indication={() => {
                return translate("asset.no-rows");
              }}
              onTableChange={onTableChange}
              perPageChange={perPageChange}
              pageChange={pageChange}
              props={props}
              selectRow={selectRow}
            />
            } 
          </>
        )}
      </ToolkitProvider>
  );
};

export default RemotePaginationTable;

