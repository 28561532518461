import React from 'react';
import { Link } from "react-router-dom";
import  { Can, translate  } from 'helpers/helpers';
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ContractCustomerDetails = ({ contract }) => (
        <div className="table-responsive">
            <Table className="table mb-0">
                <Tbody>
                    <Can I="read" a="Customer">
                        {() => (
                            <Tr>
                                <Th scope="row">{translate('customer.customer')}</Th>
                                <Td><Link to={`/customers/${contract?.customer?.id ?? null}`}>{contract?.customer_name}</Link></Td>
                            </Tr>
                        )}
                    </Can>
                    <Tr>
                        <Th scope="row">{translate('address.address')}</Th>
                        <Td>{contract?.customer_address}</Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.zip')}</Th>
                        <Td>{contract?.customer_zip}</Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.city')}</Th>
                        <Td>{contract?.customer_city}</Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.email')}</Th>
                        <Td><a href={`mailto: ${contract?.customer_email}`}>{contract?.customer_email}</a></Td>
                    </Tr>
                    <Tr>
                        <Th scope="row">{translate('address.phone')}</Th>
                        <Td><a href={`mailto: ${contract?.customer_email}`}>{contract?.customer_telephone}</a></Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    )

export default ContractCustomerDetails;
