import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { translate } from 'helpers/helpers';

const Pagination = ({ perPageChange, sizePerPage, paginationProps, from, to, totalSize, links, pageChange }) => (

   <Row>
      <Col lg={6}>
          <select onChange={perPageChange} defaultValue={sizePerPage}>
            {paginationProps?.sizePerPageList?.map((value, i )=> (
                <option key={i} value={value}>{value}</option>
            ))}
          </select>
      </Col>
      <Col lg={6} className="d-flex justify-content-lg-end">
        <label className="mr-3">
          {translate('showing')} { from } {translate('to')} { to } {translate('of')} {totalSize}
        </label>
      </Col>
      <Col lg={12} className="text-center mt-3">
        {links?.map((link, i) => {
          return(
            link.url &&
            <Button key={i} className="mr-1" color={link.active ? "light" : "outline"} onClick={() => pageChange(link.url)}>
              {link.label === "pagination.previous" ? translate('pagination.previous') :
              link.label === "pagination.next" ? translate('pagination.next') : link.label}
            </Button>
          )
        })}
      </Col>
    </Row>
    )

export default Pagination