import React, { useState} from "react";
import { Label, Nav, NavItem, NavLink, TabContent, TabPane  } from "reactstrap";
import { AvGroup} from "availity-reactstrap-validation";

import translate from 'helpers/Translation/translate';
import { TextInputField } from 'Components/Form'
import classnames from 'classnames';

const SerialNumbers = ({ asset, handleChange }) => {

  const [ activeTab, setActiveTab ] = useState('single');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab)
  }


  return (
    <>
      <Nav tabs className="nav-tabs-custom">
        <NavItem key={'single'}>
          <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active:  activeTab === 'single' })}
              onClick={() => toggle('single')}>
               {translate('asset.single-serialnumbers')}
          </NavLink>
        </NavItem>
        <NavItem key={'double'}>
          <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active:  activeTab === 'multiple' })}
              onClick={() => toggle('multiple')}>
               {translate('asset.multiple-serialnumbers')}
          </NavLink>
        </NavItem>
    </Nav>
  <TabContent activeTab={activeTab}>
      <TabPane key={'single-tab'}  tabId={'single'} className="p-3">
        <AvGroup>
          <Label className="control-label">{translate('asset.serialnumber')} <span className="text-success f-10">{translate('required')}</span></Label>
            <TextInputField
              name={"serial_number"}
              id={"serial_number"}
              value={asset?.serial_number}
              errorMessage={'form.enter-serial-numbers'}
              onChange={e => handleChange(e)}
              type={'text'}
              required={activeTab === 'single' ? true : false}
              />
          </AvGroup>
          <AvGroup>
          <Label className="control-label">{translate('asset.user')}</Label>
            <TextInputField
              name={"user"}
              id={"user"}
              value={asset?.user}
              errorMessage={'form.enter-user'}
              onChange={e => handleChange(e)}
              type={'text'}
              />
          </AvGroup>
      </TabPane>
      <TabPane key={'multiple-tab'}  tabId={'multiple'} className="p-3">
        <AvGroup>
          <Label className="control-label">{translate('asset.serialnumbers')} <span className="text-success f-10">{translate('required')}</span></Label>
            <TextInputField
              name={"serial_numbers"}
              id={"serial_numbers"}
              value={asset?.serial_numbers}
              errorMessage={'form.enter-serial-numbers'}
              placeholder={'form.enter-serial-numbers'}
              onChange={e => handleChange(e)}
              type={'text'}
              required={activeTab === 'multiple' ? true : false}
              />
          </AvGroup>
      </TabPane>
</TabContent>
</>
    )
  }

export default SerialNumbers 
