import React, { useEffect, useState } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPredifinedPermissions, createUser, updateUser, clearState } from 'store/user/actions'
import { getById, deleteImage } from "store/model/actions";
import { getCustomerDropdown, getResellerDropdown } from "store/form/actions";

// Global Components
import { Container, Col, Card, CardBody, CardTitle, Button, CardHeader } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { LoaderOnPage } from "Components/Global";
import ContactSection from "Components/Form/ContactSection";
import CreateContainer from "Containers/CreateContainer";
// User components
import UserInput from "./Components/create/UserInput";
import Permissions from "./Components/create/Permissions";
// Styles
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
// Functions
import { translate } from 'helpers/helpers';


const CreateUser = ({ match, history }) => {

  const dispatch = useDispatch()

  const [ user, setUser ] = useState({ zip: null, telephone : null })
  const [ selected_permissions, setSelectedPermissions ] = useState([])
  const [ editMode, setEditMode ] = useState(false)

  const id = match.params.id
  const url = match.path

  const me = useSelector(state => state.Global.me)
  const predifined_permissions = useSelector(state => state.Users.predifined_permissions)
  const isFetching = useSelector(state => state.Users.isFetching)
  const success = useSelector(state => state.Users.success)

  const customers = useSelector(state => state.Form.customersDropdown)
  const resellers = useSelector(state => state.Form.resellersDropdown)
  const notFound = useSelector(state => state.Model.notFound)

  const error = useSelector(state => state.Users.error)
  const errors = useSelector(state => state.Users.errors)
  const all_permissions = useSelector(state => state.Users.all_permissions)

  const getTheUser = () => {
    dispatch(getById(id, 'user'))
    .then(res => {
      const this_user = res.payload
      if(this_user) {
        setUser(this_user)
        setSelectedPermissions(this_user.has_permissions)
      }
    })
  }

  useEffect(() => {

    if(me.type === 'admin' || me.type === 'reseller') {
      dispatch(getResellerDropdown())
      dispatch(getCustomerDropdown())
    }
    dispatch(getPredifinedPermissions())

    if(url === '/users/:id(\\d+)/edit') {
      setEditMode(true)
      getTheUser()
    }
    return () => {
      dispatch(clearState())
    }
  },[url, me])


  const handleValidSubmit = e => {
    dispatch(clearState())
    editMode ?
    dispatch(updateUser(user, selected_permissions)) :
    dispatch(createUser(user, selected_permissions))
    .then(res => {
      if(res.type === 'CREATE_USER_SUCCESS') {
        history.push(`/users`, 'message.create-user-success');
      }
    })
  }

  const handleChange = (e) => {
    user[e.target.name] =  e.target.value
    setUser(user)
  };

  const selectChange = (e, change) => {
    user[change] = e.target.value
    setUser(user)

    if (change === "role") {
      const role = e.target.value;
      const filter = predifined_permissions.filter(permission => permission.role === role)
      const permissions = filter[0]['permissions']
      setSelectedPermissions(permissions)
      user['type'] = role
      setUser(user)
    }
  };

  const removeProfilePicture = () => {
    dispatch(deleteImage(id, 'user'))
    .then(() => {
      getTheUser()
    })
  }

  const updatePermissionState = (permission) => {
    let check_this = permission[0]
    if(selected_permissions?.includes(check_this)) {
      const permissions = selected_permissions.filter(item => item !== check_this)
      setSelectedPermissions(permissions)
    } else {
      setSelectedPermissions(permissions => [...permissions, check_this])
    }
  }

    return (
      <CreateContainer
        isFetching={isFetching}
        Model="User"
        breadcrumbItem={editMode ? translate("user.edit") : translate("user.create")}
        breadcrumbTitle={translate("user.user")}
        error={error}
        success={success}
        errors={errors}
        editMode={editMode}
        >
        {!(editMode && notFound) ?
          <AvForm onValidSubmit={handleValidSubmit}>
              <Col lg={12}>
                <Card>
                  <Container>
                    <UserInput
                      removeProfilePicture={removeProfilePicture}
                      customers={customers}
                      resellers={resellers}
                      user={user}
                      selectChange={selectChange}
                      handleChange={handleChange}
                      editMode={editMode}
                      />
                    {isFetching ? <LoaderOnPage />
                    : me?.id !== user?.id ?
                      <>
                        <CardHeader style={ecosystemCardHeader}>
                          <CardTitle >{translate("user.permissions")}</CardTitle>
                        </CardHeader>
                        <Permissions
                          all_permissions={all_permissions}
                          selected_permissions={selected_permissions}
                          user={user}
                          updatePermissionState={updatePermissionState}
                          />
                      </>
                    : null }
                    </Container>
                  </Card>
                  <Card>
                    <CardHeader style={ecosystemCardHeader}>
                      <CardTitle> {translate("form.contact")} </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Container>
                        <ContactSection model={user} handleChange={handleChange} onUser/>
                      </Container>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody style={{ justifyContent: "center", display: "flex"}}>
                      <Button type="submit" className="float-right " color="success" >
                        {editMode ? translate("user.edit") : translate("user.create")}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
          </AvForm>
        : null}
      </CreateContainer>
    );
  }


export default CreateUser;


