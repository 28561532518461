import { LOCALES } from '../locales'

var trans = {
    [LOCALES.SWEDISH]: {
        'DELETE_RESELLER_SUCCESS' : 'Återförsäljaren raderades',
        'GET_ASSET_FAILURE' : 'Denna asset finns ej',
        'GET_ASSETS_FAILURE' : 'Assets kunde inte hämtas',
        'GET_HISTORY_FAILURE': 'Historik kunde ej hämtas',
        'GET_HISTORY_FOR_ASSET_FAILURE': 'Historik för denna asset kunde ej hämtas',
        'IMPORT_ASSETS_CSV_FAILURE' : 'Assets kunde ej importeras',
        'UPDATE_ASSET_SUCCESS': 'Asset uppdaterad',
        'UPDATE_ASSET_FAILURE': 'Asset kunde ej uppdateras',
        'ACTIVATE_ASSET_SUCCESS' : 'Asset är nu markerad som i bruk',
        'ACTIVATE_ASSET_FAILURE' : 'Något gick fel, asseten är fortfarande markerad som ej i bruk',
        'DEACTIVATE_ASSET_SUCCESS': 'Asseten är nu markerad som ej i bruk',
        'DEACTIVATE_ASSET_FAILURE': 'Något gick fel, Asseten är fortfarande markerad som i bruk',
        'DELETE_ASSET_FAILURE' : 'Asset kunde ej raderas',
        'DELETE_ASSET_SUCCESS' : 'Asset raderades',
        'DELETE_ASSETS_FAILURE' : 'Assets kunde ej raderas',
        'DELETE_ASSETS_SUCCESS' : '{assets} Assets raderades',
        'INVENTORY_ASSET_SUCCESS' : 'Asset markerades som inventerad',
        'INVENTORY_ASSET_FAILURE' : 'Asset kunde ej inventeras',
        'INVENTORY_ASSETS_SUCCESS' : '{assets} assets markerade som inventerade',
        'INVENTORY_ASSETS_FAILURE' : 'Assets kunde ej inventeras',
        'CREATE_ASSETS_FAILURE' : 'Asset kunde inte skapas',
        'CREATE_ASSET_SUCCESS' : '{assets} Assets skapades!',
        'GET_ICECAT_DATA_FAILURE' : 'Kunde ej kontakta Icecats databas, var vänlig försök igen',
        'SYNC_ITGLUE_SUCCESS' : 'Synkronisering utförd!',
        'IMPORT_ASSETS_CSV_SUCCESS' : '{assets} assets importerade',

        'GET_WIDGETS_FAILURE' : 'Dashboard data kunde ej laddas',

        'message.sort-failure' : 'Denna sortering är ej tillgänglig',
        'message.role-kam-deleted' : 'Rollen som Key Account manager togs bort för användaren',
        'message.sync-icecat-success' : 'Produkter med tillgänglig information på Icecat har uppdaterats',
        'message.csv-import-with-error' : 'Alla rader i filen kunde inte importeras. Se felaktiga rader',
        'message.warranty-info-calculated' : 'Garantiinformationen hämtad!',
        'message.create-assets/statuse-failure' : 'Status kunde ej skapas',
        'message.report-generated-with-errors' : 'Rapporten genererades med ofullständig data',
        'message.logout' : 'Loggar ut...',
        'message.products-failure' : 'Hämtning av produkter misslyckades',
        'message.sync-it-glue-failure' : 'ITGlue sync misslyckades',
        'message.delete-customer-failure' : 'Kunde kunde ej raderas',
        'message.product-failure' : 'Produkterna kunde ej hämtas',
        'message.mail-sent' : 'Mail har skickats',
        'message.excel-lot-failure': 'Excelfil kunde ej skapas',
        'message.wrong-hdd-file' : 'Filen som importerades som hard drive audit report är ej korrekt',
        'message.wrong-lot-file' : 'Filen som importerades som lot detail audit report är ej korrekt',
        'message.asset-import-to-contract' : '{assets} assets importerades till kontraktet',
        'message.blancco-report-failure' : 'Blanccorapport kunde ej hämtas',
        'message.lot-failure' : 'Rapporten kunde ej hämtas',
        'message.no-annual-report' : 'Det finns ingen sammanställning tillgänglig för denna kund',
        'message.excel-annual-failure' : 'Excelrapport kunde ej laddas ner, var god försök igen.',
        'message.delete-report-failure' : 'Radering av rapport misslyckades',
        'message.delete-report-success' : 'Rapporten raderades',
        'message.report-success' : 'Rapport genererad',
        'message.report-failure' : 'Rapporten kunde inte skapas, var vänlig förösk igen',
        'message.create-assets/statuse-success' : 'Ny status skapad',
        'message.update-assets/statuse-success' : 'Status uppdaterad',
        'message.asset-import-fail': 'Assetimport kunde ej genomföras',
        'message.csv-import-success' : 'CSV-fil importerad',
        'message.delete-product-success' : 'Produkten raderades!'   ,
        'message.delete-product-failure' : 'Produkten kunde inte raderas',
        'message.update-product-success' : 'Produkten uppdaterades',
        'message.create-contract-success' : 'Kontraktet skapades',
        'message.create-contract-failure' : 'Kontraktet kunde inte skapas',
        'message.delete-contract-success' : 'Kontrakt raderades',
        'message.update-contract-success' : 'Kontrakt uppdaterades',
        'message.create-reseller-success' : 'Återförsäljaren skapades',
        'message.create-reseller-failure' : 'Återförsäljaren kunde inte skapas',
        'message.create-customer-success' : 'Kunden skapades',
        'message.create-customer-failure' : 'Kunden kunde inte skapas',
        'message.delete-logo-success' : 'Bild raderad',
        'message.delete-logo-failure' : 'Bild kunde inte raderas',
        'message.delete-asset-success' : 'Asset raderad',
        'message.delete-asset-failure' : 'Asset kunde inte raderas',
        'message.create-asset-success' : 'Asset/s skapade',
        'message.create-asset-failure' : 'Asset/s kunde ej skapas',
        'message.asset-failure' : 'Asset kunde ej hämtas',
        'message.history-failure' : 'Historik kunde ej laddas',
        'message.no-lot-report' : 'Det finns ingen rapport för detta LOT nummer',


        'product-created-succesfully' : 'Produkten skapades',

        'message.tour-in-profile' : 'Klart! Du kan alltid hitta guiden igen i dropdown menyn under ditt namn.',
        'message.something-wrong' : 'Något gick fel, försök igen',
        'message.password-old-new-same' : 'Det nya och det gamla lösenordet kan inte vara detsamma',
        'message.system-error' : 'Var god försök igen senare',
        'message.data-failure' : 'Kunde inte hämta dashboard data',
        'message.contract-failure' : 'Kontraktet finns ej',
        'message.contracts-failure' : 'Kontrakt kunde inte hämtas',
        'message.users-failure' : 'Användarna kunde inte hämtas',
        'message.user-failure' : 'Användaren kunde inte hämtas',
        'message.reseller-failure' : 'Återförsäljaren kunde inte hämtas',
        'message.resellers-failure' : 'Återförsäljarna kunde inte hämtas',
        'message.update-reseller-failure': 'Återförsäljaren kunde inte uppdateras',
        'message.quote-failure' : 'Offerten kunde inte hämtas',
        'message.quotes-failure' : 'Offerterna kunde inte hämtas',
        'message.credit-check-failure' : 'Kreditbedömningarna kunde inte hämtas',
        'message.contract-log-failure' : 'Kontraktsloggen kunde inte hämtas',
        'message.contracts-log-failure' : 'Kunde ej hämta kontraktsloggar',
        'message.customer-create-success' : 'Kunden skapades!',
        'message.customer-failure' : 'Kund kunde ej hämtas',
        'message.customers-failure' : 'Kunderna kunde inte hämtas',
        'message.delete-customer-failure' : 'Något gick fel och kunden raderades inte, var vänlig försök igen!',
        'message.delete-user-success' : 'Användaren är nu raderad.',
        'message.delete-user-failure' : 'Något gick fel och användaren raderades inte, var vänlig försök igen!',


        'message.contract-delete-success' : 'Kontraktet är nu raderat',
        'message.contract-delete-fail' : 'Kunde inte radera kontrakt',
        'message.archive-contract-success' : 'Kontraktet är nu arkiverat',
        'message.archive-contract-failure' : 'Kontraktet kunde inte arkiveras',
        'message.contract-create-fail' : 'Kunde inte skapa kontrakt',
        'message.contract-create-success' : 'Kontraktet skapades',
        'message.get-reseller-customers-failure' : 'Kunde inte hämta återförsäljarens kunder',
        'message.contract-update-failure' : 'Kontraktet kunde inte uppdateras',
        'message.contract-update-success' : 'Kontraktet uppdaterades',
        'message.quote-create-fail': 'Kunde inte skapa offert',
        'message.delete-reseller-failure' : 'Återförsäljaren kunde inte raderas',
        'message.delete-reseller-success' : 'Återförsäljaren raderades!',
        'message.reseller-create-fail' : 'Återförsäljaren kunde inte skapas',
        'message.uc-lookup-failure' : 'Kunde inte hämta bolagsinformation för detta nummer. Kolla om du har med ett - tecken och testa med att ta bort det.',
        'message.icecat-fail' : 'Sökningen kunde ej genomföras',
        'message.icecat-no-info' : 'Ingen information kunde hämtas. var vänlig fyll i produktinformationen manuellt nedan',
        'message.get-banks-failure' : 'Bankerna kunde ej hämtas',
        'message.get-bank-failure' : 'Banken kunde ej hämtas',
        'message.delete-bank-failure' : 'Kunde inte radera bank',
        'message.update-bank-failure': 'Banken kunde inte uppdateras',
        'message.create-bank-failure': 'Banken kunde inte skapas',
        'message.get-tariffs-failure' : 'Stibor räntorna kunde ej hämtas',
        'message.get-suppliers-failure' : 'Leverantörerna kunde ej hämtas',
        'message.get-supplier-failure' : 'Leverantören kunde inte hämtas',
        'message.delete-supplier-failure' : 'Leverantören kunde inte raderas',
        'message.create-supplier-failure' : 'Leverantören kunde inte skapas',
        'message.update-supplier-failure' : 'Leverantören kunde inte uppdateras',
        'message.get-blancco-report-fail' : 'Förfrågan mot Blanccos API kunde ej genomföras',
        'message.update-customer-failure' : 'Kunden kunde inte uppdateras',
        'message.update-supplier-success' : 'Leverantören uppdaterades!',
        'message.update-bank-success' : 'Banken uppdaterades!',
        'message.update-quote-failure' : 'Offerten kunde inte uppdateras',
        'message.update-quote-success' : 'Offerten uppdaterades!',
        'message.handle-expiration-failure' : 'Nytt utgångsalternativ kunde inte väljas',
        'message.handle-expiration-success' : 'Nytt utgångsalternativ bekräftat för kontrakt',
        'message.update-customer-success' : 'Kunden uppdaterades!',
        'message.upload-logo-success' : 'Ny logga uppladdad',
        'message.upload-logo-failure' : 'Logga ej uppladdad',
        'message.delete-customer-success' : 'Kunden raderades!',
        'message.delete-bank-success' : 'Banken raderades!',
        'message.create-supplier-success' : 'Leverantören skapades',
        'message.delete-supplier-success' : 'Leverantören raderades',
        'message.update-tariff-success': 'Värdet uppdaterades',
        'message.update-tariff-failure': 'Något gick fel, var vänlig försök igen',
        'message.buyout-quote-failure' : 'Något gick fel, var vänlig försök igen',
        'message.buyout-quote-success' : 'En förfrågan om offert har skickats',
        'message.handle-termination-success' : 'Nytt datum för kontraktets avslut har valts',
        'message.handle-termination-failure' : 'Något gick fel, var vänlig försök igen',
        'message.update-user-success' : 'Användaren uppdaterades!',
        'message.update-user-failure' : 'Användaren kunde inte uppdateras',
        'message.asset-log-no-auth' : 'Du har inte behörighet att se denna assets log',
        'message.get-blancco-report-no-hit' : 'Blancco sökning utförd utan träff',
        'message.products-fail' : 'Kunde inte hämta produkter',
        'message.market-value-import-fail' : 'Kunde inte importera filen',
        'message.update-market-value-failure' : 'Maknadsvärdet kunde ej uppdateras',
        'message.update-eol-failure' : 'EOL kunde ej uppdateras',
        'message.update-eol-success' : 'EOL uppdaterades',
        'message.market-value-import-success' : 'De estimerade marknadsvärdena importerades',
        'message.create-user-success' : 'Användaren skapad! Ett mail har skickats till email addressen.',
        'message.create-user-failure' : 'Användaren kunde inte skapas',
        'message.update-reseller-success' : 'Återförsäljaren uppdaterades',
        'message.asset-edit-success' : 'Asset uppdaterades',
        'message.update-market-value-success' : 'Estimerat marknadsvärde uppdaterades',
        'message.auth-enable-success' : 'Två faktor autentisering är nu aktiverad!',
        'message.auth-deleted-success' : 'Två faktor autentisering är nu inaktiv och du kan logga in med enbart lösenord!',
        'message.wrong-password' : 'Fel användarnamn eller lösenord',
        'message.api-token-generated' : 'API nyckel skapades!',
        'message.api-token-deleted' : 'API nyckel raderades!',
        'message.update-password-failure' : 'Lösenordet kunde ej uppdateras, var vänlig försök igen',
        'message.update-password-success' : 'Du kan nu använda ditt nya lösenord!',
        'message.email-not-existing' : 'Det finns inget registrerat konto med denna email.',
        'message.no-internet' : 'Kunde ej kontakta servern, var vänlig försök igen om en stund',
        'message.demo' : 'Vissa funktioner som email och hämtning av garantitider är inaktiva i demoversionen.',
        'message.demo-reset-success' : 'Demodata återställdes',
        'message.demo-reset-failure' : 'Demodata kunde inte nollställas',
        'message.demo-reset' : 'Återställ demo data',
        'message.status-exists' : 'Status finns redan!',
        'message.status-created' : 'Status skapades!',
        'message.get-status-failure' : 'Statusar kunde ej hämtas',
        'message.create-status-failure' : 'Status kunde ej skapas',
        'message.delete-status-failure' : 'Status kunde ej raderas',
        'message.delete-status-success' : 'Status raderades',
        'message.status-edit-success' : 'Status uppdaterades',
        'message.status-edit-fail' : 'Status kunde ej uppdateras',
        'message.create-kam-success' : 'Key account manager skapades!',
        'message.create-kam-failure' : 'Kunde ej skapa ny Key account manager',
        'message.fetch-kam-failure' : 'Kunde ej hämta Key account managers',
        'message.role-deleted-success': 'Användaren har ej längre rollen key account manager och kan nu se alla kunder',
        'message.update-kam-success': 'Key account manager uppdaterades',
        'message.page-not-found' : 'Sidan kunde ej hittas',
        'message.assets-removed-success' : 'Assets frikopplade från detta kontrakt.',
        'message.assets-removed-failure' : 'Assets kunde ej kopplas från detta kontrakt. Var god försök igen.',
        'message.assets-delete-fail' : 'Valda assets raderades ej. Var vänlig försök igen.',
        'message.assets-connected-success' : 'Assets kopplades till kontrakt',
        'message.assets-connected-failure' : 'Assets kunde ej kopplas till kontrakt',
        'message.assets-moved-success' : 'Assets flyttades till valt kontrakt',
        'message.contract-extend-success' : 'Förlängningen skapades',
        'message.contract-extend-failure' : 'Förlängningen kunde inte genomföras,',
        'message.create-product-success' : 'Produkten skapades!',
        'message.create-product-failure' : 'Något gick fel, var vänlig försök igen',
        'message.update-product-failure' : 'Något gick fel, var vänlig försök igen',
        'message.delete-image-success' : 'Profilbild raderad',
        'message.delete-image-failure' : 'Profilbild kunde ej raderas',
        'message.years-failure' : 'Åren kunde ej hämtas',
        'message.annual-report-failure' : 'Rapporten kunde ej hämtas',
        'message.morecorelotports-failure' : 'Auditrapporter kunde ej hämtas',


        'message.asset-csv-failed' : 'Export av assets kunde ej genomföras, var vänlig försök igen.',
        'message.assets-failure' : 'Assets kunde ej hämtas',
        'message.reports-failure' : 'Rapporter kunde ej hämtas',


        'address.address': 'Adress',
        'address.zip': 'Postkod',
        'address.city': 'Stad',
        'address.email': 'Mail',
        'address.phone': 'Tel',
        'handle-emv' : 'Hantera EMV',
        'created-at' : 'Skapades',
        'updated-at' : 'Uppdaterades',
        'remove-image' : 'Radera bild',
        'move-to' : 'Flytta till',
        'clear' : 'Rensa',
        'graph' : 'Graf',
        'lost-device' : 'Har du förlorat din enhet?',
        'delete-image' : 'Radera bild',
        'change-category' : 'Byt kategori',
        'monthly' : 'Månadsvis',
        'new-search' : 'Ny sökning',
        'search' : 'Sök',
        'no-eol-data' : 'EOL kan ej beräknas utan en tilldelad kategori',
        'substarts' : 'Substarter',
        'remove' : 'Ta bort',
        'clear-filters': 'Rensa filter',
        'sending' : 'Skickar...',
        'from' : 'från',
        'Key Account Managers' : 'Key Account Managers',

        'products.new-this.month' : 'Nya manuella produkter denna månad',

        'agreement.agreements' : 'Supportavtal',
        'agreement.list' : 'Supportavtal lista',
        'agreement.create' : 'Lägg upp supportavtal',

        'asset.enter-product' : 'Ange produkt',
        'asset.enter-product-info' : 'Fyll i produktens EAN-kod eller leverantör med leverantörens produktkod för att söka efter produkten.',

        'asset.manual-warranty' : 'Manuell garanti',
        'asset.total' : 'Totalt i systemet',
        'asset.new-this-month' : 'Nya assets denna månad',
        'asset.market-value' : 'Estimerat marknadsvärde',
        'asset.create-manually' : 'Skapa assets manuellt',
        'asset.computer-name' : 'Datornamn',
        'asset.mac-address' : 'Mac adress',
        'asset.no-delivery-date' : 'Leveransdatum saknas',
        'asset.age' : 'Ålder',
        'asset.filters' : 'Filter',
        'asset.life-cycle-data': 'Livscykel information',
        'asset.no-age' : 'Leveransdatum saknas',
        'asset.search-serial' : 'Sök efter serienummer',
        'asset.hide' : 'Dölj assets',
        'asset.display' : 'Visa assets',
        'asset.import' : 'Importera assets med CSV-fil',
        'asset.import-title' : 'Importering och massuppdatering av assets',
        'asset.import-text1' : 'Här kan du importera en CSV fil för automatisk mappning emot en kund eller kontrakt. För detta behöver du ha kontraktets eller kundens ID-nummer, dessa finner du på respektive info-sida.',
        'asset.import-text2' : 'Importerar du redan existerande serienummer så kommer informationen att uppdateras – detta verktyg kan alltså användas för att både lägga till nya assets och uppdatera tidigare inlagda assets.',
        'asset.help': 'Hjälp',
        'asset.help-text' : 'För att importera assets behöver du en korrekt formaterad fil.',
        'asset.download-purchased' : 'Ladda ned exempelfil - köpta assets',
        'asset.download-rented' : 'Ladda ned exempelfil - hyrda assets',
        'asset.serial' : 'Serienummer',
        'asset.contract' : 'Kontrakt ID (hittas på kontraktsidan)',
        'asset.customer' : 'Kund ID (hittas på kundkortet)',
        'asset.user' : 'Användare',
        'asset.cost' : 'Kostndasställe',
        'asset.montlhy' : 'Månadshyra',
        'asset.start' : 'Datum, i formatet ÅÅÅÅ-MM-DD',
        'asset.warranty-date' : 'Datum, i formatet ÅÅÅÅ-MM-DD',

        'asset.assets' : 'Assets',
        'asset.title' : 'Titel',
        'asset.description' : 'Beskrivning',
        'asset.picture' : 'Bild',
        'asset.product-code' : 'Tillverkarens produktkod',
        'asset.product-number': 'Produktnummer',
        'asset.ean' : 'EAN-kod',
        'asset.category' : 'Kategori',
        'asset.supplier/manufacturer' : 'Leverantör/tillverkare',
        'asset.more-info' : 'Datablad och mer information',
        'asset.technical-pdf' : 'Teknisk PDF',
        'asset.web' : 'Produktwebbplats',
        'asset.assets-show' : 'Visar endast assets för kontrakt',
        'asset.show-all' : 'Visa alla ...',
        'asset.amount' : 'Antal assets',
        'asset.amount-help' : 'Grafen visar det högsta antalet assets som kunden har hanterat under månaden.',
        'asset.spare-parts' : 'Reservdelar S/N',
        'asset.spare-parts-2' : 'Reservdelar P/N',
        'asset.warranty' : 'Garanti',
        'asset.warranty-overdue' : 'Utgången',
        'asset.warranty-ends' : 'Garanti upphör',
        'asset.warranty-search' : 'Garanti sökning',
        'asset.link-opens' : 'Länkarna öppnas i ett nytt fönster',
        'asset.no-links' : 'Inga länkar tillgängliga',
        'asset.warrantyspare' : 'Garanti & reservdelar',
        'asset.free-text' : 'Fritext',
        'asset.month' : 'Månad',
        'asset.in-use' : 'I bruk',
        'asset.product' : 'Produkt',
        'asset.details' : 'Detaljer',
        'asset.as-active' : 'Markera som aktiv',
        'asset.as-inactive' : 'Markera som inaktiv',
        'asset.as-inactive-sure' : 'Vill du markera denna asset som inaktiv? Information om användare kommer att försvinna.',
        'asset.as-active-sure' : 'Ange namn på användaren i fältet nedan',
        'asset.cost-center' : 'Kostnadsställe',
        'asset.installation-address' : 'Installationsadress',
        'asset.order' : 'Order',
        'asset.price' : 'Pris',
        'asset.invoice-no' : 'Fakturanummer',
        'asset.invoice-date' : 'Fakturadatum',
        'asset.delivery-date' : 'Leveransdatum',
        'asset.delivery-address' : 'Leveransadress',
        'asset.sub-supplier' : 'Underleverantör',
        'asset.reference' : 'Referens',
        'asset.inventoried-at' : 'Senast inventerad',
        'asset.manual-inventory' : 'Manuell inventering',
        'asset.additional-info' : 'Mer info',
        'asset.savings' : 'CO2e besparingen för denna produkt om du väljer att lämna in den för återbruk istället för återvinning.',
        'asset.savings-kg' : 'kg CO2e-eq',
        'asset.life-cycle' : 'Livscykeldata',
        'asset.potential-savings' : 'Besparing',
        'asset.no-env-data' : 'Denna asset saknar kategori och har därför ingen livcykeldata tillgänglig.',
        'asset.add' : 'Lägg till nya assets',
        'asset.use-icecat' : 'Använd produktinformation från Icecat-databasen',
        'asset.dont-use-icecat' : 'Lägg till produkt manuellt',
        'asset.monthly' : 'Månadskostnad',
        'asset.monthly-price' : 'Månadskostn./pris',
        'asset.start-date' : 'Startdatum',
        'asset.status-rent': 'Hyrd',
        'asset.status-lease': 'Leasad',
        'asset.status-purchased': 'Köpt',
        'asset.update-info' : 'Updatera information',
        'asset.active' : 'Aktiva',
        'asset.inactive' : 'Inaktiva',
        'asset.contract/order' : 'Kontrakt/Order',
        'asset.purchased' : 'Köpta',
        'asset.rented' : 'Hyrda',
        'asset.warranty-support' : 'Garanti och support',
        'assets.by-category': 'Assets kategorier',
        'assets.stats': 'Statistik',
        'assets.delete' : 'Radera assets',
        'assets.inventory' : 'Inventera',
        'asset.delete' : 'Radera asset',
        'asset.delete-sure' : 'Är du säker på att du vill radera denna asset?',
        'asset.eol' : 'Estimaterad EOL',
        'asset.multi-search' : 'Serienummer ',
        'asset.multi-placeholder' : 'Använd radbrytning eller kommatecken för att separera serienummer',
        'asset.imported-success': 'assets importerade',
        'asset.new' : 'Nya',
        'asset.updated' : 'Uppdaterade',
        'asset.invalid' : 'Felaktigt formaterade',
        'asset.imported-assets' : 'Importerade assets',
        'asset.edit' : 'Redigera asset',
        'asset.created' : 'Skapad',
        'asset.no-rows' : 'Inga rader att visa',
        'asset.web-not-available' : 'Länk till websida saknas',
        'asset.technical-pdf-not-available' : 'PDF saknas',
        'asset.icecat-info-update' : 'Produktinformationen ovan hämtas från Icecats databas och kan ej uppdateras manuellt',
        'asset.no-category' : 'Ingen kategori',
        'asset.no-description' : 'Ingen beskrivning',
        'asset.no-title' : 'Ingen titel',
        'asset.no-ean' : 'Ingen EAN kod',
        'asset.no-supplier' : 'Ingen leverantör',
        'asset.no-supplier-code' : 'Ingen leverantörskod',
        'asset.add-info' : 'Här kan du lägga till assets manuellt genom att fylla i ägare, produkt och detaljer. Du kan lägga till flera serienummer samtidigt genom att separera dem med komma tecken.',
        'asset.blancco-check-no-res' : 'Blancco sökning utan träff',
        'asset.add-new-product' : 'Hittar du ej vad du söker? Lägg till ny produkt',
        'asset.weight' : 'Vikt',
        'asset.icecat-fetch-info' : 'Din assets produktinformation hämtas från Icecats produktdatabas. Om information ej finns tillgänglig där så går det att fylla i den manuellt. Sök antingen på EAN-kod eller på tillverkare tillsammans med produktkod.',
        'asset.download-file' : 'Ladda ner exempelfil',
        'asset.no-product-code' : 'Ingen produktkod tillgänglig',
        'asset.owner' : 'Ägare',
        'asset.select-product': 'Sök efter produkt  i produktbiblioteket',
        'asset.manual-info' : 'Fyll i Produktinformation manuellt',
        'asset.purchased-info' : 'Information för köpta assets',
        'asset.back' : 'Tillbaka till assets',
        'asset.select-other-product' : 'Välj en annan produkt',
        'asset.first-customer' : 'Du måste först skapa en kund för att kunna lägga upp nya assets.',
        'asset.create-customer-permission' : 'Du har inte behörighet att skapa nya kunder i systemet. Tala med er admin.',
        'asset.create-as-purchased' : 'Denna asset kommer att läggas in i systemet som en köpt asset',
        'asset.category-report' : 'Kategori i rapport',
        'asset.report-explanation' : '(Källa: IVL Svenska Miljöinstitutet)',
        'asset.no-eol' : 'EOL ej angivet',
        'asset.no-category-info' : 'Denna asset saknar kategori och har därför ingen livcykeldata tillgänglig.',
        'asset.not-in-use' : 'Ej i bruk',
        'asset.category-no-eol' : 'Denna produktkategori saknar EOL data',
        'asset.connected-to-contract' : 'Du kan ej ändra typ så länge denna asset är knuten till ett kontakt',
        'asset.fmv' : 'EMV',
        'asset.specs' : 'Produktspecifikation',
        'asset.process-model' : 'Process modell',
        'asset.emv': 'Estimaterat marknadsvärde',
        'asset.warranty-check' : 'Garanti status',
        'asset.log' : 'Asset log',
        'asset.warranty-end': 'Garanti slutdatum',
        'asset.purchased-at': 'Inköpsdatum',
        'asset.all': 'Alla assets',
        'asset.status-handle' : 'Hantera status',
        'asset.type' : 'Typ',
        'asset.statuses' : 'Asset status',
        'asset.drop-csv' : 'Släpp eller välj din CSV fil här',
        'asset.handle-assets-on-contract' : 'Hantera assets på kontrakt',
        'asset.history': 'Asset historik',
        'asset.asset-log' : 'Loggar',
        'asset.assets-deleted' : 'Raderade assets',
        'asset.purchase-date' : 'Inköpsdatum',
        'asset.time' : 'Tidpunkt',
        'asset.take-stock' : 'Inventera',
        'asset.monthly-cost' : 'Månadskostnad',
        'asset.status-connected' : 'Antal assets i systemet med denna status',
        'asset.serialnumber' : 'Serienummer',
        'asset.serialnumbers' : 'Serienummer',
        'asset.single-serialnumbers' : 'Ett serienummer',
        'asset.multiple-serialnumbers' : 'Flera serienummer',

        'azure.need-to-sign-in' : 'Du behöver logga in med ditt microsoft konto för att kunna hämta denna information',


        'no-warranty' : 'Saknas',
        'csv-download' : 'Ladda ned CSV',
        'required' : 'Obligatoriskt',


        'asset.asset' : 'Asset',
        'assetstatus.assetstatus' : 'Asset status',
        'nav.assets-api' : 'Assets API',

        'billing' : 'Fakturering',
        'details' : 'Detaljer',
        'period' : 'Period',
        'ecosystem-customer' : 'Betalande kund',
        'enter-ean' : 'EAN koden måste vara 13 nummer lång',
        'Statuses' : 'Statusar',
        'select-filters' : 'Filter',
        'select-actions' : 'Funktioner',
        'select-assets' : 'Välj assets',


        'blancco.list' : 'Lista Blancco rapporter',

        'bank.name' : 'Namn',
        'bank.full-name' : 'Fullständigt namn',
        'bank.updated' : 'Senast uppdaterad',
        'bank.create' : 'Lägg till ny bank',
        'bank.banks' : 'Banker',
        'bank.bank' : 'Bank',
        'bank.delete' : 'Radera bank',
        'bank.delete-sure' : 'Är du säker på att du vill radera denna bank?',
        'bank.edit' : 'Uppdatera bank info',
        'bank.invoice-fee' : 'Faktureringsavgift',
        'bank.invoice-fee-profit' : 'Faktureringsavgift kickback',
        'bank.arrangement-fee' : 'Uppläggningsavgift',
        'bank.arrangement-fee-profit' : 'Uppläggningsavgift kickback',
        'bank.delayed-rent-profit' : 'Del av framskjuten uppstart',
        'bank.extension-fee-profit' : 'Del av förlängning',
        'bank.insurance-fee' : 'Försäkringsavgift',
        'bank.insurance-fee-profit' : 'Försäkringsavgift kickback',
        'bank.go-back' : 'Gå tillbaka',

        'button.move-assets' : 'Flytta {assets} assets till valt kontrakt',
        'button.assets-move' : 'Flytta assets',
        'button.move-assets-text' : 'Dessa assets kommer att flyttas över till det valda kontraktet.',


        'button.remove-assets' : 'Ta bort {assets} assets från kontrakt',
        'button.assets-remove' : 'Ta bort {assets} från kontrakt',
        'button.assets-remove-text' : 'Dessa assets kommer inte längre att tillhöra detta kontrakt.',

        'button.add-assets' : 'Koppla {assets} assets till detta kontrakt',
        'button.assets-add' : 'Koppla assets',
        'button.add-assets-text' : 'Dessa assets kommer att kopplas till detta kontrakt.',

        'button.delete-assets' : 'Radera {assets} assets',
        'button.assets-delete' : 'Radera assets',
        'button.assets-delete-text' : 'Är du säker att på att du vill radera dessa assets?',

        'button.inventory-assets' : 'Inventera {assets} assets',
        'button.assets-inventory' : 'Inventera dessa assets',
        'button.assets-inventory-text' : 'Dessa assets kommer att uppdateras med datum och tidpunkt för senaste inventering.',

        'button.actions' : 'Alternativ för {assets} asset',


        'blancco.info' : 'Detta är ett testverktyg för att hämta data från Blancco-API:et.',
        'blancco.search' : 'Sök på serienummer för asset; t.ex:',
        'blancco.or' : 'eller',
        'blancco.search-help' : 'OBS! Systemet har stöd för flera assets samtidigt, det här testverktyget tar endast ett serienummer som input dock.',
        'blancco.PDF' : 'Vid PDF kommer en PDF-fil att genereras och öppnas. I annat fall visas returnerad rådata',
        'blancco.result' : 'Resultat för din sökning. Resultatet består av rådata i XML-format.',
        'blancco.back' : 'Gå tillbaka och gör en ny sökning',
        'blancco.no-result' : 'Inga resultat',
        'blancco.error' : 'Något gick fel i sökningen, följande felmeddelande returnerades från Icecat-databasen:',

        'close' : 'Stäng',
        'cancel' : 'Avbryt',
        'type' : 'Typ',


        'category.has-eol': 'Med EOL',
        'category.no-eol': 'Utan EOL',

        'contract.id' : 'Kontrakt id',
        'contract.add-assets' : 'Lägg till assets till kontrakt',
        'contract.substart' : 'Substarter',
        'contract.contracts' : 'Kontrakt',
        'contract.create' : 'Skapa kontrakt',
        'contract.hide' : 'Dölj kontrakt',
        'contract.display' : 'Visa kontrakt',
        'contract.contract' : 'Kontrakt',
        'contract.title' : 'Titel',
        'contract-extension-start': 'Förlängning start datum',
        'contract.end' : 'Slutdatum',
        'contract.expiration' : 'Vid utgång',
        'contract.agreement-no' : 'Avtalsnummer',
        'contract.total' : 'Total',
        'contract.created' : 'Skapad',
        'contract.list' : 'Lista',
        'contract.archived' : 'Arkiverade',
        'contract.search' : 'Sök kontrakt',
        'contract.log' : 'Kontraktlogg',
        'contract.logs' : 'Logg',
        'contract.log-time' : 'Tid',
        'contract.log-comment' : 'Händelse',
        'contract.deleted' : 'Kontrakt raderat',
        'contract.delete' : 'Radera kontrakt',
        'contract.delete-help' : 'Är du säker på att du vill radera detta kontrakt?',
        'contract.delete-sure' : 'Är du säker på att du vill radera detta kontrakt?',
        'contract.edit-expiration' : 'Uppdatera utgångsalternativ',
        'contract.upon-expiration' : 'Vid kontraktsutgång',
        'contract.by' : 'Upprättat av',
        'contract.type' : 'Typ',
        'contract.length' : 'Längd',
        'contract.agreement-nr' : 'Avtalsnummer',
        'contract.insurance' : 'Försäkring',
        'contract.setup-fee' : 'Uppläggningsavgift',
        'contract.residual-value' : 'Restvärde',
        'contract.object-value' : 'Objektvärde',
        'contract.bank' : 'Bank/finansiering',
        'contract.customer-details' : 'Kunddetaljer',
        'contract.extension' : 'Förlängningar',
        'contract.start' : 'Startdatum',
        'contract.monthly-cost' : 'Månadskostnad',
        'contract.requested-by' : 'Begärd av',
        'contract.update' : 'Uppdatering',
        'contract.verify' : 'Verifiera',
        'contract.rent' : 'Hyra',
        'contract.lease' : 'Leasing',
        'contract.end-date' : 'Slutdatum',
        'contract.selected-by': 'Utgångsalternativ valt av',
        'contract.confirmed-by': 'Bekeräftat av',
        'contract.expiration-extend' : 'Kontraktet kommer automatiskt att förlängas med 12 månader vid utångsdatum om inget annat val har gjorts. Du kan här välja hur många månader du vill förlänga kontraktet med.',
        'contract.expiration-terminate' : 'Kontraktet avslutas vid utgångsdatumet och produkterna returneras. Om du av någon anledning önskar att avsluta kontrakt tidigare än {date} så kan du välja ett nytt datum nedan.',
        'contract.buy-out-all' : 'Ja, jag vill ha en offert för alla assets på detta kontrakt',
        'contract.select-assets' : 'Välj assets. (Tar dig till en annan vy)',
        'contract.buy-all' : 'Vill du köpa ut alla ({assets}) assets från detta kontrakt?',
        'contract.no-assets' : 'Det finns inga assets specificerade på detta kontrakt',
        'contract.terminated' : 'Avslutat',
        'contract.no-extensions' : 'Det finns inga förlängningar att visa på detta kontrakt',
        'contract.no-log' : 'Detta kontrakt har ingen tillgänglig historik',
        'contract.archive' : 'Arkivera kontrakt',
        'contract.start-date': 'Startdatum',
        'contract.special-conditions' : 'Särskilda villkor',
        'contract.select-monthly' : 'Välj enskild månadskostnad',
        'contract.down-payment' : 'Upplägningsavgift',
        'contract.surcharge-value' : 'Lösenvärde',
        'contract.payment-period' : 'Betalningsperiod',
        'contract.bank/financier' : 'Bank/finansiering',
        'contract.include-insurance' : 'Inkludera försäkring',
        'contract.select-end-date' : 'Välj slutdatum',
        'contract.use-card' : 'Använd tillgänglig information från kundkortet',
        'contract.customer-info' : 'Kundinformation',
        'contract.delivery-address' : 'Leveransadress',
        'contract.add-delivery-address' :'Lägg till annan leveransadress',
        'contract.delivery-zip' : 'Leverans postkod',
        'contract.delivery-city' : 'Leverans stad',
        'contract.comment' : 'Kommentar',
        'contract.monthly' : 'Månadsvis i förskott',
        'contract.quarterly' : 'Kvartalsvis i förskott',
        'contract.select-payment-period' : 'Välj betalningsperiod',
        'contract.option-extension' : 'Förlängning',
        'contract.option-surcharge' : 'Utköp',
        'contract.option-termination' : 'Avslutas',
        'contract.product' : 'Produkt',
        'contract.product-price' : 'Pris',
        'contract.product-amount' : 'Antal',
        'contract.add-product' : 'Lägg till produkt',
        'contract.edit' : 'Redigera kontrakt',
        'contract.change-montly-cost' : 'Ändra månadskostnad',
        'contract.residual-value-guarantor' : 'Restvärdesgarant',
        'contract.import-assets' : 'Importera assets till detta kontrakt',
        'contract.import-with-csv' : 'Importera assets med CSV fil',
        'contract.select-from-list': 'Lägg till assets från lista',
        'contract.remove-from-contract' : 'Ta bort assets från kontrakt',
        'contract.handle-assets' : 'Hantera assets',
        'contract.supplier' : 'Leverantör',
        'contract.description' : 'Beskrivning',
        'contract.active' : 'Aktiva kontrakt',
        'contract.inactive' : 'Inaktiva',
        'contract.archive-help' : 'När du arkiverar ett kontrakt så kommer alla assets knutna till detta kontrakt att raderas. Om du önskar att ha kvar dessa assets i systemet så bör du ta bort de från kontraktet eller förflytta de till ett nytt kontrakt innan du arkiverar det.',
        'contract.move-to-other-contract' : 'Flytta assets till ett annat kontrakt',
        'contract.create-copy' : 'Skapa en kopia',
        'contract.create-copy-text' : 'Du kan redigera informationen nedan. All information förutom tillhörande assets kommer att sparas som ett nytt kontrakt',
        'contract.is-active' : 'Aktivt',
        'contract.is-inactive' : 'Kontrakt kommer att listas som inaktivt.',
        'contract.extend' : 'Förläng kontrakt',
        'contract.extend-help' : 'Välj det nya slutdatumet för kontraktet. Tidigare datum kommer att listas under historiken.',
        'contract.new-end-date' : 'Nytt startdatum',
        'contract.back-to-contract' : 'Tillbaka till kontrakt',
        'contract.create-substart' : 'Skapa sub start',
        'contract.create-substart-text' : 'Denna substart kommer att tillhöra ramavtalet nedan. Assets som ansluts till denna substart kommer även att listas med huvudavtalet.',
        'contract.other-substarts' : 'Andra substarter',
        'contract.sub-starts' : 'Substarter',

        'direct' : 'Direktavtal',
        'framework' : 'Ramavtal',
        'substart' : 'Substart',
        'active' : 'Aktivt',
        'inactive' : 'Inaktivt',
        'rent' : 'Hyrd',
        'quarterly' : 'Kvartalsvis',

        'contact.mail' : 'Mail',
        'contact.address' : 'Adress',
        'contact.phone' : 'Tel',
        'contact.city' : 'Stad',
        'contact.zip' : 'Postkod',

        'complience.title' : 'Rapportering som stärker ert företags miljöprofil och uppfyller kraven i GDPR.',
        'complience.subheading' : 'Med en transparent och tydlig rapportering hjälper vi er med att bevisa efterlevnad av kraven i GDPR samtidigt som vi ger er underlag som styrker ert miljöengagemang.',
        'complience.list-1' : 'Hur stor är miljöpåverkan från er IT-utrustning? ',
        'complience.list-2' : 'Har ni någon gång funderat på hur stor miljöbesparing ni är med och bidrar till genom att välja återbruk framför återvinning? ',
        'complience.list-3' : 'Kan ni bevisa att era gamla datorer är raderade enligt kraven i GDPR?',
        'complience.text-1' : 'Vi ger er tillgång till all rapportering och information direkt i kundportalen där ni också har möjlighet ladda ner dessa rapporter och visa era kollegor eller bifoga till ert företags rapportering och redovisning.',
        'complience.text-2' : 'Det finns över 20 olika rapporter som styrker efterlevnad från början till slut. Dessa rapporter bildar tillsammans ett flöde som kan bifogas till allt från ISO arbete till externa IT-revisioner. ',
        'complience.list-title' : 'Till de vanligaste rapporterna hör:',
        'complience.list-4' : 'Mottagningsrapport',
        'complience.list-5' : 'Inventeringsrapport enheter',
        'complience.list-6' : 'Inventeringsrapport lagringsmedia',
        'complience.list-7' : 'Kategorirapport, vikt, antal och disposition',
        'complience.list-8' : 'Destruktionscertifikat',
        'complience.list-9' : 'Återvinningscertifikat',
        'complience.list-10' : 'Raderingscertifikat',
        'complience.list-11' : 'Miljörapport',
        'complience.list-12' : 'Ekonomirapport',
        'complience.contact' : 'Nyfiken på hur dessa rapporter ser ut och hur ni kan använda dom för att stärka ert varumärke? Hör gärna av er så berättar vi mer!',
        'contract.select-sub-start-date' : 'Välj Substart datum',
        'contract.sub-start-date' : 'Substart datum',
        'contract.framework' : 'Ramavtal',
        'contract.direct' : 'Direktavtal',
        'contract.history' : 'Historik',
        'contract.show-extensions' : 'Visa förlängningar',

        'customer.margin' : 'Marginal',
        'customer.customers' : 'Kunder',
        'customer.create' : 'Skapa kund',
        'customer.hide' : 'Dölj kunder',
        'customer.display' : 'Visa kunder',
        'customer.customer' : 'Kund',
        'customer.card' : 'Kundkort',
        'customer.edit' : 'Redigera kund',
        'customer.update' : 'Uppdatera kund',
        'customer.list' : 'Lista kunder',
        'customer.join' : 'Gick med',
        'customer.select' : 'Välj kund',
        'customer.delete' : 'Radera kund',
        'customer.delete-sure' : 'Är du säker på att du vill radera denna kund? Alla assets och information kopplad till kunden kommer att raderas permanent.',
        'customer.no-rows' : 'Inga kunder att visa',
        'customer.go-back' : 'Tillbaka till kunder',
        'customer.new' : 'Ny kund',
        'customer.nr' : 'Kund nr',


        'dashboard.contracts-due-soon': 'Kontrakt med slutdatum inom sex månader',
        'dashboard.warranty-opportunity' : 'Garantiinformation',
        'dashboard.assets-by-reseller' : 'Totalt antal assets för denna ÅF',
        'dashboard.hello': 'Välkommen',
        'dashboard.close' : 'Stäng',
        'dashboard.import-assets' : 'Importera assets',
        'dashboard.display-contracts' : 'Visa kontrakt',
        'dashboard.credit-checks' : 'Kreditbedömningar',
        'dashboard.show-team' : 'Visa användare',
        'dashboard.hide-team' : 'Dölj användare',
        'dashboard.contracts' : 'Kontrakt',
        'dashboard.contract' : 'Kontrakt',
        'dashboard.new-contracts' : '{value} nya kontrakt denna månad',
        'dashboard.quotes' : 'Offerter',
        'dashboard.quote' : 'Offert',
        'dashboard.new-quotes' : '{value} nya offerter denna månad',
        'dashboard.customers' : 'Kunder',
        'dashboard.customer' : 'Kund',
        'dashboard.reseller' : 'ÅF',
        'dashboard.new-customer' : '{value} nya kunder denna månad',
        'dashboard.assets' : 'Assets per kund',
        'dashboard.customer-assets' : 'Dina kunders assets',
        'dashboard.show-customers' : 'Visa kunder',
        'dashboard.hide-customers' : 'Dölj kunder',
        'dashboard.contract-log' : 'Kontrakt log',
        'dashboard.none' : 'Inga',
        'dashboard.new' : 'Nya',
        'dashboard.go-profile' : 'Till profilsida',
        'dashboard.handle' : 'Hantera Kontrakt och Offerter',
        'dashboard.reseller-expire-3' : 'Upphör inom 6 månader',
        'dashboard.reseller-expire-6' : 'Går ut snart',
        'dashboard.reseller-outside-warranty' : 'Utgången',
        'dashboard.reseller-resale' : 'Möjligt nyfärsäljningsvärde',
        'dashboard.reseller-warranty-status' : 'Garantistatus',
        'dashboard.reseller-fair-market-value' : 'Affärsmöjligheter',
        'dashboard.reseller-present-value' : 'Utanför garanti',
        'dashboard.reseller-near' : 'Mindre än 3 månader',
        'dashboard.reseller-long' : 'Mindre än 6 månader',
        'dashboard.fair-market-value' : 'Estimerat marknadsvärde',
        'dashboard.new-resale-value' : 'Nytt försäljningsvärde',
        'dashboard.reseller-unknown': 'Okänd',
        'dashboard.assets-by-customer' : 'Assets per kund',
        'dashboard.warranty' : 'Garantitider för assets',
        'dashboard.emission-data' : 'CO2e-besparing vid återbruk',
        'dashboard.reseller-inside' : 'Giltig',
        'dashboard.opportunity-graph' : 'Potentiella affärsmöjligheter per kund',
        'dashboard.warranty-status' : 'Garantistatus för assets',
        'dashboard.potential-saving': 'Potentiell miljöbesparing',
        'dashboard.opportunity' : 'Potentiella affärsmöjligheter',




        'distribution.title' : 'Cirkulär distribution av kvalitetssäkrade produkter och komponenter från de ledande tillverkarna',
        'distribution.sub-title' : '100% cirkulär betyder att den från första början är designad för återbruk där produkten tillåts att byta ägare utan inskränkning samt med möjlighet för brukaren att själv byta felande komponenter. ',
        'distribution.100' : 'Att välja en rekonditionerad produkt är 100% cirkulärt!',
        'distribution.100-text' : 'Vårt rekonditioneringsprogram ger er tillgång till samma beprövade teknik som används av de största OEM tillverkarna men till ett lägre pris. De produkter och komponenter vi processar är ordentligt kvalitetstestade, ofta genom flera fristående testmetoder och testprogram och vi garanterar fullt ut allt vi säljer vilket betyder trygga garantier upp till 3 år.',
        'distribution.100-text-2' : 'Vi riktar oss till återförsäljare, infrastrukturspecialister och datacenters som kräver högsta kvalitet, god tillgänglighet och som önskar utöka sitt engagemang mot en hållbar och cirkulär modell. ',
        'distribution.table-title' : 'Att köpa från oss ger betydande miljömässiga hållbarhetsfördelar, vi vill att ni skall förvänta er mer från den hårdvara ni köper, kräv att den alltid är 100% cirkulär. ',
        'distribution.table-subtitle' : 'Hur väljer man 100% cirkulära komponenter för ett utökat hållbarhetsengagemang?',
        'distribution.table-1-title' : 'Processorer & Minnesmoduler',
        'distribution.table-1-text-1' : 'Välj alltid original när ni byter eller uppgraderar er hårdvara. På så vis sparar ni pengar och bidrar till 100% cirkulärt. Med original menar vi den ursprungliga tillverkaren av komponenten såsom Intel för processorer och Hynix eller Samsung för minnesmoduler.',
        'distribution.table-1-text-2' : 'Självklart lagerför vi även komponenter från de stora OEM’arna, HP, IBM, Lenovo och Dell.',
        'distribution.table-2-title' : 'Hårddiskar',
        'distribution.table-2-text-1' : 'Samma prestanda, samma kvalitet och med trygga garantier upp till 70% lägre pris mot för nytt så finns det inga skäl att välja något annat än 100% cirkulärt.',
        'distribution.table-2-text-2' : 'Med vår raderings- test- och rekonditioneringsprocess av hårddiskar kan vi säkerställa högsta möjliga säkerhet och kvalitet oavsett disktyp eller diskstorlek.',
        'distribution.table-3-title' : 'Cirkulära komponenter',
        'distribution.table-3-text-1' : 'Det finns mer att spara på när det gäller miljö och pengar, välj rekonditionerade nätverkskort, HBA’er, GPU’er, GBIC’ar, SFP’er och mycket mer.',
        'distribution.table-3-text-2' : 'Den enda skillnaden mellan ett rekonditionerat 10GbE nätverkskort och ett nytt är priset, upp till 70% lägre pris.',
        'distribution.1' : 'Vi har alltid lager av kvalitetstestade med DDR3- och DDR4-minnen ',
        'distribution.2' : 'Vi har alltid lager av kvalitetstestade Intel v3 och v4 processorer' ,
        'distribution.3' : 'Brett utbud från olika tillverkare, även OEM’er',
        'distribution.4' : 'Vi erbjuder alltid kompabilitetskontroller',
        'distribution.5' : 'Stort utbud (1000+) av SAS, SATA, SSD i lager',
        'distribution.6' : 'Storlekar från 146GB till 8TB ',
        'distribution.7' : 'Från de stora tillverkarna och OEM’arna',
        'distribution.8' : 'Alltid konditionstestade med protokoll',
        'distribution.9' : 'PSU’er, HBA’er, grafikkort och expansionskort',
        'distribution.10' : 'All typer av kablage',
        'distribution.11' : 'Nätverkskomponenter till servrar och switchar såsom SFP’er m.m.',
        'distribution.form' : 'Vill ni gå med i vårt nätverk av kanalpartners och ta del av våra erbjudanden? VI hör gärna av oss och berättar mer!',

        'date' : 'Datum',
        'delete' : 'Radera',
        'edit' : 'Redigera',
        'archive': 'Arkivera',
        'update' : 'Uppdatera',
        'hide' : 'Dölj',
        'display' : 'Visa',
        'create' : 'Skapa',
        'include-all' : 'Inkludera alla',
        'include-all-categories' : 'Inkludera alla kategorier',
        'include-all-customers' : 'Inkludera alla kunder',
        'include-all-resellers' : 'Inkludera alla Återförsäljare',
        'include-all-suppliers' : 'Inkludera alla leverantörer',
        'inventory' : 'Inventera',
        'export-csv': 'Exportera till CSV',
        'info-not-avaiable' : 'Ej tillgänglig',
        'fetching' : 'Hämtar info',
        'clear-form' : 'Rensa fält',
        'select-months' : 'Välj antal månader',
        'read' : 'Läsa',
        'link': 'Länk',
        'editable-info' : 'Redigerbar information',
        'history': 'Historik',
        'no-info' : 'Ingen info',
        'no-available-data' : 'Ingen tillgänglig data',
        'no-auth' : 'Ej behörig',
        'no-auth-description' : 'Admin kan hjälpa dig med dina behörigheter',
        'no-category' : 'Kategori saknas',
        'no-title' : 'Titel saknas',
        'no-description' : 'Ingen beskrivning',
        'created' : 'Skapades',
        'upload-image' : 'Ladda upp produktbild',
        'archived': 'Arkiverat',
        'show-product' : 'Visa produkt info',

        'morecoreport.morecoreport' : 'Moreco rapporter',



        'email.sent' : 'Förfrågan om nytt lösenord har skickats',
        'email.something-wrong' : 'Något gick fel, vänligen försök igen',


        'erasure.title' : 'Fullt certifierad dataradering och datadestruktion',
        'erasure.subheading' : 'Det är extremt viktigt att lagringsmedia blir korrekt raderad innan IT-tillgångar avyttras, återanvänds eller skickas för återvinning.',
        'erasure.general-1' : 'Exponering av konfidentiell och känslig information har blivit en allt vanligare företeelse. I media skrivs det varje vecka om berättelser kring uppmärksammade fall som gäller förlust av känsliga uppgifter. I dagens informationssamhälle utgör våra informationstillgångar en betydande risk på grund av de stora mängder konfidentiell information som lagras på dem.',
        'erasure.general-2' : 'DEFINITIONEN AV KÄNSLIG ELLER KONFIDENTIELL INFORMATION ÄR INFORMATION SOM SKYDDAS MOT OBERÄTTIGAD SPRIDNING.',
        'erasure.general-3' : 'En enklare beskrivning eller snarare tumregel är: All information som ej är offentligt tillgänglig skall anses konfidentiell eller känslig. Alla företag investerar i flera olika lösningar för att skydda sig mot obehörig åtkomst till lagrad information. På de flesta företag finns det antivirussystem, brandväggar, VPN lösningar, IDS\'er (Intrusion Detection System) m.m. Hur många företag har då motsvarande informationsskydd när systemen fasas ut?',
        'erasure.general-4' : 'Har ert företag investerat i överskrivningsprogramvara, avmagnetiseringsutrustning eller en metalltugg? Troligen inte, fokus ligger på kärnverksamheten och leveranskvalitet till era kunder. Tyvärr duger det inte att borra tre hål i hårddisken, det enda man åstadkommit är att göra den obrukbar, information finns kvar och med dagens teknik och resurser kan merparten av informationen återskapas.',
        'erasure.general-5' : 'Det är extremt viktigt att lagringsmedia blir korrekt raderad innan IT-tillgångar avyttras, återanvänds eller skickas för återvinning. Företag och dess anställda är skyldiga enligt lag (1990:409) att garantera säker hantering av känslig information. Tyvärr räcker det idag inte enbart med att för egen del veta att informationen är korrekt raderad. För att slippa möta eventuella rättsliga påföljder som bristande efterlevnad kan innebära måste företagen kunna bevisa att informationen är korrekt förstörd enligt godkänd standard.',
        'erasure.experts' : 'Moreco är experter på dataradering och destruktion',
        'erasure.experts-text' : 'Vi bjuder på en timmes gratis konsultation inklusive behovsanalys. Behovsanalysen hjälper er att ta fram ett beslutsunderlag och ge svar på om ni behöver förändra något för att öka säkerheten. Vår paradgren är dock att utföra datadestruktionen åt er eller dataradering på plats hos er, något vi i stort sett är ensamma om att kunna utföra. Vi kan även hjälpa er att ta fram en tillförlitlig policy för datadestruktion.',

        'read-more' : 'Läs mer',
        'faq.general' : 'Allmänna frågor',
        'faq.security' : 'Ecosystem Säkerhet',
        'faq.privacy' : 'Integritetspolicy',
        'faq.support' : 'Support',
        'faq.comming-soon' : 'Mobilversionen kommer snart! Du kan besöka denna sida från din dator.',
        'faq.technical-support' : 'Upplever du tekniska problem eller har svårt att hitta det du söker? Kontakta oss gärna för support!',
        'faq.email-us' : 'Maila oss',
        'faq.assets-how' : 'Hur kan jag lägga upp mina assets i systemet?',
        'faq.assets-1' : 'Assets kan skapas på 3 olika sätt. Genom att ladda upp en CSV-fil, genom manuell inmatning och via vårt API.',
        'faq.assets-import-title' : 'Jag har många assets som jag vill ladda upp samtidigt',
        'faq.assets-import-text' : 'Du kan importera en CSV-fil med dina assets om du vill skapa eller uppdatera flera assets samtidigt. Ladda ner exempelfilen för att se vilka fält som krävs.',
        'faq.assets-import-link' : 'Gå till sidan för import',
        'faq.assets-manual-title' : 'Jag vill bara lägga till en typ av asset och har inte all produktinformation tillgänglig.',
        'faq.assets-manual-text' : 'På sidan lägg till ny asset kan du lägga till en eller flera assets av samma produkt manuellt. Du kan söka efter din produkt i vårt produktbibliotek och hittar du ej vad du söker där kan du göra en sökning i Icecats produkt katalog. När du har valt din produkt behöver du bara fylla i serienumret för din/dina assets och informationen du vill spara.',
        'faq.assets-manual-link' : 'Till sidan lägg till ny asset',
        'faq.assets-api-title' : 'Jag vill skapa en integration och hantera mina assets via ert API.',
        'faq.assets-api-text' : 'För att börja använda vårt API behöver du först skapa en nyckel. Detta gör du lätt via din profil.',
        'faq.assets-api-link' : 'Till min profil',
        'faq.assets-serial-title' : 'Varför behöver jag ange serienummret för mina assets?',
        'faq.assets-serial-text' : 'Vi använder serienummret för att hämta information om din asset. Med hjälp av detta kan vi söka efter tillgänglig garantidata och annan information som sedan används för olika beräkningar som exempelvis din assets förväntade livslängd.',
        'faq.assets-eol-title' : 'Vad är EOL?',
        'faq.assets-eol-text' : 'EOL är en förkortning för end of life, och kan översättas till förväntad livslängd. Vi beräknar den förväntade livslängden för varje asset med hjälp av produktens kategori och tillgängligt inköpsdatum/leveransdatum. Inköpsdatumet får vi oftast tillsammans med garantiinformationen men om du ser att det saknas är det viktigt att du fyller i leveransdatumet om du vill hålla koll på hur dina assets åldras.',

        'form.select-user' : 'Välj användare',
        'form.select-lotnumber' : 'Välj lot nummer',
        'form.select-year' : 'Välj år',
        'form.supplier-code' : 'Leverantörens produktkod',
        'form.enter-product-code' : 'Ange produktkod',
        'form.enter-weight' : 'Ange vikt',
        'form.enter-product-url' : 'Ange address till eventuel hemsida',
        'form.enter-market-value' : 'Ange marknadsvärde',
        'form.enter-pdf-url' : 'Ange url till eventuell pdf',
        'form.enter-ean' : 'Ange EAN, 13 siffror',
        'form.select-role' : 'Välj roll',

        'form.enter-computer-name' : 'Ange datornamn',
        'form.enter-mac-address' : 'Ange Mac adress',
        'form.enter-external-number': 'Eventuellt externt kund nummer',
        'form.select-a-product': 'Välj produkt',
        "form.enter-additional-info" : 'Mer information',
        'form.fetch' : 'Hämta info',
        'form.logo' : 'Ladda upp logotyp',
        'form.photo' : 'Profilbild',
        'form.drop' : 'Droppa fil här eller tryck för att välja.',
        'form.contact' : 'Kontaktinformation',
        'form.org-no' : 'Org. nr',
        'form.name' : 'Namn',
        'form.connect-reseller': 'Kopplad till Återförsäljare',
        'form.comments': 'Kommentarer',
        'form.avatar' : 'Ladda upp profilbild',
        'form.call-me' : 'Ring mig',
        'form.email-me' : 'Maila mig',
        'form.leave' : 'Lämna tom',
        'form.select-date' : 'Välj datum',
        'form.account' : 'Konto',
        'form.categories' : 'Kategorier',
        'form.select-customer' : 'Välj kund',
        'form.select-contract' : 'Ej kopplad till kontrakt',
        'form.select-product' : 'Välj produkt eller lägg till ny nedan',
        'form.select-category' : 'Välj kategori',
        'form.select-supplier' : 'Välj leverantör',
        'form.select-reseller' : 'Välj återförsäljare',
        'form.select-status' : 'Välj Status',
        'form.select-asset-type' : 'Välj typ',
        'form.select-reseller-first' : 'Välj ÅF först',
        'form.enter-customer-name': 'Kundens namn',
        'form.enter-customer-name-message': 'Ange Kundens namn',
        'form.enter-orgno': 'Organisationsnummer',
        'form.enter-orgno-message': 'Fyll i Organisationsnummer',
        'form.customer-city' : 'Kundens stad',
        'form.enter-customer-city' : 'Fyll i kundens stad',
        'form.customer-phone' : 'Kundens telefonnummer',
        'form.enter-customer-phone' : 'Fyll i kundens telefonnummer',
        'form.customer-zip' : 'Kundens postkod',
        'form.enter-customer-zip' : 'Fyll i kundens postkod',
        'form.customer-address' : 'Kundens adress',
        'form.enter-customer-address' : 'Fyll i kundens adress',
        'form.customer-email' : 'Kundens email',
        'form.enter-customer-email' : 'Fyll i kundens email',
        'form.enter-comment' : 'Lägg till kommentar',
        'form.enter-title' : 'Titel',
        'form.enter-title-message' : 'Välj en titel till kontraktet för att lättare kunna särskilja dem åt',
        'form.enter-agreement-no' : 'Avtalsnummer',
        'form.enter-agreement-no-message' : 'Fyll i avtalsnummer',
        'form.select-type' : 'Välj typ av kontrakt',
        'form.select-length' : 'Välj antal månader',
        'form.select-financier' : 'Välj bank/financiär',
        'form.surcharge' : 'Lösenvärde',
        'form.enter-surcharge' : 'Fyll i lösenvärde',
        'form.residual-value' : 'Restvärde',
        'form.enter-residual-value' : 'Fyll i restvärdet',
        'form.down-payment' : 'Upplägningsavgift',
        'form.enter-down-payment' : 'Fyll i eventuell upplägningsavgift',
        'form.monthly-cost' : 'Månadskostnad',
        'form.enter-monthly-cost' : 'Fyll i månadskostnad',
        'form.special-conditions' : 'Fyll i eventuella speciella villkor',
        'form.select-upon-expiration' : 'Välj utgångsalternativ',
        'form.delivery-address' : 'Leveransadress',
        'form.delivery-zip' : 'Leverans postkod',
        'form.delivery-city' : 'Leverans stad',
        'form.full-name': 'Fullständigt namn',
        'form.enter-supplier' : 'Ange tillverkare',
        'form.enter-supplier-code' : 'Ange tillverkarens kod',
        'form.enter-product-number' : 'Ange produktnummer',
        'form.enter-product-description' : 'Fyll i produkt beskrivning',
        'form.enter-cost-center' : 'Fyll i kostandsställe',
        'form.enter-serial-numbers' : 'Fyll i serienummer. Separera dem med kommatecken',
        'form.enter-user' : 'Fyll i eventuell användare av asset',
        'form.enter-installation-address' : 'Ange installationsadress',
        'form.enter-description': 'Fyll i beskrivning',
        'form.enter-invoice-no' : 'Fyll i fakturanummer',
        'form.enter-order-no' : 'Fyll i ordernummer',
        'form.enter-reference' : 'Ange referens',
        'form.enter-price' : 'Fyll i pris',
        'form.enter-org' : 'Fyll i organisationsnummer',
        'form.enter-name' : 'Fyll i namn',
        'form.enter-email' : 'Fyll i email',
        'form.enter-address' : 'Fyll i adress',
        'form.enter-zip' : 'Fyll i postkod',
        'form.enter-phone' : 'Fyll i telefonnummer',
        'form.enter-city' : 'Fyll i stad',
        'form.enter-comments' : 'Övriga kommentarer',
        'form.enter-delivery-address' : 'Andge leveransadress',
        'form.enter-delivery-zip' : 'Ange postkod',
        'form.enter-delivery-city' : 'Ange stad',
        'form.enter-password' : 'Fyll i nytt lösenord',
        'form.confirm-password' : 'Bekräfta nytt lösenord',
        'form.confirm-password-error' : 'Lösenorden stämmer ej överens',
        'form.password': 'Nytt Lösenord - minst 10 tecken',
        'form.enter-your-password' : 'Fyll i ditt lösenord',
        'form.six-digit-code' : '6 siffrig kod',
        'form.enter-seix-digit-code' : 'Fyll i den 6 siffriga koden från din enhet',
        'form.old-password' : 'Gammalt lösenord',
        'form.new-password' : 'Nytt lösenord',
        'form.enter-old-password' : 'Fyll i ditt gamla lösenord',
        'form.enter-new-password' : 'Fyll i ditt nya lösenord',
        'form.contact-info' : 'Kontaktinformation',
        'form.enter-sub-supplier' : 'Fyll i underleverantör',
        'form.no-status' : 'Ingen status',
        'form.enter-contract-supplier' : 'Leverantör för detta kontrakt',
        'form.enter-residual-value-guarantor' : 'Ange eventuell restvärdesgarant',
        'form.enter-bank' : 'Bank',
        'form.enter-object-value' : 'Objektvärde',
        'form.enter-special-conditions' : 'Ange särskilda villkor',
        'form.select-start-date-warning' : 'Du måste välja startdatumet',
        'form.select-end-date-warning' : 'Du måste välja slutdatumet',
        'form.contracts' : 'Välj kontrakt',
        'form.select-delivery-date-warning' : 'Leveransdatumet är obligatoriskt. Detta datum kommer att användas för beräkningar om inköpsdatumet ej går att hämta.',
        'form.no-user-info' : 'Ingen användarinformation',
        'form.eol-include-expired' : 'Inkludera utgågna assets',
        'form.enter-margin' : 'Ange marginal',


        'gdpr.person-title' : 'Behandling av personuppgifter i Ecosystem',
        'gdpr.sensitive-title' : 'Känsliga personuppgifter i Ecosystem',
        'gdpr.pul-title' : 'Ansvarig och biträde för uppgifter i Ecosystems tjänster',
        'gdpr.resp-title' : 'Ecosystem som personuppgiftsansvarig',
        'gdpr.li-title' : 'Grundläggande principer i GDPR',
        'gdpr.more-title' : 'Mer information om dataskyddsfördningen',

        'gdpr.title' : 'GDPR och dataskydd',
        'gdpr.sub-title' : 'GDPR står för General Data Protection Regulation och är en dataskyddsförordning från EU som är en lag i Sverige för att skydda individers integritet. Du som kund har ett flertal saker som du behöver tänka på gällande behandling av personuppgifter. Du är som kund personuppgiftsansvarig för personuppgifterna i Ecosystem. Ecosystem är personuppgiftsbiträde för dessa uppgifter.',
        'gdpr.person-1' : 'Personuppgifter kan förklaras som varje upplysning som avser en identifierad eller identifierbar enskild person (även kallad registrerad), varvid en identifierbar fysisk person är en person som direkt eller indirekt kan identifieras, särskilt med hänvisning till en identifierare som ett namn, ett identifikationsnummer, en lokaliseringsuppgift eller onlineidentifikatorer, eller till en eller flera faktorer som är specifika för den fysiska personens fysiska, fysiologiska, genetiska, psykiska, ekonomiska, kulturella eller sociala identitet. Behandling av dessa uppgifter innebär att du genomför en åtgärd eller kombination av åtgärder beträffande personuppgifter eller uppsättningar av personuppgifter, oberoende av om de utförs automatiserat eller ej. Exempel på behandling av personuppgifter är insamling, strukturering, lagring, bearbetning, spridning eller radering.',
        'gdpr.person-2' : 'Du som personuppgiftsansvarig behöver veta vilka personuppgifter du samlar in, varför och hur länge du ska ha kvar dessa uppgifter i Ecosystem. Vilka personuppgifter som kommer att behandlas i Ecosystems tjänster vet bara ni som organisation.',
        'gdpr.sensitive-1' : 'Det finns en speciell kategori av personuppgifter som lagen tar upp och som du som personuppgiftsansvarig behöver vara extra uppmärksam på, det är känsliga personuppgifter.',
        'gdpr.sensitive-2' : 'Exempel på känsliga personuppgifter är uppgifter som avslöjar etniskt ursprung, politiska åsikter, religiös eller filosofisk övertygelse eller uppgifter om hälsa och sexualliv. Vilka känsliga personuppgifter som kommer att behandlas i Ecosystems tjänster vet bara ni som personuppgiftsansvarig. Om ni till följd av er, era kunder eller leverantörers verksamhet kommer att behandla känsliga personuppgifter behöver ni innan en sådan behandling påbörjas ta reda på vilka säkerhetsåtgärder som kan komma att krävas vid sådan behandling. Vissa funktioner rekommenderas inte att användas om ni kommer behandla känsliga personuppgifter, vilket ni måste ta reda på.',
        'gdpr.sensitive-link' : 'Läs mer om känsliga uppgifter här',
        'gdpr.pul-1': 'I behandlingen av personuppgifter finns det framförallt två roller som du bör känna till och beroende på vilken roll du har finns det olika ansvarsområden. All behandling av personuppgifter i Ecosystem är du som kund personuppgiftsansvarig för.',
        'gdpr.pul-2': 'Den personuppgiftsansvariga (PuA) är den som enligt lagen har det yttersta ansvaret för behandlingen och bestämmer ändamål och medlen. Den personuppgiftsansvarige ska se till att lagen följs, ska informera de personer vars personuppgifter behandlas och ska säkerhetsställa den personuppgiftsbiträdes efterlevnad. Personuppgiftsbiträdet (PuB) behandlar personuppgifterna för den personuppgiftsansvariges räkning och har ansvar för de tekniska och organisatoriska säkerhetsåtgärderna.',
        'gdpr.pul-3': 'Ecosystem är personuppgiftsbiträde och vidtar tekniska och organisatoriska säkerhetsåtgärder för att du ska känna dig trygg med att dina insamlade personuppgifter ska behandlas säkert och enligt lagen.',
        'gdpr.resp-1' : 'All behandling av personuppgifter om dig som kund eller användare är vi personuppgiftsansvariga över när du använder Ecosystems tjänster eller kontaktar oss.',
        'gdpr.li-1' : 'Laglighet, korrekthet och öppenhet',
        'gdpr.li-2' : 'Ändamålsbegränsning',
        'gdpr.li-3' : 'Uppgiftsminimering',
        'gdpr.li-4' : 'Korrekthet',
        'gdpr.li-5' : 'Lagringsminimering',
        'gdpr.li-6' : 'Integritet och konfidentialitet',
        'gdpr.li-7' : 'Ansvarsskyldighet',
        'gdpr.read-more' : 'Vad de grundläggande principerna innebär kan du läsa om på',
        'gdpr.read-more-link' : 'datainspektionen.se',
        'gdpr.more-1': 'Inom varje EU-medlemsland finns en tillsynsmyndigheten som kontrollera efterlevnaden av dataskyddsförordningen. I Sverige heter denna myndighet Datainspektionen. På deras hemsida finns mer information och hjälp som du kan ta del av för att ta reda på vad du behöver göra',

        'info.inventoried-at' : 'Massuppdatera senaste inventering av assets',
        'info.serialnumber' : 'Unikt i systemet.',
        'info.product-id' : 'Koppla produktinformation till din asset genom att ange produktens ID om den redan finns i systemet. En lista på tillgängliga produkter listas nedan.',
        'info.supplier' : 'Obligatoriskt om Produkt ID inte är ifyllt. Leverantören används tillsammans med leverantörskoden för att hämta produktinformation. Tex: Apple',
        'info.supplier-code' : 'Obligatorisk information om Produkt ID inte är ifyllt. Ex 6EB98EA',
        'info.customer-id' : 'Kundens ID används för att koppla asseten till rätt kund. En lista över kundernas ID kan hittas längre ner på sidan.',
        'info.contract-id' : 'Fylls i om du vill koppla en asset till ett kontrakt i systemet',
        'info.type' : 'Kan ha 2 värden. [ purchased, rented ]. purchased för köpta assets och rented för hyrda. Om kontrakt ID finns tillgängligt kommer typen automatiskt att sättas till rented',
        'info.in-use' : '1 för att sätta till aktiv, 0 för att sätta asset till inaktiv. Detta fält kan användas om man ej vill ange användare.',
        'info.user' : 'Om du vill spara information om användaren',
        'info.delivery' : 'Leveransdatumet används för alla livscykelberäkningar i systemet',
        'info.monthly-cost' : 'Månadskostnaden kommer att adderas på kontraktet som de är bundna till. Anges endast med siffror ex, 3452.',
        'info.order': 'Fält för orderinformation',
        'info.reference': 'För extra information',
        'info.warranty-end': 'En sökning efter garantidata kommer att göras när asseten skapas. Detta fält kan användas för att manuellt ange garantitiden i de fall då ingen garantidata kan hittas.',
        'info.price' : 'Enbart siffror, ex 3452',
        'date-missing' : 'Datum saknas',
        'info.format-date' : 'Datum format YYYY-MM-DD',


        'icecat.info' : 'Detta är ett testverktyg för att hämta Icecat-data.',
        'icecat.search' : 'Sök på EAN-kod t.ex:',
        'icecat.or' : 'eller',
        'icecat.search-2' : 'Sök på tillverkare och produktkod, t.ex.',
        'icecat.success' : 'Vid lyckad hämtning så sparas datan lokalt för snabbare åtkomst i framtiden.',
        'icecat.title' : 'Testdata för Icecat',
        'icecat.back' : 'Gå tillbaka och utför ny sökning',
        'icecat.error' : 'Något gick fel. Detta är svaret från icecat-databasen',
        'icecat.no-result' : 'Inget sökresultat',
        'icecat.result' : 'Sökresultatet för',

        'key.key' : 'Key Account Managers',

        'login.back' : 'Tillbaka till login',
        'login.welcome-back': 'Välkommen tillbaka!',
        'login.signin-to-continue' : 'Logga in med ditt konto',
        'login.mail' : 'Email',
        'login.mail-login' : 'Logga in med ditt konto',
        'login.mail-place' : 'Fyll i email',
        'login.password': 'Lösenord',
        'login.password-place': 'Fyll i lösenord',
        'login.login': 'Logga in',
        'login.forgot': 'Glömt ditt lösenord?',
        'login.dont-have' : 'Har du inget konto än ?',
        'login.sign-up': 'Registrera dig',
        'login.allready' : 'Har du redan ett konto ?',
        'login.register': 'Registrera dig',
        'login.register-text' : 'Registrera dig för att logga in och börja använda Ecosystem',
        'login.username' : 'Användarnamn',
        'login.username-place' : 'Fyll i användarnamn',
        'login.by-registering' : 'Genom att registrera dig godkänner du Morecos',
        'login.terms' : 'Användarvillkor',
        'login.forgot-title' : 'Glömt ditt lösenord?',
        'login.forgot-text' : 'Fyll i mailen kopplat till ditt konto.',
        'login.reset' : 'Skicka återställställningslänk',
        'login.go-back': 'Gå tillbaka till',
        'login.welcome' : 'Välkommen',
        'login.change-password-title' : 'Byt lösenord',
        'login.change-password-text' : 'Välj ditt nya lösenord',
        'login.new-password' : 'Spara nytt lösenord',
        'login.password-was-updated' : 'Du kan nu använda ditt nya lösenord!',
        'login.token-expired' : 'Din kod har gått ut, du behöver beställa en ny länk',
        'login.two-factor-auth' : 'Två faktor autentisering',
        'login.two-factor-auth-text' : 'Fyll i den 6 siffriga koden från applikationen på din enhet',



        'logistics.title' : 'Säker IT-logistik betyder inlåst och övervakad, dörr till dörr.',
        'logistics.text1' : 'En obruten leveranskedja är som viktigast den dagen man avyttrar sin använda IT-utrustning. Den största kostnaden med att få sin dator stulen är den informationsförlust man lider och eventuella sanktionsavgifter kopplade till GDPR.',
        'logistics.text2' : 'Våra IT-logistiklösningar utgår ifrån att uppnå högsta möjliga säkerhet genom implementering av enkla logistikrutiner där vi utför transporter i speciella säkerhetsskåp. Moreco erbjuder en prenumerationstjänst för säkerhetsskåp speciellt avsedda för IT med fasta eller flexibla utbyten, ni väljer. Fördelen med tjänsten att ni alltid förvarar er gamla IT-utrustning inlåst tilldess den kan raderas på rätt sätt.',
        'logistics.title2' : 'En kontaktpunkt för en säkrare och mer effektiv process',
        'logistics.text3' : 'Moreco har lång erfarenhet av returlogistik av IT produkter och ett mycket omfattande internationellt kontaktnät vilket säkerställer att vi kan leverera rätt returlogistiklösning för er oavsett var i världen ni behöver assistans. Att samarbeta med oss ger fördelar som;',
        'logistics.list-1' : 'Rätt erfarenhet och resurser',
        'logistics.list-2' : 'Rätt säkerhetsutrustning',
        'logistics.list-3' : 'En enda kontaktyta för en säkrare och effektivare process',
        'logistics.contact' : 'Säkra IT transporter? Då har ni kommit rätt, välj hur du vill blir kontakt så hör vi av oss!',

        'logout' : 'Logga ut',
        'loading': 'Laddar...',
        'lookup.unavailable' : 'Sökning utförd utan resultat',
        'lookup.pending' : 'Sökning pågår, detta kan ta upp till några timmar',
        'lookup.complete': 'Sökning genomförd',

        'nav.assets-list-rented' : 'Lista hyrda assets',
        'nav.menu' : 'Meny',
        'nav.dashboard' : 'Dashboard',
        'nav.assets' : 'Assets',
        'nav.assets-history' : 'Historik',
        'nav.assets-list' : 'Lista',
        'nav.assets-create' : 'Skapa nya',
        'nav.assets-import' : 'Importera',
        'nav.contracts' : 'Kontrakt',
        'nav.contracts-list': 'Lista',
        'nav.contracts-create': 'Skapa nytt',
        'nav.resellers' : 'Återförsäljare',
        'nav.resellers-list' : 'Lista',
        'nav.resellers-create' : 'Skapa ny',
        'nav.customers' : 'Kunder',
        'nav.customers-list' : 'Lista',
        'nav.customers-create' : 'Skapa ny',
        'nav.users' : 'Användare',
        'nav.users-list' : 'Lista',
        'nav.users-create' : 'Skapa ny',
        'nav.tools' : 'Verktyg',
        'nav.bank' : 'Banker',
        'nav.categories' : 'Kategorier',
        'nav.suppliers' : 'Leverantörer',
        'nav.services' : 'Tjänster',
        'nav.soon' : 'Kommer snart',
        'nav.erasure' : 'Datadestruktion',
        'nav.onsite' : 'På platsen',
        'nav.logistics' : 'IT logistik',
        'nav.recovery' : 'Återbruk',
        'nav.distribution' : 'Cirkulär distribution',
        'nav.complience' : 'Efterlevnad',
        'nav.assets-status' : 'Statusar',
        'nav.handle-kam' : 'Hantera Key Account Managers',

        'notifications.notifications' : 'Meddelanden',
        'notifications.no-2FA' : '2FA är ej aktiverat',
        'notifications.no-2FA-text' : 'Öka säkerheten för ditt konto genom att aktivera två faktor autentisering.',
        'notifications.no-notifications' : 'Du har inga nya meddelanden',


        'no' : 'Nej',


        'onsite.title' :'Nedmontering och avveckling på plats',
        'onsite.text1' :'Moreco har hjälpt allt ifrån fackförbundet Unionen med nedmontering och avveckling av arbetsplatser till träningskedjan SATS med nedmontering och avveckling av deras datahall.',
        'onsite.text2' :'Projektledning, planering och genomförande som bygger på erfarenhet, processer och upparbetade rutiner säkerställer utfall i världsklass, det är därför vi får uppdrag även från våra kollegor i branschen. Vi ser oss också som specialister på organisationer med stor geografisk spridning som exempelvis Försäkringskassan samt Länsstyrelsen där vi utfört våra avvecklingstjänster på 40-talet orter, allt mellan Kiruna, Trelleborg och Gotland.',
        'onsite.list' :'Vi erbjuder ett antal tjänster som kan utföras på plats i era lokaler. ',
        'onsite.list-1' :'100% säker dataradering',
        'onsite.list-2' :'Fysisk destruktion av media genom shredding',
        'onsite.list-3' :'Racka ur servrar och annan infrastruktur',
        'onsite.list-4' :'Borttagning av kablage och rack',
        'onsite.list-5' :'Packning i våra säkerhetsskåp ',
        'onsite.list-6' :'Bortforsling med övervakade direkttransporter',
        'onsite.text3' :'Hos Moreco har vi mer än 20 års erfarenhet och expertkompetens kring säker IT-logistik från just datahallar. Återtag i datahallen kräver helt andra rutiner för säkerhet, projektledning och inte minst en helt annan produktkunskap kring infrastrukturen än vad vanliga arbetsplatsprojekt kräver.',
        'onsite.contact' : 'På väg till molnet? Byta kontor? Kontakta oss så hjälper vi er på resan!',

        'placeholder.name' : 'Ange namn',
        'purchased' : 'Köpt',
        'rented' : 'Hyrd',
        'lease' : 'Leasad',
        'upload-logo' : 'Ladda upp logo',
        'go-to' : 'Gå till',
        'call-us' : 'Ring oss',
        'mail-us' : 'Maila oss',
        'covered' : 'Täcks av',
        'outside' : 'Utanför',
        'handle' : 'Hantera',
        'all' : 'Alla',
        'unknown' : 'Okänd',
        'extensions' : 'Förlägningar',
        'size-per-page' : 'Visa antal per sida',
        'select-columns' : 'Kolumner',
        'download-csv' : 'Ladda ner CSV',
        'displayed-in-tables' : 'Visas i tabeller',
        'calculating' : 'Utför beräkningar',
        'expired' : 'Utgågna',
        'undefined' : 'Okänt',


        'months' : '{values} månader',
        'years' : '{years} år',
        'editable': 'Redigerbar',
        'no-title': 'Ingen titel',
        'clear-filters' : 'Nollställ filter',
        'file-imported' : 'Filen importerades!',


        'product.info' : 'Produktinformation',
        'product.attributes' : 'Specifikation',
        'product.processor' : 'Processor',
        'product.storage-media' : 'Lagringsmedia',
        'product.display-size' : 'Skärm storlek',
        'product.hard-drive' : 'Lagringskapacitet',
        'product.history' : 'Asset historik',
        'product.memory' : 'Internminne',

        'product.info-found' : 'Produktinformation hittades',
        'product.editable-help': 'Alla produkter som har skapats manuellt är redigerbara',
        'product.supplier' : 'Tillverkare',
        'product.manual': 'Manuell',
        'product.check-icecat': 'Sök efter produktinformation',

        'profile.joined' : 'Gick med',
        'profile.last-login' : 'Senast inloggad',
        'profile.personal-info' : 'Personlig information',
        'profile.change-password' : 'Byt lösenord',
        'profile.edit' : 'Ändra profil',
        'profile.send-reset-link' : 'Skicka länk för nytt lösenord',
        'profile.delete-account' : 'Radera konto',

        'product.icecat-products' : 'Icecat produkter',
        'product.manual-products' : 'Manuella produkter',

        'product.no-eol' : 'Kategorier utan EOL',

        'product.not-found-icecat' : 'Produkten kunde ej hittas i produktkatalogen. Fyll i information nedan för att skapa en egen produkt. Du behöver välja en av produktkategorierna för att lägga upp den.',
        'product.supplier-code' : 'Tillverkarens produktkod',

        'product.name' : 'Namn',
        'product.search' : 'Sök efter produkt',
        'product.categories' : 'Icecat kategorier',
        'product.market-value' : 'Marknadsvärde',
        'product.delete' : 'Radera produkt',
        'product.delete-sure' : 'Är du säker på att du vill radera denna produkt? ',
        'product.products' : 'Produkter',
        'product.product' : 'Produkt',
        'product.amount' : 'Antal',
        'product.price-unit' : 'Pris/enhet',
        'product.monthly-cost' : 'Månadskostnad',
        'product.products-handle': 'Hantera produkter',
        'product.EFMV': 'Har ett estimerat marknadsvärde',
        'product.products-list': 'Lista produkter',
        'product.products-fmv-import': 'Importera EMV',
        'product.handle-eol' : 'Hantera EOL',
        'product.has-eol': 'Kategorier med EOL',
        'product.with-fmv' : 'Med marknasvärde',
        'product.no-fmv' : 'Saknar marknadsvärde',
        'product.last-update' : 'Senast uppdaterad',
        'product.import-title' : 'Importera marknadsvärden',
        'product.import-text1' : 'Ladda upp fil',
        'product.imported-values' : 'Uppdaterade produkter',
        'product.categories-list' : 'Lista kategorier',
        'product.handle-value': 'Återförsäljningsvärde',
        'product.handle-resale-value' : 'Hantera återförsäljningsvärde',
        'product.handle-emv' : 'Hantera EMV',
        'product.sync-itglue' : 'Manuel ITGlue synkonisering av assets',
        'product.sync-itglue-text' : 'Ecosystem synkroniserar dagligen. Detta verktyg kan användas för att manuellt uppdatera assetinformationen från ITGlue. Detta kan ta några minuter',
        'product.syncing-with-it-glue' : 'Synkroniserar',
        'product.synchronize': 'Synkronisera',
        'product.create' : 'Skapa ny produkt',
        'product.list' : 'Lista produkter',
        'product.my-products' : 'Mina produkter',
        'product.my-products-text' : 'Detta är dina produkter som ej skapats via Icecat. De är endast synliga inom din organisation och behöver tilldelas en kategori från Icecat för att livscykeldata ska kunna presenteras på din asset.',
        'product.my-product' : 'Min produkt',
        'product.edit' : 'Redigera produkt',


        'quote.quote' : 'Offert',
        'quote.quotes' : 'Offerter',
        'quote.create' : 'Skapa offert',
        'quote.hide' : 'Dölj offerter',
        'quote.display' : 'Visa offerter',
        'quote.details' : 'Detaljer',
        'quote.list' : 'Lista offerter',
        'quote.ask-credit' : 'Begär kreditbedömning',
        'quote.create-contract' : 'Omvandla till kontrakt',
        'quote.delete' : 'Radera offert',
        'quote.delete-help' : 'Är du säker på att du vill radera denna offert?',
        'quote.go-back' : 'Gå tillbaka till offerter',
        'quote.edit' : 'Redigera offert',

        'recovery.title' : 'Morecos modell för att maximera er gamla IT-utrustnings finansiella värde',
        'recovery.subheading' : 'Den cirkulära ekonomin är mer än bra för vår miljö, den gör även nytta för er ekonomi då modellen bidrar till en ökad efterfrågan av begagnade reservdelar och tillbehör.',
        'recovery.list' : 'Hur vi jobbar för att öka värdet på er gamla utrustning',
        'recovery.list-1' : 'Moreco jobbar mot flertalet marknader och kanaler. I första hand ger detta rätt förutsättningar för optimalt återbruk vilket skapar intäkter även på äldre utrustning. I andra hand bidrar det till en marknadsspridning som upprätthåller värdet även vid stora volymer.',
        'recovery.list-2' : 'Vår marknadsbevakning håller koll på marknadspriser, tillgång och efterfrågan ner på produktnummernivå vilket ger oss förutsättningar att optimera flödet av produkter och komponenter nedströms.',
        'recovery.list-3' : 'Vi har en komponentstrategi vilket innebär att vi ser till marknadsvärdet och efterfrågan ner på minsta möjliga nivå. Kort innebär det att tre komponenter från en samlad enhet kan inbringa mer värde separat var för sig än om de säljs tillsammans i enheten.',
        'recovery.contact' : 'Vill ni har bättre betalt? Välj hur ni vill bli kontaktade så berättar hur.',

        'reseller.reseller' : 'Återförsäljare',
        'reseller.resellers' : 'Återförsäljare',
        'reseller.card' : 'Återförsäljare kort',
        'reseller.name' : 'Namn',
        'reseller.org-no' : 'Org. nr',
        'reseller.comments': 'Kommentarer',
        'reseller.tariff': 'Tariff rabatt',
        'reseller.now': 'Kickback nu',
        'reseller.later': 'Kickback sen',
        'reseller.lease' : 'Tillåt leasing-offerter',
        'reseller.create' : 'Skapa återförsäljare',
        'reseller.edit' : 'Redigera återförsäljare',
        'reseller.update' : 'Uppdatera återförsäljare',
        'reseller.list' : 'Lista återförsäljare',
        'reseller.delete' : 'Radera återförsäljare',
        'reseller.delete-sure' : 'Är du säker på att du vill radera denna återförsäljare? All information som kunder, assets och användare kopplade till denna återförsäljare kommer att raderas',
        'reseller.contact-person': 'Kontaktperson',
        'reseller.go-back': 'Gå tillbaka till återförsäljare',

        'reports.data-security': 'Data säkerhet',

        'reports.search' : 'Sök',
        'reports.lot-number': 'Lot',
        'reports.size' : 'Storlek MB',
        'reports.erasure-method' : 'Raderinsmetod',
        'reports.status' : 'Status',
        'reports.vendor' : 'Leverantör',
        'reports.parent-serial' : 'S/N moder asset',
        'reports.recycle1': 'Minskar mängden avfall som skickas till deponier och förbränningsanläggningar',
        'reports.recycle2': 'Bevarar naturresurser som virke, vatten och mineraler',
        'reports.recycle3': 'Ökar den ekonomiska säkerheten genom att abvända en inhemsk materialkälla',
        'reports.recycle4': 'Förebygger föroreningar genom att minska behovet av att bryta nya råvaror',
        'reports.recycle5': 'Sparar energi',
        'reports.recycle-title' : 'Även om det ännu inte finns några besparingar från återanvändning, finns det flera fördelar med att låta oss återvinna dina produkter:',
        'reports.summary' : 'Sammanställning',
        'reports.start-end-error' : 'The end date has to be later than the start date',
        'reports.delete-text' : 'Är du säker på att du vill radera denna rapport? Den kommer inte längre att vara tillgänglig på årsrapporten efter radering. Om du önskar att uppdatera information i rapporten så kan du importera nya filer på samma lotnummer och rapporten kommer då att skrivas över med den nya informationen. ',
        'reports.yearly-text-for-reseller' : 'Årsredovisningen är en sammanfattning av lotsrapporterna under det valda året. Välj mellan att få en överblick över alla kunder eller välj en specifik kund för att få en mer detaljerad rapport. Denna rapport är tillgänglig för dina kunder med samma information förutom avräkningsvärdet.',
        'reports.cosmetic' : 'Kosmetiskt',
        'reports.functional' : 'Funktionellt',
        'reports.units' : 'Enheter',
        'reports.model': 'Modell',
        'reports.parent-sn': 'Moder S/N',
        'reports.title-lot': 'Rapport för lotnummer ',
        'reports.report' : 'Rapport',
        'reports.total-charges' : 'Summa kostnader',
        'reports.total-credits' : 'Summa kreditering',
        'reports.total-assets' : 'Totalt antal assets ',
        'reports.handled-hdd' : 'Hanterade hårddsikar ',
        'reports.finance' : 'Finansiell specifikation',
        'reports.read' : 'Läs rapport',
        'reports.deleted-assets': 'Assets raderade från Ecosystem',
        'reports.lots-handled': 'ordrar hanterade av Moreco ',
        'reports.destruction' : 'Säker destruktion',
        'reports.disks-shredded' : 'diskar destruerade',
        'reports.erasure': 'Dataradering',
        'reports.erasure-certificate': 'Dataraderingscertifikat',
        'reports.environment': 'Miljö',
        'reports.this-is-equal': 'Detta motsvarar energiförbrukningen för ',
        'reports.homes': 'hem för ett helt år',
        'reports.units-erased': 'enheter raderade med Blancco',
        'repors.product-specification': 'Produkter specifikation',
        'reports' : 'Rapporter',
        'reports.delete' : 'Radera Lot-rapport',
        'reports.delete-sure' : 'Är du säker på att du vill radera denna rapport? Den kommer inte att längre att visas i den årliga rapporten.',
        'reports.generation-time': 'Tiden för rapportgenereringen kan variera beroende på hur många assets i Lot rapporten som behöver kontrolleras i Blanccos databas.',
        'reports.audit' : 'Auditrapporter',
        'reports.from' : 'Från',
        'reports.to' : 'Till',
        'reports.create' : 'Generera rapport',
        'reports.title' : 'Rapportgenerering',
        'reports.enter-lot' : 'Ange LOT nummer',
        'reports.enter-technician' : 'Ange ansvarig',
        'reports.drop-reports' : 'Ladda upp rapporter från MRM',
        'reports.no-annual-available' : 'Inga årliga rapporter tillgängliga',
        'reports.blancco' : 'Blancco rapporter',
        'reports.create-new' : 'Skapa ny rapport',
        'reports.missing-hdd-audit': 'Serienummer ej hittade i hard drive audit report',
        'reports.missing-disposition': 'Assets som saknar disposition',
        'reports.hard-drives' : 'Hårddiskar',
        'reports.lot-report-exists' : 'Detta lotnummer finns redan i systemet. Om du går vidare med att ladda upp filerna kommer den gamla rapportern att skrivas över.',

        'lotnumber' : 'LOT #',
        'report.start-date' : 'Startdatum',
        'report.end-date' : 'Slutdatum',
        'reports.finalized' : 'Slutförd datum',
        'reports.lot' : 'LOT rapporter',
        'reports.delete-sure' : 'Är du säker på att du vill radera rapporten för detta LOT nummer? Informationen kommer ej längre att finnas med i den årliga rapporten.',
        'reports.delete': 'Radera LOT rapport',
        'reports.handled-hdd': 'Hanterade hårddiskar ',
        'reports.annual' : 'Årsrapport',
        'reports.mail': 'Meddela användare',
        'reports.detail-charges' : 'Hanteringskostnader',
        'reports.hdd-charges' :'Säkerhetstjänster',
        'reports.recycling-charges': 'Återvinningsavgifter',
        'reports.other-charges': 'Övriga kostnader',
        'reports.savings-comparison' : 'Besparingsjämförelse',
        'reports.equal-homes' : 'Motsvarar energianvändning för {homes} per år',
        'reports.overview' : 'Överblick',
        'reports.yearly-text-for-customer' : 'Välj år för att hämta årsrapport.',
        'reports.no-available' : 'Det finns inga tillgängliga årsrapporter att hämta.',
        'reports.received' : 'Mottagen datum',
        'reports.error-message' : 'Datum för mottagandet måste vara före slutförandedatum',
        'reports.reports-day' : 'Hanteringstid dagar',
        'reports.diploma' : 'Diplom',
        'reports.diploma-title' : 'Intyg miljöbesparing',
        'reports.diploma-text-1' : ' har genom sin hantering av oanvänd IT elektronik bidragit till minskade utsläpp av växthusgaser och därmed tagit ansvar för ett hållbart nyttjande av jordens resurser.',
        'reports.diploma-text-2' : '\'s bidrag till minskade utsläpp för år {year} uppgår till ',
        'reports.comparison-houses' : 'Besparingen motsvarar årsenergiförbrukningen från {houses} hushåll',
        'reports.disk-serial-number': 'Serienummer disk',
        'reports.product-serial-number': 'Serienummer produkt',
        'reports.performed-security' : 'Utförd säkerhet',
        'reports.technician': 'Tekniker',
        'reports.manufacturer' : 'Tillverkare',
        'reports.reports' : 'Rapporter',
        'reports.excel-full-report' : 'Rapport Excel',
        'reports.pdf-full-report' : 'Rapport PDF',

        'charges' : 'Avgifter',
        'value' : 'Värde',

        'blancco.get-pdf' : 'Sök',

        'environmental-savings': 'Miljöbesparing',
        'LOT #' : 'LOT #',
        'Summary' : 'Summering',
        'category' : 'Kategori',
        'units' : 'Enheter',
        'functional' : 'Funktionellt',
        'cosmetic' : 'Kosmetiskt',
        'disposition' : 'Disposition',
        'settlement-value' : 'Avräkningsvärde',
        'buy-price' : 'Inköpsvärde',
        'blancco-erased' : 'Enheter raderade med Blancco',
        'shredded' : 'Destruerade',
        'total' : 'Totalt',
        'resale' : 'Återförsäljning',
        'recycle' : 'Återvinning',
        'technician': 'Ansvarig',
        'add-address' : 'Lägg till fler adresser',
        'send-email' : 'Skicka mail',

        'fetch' : 'Hämta',
        'quick-select' : 'Snabbval',
        'Asset' : 'Asset',
        'categories' : 'Kategorier',


        'rightbar.layout-settings' : 'Inställningar sidlayout',
        'rightbar.layout-width' : 'Meny',
        'rightbar.layout-fluid' : 'Med beskrivning',
        'rightbar.layout-boxed' : 'Ikoner',
        'rightbar.topbar-theme' : 'Topbar Tema',
        'rightbar.light' : 'Ljust',
        'rightbar.dark' : 'Mörkt',
        'rightbar.left-sidebar' : 'Sidomeny utseende',
        'rightbar.default' : 'Default',
        'rightbar.compact' : 'Kompakt',
        'rightbar.icon' : 'Ikoner',
        'rightbar.left-sidebar-theme' : 'Sidomeny Tema',

        'show-entries' : 'Visa rader',
        'previous' : 'Föregående',
        'next' : 'Nästa',
        'showing' : 'Visar',
        'to': 'till',
        'of' : 'av',
        'entries' : 'rader',
        'sek' : 'kr',
        'select' : 'Välj',
        'show' : 'Visa',
        'upload' : 'Ladda upp',
        'uploading': 'Laddar upp',
        'NA' : 'Information ej tillgänglig för tillfället',
        'in-system' : 'I systemet',
        'all-categories' : 'Alla kategorier',
        'select-contract' : 'Välj kontrakt',
        'not-found' : 'finns ej i systemet',
        'copied' : 'Kopierad!',
        'Status' : 'Status',
        'preparing' : 'Förbereder',
        'create-new' : 'Skapa ny',
        'list' : 'Lista',
        'show-spec' : 'Visa specifikation av fälten',


        'signed': 'Signerat',
        'extension' : 'Förlängning',
        'pending' : 'Inväntar signering',
        'surcharge' : 'Utköp',
        'cancellation' : 'Avslutas',
        'buy-out-request' : 'Utköp förfrågan',
        'pagination.previous' : 'Föregående',
        'pagination.next' : 'Nästa',

        'no-statuses' : 'Du måste lägga till minst en status för att kunna tilldela denna asset en status',
        'select-customer' : 'Välj kund först',
        'status.add-new' : 'Skapa ny',
        'status.delete' : 'Radera status',
        'status.delete-sure' : 'Är du säker på att du vill radera statusen? All assets med denna status kommer att förlora den.',
        'status.update' : 'Uppdatera status',
        'status.update-sure' : 'Är du säker på att du vill uppdatera denna status? Alla assets med detta status id kommer att uppdateras med den uppdaterade statusen.',


        'start.title' : 'Kom igång!',
        'start.step': 'Steg ',
        'start.step-customer' : 'Skapa en kund',
        'start.step-customer-assets-1' : 'När du har skapat din kund kan du börja lägga upp assets åt den. De kan antingen vara kopplade till ett kontrakt eller frikopplade i systemet. Kundens assets kommer att kategorieseras efter tillgänglig garantidata och du kommer att få möjlighet att övervaka varje assets livcykel och ta del av potentiella affärsmöjligheter baserade på när kunden behöver byta ut sina produkter.',
        'start.step-customer-assets-2' : 'Din kund kommer att dela samma asset vy som dig. Enda skillnaden är att de inte har tillgång till det estimerade marknadsvärdet och dina statusar. De kan skapa sina egna statusar som kommer att vara synliga för dig, du kan dock inte redigera dessa statusar.',
        'start.step-contract' : 'Skapa ett kontrakt',
        'start.step-customer-contracts' :'Du kan skapa kontrakt med dina kunder och koppla assets till dem. De listas på kundkortet och under kontrakt i sidomenyn. När att kontrakt arkiveras så raderas alla assets kopplade till kontraktet. Kunden delar samma vy som dig men kan ej redigera kontraktet.',
        'start.step-asset' : 'Skapa assets',
        'asset.use-API' : 'Använd vårt API',
        'asset.create-with-API' : 'För att börja använda vårt API behöver du en nyckel. Denna finner du på din profil.',
        'start.step-status' : 'Skapa dina egna statusar för dina assets',
        'start.step-user' : 'Skapa användare med skräddarsydda rättigheter i systemet',
        'start.step-user-text': 'Du kan skapa användare åt din kund så att de även kan logga in och använda Ecosystem. Dessa användare kan ha två olika typer av konton, Enduser Basic och Enduser Admin. Det som skiljer dem åt är de förvalda behörigheterna, dessa kan du dock ändra exakt som du vill.',
        'start.lifecycle' : 'Din livscykeldata',

        'start.step-contract-text' : 'Du kan skapa 2 olika typer av kontrakt i systemet, Direktavtal och ramavtal. Till ramavtalen kan du skapa substarter där du kan lägga till assets om du vill dela upp dem, eller så väljer du att koppla alla assets direkt till huvudavtalet.',
        'start.step-contract-extensions' : 'Du kan förlänga ditt kontrakt. Förlängningen kommer då att listas på kontraktet och slutdatumet på kontraktet kommer att uppdateras till det nya valda datumet.',
        'start.step-contract-substarts' : 'Substarterna skapas från ramavtalet och kommer att listas på det. Alla assets som kopplas till substarter kommer även att listas på sitt huvudavtal. Substarterna har i övrigt samma funktionalitet som ett vanligt kontrakt och kan uppdateras, förlängas och arkiveras.',
        'start.step-contract-history' : 'arje kontrakt har en historik som listas på kontraktet. Där loggas exempelvis uppdateringar och vem som gjort dem.',
        'start.step-contract-assets' :'För att knyta assets till ett kontrakt kan du importera dem från kontraktet eller ange kontraktets id när du importerar assets med CSV fil. Du kan sedan välja att flytta assets mellan kontrakt eller frikoppla dem. Du kan även radera dem helt. När en asset knyts till ett kontrakt så blir det av typen hyrd. Om du väljer att koppla ifrån en asset från kontraktet kommer den fortfarande vara av typen hyrd tills du ändrar den.',
        'start.step-contract-archive' : 'När du väljer att arkivera ett kontrakt så raderas alla assets som är knutna till det.',

        'start.step-asset-text' : 'Du kan skapa assets på 3 olika sätt om du har behörighet för att skapa assets i systemet. Varje asset är unik med sitt serienummer, och detta används även för att hämta information om din asset.',
        'start.step-assets-manually-text' : 'Om du inte har all nödvändig information redo i en CSV fil så kan du lägga till dina assets manuellt. Det du behöver är serienumret, leverantörens namn och produktkod eller så kan du söka efter din produkt i produktkatalogen.',
        
        'start.step-asset-user' : 'Du kan ange om din asset är i bruk och vem den används av.',
        'start.step-asset-warranty' : 'När du registrerar ett nytt serienummer så gör vi en sökning åt dig för att hämta garantidata för din produkt. Om denna hittas kommer den att presenteras på din asset. Om sökningen genomförs och garantidata ej går att hitta kan du välja att ange den manuellt om du har den tillgänglig.',
        'start.step-asset-eol' :'Varje produkt som har en produktkategori tilldellad kommer att få ett EOL - datum. EOL står för End Of Life och är ett värde för den uppskattade livslängden för din produkt. Om din asset är äldre än detta datum så kommer det att markeras för att uppmärskamma dig om att det kanske är dags att byta ut den.',
        'start.step-asset-co2' :' När IT produkter återanvänds istället för att kasseras hjälper vi miljön genom att minska CO2e utsläppen. Om din assets produktkategori har tillgänglig data om dess CO2e påverkan så visas det på din asset och en summering presenteras på din dashboard.',
        'start.step-asset-delivery' :'Leveransdatumet används för att utföra olika beräkningar för din asset så se till att alltid ha det ifyllt.',
        'start.step-asset-product' :'När du vill skapa en ny asset så görs en sökning efter tillgänglig produktinformation. Sökningen kan antingen göras med EAN-kod eller med leverantören och leverantörens produktkod. Om ingen tillgänglig produktinformation finns går det att skapa en produkt mannuellt. För att dessa produkter också ska kunna få livscykeldata krävs det att en produktkategori väljs. Dina manuella produkter kan du sedan lista och hantera som du vill.',
        
        'start.step-customer-text' : 'Du kan skapa nya kunder i systemed på sidan Skapa kund. Varje kund får ett eget ID i systemet och ett separat kundkort där du kan hitta all information rörande just den kunden.',

        'start.step-status-text-customer' : 'Lägg till dina egna statusar för att hålla koll på dina assets.',
        'start.step-status-text-reseller' : 'Du kommer att kunna se två olika statusar på en asset. Den ena är kundens status som du kan använda vid sortering och filtrering och den interna Globala statusen som du kan lägga till och ta bort från en status.',
        'start.step-status-text-admin' : 'Du kommer att kunna se tre olika statusar på en asset. Du kommer att kunna se kundens och återförsäljaren status som du kan använda vid sortering och filtrering och även den Globala statusen som du kan lägga till och ta bort från en asset.',

        'start.step-user-text-customer' : 'Om du har behörighet att skapa nya användare kan du skapa fler användare inom din organisation. Varje roll har vissa förbestämda behörigheter som kan specialanpassar för varje användare som du vill skapa.',
        'start.step-user-text-reseller' : 'Du kan skapa fler användare inom din organisation och åt dina kunder. Varje roll har vissa förbestämda behörigheter som kan specialanpassas för varje användare som du vill skapa. Observera att en ny användare som skapas och som har rättighet att läsa kunder kommer att kunna se alla kunder som är upplagda i systemet. Om du vill begränsa åtkomsten kan du lägga upp användaren utan läsbehörighet av kunder, göra den till en Key Account Manager och tilldela valda kunder och sedan slå på läsbehörigheten igen.',
        'start.step-user-text-admin' : 'Du kan skapa fler användare inom din organisation och åt dina återförsäljare och kunder. Varje roll har vissa förbestämda behörigheter som kan specialanpassar för varje användare som du vill skapa.',
        
        'start.step-reseller' : 'Skapa en återförsäljare',
        'start.step-reseller-text' : 'Du kan skapa återförsäljare på sidan skapa återförsäljare. Varje återförsäljare får sitt eget återförsäljar-ID som kan användas för att ansluta kunder och kontrakt till dem. All information om återförsäljaren finns på deras återförsäljarkort.',

        'start.dont-show' : 'Visa inte detta som startsida igen',

        'start.step-kam' : 'Skapa Key Account Managers',
        'start.step-kam-fetch' : 'Hämta Key Account Managers',
        'start.step-kam-text' : 'Du kan välja vilka kunder och information varje användare ska ha tillgång till inom din organisation. ',
        'start.step-kam-text-2' : 'Key account manager är inte en separat roll utan mer som ett filter för vilken information som visas. En användare som tilldela ett antal kunder kommer enbart att ha tillgång till informationen kopplad till dessa, men dess behörighet i systemet kommer fortfarande att styras av behörigheterna som valts. En Key account manager kan tex ha behörighet att skapa nya kunder medan en annan inte har den behörigheten.',

        'supplier.supplier': 'Leverantör',
        'supplier.suppliers': 'Leverantörer',
        'supplier.name' : 'Namn',
        'supplier.updated' : 'Senast uppdaterad',
        'supplier.create' : 'Lägg till ny leverantör',
        'supplier.edit' : 'Uppdatera leverantör',
        'supplier.delete' : 'Radera leverantör',
        'supplier.delete-sure' : 'Är du säker på att du vill radera leverantören? Den kommer inte längre vara tillgänglig vid skapandet av kontrakt',

        'support-agreement.support-agreement' :'Supportavtal ',
        'support-agreement.support-agreements' :'Supportavtal',
        'support-agreement.list' :'Lista avtal',
        'support-agreement.search' :'Sök avtal',
        'support-agreement.archive' :'Arkiv',
        'support-agreement.archive-info' :'Detta avtal är arkiverat',
        'support-agreement.archive-yes' :'Ja, arkivera.',
        'support-agreement.to-archive' :'Arkivera avtal',
        'support-agreement.to-archive-text' :'Är du säker på att du vill arkivera avtalet?',
        'support-agreement.archived-support-agreements' :'Arkiverade supportavtal',
        'support-agreement.add' :'Lägg upp supportavtal',
        'support-agreement.delete' :'Radera avtal ',
        'support-agreement.delete-info' :'Är du säker på att du vill radera avtal # ',
        'support-agreement.delete-yes' :'Ja, jag vill radera avtalet.',
        'support-agreement.delete-no' :'Nej, tillbaka till avtalet',
        'support-agreement.edit' :'Redigera avtal',
        'support-agreement.contract_no' :'Avtalsnummer',
        'support-agreement.start_date' :'Startdatum',
        'support-agreement.end_date' :'Slutdatum',
        'support-agreement.cost' :'Kostnad',
        'support-agreement.termination' :'Uppsägningsregler',
        'support-agreement.conditions' :'Servicevillkor',
        'support-agreement.no-agreements' :'Inga avtal att visa just nu',
        'support-agreement.assets' :'Assets kopplade till detta avtalsnummer',
        'support-agreement.search-title' :'Sök och filtrera supportavtal',
        'support-agreement.search-info' :'Sökverktyget använder en inkluderande matchning där samtliga värden behöver matcha för att visa resultat. Fritextfältet söker genom avtalsnummer. Efter sökningen kan du använda den vanliga sökrutan för att filtrera resultaten ytterligare.',
        'support-agreement.free' :'Sök via Asset på serienummer, produktkod, leverantör eller kategori',
        'support-agreement.title' :'Titel',
        'support-agreement.upload-assets' :'Hantera assets',
        'support-agreement.select-serials' :'Ange serienummer',
        'support-agreement.select-assets-desc' :'Ange serienummer för de assets du vill koppla till detta avtal, använd radbrytning för att separera dem',
        'support-agreement.remove-assets' :'Koppla från assets',
        'support-agreement.add-assets' :'Koppla assets',
        'support-agreement.add-asset-info' :'Här kan du välja vilka assets du vill koppla till detta supportavtal',
        'support-agreement.remove-asset-info' :'Här kan du välja vilka assets du vill ta bort från detta supportavtal',
        'support-agreement.confirm-remove' :'Vill du ta bort dessa assets från detta supportavtal?',
        'support-agreement.back-to' :'Tillbaka till supportavtalet',

        'api-token-info' : 'Observera att du inte kan se din nyckel igen efter att den har genererats. API-nyckeln upphör inte att gälla, men den kan återkallas när som helst.',
        'table.show-results' : 'Visa { from } till { to } av { size } rader',

        'user.notify-user': 'Notifikationer',
        'user.notify-on': 'Användaren får email om nya rapporter i systemet',
        'user.notify-off': 'Skicka email till denna användare när nya rapporter finns tillgängliga i systemet',

        'user.revoke-key' : 'Återkalla API nyckel',
        'user.no-user-for-kam' : 'Det finns inga tillgängliga användare du kan göra till key account managers',
        'user.total' : 'Totalt i systemet',
        'user.new-this-month' : 'Nya användare denna månad',
        'user.last-login' : 'Senast inloggad',
        'user.users' : 'Användare',
        'user.display' : 'Visa användare',
        'user.hide' : 'Dölj användare',
        'user.create' : 'Skapa användare',
        'user.user' : 'Användare',
        'user.role' : 'Roll',
        'user.edit' : 'Redigera användare',
        'user.permissions' : 'Behörighet i systemet',
        'user.permission-read' : 'Läsa',
        'user.permission-update' : 'Uppdatera',
        'user.permission-create' : 'Skapa',
        'user.permission-delete' : 'Radera',
        'user.handle' : 'Hantera',
        'user.delete' : 'Radera användare',
        'user.delete-sure' : 'Är du säker på att du vill radera detta konto? Raderingen är permanent och användarens data kommer att försvinna.',
        'user.name' : 'Namn',
        'user.account' : 'Konto',
        'user.list' : 'Lista användare',
        'user.user-back' : 'Tillbaka till användaren',
        'user.permissions-info' : 'Här kan du skräddarsy användarens behörigheter i systemet genom att inaktivera eller aktivera dem.',
        'user.change-password' : 'Byt lösenord',
        'user.edit-profile': 'Redigera profil',
        'user.account-security' : 'Kontosäkerhet',
        'user.enable-2FA' : 'Aktivera 2FA',
        'user.disable-2FA' : 'Inaktivera 2FA',
        'user.2fa-login-text' : 'Bekräfta din åtkomst till ditt konto genom att ange autentiseringskoden från din autentiseringsapplikation',
        'user.setup-2FA': 'Installation av två faktor autentisering',
        'user.setup-2FA-step1-title' : 'Installera app för tvåfaktorsautentisering',
        'user.setup-2FA-step1-text' : 'Ladda ner och installera din valda autentiseringsapp på din din enhet.',
        'user.setup-2FA-step2-title' : 'Scanna QR-koden',
        'user.setup-2FA-step2-text' : 'Öppna appen och använd din kamera och scanna QR-koden.',
        'user.setup-2FA-step3-title' : 'Fyll i koden',
        'user.setup-2FA-step3-text' : 'Fyll i den 6-siffriga koden på din enhet för att aktivera 2FA.',
        'user.disable-2FA-text' : 'Två faktor autentiseringen som du har aktiverat ökar säkerheten och gör det svårare för någon obehörig att logga in med ditt konto. Om du inaktiverar den så kommer du att kunna logga in med enbart lösenord.',
        'user.keep-enabled' : 'Behåll 2FA aktiverad',
        'user.profile' : 'Profil',
        'user.old-code' : 'Koden är ej giltig, var vänlig försök igen med en ny',
        'user.api-key': 'API nyckel',
        'user.api-key-text' : 'Används för åtkomst av Ecosystems API.',
        'user.get-key' : 'Få nyckel',
        'user.upload-profile-picture' : 'Ladda upp profilbild',
        'user.handle-kam' : 'Hantera key account manager',
        'user.create-kam' : 'Lägg till en ny KAM',
        'user.list-kam' : 'Lista KAMs',
        'user.update-kam' : 'Uppdatera key account manager',
        'user.no-selected-customers' : 'OBS! Denna användare kommer att få tillgång till alla kunder',
        'user.no-selected-customers-text' : 'Om du väljer att fortsätta kommer denna användare ej längre att ha rollen key account manager och kommer inte längre att ha utvald information utan se alla kunder is systemet.',
        'user.yes-save-without-selected-customers' : 'Ta bort rollen',
        'user.delete-kam' : 'Ta bort rollen key account manager',
        'user.key-account-managers' : 'Key account managers',
        'user.api' : 'Ecosystem API',
        'user.2fa-mobile' : 'Denna funktion kan för tillfället endast aktiveras via desktop',

        'warranty' : 'Garanti',
        'warranty.expired' : 'Utgången',
        'warranty.due-soon' : 'Går snart ut',
        'warranty.unknown' : 'Okänd',
        'warranty.hardware-replacement-title' : 'Potentiella affärsmöjligheter',
        'warranty.purchased-assets' : 'Köpta assets',
        'warranty.rented-assets' : 'Hyrda assets',
        'warranty.total-value' : 'Totalt värde',
        'warranty.based-on' : 'Detta värde baseras på priset av ',
        'warranty.price-missing' : 'Pris saknas på ',
        'warranty.based-on-warranty' : 'assets med garanti som har gått ut eller som går ut inom sex månader',
        'warranty.co2e-text' : 'Den totala CO2e besparingen för alla assets i denna kategori med garanti som redan har gått ut eller som går ut inom sex månader om produkterna går till återbruk istället för återvinning är ',
        'warranty.assets-covered' : 'Alla dina assets i denna kategori är täckta av garanti',
        'warranty.calculating' : 'Sammanställer garantiinformation',
        'warranty.calculating-text' : 'Detta kan ta några sekunder med många assets',

        'KAM' : 'KAM',
        'Assets' : 'Assets',
        'icecat-products' : 'Icecat',
        'manual-products' : 'Manuella',

        'yes' : 'Ja',
        'view' : 'Visa',
        'change' : 'Ändra',
        'help' : 'Hjälp',
        'upload-file' : 'Ladda upp fil',
        'login-other-account' : 'Logga in med ett annat konto',


        'docs' : 'Dokumentation',
        'docs.about-asset' : 'Om dina assets',
        'docs.about-asset-text' : 'Vilken information som finns att tillgå och vart du hittar den.',
        'docs.create-asset' : 'Skapa nya assets',
        'docs.create-asset-text' : 'Hur du kan ladda upp och skapa nya assets i systemet.',
        'docs.update-asset' : 'Redigera din asset',
        'docs.update-asset-text' : 'Vilken information som kan redigeras och hur?',
        'docs.delete-asset' : 'Radera din asset',
        'docs.delete-asset-text' : 'Vad händer och hur?',

        'create_asset' : 'asset',
        'read_asset' : 'asset',
        'update_asset' : 'asset',
        'delete_asset' : 'asset',

        'create_support_agreement' : 'Skapa Supportavtal',
        'read_support_agreement' : 'Läsa Supportavtal',
        'update_support_agreement' : 'Uppdatera Supportavtal',
        'delete_support_agreement' : 'Radera Supportavtal',

        'create_contract' : 'Skapa Kontrakt',
        'read_contract' : 'Läsa Kontrakt',
        'update_contract' : 'Uppdatera Kontrakt',
        'delete_contract' : 'Radera Kontrakt',

        'create_quote' : 'Skapa Offert',
        'read_quote' : 'Läsa Offert',
        'update_quote' : 'Uppdatera Offert',
        'delete_quote' : 'Radera Offert',

        'create_reseller' : 'Skapa Återförsäljare',
        'read_reseller' : 'Läsa Återförsäljare',
        'update_reseller' : 'Uppdatera Återförsäljare',
        'delete_reseller' : 'Radera Återförsäljare',

        'create_customer' : 'Skapa Kund',
        'read_customer' : 'Läsa Kund',
        'update_customer' : 'Uppdatera Kund',
        'delete_customer' : 'Radera Kund',

        'create_user' : 'Skapa Användare',
        'read_user' : 'Läsa Användare',
        'update_user' : 'Uppdatera Användare',
        'delete_user' : 'Radera Användare',

        'warrantyEndsInside' : 'Mer än 6 månader',
        'warrantyEndsOutside' : 'Utanför',
        'warrantyEndNotAvailable' : 'Okänd',
        'warrantyEnds3Months' : 'Mindre än 3 månader',
        'warrantyEnds6Months' : 'Mindre än 6 månader',

        'threeMonths' : '3 Månader',
        'sixMonths' : '6 Månader',
        'inside' : 'Innanför',
        'unkown' : 'Utanför',

    }
}

export default trans