import React from "react";
import { Row, Col} from 'reactstrap'
import { translate, Can } from "helpers/helpers";
import SectionContainer from "./SectionContainer";
import { Table, Tr, Th, Td, Tbody } from 'react-super-responsive-table';


const SectionFinance = ({ report, downloadReport }) =>  (

  <SectionContainer
    report={report}
    downloadReport={downloadReport}
    title={translate('reports.finance')}
    body={
      <Row>
        <Col lg={6}>
          <h5>{translate('reports.total-charges')}</h5>
          <Table className="table f-10">
            <Tbody>
              <Tr>
                <Th style={{ width: '70%'}}>{translate('reports.detail-charges')}</Th>
                <Td >
                  <strong>{parseInt(report?.charge_detail_audit)}</strong> kr
                </Td>
              </Tr>
              <Tr>
                <Th>{translate('reports.hdd-charges')}</Th>
                <Td >
                  <strong>{parseInt(report?.charge_hdd_security)}</strong> kr
                </Td>
              </Tr>
              <Tr>
                <Th>{translate('reports.recycling-charges')}</Th>
                <Td >
                  <strong>{parseInt(report?.charge_recycling)}</strong> kr
                </Td>
              </Tr>
              <Tr>
                <Th>{translate('reports.other-charges')}</Th>
                <Td >
                  <strong>{parseInt(report?.charge_other_services)}</strong> kr
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Col>
        <Col lg={6}>
        <h5>{translate('reports.total-credits')}</h5>
          <Table className="table f-10">
          <Tbody>
            <Can I="read" a="Customer">
              {() => (
                <Tr>
                  <Th style={{ width: '70%'}}> {translate('settlement-value')}</Th>
                  <Td >
                    <strong>{parseInt(report?.settlement_value)}</strong> kr
                  </Td>
                </Tr>
              )}
            </Can>
            <Tr>
              <Th> {translate('buy-price')} </Th>
              <Td >
                <strong>{parseInt(report?.buy_price)}</strong> kr
              </Td>
            </Tr>
          </Tbody>
        </Table>
        </Col>
      </Row>
    }
    />

)

export default SectionFinance


