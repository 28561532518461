import React from "react";
import { Link } from "react-router-dom";
import { CardHeader, CardTitle } from "reactstrap";
import translate from "helpers/Translation/translate";
import ContractSubstarts from './ContractSubstarts';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'

const ContractStatus = ({ contract, editMode, createSubstart }) => (

  editMode || createSubstart ?
  <CardHeader style={ecosystemCardHeader}>
    <CardTitle>{
      editMode ?
      <div>
        {contract.title} <br/>
        <Link to={`/customers/${contract?.customer?.id}`} >{contract?.customer?.name}</Link>
      </div>
      : createSubstart ?  translate("contract.create-substart") : null}
    </CardTitle>
    {createSubstart && <ContractSubstarts contract={contract}/> }
  </CardHeader>
  : null
)

export default ContractStatus