import React from "react";
import { Row, Col } from "reactstrap";
import { Can, translate } from 'helpers/helpers';
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import moment from 'moment'

const ProductInfo = ({ product }) => (

  <Row>
    <Col lg={12}>
      <Table className="table mt-3">
      <Tbody>
        <Tr>
            <Th style={{ width: '70%' }}> {translate('asset.supplier/manufacturer')}</Th>
            <Td className="text-muted"> {product?.supplier ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.supplier-code')}</Th>
            <Td className="text-muted"> {product?.supplier_code ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('asset.ean')} </Th>
            <Td className="text-muted"> {product?.ean ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th style={{ width: '60%' }}> PDF <i className="mdi mdi-file-pdf-box-outline f-14"></i></Th>
            <Td className="text-muted">
                {product?.pdf_url ?
                  <a href={product.pdf_url} target="_blank" rel="noopener noreferrer">
                    {translate('asset.technical-pdf')}
                  </a> : '-'}
            </Td>
        </Tr>
        <Tr>
          <Th> URL <i className="mdi mdi-web f-14"></i></Th>
          <Td className="text-muted">
              {product?.product_url ?
                <a href={product.product_url} target="_blank" rel="noopener noreferrer">
                  {translate('asset.web')}
                </a> : '-'}
          </Td>
        </Tr>
        <Tr>
            <Th> {translate('asset.weight')} kg</Th>
            <Td className="text-muted"> {product?.weight ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.processor')}</Th>
            <Td className="text-muted"> {product?.processor ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.hard-drive')}</Th>
            <Td className="text-muted"> {product?.hard_drive ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.memory')}</Th>
            <Td className="text-muted"> {product?.memory ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.storage-media')}</Th>
            <Td className="text-muted"> {product?.storage_media ?? '-'}</Td>
        </Tr>
        <Tr>
            <Th> {translate('product.display-size')}</Th>
            <Td className="text-muted"> {product?.display_size ?? '-'}</Td>
        </Tr>
        {!product?.use_icecat ?
        <>
        <Can I="read" a="Customer">
          {() => (
            <Tr>
                <Th > {translate('customer.customer')} </Th>
                <Td className="text-muted"> {product?.customer?.name ?? '-'}</Td>
            </Tr>
          )}
        </Can>
        <Can I="read" a="Reseller">
          {() => (
            <Tr>
                <Th> {translate('reseller.reseller')} </Th>
                <Td className="text-muted"> {product?.reseller_id ?? '-'} </Td>
            </Tr>
          )}
        </Can>
        </> : null}
        <Tr>
            <Th style={{ width: '60%' }}> ID</Th>
            <Td className="text-muted"> {product?.id} </Td>
        </Tr>
        <Tr>
            <Th> Assets</Th>
            <Td className="text-muted">
            { product?.total_assets > 0 ?
              <span className="badge-primary badge-pill">
                {product?.total_assets?.toLocaleString()}
              </span>
              : '-'}
            </Td>
        </Tr>
        <Tr>
            <Th> EMV </Th>
            <Td className="text-muted"> {product?.market_value ? product?.market_value?.toLocaleString() + ' kr' : '-'} </Td>
        </Tr>
        <Tr>
            <Th> {translate('created')} </Th>
            <Td className="text-muted">
                {moment(product?.created_at).format('YYYY-MM-DD')}
            </Td>
        </Tr>
      </Tbody>
      </Table>
    </Col>
  </Row>

)

export default ProductInfo;
