import React from 'react';
// Redux and actions
// Components
import { Row, Col, Button, FormGroup } from 'reactstrap';
import MySearch from './MySearch'
// Functions
import { Can } from 'helpers/helpers';
import { Link } from "react-router-dom";
import ProductListFilters from 'Pages/Product/Components/ProductListFilters';
import ContractListFilters from 'Pages/Contract/Components/list/ContractListFilters';
import ReportFilters from 'Pages/Report/Components/ReportFilters';
import BlanccoFilters from 'Pages/Report/Components/BlanccoFilters';
import { AssetFilters } from 'Pages/Asset/Components/AssetList/Table';
import HandleEmvFilters from 'Pages/Product/Components/HandleEmvFilters';

const RemotePaginationFilters = ({
    filter,
    onChange,
    clearFilter,
    path,
    predefinedFilter,
    can,
    createLink,
    buttonText,
    handleKeyPress,
    getInputValueForSearch,
    setDate,
    props
}) => {
  return (
          <>
          <Row className="mb-5">
            {
              path === '/assets' ?  !predefinedFilter && <AssetFilters filter={filter} onChange={onChange} clearFilter={clearFilter}/> :
              path === '/products' ? <ProductListFilters filter={filter} onChange={onChange}/> :
              path === '/products/emv' ? <HandleEmvFilters filter={filter} onChange={onChange} /> :
              path === '/contracts' ? <ContractListFilters filter={filter} onChange={onChange}/> :
              path === '/moreco-reports/lots' ? <ReportFilters filter={filter} onChange={onChange} setDate={setDate} clearFilter={clearFilter}/> :
              path === '/moreco-reports/blancco' ? <BlanccoFilters filter={filter} onChange={onChange} /> : null}
          </Row>
          <Row>
            <Col md={6}>
                {can && createLink && (
                  <Can I="create" a={can}>
                    <Link to={createLink}>
                      <Button color="success" className="col-6">
                        {buttonText}
                      </Button>
                    </Link>
                  </Can>
                )}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <MySearch
                    {...props.searchProps}
                    handleKeyPress={handleKeyPress}
                    onSearch={getInputValueForSearch}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
  );
};

export default RemotePaginationFilters;

