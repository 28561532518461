import React from "react";
import { Row, Col } from "reactstrap";
import { translate, calculateAge, displayEOL, displayWarranty, Can } from "helpers/helpers";

import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NotAvailable from 'assets/images/no-image.jpeg'
import CO2e from 'assets/images/co2.png'

const AssetInfo = ({ asset, me }) => (

        <Row>
            <Col lg={4} className="pl-5">
             <span className={`mb-5 float-right badge badge-pill ${asset.type === 'purchased' ? 'badge-primary' : 'bg-soft-primary'}`}>{translate(`${asset.type}`)}</span>
                <img className="product-detail-imgs img-fluid" src={asset?.product?.image ?? NotAvailable} alt="product" />
            </Col>
            <Col lg={8}>
                <Table className="table">
                    <Tbody>
                        <Tr>
                            <Th> {translate('asset.supplier/manufacturer')}</Th>
                            <Td className="text-muted">
                                {asset?.product?.supplier ?? translate('asset.no-supplier')}
                            </Td>
                        </Tr>
                        <Tr>
                            <Th> {translate('asset.product-code')}</Th>
                            <Td className="text-muted">
                                {asset?.product?.supplier_code ?? translate('asset.no-supplier-code')}
                            </Td>
                        </Tr>
                        <Tr>
                            <Th> {translate('asset.ean')} </Th>
                            <Td className="text-muted"> 
                                {asset?.product?.ean ?? '-'}
                            </Td>
                        </Tr>
                        <Tr>
                            <Th> {translate('asset.serial')}</Th>
                            <Td className="text-muted"> {asset.serial_number}</Td>
                        </Tr>
                        <Tr >
                            <Th> {translate('asset.age')} </Th>
                            <Td> {calculateAge(asset)} </Td>
                        </Tr>
                        <Tr>
                            <Th> EOL </Th>
                            <Td> {displayEOL(asset)} </Td>
                        </Tr>
                        <Tr>
                            <Th> {translate('asset.warranty')} </Th>
                            <Td> {displayWarranty(asset)} </Td>
                        </Tr>

                        <Tr>
                            <Th> {translate('asset.in-use')}</Th>
                            <Td> {asset.in_use ?
                                    <span className="badge badge-pill bg-soft-success">{
                                    asset?.user ?? translate('yes')}</span> : null}
                                {!asset.in_use ? <span className="badge badge-pill bg-soft-danger">{translate('no')}</span> : null}
                            </Td>
                        </Tr>
                        <Tr>
                            <Th>Status</Th>
                            <Td>
                                {asset.status_customer ?? '-' }
                            </Td>
                        </Tr>
                        <Can I="read" a="Reseller">
                            {() => (
                                <Tr>
                                    <Th>Admin Status</Th>
                                    <Td>{asset?.status_admin ?? '-'}</Td>
                                </Tr>
                            )}
                        </Can>
                        <Can I="read" a="Customer">
                            {() => (
                            <Tr>
                                <Th>{translate('reseller.reseller')} Status</Th>
                                <Td>{asset?.status_reseller ?? '-'}</Td>
                            </Tr>
                        )}
                        </Can>
                        <Tr>
                            <Th> <img src={CO2e} width="30px" alt="icon with clouds" className=" mb-2"/> </Th>
                            <Td> <strong className="text-success">{asset?.product?.category?.emissions?.carbon_savings ?
                                asset?.product?.category?.emissions?.carbon_savings?.toLocaleString() + ' kg CO2e' : translate('no-available-data')}</strong></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )


export default AssetInfo;
