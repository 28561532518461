import React from "react";
import { Col } from "reactstrap";

function Tag({ value, onDelete }) {
    return <div className="tag">
        <div className="tag-text">
            {value}
        </div>
        <button onClick={() => onDelete(value)}>&times;</button>
    </div>;
}

export default function TagEditor({ tags = [], onTagsChange }) {
    const ref = React.useRef();
    const isPasting = React.useRef(false);

    const handleTagsChange = React.useCallback((tags) => {
        const tagSet = new Set((tags ?? []).filter((t) => !!t));
        onTagsChange([...tagSet]);
    }, [onTagsChange]);

    const onDelete = React.useCallback((value) => {
        handleTagsChange(tags.filter((tag) => tag !== value));
    }, [handleTagsChange, tags]);

    const handleKeyDown = React.useCallback((e) => e.key === "Backspace" && !ref.current.innerText && handleTagsChange(tags.slice(0, -1)), [handleTagsChange, tags]);

    const handlePaste = React.useCallback((e) => {
        const text = e.clipboardData.getData('text').trim();
        if (text) {
            handleTagsChange(tags.concat(text.split(/\s+/)));
            isPasting.current = true;
        }
    }, [tags, handleTagsChange]);

    const handleInput = React.useCallback((e) => {
        if (isPasting.current) {
            isPasting.current = false;
            ref.current.innerText = "";
            return;
        }

        const text = ref.current.innerText;
        if (text.trim()) {
            const newTags = text.split(/\s+/);
            if (newTags[newTags.length - 1] === '') {
                newTags.pop();
                handleTagsChange(tags.concat(newTags.filter((t) => !!t)));
                ref.current.innerText = "";
            } else if (newTags.length > 1 && newTags[0]) {
                ref.current.innerText = newTags.pop();
                handleTagsChange(tags.concat(newTags));
            }
        } else if (text.length === 0 && 
            e?.inputType === 'deleteContentBackward' &&
            tags.length) {
                handleTagsChange(tags.slice(0, -1));
        }
    }, [handleTagsChange, tags]);

    const handleBlur = React.useCallback(() => {
        const text = ref.current.innerText.trim();
        if (text) {
            handleTagsChange(tags.concat([text]));
            ref.current.innerText = "";
        }
    }, [handleTagsChange, tags]);

    return <Col lg={12}>
        <div className="form-control tag-editor-container">
            <div className="tag-editor">
                {tags.map((tag) => <Tag value={tag} key={tag} onDelete={onDelete} />)}
                <div className="tag-input" 
                 contentEditable
                 onInput={handleInput}
                 ref={ref}
                 onPaste={handlePaste}
                 onKeyDown={handleKeyDown}
                 onBlur={handleBlur}>
                </div>
            </div>
        </div>
    </Col>;
}