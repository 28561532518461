import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import Statuses from './Components/Statuses';
import { Container, Card, CardBody } from "reactstrap";
import ListHeader from "Components/Lists/ListHeader";
import CreateContainer from 'Containers/CreateContainer';
// Functions
import translate from 'helpers/Translation/translate';


const AssetStatuses = () => {
    const error = useSelector(state => state.Model.error)
    const errors = useSelector(state => state.Model.errors)
    const success = useSelector(state => state.Model.success)
        return (
            <CreateContainer
                Model="Assetstatus"
                editMode={false}
                breadcrumbTitle="Asset status"
                breadcrumbItem={translate('asset.status-handle')}
                error={error}
                errors={errors}
                success={success}
                >
                <Card>
                    <ListHeader title={translate('asset.status-handle')} />
                    <CardBody>
                        <Container className="col-lg-10 mx-auto">
                            <Statuses />
                        </Container>
                    </CardBody>
                </Card>
            </CreateContainer>
        )
    }

export default AssetStatuses