import React from 'react';
import Icons from "assets/styles/Icons";
import { translate } from 'helpers/helpers';

const AssetTableTitle = ({ textColor, title }) => (
    <>
        <h4 className={textColor}>
            <i className={`f-24 ${Icons.shield_alert}`}></i>
            {translate(`${title}`)}
        </h4>
        <hr />
    </>

)

export default AssetTableTitle