import React from "react";

// Components
import { Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

// Functions
import { translate } from 'helpers/helpers';
import { CsvButton } from "Components/Global";

const columns = [
  {
    text: translate('customer.customer'),
    dataField: "name",
    csvText: 'Customer Name',
    sort: true,
    headerStyle: () => {
      return { minWidth: '100px'};
    }
  },
  {
    text: translate('period'),
    dataField: "asset_count[0].period",
    csvText: 'Period',
    sort: true,
    headerStyle: () => {
      return { minWidth: '100px'};
    }
  },
  {
    text: 'Assets',
    dataField: "asset_count[0].count",
    csvText: 'Assets',
    sort: true,
    headerStyle: () => {
      return { minWidth: '100px'};
    },
    formatter: (cell, row) => {
      return cell?.toLocaleString()
    },
  },
  {
    text: 'Previous',
    dataField: "asset_count[1].period",
    csvText: 'Previous period',
    sort: true,
    headerStyle: () => {
      return { minWidth: '100px'};
    },
    headerFormatter: () => {
      return translate('previous')
    },
  },
  {
    text: 'Assets',
    dataField: "asset_count[1].count",
    csvText: 'Assets',
    sort: true,
    headerStyle: () => {
      return { minWidth: '100px'};
    }
  }
]
const { SearchBar } = Search;

const BillingTable = ({ data }) => (

        <ToolkitProvider
            keyField='id'
            data={data}
            columns={columns}
            search
            exportCSV = {
              {
                fileName: 'Customers.csv',
                exportAll: true,
                separator: ';',
              }
            }
          >
            {props => (
              <>
                <Row style={{ justifyContent: 'space-between'}} className="mt-4">
                  <Col lg={3}>
                    <CsvButton { ...props.csvProps }/>
                  </Col>
                  <Col lg={3} className="mb-2">
                    <SearchBar { ...props.searchProps }className="custome-search-field" />
                  </Col>
                </Row>

                <BootstrapTable
                  striped
                  bordered={ false }
                  hover
                  condensed
                  noDataIndication={ () => { return translate('asset.no-rows') }}
                  wrapperClasses="table-responsive"
                  pagination={ paginationFactory() }
                  { ...props.baseProps }
                  defaultSorted={[{
                    dataField: 'name',
                    order: 'asc'
                  }]}
                  />
              </>
            )}
        </ToolkitProvider>

    )



export default (BillingTable);


