import React from "react";
import { Row, Col, Card, CardBody, Button, UncontrolledCollapse, Label } from "reactstrap";
import  UploadFile from 'Components/Form/UploadFile'
import ImportExampleFile from 'Components/Global/ImportExampleFile';
import AssetImportSpecification from './AssetImportSpecification';
import AssetImportWizard from './AssetImportWizard';
// Functions
import { translate, Can } from 'helpers/helpers';
import { CSVUploader } from "./CSVUploader";
import { FILE_FIELDS } from "Pages/Asset/Constants";
import { AvForm } from "availity-reactstrap-validation";
import { fileWithRenamesToSerialized, toFileWithRenames } from "helpers/Imports/Transformations";


const AssetImportSection = ({ isFetching, upload }) => {
    const [useWizard, setUseWizard] = React.useState(true);

    const [data, setData] = React.useState({});

    React.useEffect(() => {
        setData({});
    }, [useWizard]);

    const handleUpload = React.useCallback(() => {
        const { renames } = toFileWithRenames(data);
        upload({ file: data.file, ...fileWithRenamesToSerialized(renames) });
    }, [data]);

    return(
        <CardBody>
            <Row className="mb-5">
                <Col lg={12} sm={12}>
                        <Can I="read" a="Customer">
                            {() => (
                            <>
                                <span className="mb-2">{translate('asset.import-text1')}</span>
                            </>
                            )}
                        </Can>
                        <span>{translate('asset.import-text2')}</span>
                        <br />
                        <p>{translate('asset.help-text')}</p>
                        <ImportExampleFile />
                        <p>
                            <input
                             className="mt-1"
                             type="checkbox"
                             name='useWizard'
                             checked={useWizard}
                             value={useWizard} 
                             onChange={() => setUseWizard(!useWizard)} 
                            />
                            {' '}
                            <Label for="useWizard" className="mt-3">I want to use the wizard</Label>
                        </p>
                        {useWizard && <AssetImportWizard onUpload={upload} isFetching={isFetching} />}
                        <Button color="primary" className=" mt-3" id={'spec'}>
                            {translate('show-spec')}
                        </Button>
                        <UncontrolledCollapse toggler={'spec'}>
                            <Card>
                                <CardBody>
                                    <AssetImportSpecification />
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                </Col>
            </Row>
            {!useWizard && <AvForm>
                <CSVUploader 
                    data={data} 
                    onDataChange={setData} 
                    labels={FILE_FIELDS} 
                    renamesClassName="mx-5"/>
            </AvForm>}
            {!useWizard && (
                isFetching ?
                    <Button className="btn-primary col-lg-12 mt-1">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                        {translate('uploading')}
                    </Button> :
                    <Button className="btn-success col-lg-12 mt-1" onClick={handleUpload}>
                        {translate('upload')}
                    </Button>
                )
            }
    </CardBody>
    )
}

export default AssetImportSection