import React from 'react'
import {  Row, Col } from "reactstrap";
import moment from 'moment'
import translate from 'helpers/Translation/translate';
import { Link } from "react-router-dom";
import { Can } from 'helpers/helpers';

import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const AssetDetails = ({ asset }) => (

        <Row>
            <Col sm={12}>
                    <Table className="table">
                        <Tbody >
                        <Can I="read" a="Customer">
                                    {() => (
                                        <>
                                        <Tr>
                                          <Th>{translate("customer.customer")}</Th>
                                          <Td>{asset?.customer && <a href={`/customers/${asset?.customer?.id}`}> {asset?.customer?.name} </a>}</Td>
                                        </Tr>
                                        <Tr>
                                            <Th>Key account managers</Th>
                                            <Td>
                                                {asset?.customer?.key_account_managers?.length ?
                                                <ul className="ecosystem-list">
                                                    {asset?.customer?.key_account_managers.map(manager => {
                                                        return <li key={manager.id}> <a href={`/users/${manager.id}`}>{manager.name}</a> </li>
                                                    })}
                                                </ul> : '-'}
                                            </Td>
                                        </Tr>
                                        </>
                                    )}
                                </Can>
                                <Tr>
                                    <Th>{translate('asset.description')}</Th>
                                    <Td>{asset?.description ?? '-'}</Td>
                                </Tr>
                                <Tr>
                                    <Th>{translate('asset.sub-supplier')}</Th>
                                    <Td>{asset?.sub_supplier_no ?? '-'}</Td>
                                </Tr>
                                <Tr>
                                    <Th>{translate('asset.order')}</Th>
                                    <Td>{asset?.order_no ?? '-'} </Td>
                                </Tr>
                                <Tr>
                                    <Th>{translate('asset.invoice-no')}</Th>
                                    <Td>{asset?.invoice_no ?? '-'}</Td>
                                </Tr>
                                <Tr>
                                    <Th>{translate('asset.invoice-date')}</Th>
                                    <Td>{asset?.invoice_date ?? '-'}</Td>
                                </Tr>

                            {!asset.contract_id &&
                            <>
                            <Tr>
                                <Th>{translate('asset.cost-center')}</Th>
                                <Td>{asset?.cost_center ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Th>{translate('asset.delivery-date')}</Th>
                                <Td>{asset?.delivery_date ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Th>{translate('asset.installation-address')}</Th>
                                <Td>{asset?.installation_address ?? '-'}</Td>
                            </Tr>
                            </>}
                            <Tr>
                                <Th>{translate('asset.reference')}</Th>
                                <Td>{asset?.reference ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Th>{translate('asset.computer-name')}</Th>
                                <Td>{asset?.computer_name ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Th>{translate('asset.mac-address')}</Th>
                                <Td>{asset?.mac_address ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Th>{translate('asset.additional-info')}</Th>
                                <Td>{asset?.additional_info ??'-'}</Td>
                            </Tr>
                            <Tr>
                                <Th >{translate('asset.inventoried-at')}</Th>
                                <Td>{asset.inventoried_at ? moment(asset.inventoried_at).format('YYYY-MM-DD HH:mm') : '-' }</Td>
                            </Tr>
                        </Tbody>
                    </Table>
            </Col>
        </Row>
    )

export default AssetDetails;