import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { Col, Row, FormGroup, Label } from "reactstrap";
import { TextInputField, TextAreaField, OptionSelect, UploadFile } from "Components/Form/index";
import NotAvailable from 'assets/images/no-image.jpeg'
import { translate } from 'helpers/helpers';

const ProductFields = ({
  handleChange,
  product,
  getImage,
  categories,
  editMode,
  onAsset,
  customersDropdown,
  showCustomerEditor
}) => {

  const upload = (file) => {
    var reader = new FileReader();
    if (file.length > 0) {
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        var image = reader.result;
        getImage(image);
      };
    } else {
      getImage("");
    }
  };

  return (
    <Row className="mt-5">
      <Row className="d-flex justify-content-center col-12">
        {product?.image || product?.image_url ? (
          <Col lg={6}>
            <img
              className="product-detail-imgs img-fluid mb-5 "
              src={(product?.image || product?.image_url) ?? NotAvailable}
              alt="product"
              id="expandedImg1"
            />
          </Col>
        ) : (
          !!getImage && <Col lg={6}>
            <UploadFile getFiles={upload} title={translate("upload-image")} />
          </Col>
        )}
      </Row>

      {showCustomerEditor && (
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="customer_id">
              {translate("customer.customer")}
            </Label>
            <AvField
              type="select"
              required
              value={product?.customer_id ?? ""}
              name="customer_id"
              className="form-control select2"
              errorMessage={translate("form.select-category")}
              onChange={(e) => handleChange(e)}
            >
              <OptionSelect message={"form.select-customer"} value={null} />
              {customersDropdown?.map((customer) => {
                return (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                );
              })}
            </AvField>
          </FormGroup>
        </Col>
      )}
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="supplier">
            {translate("product.supplier")} <span className="text-success f-10">{translate('required')}</span>
          </Label>
          <TextInputField
            name={"supplier"}
            value={product?.supplier ?? ""}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-supplier"}
            type={"text"}
            required
            readonly={editMode || product?.type === "manual" ? false : true}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="supplier_code">
            {translate("form.supplier-code")} <span className="text-success f-10">{translate('required')}</span>
          </Label>
          <TextInputField
            name={"supplier_code"}
            value={product?.supplier_code ?? ""}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.supplier-code"}
            type={"text"}
            required
            readonly={editMode || product?.type === "manual" ? false : true}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="title">
            {translate("asset.title")} <span className="text-success f-10">{translate('required')}</span>
          </Label>
          <TextInputField
            name={"title"}
            value={product?.title ?? ""}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-title"}
            type={"text"}
            required
            readonly={editMode || product?.type === "manual" ? false : true}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="name">
            {translate("product.name")}
            <span className="text-muted ml-1 f-10">
              ({translate("displayed-in-tables")})
            </span>
          </Label>
          <TextInputField
            name={"name"}
            value={product?.name ?? ""}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-name"}
            type={"text"}
            readonly={editMode || product?.type === "manual" ? false : true}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="product_category_id">
            {translate("asset.category")} <span className="text-success f-10">{translate('required')}</span>
          </Label>
          {onAsset ? (
            <TextInputField
              name={"category"}
              type={"text"}
              value={product?.product_category ?? ""}
              errorMessage={"form.select-category"}
              readonly
            />
          ) : (product?.type === "manual" || !product?.use_icecat) &&
            categories?.length < 1 ? (
            <i className="bx bx-loader bx-spin text-success mt-4 ml-2"></i>
          ) : product?.type === "icecat" ? (
            <TextInputField
              name={"category"}
              type={"text"}
              value={product?.category ?? ""}
              errorMessage={"form.select-category"}
              readonly
            />
          ) : (
            <AvField
              type="select"
              required
              value={product?.product_category_id ?? ""}
              disabled={editMode || product?.type === "manual" ? false : true}
              name="product_category_id"
              className="form-control select2"
              errorMessage={translate("form.select-category")}
              onChange={(e) => handleChange(e)}
            >
              <OptionSelect message={"form.select-category"} value={null} />
              {categories?.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </AvField>
          )}
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="ean">EAN</Label>
          <TextInputField
            name={"ean"}
            type={"number"}
            max={13}
            min={13}
            value={product?.ean ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            errorMessage={"form.enter-ean"}
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="weight">{translate("asset.weight")}</Label>
          <TextInputField
            name={"weight"}
            value={product?.weight ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-weight"}
            type={"number"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="display_size">{translate("product.display-size")}</Label>
          <TextInputField
            name={"display_size"}
            value={product?.display_size ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="storage_media">{translate("product.storage-media")}</Label>
          <TextInputField
            name={"storage_media"}
            value={product?.storage_media ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="hard_drive">{translate("product.hard-drive")}</Label>
          <TextInputField
            name={"hard_drive"}
            value={product?.hard_drive ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="processor">{translate("product.processor")}</Label>
          <TextInputField
            name={"processor"}
            value={product?.processor ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="memory">{translate("product.memory")}</Label>
          <TextInputField
            name={"memory"}
            value={product?.memory ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="product_url">WEB URL</Label>
          <TextInputField
            name={"product_url"}
            value={product?.product_url ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-product-url"}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <Label htmlFor="pdf_url">PDF URL</Label>
          <TextInputField
            name={"pdf_url"}
            value={product?.pdf_url ?? ""}
            readonly={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-pdf-url"}
            type={"text"}
          />
        </FormGroup>
      </Col>
      <Col lg={12}>
        <FormGroup>
          <Label htmlFor="description">{translate("asset.description")}</Label>
          <TextInputField
            name={"description"}
            value={product?.description ?? ""}
            disabled={editMode || product?.type === "manual" ? false : true}
            onChange={(e) => handleChange(e)}
            type={"text"}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ProductFields;
