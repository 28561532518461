import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useLocation } from 'react-router-dom'
import {
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  resetDemo
} from "store/actions";
import { Alert, Col } from "reactstrap";

// Layout Related Components
import Header from "./Header";
import Menu from "./Navigation/Menu";
import Footer from "./Footer";
import { UserContext } from '../Context';
import DemoMessage from "Components/Global/DemoMessage";

const DemoMode = process.env.REACT_APP_DEMO_MODE

const Layout = ({ toggleRightSidebar, children }) => {

  let location = useLocation();
  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  window.scrollTo(0, 0);
  let currentage = capitalizeFirstLetter(location?.pathname);
  document.title = currentage + " | Ecosystem ";

  const [ isMobile, setIsMobile ] = useState(false)
  const me = useSelector(state => state.Global.me)
  const isAdmin = useSelector(state => state.Global.isAdmin)
  const isCustomer = useSelector(state => state.Global.isCustomer)
  const isReseller = useSelector(state => state.Global.isReseller)
  const isResetting = useSelector(state => state.Tool.isResetting)
  const success = useSelector(state => state.Tool.success)

  const leftSideBarTheme = useSelector(state => state.Layout.leftSideBarTheme)
  const layoutWidth = useSelector(state => state.Layout.layoutWidth)
  const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
  const topbarTheme = useSelector(state => state.Layout.topbarTheme)
  const showRightSidebar = useSelector(state => state.Layout.showRightSidebar)
  const layoutType = useSelector(state => state.Layout.layoutType)

  const dispatch = useDispatch();


  useEffect(() => {

    if (leftSideBarTheme) dispatch(changeSidebarTheme(leftSideBarTheme))
    if (layoutWidth) dispatch(changeLayoutWidth(layoutWidth))
    if (leftSideBarType) dispatch(changeSidebarType(leftSideBarType))
    if (topbarTheme) dispatch(changeTopbarTheme(topbarTheme))
    if (showRightSidebar) toggleRightSidebar()

    var hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = navigator.userAgent;
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
      setIsMobile(hasTouchScreen)
  }},[dispatch]);


  const toggleMenuCallback = () => {

    if (isMobile) changeSidebarType("default", isMobile)
    else if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  };

    return (
      <>
        <div id="layout-wrapper">
          <UserContext.Provider
            me={me}
            isAdmin={isAdmin}
            isReseller={isReseller}
            isCustomer={isCustomer}
            >
            <Header
              toggleMenuCallback={toggleMenuCallback}
              resetDemo={resetDemo}
              isResetting={isResetting}
              success={success}
              />
            <Menu
              layoutType={layoutType}
              toggleMenuCallback={toggleMenuCallback}
              type={leftSideBarType}
              location={location}
            />
            <div className="main-content">
              {DemoMode &&
              <Col lg={12}>
                <Alert color="danger" style={{ marginTop: '80px', marginBottom: '-60px'}}>
                  <DemoMessage isAdmin={isAdmin} resetDemo={resetDemo} isResetting={isResetting} success={success}/>
                </Alert>
              </Col>
               }
              {children}
            </div>
          </UserContext.Provider>

        <Footer />
        </div>
      </>
    );
  }


export default Layout;

