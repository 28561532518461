import React from "react";
import { Row, Col, Card, CardBody, Button, UncontrolledCollapse } from "reactstrap";
// Functions
import { translate, Can } from 'helpers/helpers';
import { getCorruptedHeaders, toHeaderText } from "helpers/Imports/HeaderProcessor";
import { getErrorForCell } from "helpers/Imports/ErrorProcessing";

import BootstrapTable from 'react-bootstrap-table-next';
   
const AssetImportErrors = ({ imports, onClearErrors }) => {

    const { errors, headers, data } = imports ?? {};

    const fieldColumns = React.useMemo(() => {
        const corruptedHeaders = getCorruptedHeaders(errors, headers);

        if (!corruptedHeaders.length) {
          return [];
        }

        return corruptedHeaders.map((header) => ({ 
          dataField: header,
          text: toHeaderText(header),
          classes (cell, row) {
            const rowIndex = row.idx;
            if (errors[rowIndex]?.[header]) {
              return 'bg-danger';
            }
            return '';
          },
          formatter:  (cell, row) => {
            const rowIndex = row.idx;
            if (errors[rowIndex]?.[header]) {
              return getErrorForCell(errors[rowIndex][header], cell);
            }
            return cell;
          }
        }));
    }, [errors, headers]);


    const rows = React.useMemo(() => data 
      ? Object.entries(data).map(([idx, row]) => ({ ...row, idx })) 
      : [], [data]);
    
    const columns = React.useMemo(() => {
      if (fieldColumns.length) {
        return [{ dataField: 'idx', text: 'Line', formatter (cell) { return +cell + 1 } }]
          .concat(fieldColumns);
      }
      return [];
    }, [fieldColumns]);

    if (!columns.length || !data) {
        return null;
    }

    return(
        <CardBody>
        <Row>
            <Col lg={12}>
                <BootstrapTable keyField='id' data={ rows } columns={ columns } />
            </Col>
            <Col lg={12}>
              <Button className="btn-danger col-lg-12 mt-1" onClick={onClearErrors}>
                  Clear errors and try again
              </Button>
            </Col>
        </Row>
    </CardBody>
    )
}

export default AssetImportErrors;