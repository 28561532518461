import React from "react";
import { translate, Can } from "helpers/helpers";
import { Table, Tr, Th, Td, Tbody } from 'react-super-responsive-table';


const BlanccoDisk = ({ drive }) => (
    <Table className="table">
      <Tbody>
        <Tr>
          <Th>{translate('reports.disk-serial-number')}</Th>
          <Td>{drive.disk_serial_number}</Td>
        </Tr>
        <Tr>
          <Th>{translate('reports.model')}</Th>
          <Td>{drive.disk_model}</Td>
        </Tr>
        <Tr>
          <Th>{translate('reports.manufacturer')}</Th>
          <Td>{drive.disk_manufacturer}</Td>
        </Tr>
        <Tr>
          <Th>{translate('asset.category')}</Th>
          <Td>{drive.disk_category}</Td>
        </Tr>
        <Tr>
          <Th>{translate('reports.performed-security')}</Th>
          <Td>{drive.performed_security}</Td>
        </Tr>
        <Tr>
          <Th>{translate('reports.technician')}</Th>
          <Td>{drive.technician}</Td>
        </Tr>
        <Can I="read" a="Reseller">
          {() => (
          <Tr>
            <Th>Service tracking ID</Th>
            <Td>{drive.service_tracking_id}</Td>
          </Tr>
          )}
        </Can>
      </Tbody>
    </Table>
)

export default BlanccoDisk