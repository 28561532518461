import React from "react";
import { useDispatch } from "react-redux";
import { notifyUserAboutReport } from "store/user/actions";

import { Input , Card, CardBody, FormGroup, Label, CardTitle } from "reactstrap";
import { Can, translate } from 'helpers/helpers';
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";

const NotifyUser = ({ me, user, getThisUser }) => {
    const dispatch = useDispatch();

return (
    me?.id !== user?.id ?
    <Can I="update" a="User">
        {() => (
            <Card>
                <CardBody>
                <CardTitle style={ecosystemCardHeader}>{translate('user.notify-user')}</CardTitle>
                <FormGroup check className="mt-3">
                    <Label check>
                      <Input
                          checked={user?.report_notification ?? ''}
                          type="checkbox"
                          onChange={(e) => dispatch(notifyUserAboutReport(user.id, !user.report_notification))
                            .then(res => {
                                if(res.type === "NOTIFY_USER_ABOUT_REPORT_SUCCESS"){
                                    getThisUser()
                                }
                            })}
                      />
                      {user?.report_notification ? translate('user.notify-on')
                      : translate('user.notify-off')}
                    </Label>
              </FormGroup>
                </CardBody>
            </Card>
        )}
    </Can>
    : null
  )
}

export default NotifyUser
