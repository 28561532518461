import React from "react"
import { Link } from "react-router-dom";
import { Can, translate } from "helpers/helpers";
import { Icons } from 'assets/styles/index';
import NavItem from './NavItem'

const NavContracts = ({ toggleMenu, layoutType } ) => (
        <NavItem
            id={'contract'}
            layoutType={layoutType}
            item={
            <>
                <i className={Icons.contract}></i>
                <span className="ml-1">{translate('contract.contracts')}</span>
            </>
            }
            menu={
                <ul className="sub-menu ecosystem-list pl-2">
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link to="/contracts">{translate('contract.list')}</Link>
                    </li>
                <Can I="create" a="Contract">
                    {() => (
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link to="/contracts/create">{translate('nav.contracts-create')}</Link>
                    </li>
                    )}
                </Can>
                <Can I="read" a="Contract">
                    {() => (
                    <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                        <Link  to="/contracts/history">{translate('contract.history')}</Link>
                     </li>
                    )}
                </Can>
            </ul>
            }
        />
    )


export default NavContracts