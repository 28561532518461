import React from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import { Alerts } from '.';
import translate from 'helpers/Translation/translate';


  const PopUpModal = (
    { 
      success,
      error, 
      errors, 
      buttonText, 
      title, 
      isOpen, 
      togglemodal, 
      action, 
      children,
      useSpan = true,
      className="exampleModal",
      size
    }) =>  (
          <Modal size={size} isOpen={isOpen} className={className} centered={true} tabIndex="-1">
            <div className="modal-content">
              <ModalHeader>{title}</ModalHeader>
              <Alerts success={success} error={error} errors={errors} />
              <ModalBody>
                {useSpan ? <span className="p-3">
                  {children}
                </span> : children}
              </ModalBody>
              <ModalFooter>
                {action && <Button type="button" color="success" className="" onClick={action}>{buttonText}</Button>}
                <Button type="button" color="light" className="" onClick={() => togglemodal(false)}>{translate('close')}</Button>
              </ModalFooter>
            </div>
          </Modal>
      )

export default PopUpModal;