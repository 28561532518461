
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom';

// Components
import PopUpModal from './PopUpModal'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from "reactstrap"

// Functions
import { Can, translate } from 'helpers/helpers';
import { useSelector, useDispatch } from "react-redux";
import { deleteModel, clearModelState } from "store/model/actions";


const HandleDropDown = ({ can, model, id, history, children, me }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const error = useSelector(state => state.Model.error)
  const success = useSelector(state => state.Model.success)
  const dispatch = useDispatch()

  const action = () => {
    dispatch(clearModelState())
    dispatch(deleteModel( id, model))
    .then(res => {
      if(res?.type === `DELETE_${model}_SUCCESS`) {
        setIsOpen(false)
        history.push(`/${model}s`, `message.delete-${model}-success`)
      }
    }
    ,[dispatch, id, model])
  }


  return (
   <>
    <Can I="update" a={can}>
      {() => (
        <UncontrolledDropdown style={{zIndex: 20}}>
          <DropdownToggle className="card-drop float-right btn btn-light btn-sm">
            <span style={{ color: 'black'}}>{translate('handle')}</span>
          </DropdownToggle>
          <DropdownMenu right className="mt-5">
             {children}
            <DropdownItem >
              <Link className="btn btn-warning col-lg-12 mb-2 " to={`/${model}s/${id}/edit`}>
                  {translate('edit')}
              </Link>
            </DropdownItem>
            {(model === 'user' && (me?.id === id)) ? null :
            <Can I="delete" a={can}>
              {() => (
                <DropdownItem>
                  <Link to="#" onClick={(e) => {e.preventDefault();setIsOpen(true)}} className="btn btn-danger col-lg-12 mb-2 ">
                    {translate('delete')}
                  </Link>
                </DropdownItem>
              )}
            </Can>}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </Can>

    <PopUpModal
      isOpen={isOpen}
      togglemodal={setIsOpen}
      title={translate(`${model}.delete`)}
      success={success}
      error={error}
      action={action}
      buttonText={translate(`${model}.delete`)}
      >
      <p className="mb-2"> {translate(`${model}.delete-sure`)}</p>
    </PopUpModal>

    </>
)
}

export default withRouter(HandleDropDown)