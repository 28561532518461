import React from "react";
import translate from '../Translation/translate';


const BlanccoColumns = [
    {
        text: 'Disk serial number',
        dataField: "disk_serial_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('reports.disk-serial-number'))
        },
        formatter: (cell, row) => {
            return (
                row.product_serial_number ? cell :
                <a href={`/moreco-reports/blancco/disk/${cell}`} className="d-flex">
                    {cell}
                </a>
            )
        }
    },
    {
        text: 'Product serial number',
        dataField: "product_serial_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '170px'};
        },
        headerFormatter: () => {
            return (translate('reports.product-serial-number'))
        },
        formatter: (cell, row) => {
            return ( row.disk_serial_number !== cell ?
                <a href={`/moreco-reports/blancco/asset/${cell}`} className="d-flex">
                    {cell}
                </a>
                : null
            )
        }
    },
    {
        text: 'Model',
        dataField: "disk_model",
        sort: true
        ,
        headerFormatter: () => {
            return (translate('reports.model'))
        },
        headerStyle: () => {
            return { minWidth: '170px'};
        },
    },
    {
        text: 'Time',
        dataField: "blancco_report.time",
        sort: true,
        headerStyle: () => {
            return { minWidth: '120px'};
        },
        headerFormatter: () => {
            return (translate('asset.time'))
          },
    },
    {
        text: 'Size MB',
        dataField: "blancco_report.size",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.size'))
        },
        headerStyle: () => {
            return { minWidth: '70px'};
        }
    },
    {
        text: 'Erasure method',
        dataField: "blancco_report.erasure_method",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.erasure-method'))
        },
        headerStyle: () => {
            return { minWidth: '120px'};
        }
    },
    {
        text: 'LOT number',
        dataField: "lot_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('reports.lot-number'))
          },
    },
    {
        text: 'State',
        dataField: "blancco_report.state",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('reports.status'))
          },
    },
]


const getBlanccoColumns = (role, page) => {

    var notAvailableFor = {
      reseller: {},
      admin:{},
      customer: {}
    };

    notAvailableFor['reseller']['all'] = [ '']
    notAvailableFor['admin']['all'] = [ '']
    notAvailableFor['customer']['all'] = [ 'customer_id']

    notAvailableFor['reseller']['lot-report'] = [ 'lot_number']
    notAvailableFor['admin']['lot-report'] = [ 'lot_number']
    notAvailableFor['customer']['lot-report'] = [ 'customer_id', 'lot_number']

    var filteredColumns = (role && page ) ? BlanccoColumns.filter(d => !notAvailableFor[role][page].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getBlanccoColumns