import React from "react";
import translate from '../Translation/translate';
import { Link } from "react-router-dom";
import moment from 'moment'

const ResellerListColumns = [
    {
        text: 'ID',
        dataField: "id",
        sort: true,
        headerStyle: () => {
          return { minWidth: '50px', width: '30px'};
        },
        formatter: (cell, row) => {
          return(
              <a href={`/resellers/${cell}`}>
                {cell}
              </a>)
        },
    },
    {
        text: 'Reseller',
        dataField: "name",
        sort: true,
        headerFormatter: () => {
          return (translate('reseller.reseller'))
        }
    },
    {
        text: 'Organizational number',
        dataField: "org_no",
        sort: true,
        headerFormatter: () => {
          return (translate('form.org-no'))
        }
    },
    {
        text: 'Customers',
        dataField: "customers",
        sort: false,
        formatter: (cell, row) => {
            return (
              <ul className="ecosystem-list">
                {row.customers ? row.customers.map((customer, idx) => {
                    return (
                      <li key={idx} className="pb-3">
                        <Link
                          key={customer.id}
                          to={`customers/${customer.id}`}
                          className="team-member d-inline-block mr-2"
                          id={`customer_${customer.id}`}
                        >
                        {customer.name}
                        </Link>
                      </li>
                    );
                    })
                : null}
              </ul>
            )
          },
        headerFormatter: () => {
            return (translate('customer.customers'))
        },
        headerStyle: () => {
            return { width: '300px'};
        },
    },
    {
      text: 'Created at',
      dataField: "created_at",
      sort: true,
      formatter: (cell, row) => {
          return (moment(cell).format('YYYY-MM-DD'))
      },
      headerStyle: () => {
          return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('created-at'))
      }
  },
  {
      text: 'Updated',
      dataField: "updated_at",
      sort: true,
      formatter: (cell, row) => {
          return (moment.utc(cell).local().format('YYYY-MM-DD HH:mm'))
      },
      headerStyle: () => {
          return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('updated-at'))
      }
  },
]


const getResellerColumns = type => {
  var notAvailableOn= {
    ids: {},
    all: {}
  };

    notAvailableOn['ids'] = ['reseller_id', 'org_no' ]
    notAvailableOn['all'] = [ ]

    var filteredColumns = type ? ResellerListColumns.filter(d => !notAvailableOn[type].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getResellerColumns