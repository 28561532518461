import React from "react";
import { RemotePaginationTable } from "Components/Lists";
import { Button } from "reactstrap";
import { getContractColumns, translate } from 'helpers/helpers';
import { useSelector } from "react-redux";
import ContractCreateUpdate from "Pages/Contract/contract-create-update";
import { Alerts } from 'Components/Global';

export default function ContractsBody({ onItemClick, context }) {

    const [creating, setCreating] = React.useState(false);
    const success = useSelector(state => state.Model.success);
    const formatter = React.useCallback((cell, row) => <Button onClick={() => onItemClick(row)} color="success">{cell}</Button>, [onItemClick]);

    const columns = React.useMemo(() => {
        const columns = getContractColumns('admin', 'all');
        return columns.map((c) => c.dataField === 'id' ? { ...c, formatter } : c);
    }, [formatter]);

    const pagination = useSelector(state => state.Model.contracts);

    const toggleCreating = () => setCreating(!creating);
    const predefinedFilter = React.useMemo(() => ({ customer_id: context.customer?.id }), [context?.customer]);
    return (
    <div>
        <Alerts success={success} />
        {creating 
            ? <Button color="danger" onClick={toggleCreating}>{translate('cancel')}</Button>
            : <Button color="success" onClick={toggleCreating}>+ {translate('nav.contracts-create')}</Button>
        }
        {!creating && <RemotePaginationTable
            model={'contracts'}
            pagination={pagination}
            columns={columns}
            pageUrl={`${process.env.REACT_APP_API_URL}/contracts`}
            can={'Contract'}
            predefinedFilter={predefinedFilter}
        >
        </RemotePaginationTable>}
        {creating && <ContractCreateUpdate onContractUpdate={onItemClick} embed />}
    </div>);
}
