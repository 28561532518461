import React, { useCallback, useEffect, useState } from "react";
// Redux and actions
import { useSelector, useDispatch } from "react-redux";
// Redux and actions
import { getResellerDropdown, getCustomersForThisReseller, getCustomerDropdown } from "store/form/actions";
import { getById, updateModel, createModel, clearModelState } from 'store/model/actions'
// Components
import { Card, CardBody, Button, Row, Container } from "reactstrap";
import ContractFinancialDetails from './Components/form/ContractFinancialDetails';
import ContractCustomerDetails from './Components/form/ContractCustomerDetails';
import ContractTypeDetails from './Components/form/ContractTypeDetails';
import ContractStatus from './Components/form/ContractStatus';
import ContractCardHeader from './Components/form/ContractCardHeader';
import { AvForm } from "availity-reactstrap-validation";
import CreateContainer from "Containers/CreateContainer";
import { translate} from 'helpers/helpers';


const ContractCreateUpdate = ({ match, location, history, embed = false, onContractUpdate }) => {

  const [ contract, _setContract ] = useState({ parent_id: '', active: true })
  const [ deliveryAddress, setDeliveryAddress ] = useState(false)
  const [ displayDateError, setDisplayDateError ] = useState(false)
  const [ createSubstart, setCreateSubstart ] = useState(false)
  const [ editMode, setEditMode ] = useState(false)

  const error = useSelector(state => state.Model.error)
  const errors = useSelector(state => state.Model.errors)
  const success = useSelector(state => state.Model.success)
  const isFetching = useSelector(state => state.Model.isFetching)
  const customersDropdown = useSelector(state => state.Form.customersDropdown)
  const resellersDropdown = useSelector(state => state.Form.resellersDropdown)

  const dispatch = useDispatch()

  const url = match?.path
  const id = match?.params.id

  const setContract = useCallback((c) => _setContract({ ...contract, ...c }), [contract]);

  const getContract = () => {
    dispatch(getById(id, 'contract'))
    .then(res => {
        if(res.payload.delivery_address || res.payload.delivery_zip || res.payload.delivery_city) setDeliveryAddress(true)
        setContract(res.payload)
    }).then((() => {
      if(contract) dispatch(getCustomersForThisReseller(contract?.reseller_id))
    }))
  }

  useEffect(() => {
    dispatch(getCustomerDropdown())
    dispatch(getResellerDropdown())
    if(url === "/contracts/:id(\\d+)/edit") {
      setEditMode(true)
      getContract()
    }
    if(url === "/contracts/:id(\\d+)/create-substart") {
      setCreateSubstart(true)
      getContract()
    }
    return () => {
      dispatch(clearModelState())
      location && (location.state = null);
    }
  }, [])


  const dateChange = (name, date) => {
    contract[name] = date
    setContract(contract)
  };

  const handleChange = (e) => {
    contract[e.target.name] = e.target.value

    if(e.target.name === 'reseller_id') {
      dispatch(getCustomersForThisReseller(e.target.value))
      contract.customer_id = ''
    }
    setContract(contract)
  };

  const setActive = active => {
    contract.active = active
    setContract(contract)
  }

  const handleSubmit = () => {
    if(!contract.start_date || !contract.start_date) {
      setDisplayDateError(true)
    } else {
      if(editMode) {
        dispatch(updateModel('contract', contract))
        .then(res => {
          if(res.type === "UPDATE_contract_SUCCESS" ) {
            onContractUpdate?.(res.payload);
            return history.push( `/contracts/${res.payload.id}`, "message.update-contract-success");
          }
        })
      } else {
        if (createSubstart) {
          contract.parent_id = id
          contract.type = 'substart'
          setContract(contract)
        }
        dispatch(createModel('contract', contract))
        .then(res => {
          if(res.type === "CREATE_contract_SUCCESS" ) {
            onContractUpdate?.(res.payload);
            return history?.push(`/contracts`, "message.create-contract-success");
          }
        })
      }
    }
  };

  const cardBody = <CardBody>
    <Container className={`${!embed ? 'col-lg-10' : ''} mx-auto`}>
      <AvForm onValidSubmit={handleSubmit}>
        <ContractCustomerDetails
          getChange={handleChange}
          getDeliveryAddress={(deliveryAddress) => setDeliveryAddress(deliveryAddress)}
          deliveryAddress={deliveryAddress}
          customers={customersDropdown}
          resellers={resellersDropdown}
          editMode={editMode}
          contract={contract}
          createSubstart={createSubstart}
        />
      <hr />
      <ContractStatus contract={contract} setActive={setActive}/>
      <ContractTypeDetails
        dateChange={dateChange}
        getChange={handleChange}
        editMode={editMode}
        createSubstart={createSubstart}
        contract={contract}
        displayDateError={displayDateError}
      />
      <hr />
      <ContractFinancialDetails getChange={handleChange} contract={contract} />
      <hr />
      <Row className="d-flex">
        <Button type="submit" color="success" className=" mx-auto">
          {editMode ? translate("contract.edit") : createSubstart ? translate("contract.create-substart"): translate("contract.create")}
        </Button>
      </Row>
    </AvForm>
    </Container>
  </CardBody>;

    return !embed ? (
          <CreateContainer
            Model={'Contract'}
            isFetching={isFetching}
            breadcrumbTitle={translate("contract.contracts")}
            breadcrumbItem={editMode ? translate("contract.edit") : translate("contract.create")}
            editMode={editMode}
            success={success}
            error={error}
            errors={errors}
          >
              <ContractCardHeader contract={contract} editMode={editMode} createSubstart={createSubstart}/>
              {cardBody}
          </CreateContainer>
    ) : cardBody;
  }

export default ContractCreateUpdate

