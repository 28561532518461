import React from 'react';
import { Card } from 'reactstrap';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const CustomToggleList = ({ columns, toggles, onColumnToggle }) => {

  var AssetToggles = cookies.get("AssetToggles")
  if(!AssetToggles) {
    cookies.set('AssetToggles', toggles)
    AssetToggles = toggles;
  }
  const toggleThis = ( dataField, toggle ) => {
    AssetToggles[dataField] = !toggle
    cookies.set('AssetToggles', AssetToggles)
    onColumnToggle(dataField, toggle)
  }

  return (
    <div className="col-12 p-0" data-toggle="buttons">
      <Card>
        {columns?.map(column => ({ ...column, toggle: AssetToggles[column.dataField] }))
        .map(column => {
          if( column.dataField === 'in_use' ||
              column.dataField === 'deleted_at'
              ) { return null }
          return (
            <button
              type="button"
              key={ column.dataField }
              className={ `btn mb-1 col-lg-12 ${column.toggle ? 'btn-success'  : 'btn-secondary' }` }
              data-toggle="button"
              aria-pressed={ column.toggle ? 'true' : 'false' }
              onClick={ () => toggleThis(column.dataField, column.toggle)}
            >
              { column.text }
            </button>
              )
            })
        }
    </Card>
    </div>
  )
}

  export default CustomToggleList