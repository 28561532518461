import React, { useEffect } from 'react';
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getKeyAccountManagers, clearState } from "store/user/actions";
// Components
import { Alerts} from 'Components/Global/index';
import TableList from "Components/Lists/TableList";
import PageContainer from 'Containers/PageContainer'
// Functions
import { translate, getKamColumns } from 'helpers/helpers';


const KeyAccountManagerList = ({ history }) => {

        const KeyAccountManagers = useSelector(state => state.Users.KeyAccountManagers )
        const success = useSelector(state => state.Users.success )
        const error = useSelector(state => state.Users.error )
        const isFetching = useSelector(state => state.Users.isFetching)
        const me = useSelector(state => state.Global.me)

        const dispatch = useDispatch()

        useEffect(() => {
            dispatch(getKeyAccountManagers())
            return () => dispatch(clearState())
          }, [dispatch, history.length]
        )


        return (
            <PageContainer
                isFetching={isFetching}
                Model={"Key"}
                breadcrumbTitle={translate("user.users")}
                breadcrumbItem={translate("Key Account Managers")}
                listHeaderTitle={translate('customer.customers')}
                data={KeyAccountManagers ?? []}
                alerts={<Alerts success={success} error={error} />}
                >
                <TableList
                    data={KeyAccountManagers}
                    columns={getKamColumns(me?.type)}
                    model={'Key'}
                    create_link={"/users/key-account-managers/create"}
                    button_text={translate("user.create-kam")}
                    dataField={"name"}
                />
            </PageContainer>
        )
}


export default KeyAccountManagerList;