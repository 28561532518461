import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Functions
import { translate } from 'helpers/helpers';

const GetToken = ({ apiToken }) => {

  const [copy, setCopy] = useState(false);

  return (
    <>
    <p>{translate("api-token-info")}</p>
    <span className="mt-3 mb-3 text-success">
      <CopyToClipboard text={apiToken} onCopy={() => setCopy(true)} >
        <span>{apiToken}</span>
      </CopyToClipboard>
      <i className="mdi mdi-content-copy ml-1"></i>
      {copy ? <span className="ml-2">{translate('copied')}</span> : null}
    </span>
    </>
    )
  }

export default GetToken
