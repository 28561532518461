import React,  { useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";

// Redux and actions
import { getWidgets, clearState } from 'store/dashboard/actions'

// Components
import { LoaderOnPage } from "Components/Global";
import { Row, Col, UncontrolledAlert, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import TableList from "Components/Lists/TableList";
import WarrantyCardCustomer from "./Components/Warranty/WarrantyCardCustomer";
import { ContractListDashboardColumns, translate } from 'helpers/helpers';

// Styles
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";
import ecosystemDashboardCard from 'assets/styles/EcosystemDashboardCard'




const ResellerDashboard  = () => {

  const error = useSelector(state => state.Dashboard.error)
  const customersPurchasedAssets = useSelector(state => state.Dashboard.customersPurchasedAssets)
  const customersContractsExpiresSoon = useSelector(state => state.Dashboard.customersContractsExpiresSoon)
  const customersContracts = useSelector(state => state.Dashboard.customersContracts)

  const isFetching = useSelector(state => state.Dashboard.isFetching)
  const success = useSelector(state => state.Dashboard.success)

	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWidgets())
    return () => {
      dispatch(clearState())
    }
  },[])




  return (
        <Col xl={12}>
            {error && <UncontrolledAlert color="danger">{error}</UncontrolledAlert>}
            {success && <UncontrolledAlert color="danger">{success}</UncontrolledAlert>}
            {isFetching ? <LoaderOnPage /> :
            <Row>
                <Col lg={12} md={12}>
                  <Row>
                    {customersPurchasedAssets?.map((customer, key) => {
                      return (
                        <Col lg={4} md={6} key={key}>
                          <WarrantyCardCustomer title={customer.name} warranty={customer.warranty}/>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
                {customersContracts ?
                <Col lg={12}>
                    <Card style={ecosystemDashboardCard}>
                        <CardHeader style={ecosystemCardHeader}>
                            <CardTitle className="text-center">
                              {translate('dashboard.contracts-due-soon')}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                        <TableList
                            model={'Contract'}
                            data={customersContractsExpiresSoon ?? []}
                            columns={ContractListDashboardColumns}
                            dataField={'end_date'}
                            />
                        </CardBody>
                    </Card>
                </Col>
                : null}
            </Row>}
        </Col>
    );
  }



export default ResellerDashboard;

