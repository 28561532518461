import React, { useState, useEffect } from 'react';
// Components
import PageContainer from 'Containers/PageContainer'
import AssetsTable from './Components/AssetList/Table/AssetsTable';
// Functions
import { translate } from 'helpers/helpers';
import { useSelector } from "react-redux";

const AssetList = ({ match, location }) => {

  const [ filter, setFilter ] = useState(null)
  const [ title, setTitle] = useState(undefined)
  const [ textColor, setTextColor] = useState(undefined)
  const me = useSelector((state) => state.Global.me);
  const pagination = useSelector(state => state.Model.assets)


  useEffect(() => {

    const warranty = new URLSearchParams(location.search).get("warranty")
    const customer_id = me.type === 'customer' && location.search ? me.customer_id : new URLSearchParams(location.search).get("customer_id")
    const product_category = new URLSearchParams(location.search).get("product_category")
    const missing_price = new URLSearchParams(location.search).get("missing_price")
    const type = new URLSearchParams(location.search).get("type")

    if(location.search) {
      setFilter({ warranty: warranty, customer_id: customer_id, product_category: product_category, missing_price: missing_price, type: type})
    }

    switch(warranty) {
      case 'expired' :
        setTitle('warranty.expired')
        setTextColor('text-danger')
        break;
      case 'not_available':
        setTitle('warranty.unknown')
        setTextColor('text-muted')
        break;
      case 'six_months':
        setTitle('warranty.due-soon')
        setTextColor('text-warning')
        break;
      default: return 
    }
    if(!warranty) {
      setTitle(null)
      setTextColor(null)
    }

    return () => {
      setTitle(null)
      setTextColor(null)
    };

  },[match.path, location.search, location.pathname, me.customer_id, me.type])

    return (
      <PageContainer
        Model={"Asset"}
        data={pagination?.total}
        breadcrumbTitle={'Assets'}
        breadcrumbItem={translate('nav.assets-list')}
        listHeaderTitle={'Assets'}
        success={location.state ? translate(`${location.state}`) : null}
        >
          <AssetsTable
            predefinedFilter={filter}
            title={title}
            textColor={textColor}
            match={match}
            />
        </PageContainer>
    )
 }


export default AssetList