import React, { useState, useEffect } from 'react';
import {  useSelector, useDispatch } from "react-redux";
// Redux and actions
import { getContractsForThisCustomer } from 'store/form/actions'
import { getAll, getById, updateModel, createModel, clearModelState } from 'store/model/actions'
// Components
import { Row, Col, Button, UncontrolledCollapse } from "reactstrap";
import { SelectProduct, TypeOfAsset, AssetDetails, AssetOrder, AssetUser, SerialNumbers, Section, DeliveryDate  } from "./Components/Form/index";
import { AvForm } from "availity-reactstrap-validation";
import ProductFields from './Components/Form/ProductFields';
import CreateContainer from "Containers/CreateContainer";
// Functions
import { translate } from 'helpers/helpers';


const AssetCreateUpdate = ({ match, history }) => {

  const dispatch = useDispatch();
  const [ editMode, setEditMode ] = useState(false)
  const [ asset, setAsset ] = useState({
    customer_id: undefined,
    delivery_date: '',
    invoice_date: '',
    warranty_end: '',
    in_use: false,
    product: {
      supplier_code: '',
      supplier: '',
      product_category_id: '',
    }
  })
  const url = match?.path
  const id = match?.params?.id

  const isFetching = useSelector(state => state.Model.isFetching)
  const error = useSelector(state =>  state.Model.error)
  const errors = useSelector(state =>  state.Model.errors)
  const success = useSelector(state =>  state.Model.success)
  const assetError = useSelector(state => state.Assets.error)
  const assetErrors = useSelector(state =>  state.Assets.errors)
  const contracts = useSelector(state =>  state.Form.contracts)
  const statuses = useSelector(state =>  state.Model.status)


  useEffect(() => {
    if(url === "/assets/:id(\\d+)/edit") {
      dispatch(getById(id, 'asset')).then(res => {
      if(res.payload) {
        setEditMode(true)
        setAsset(res.payload)
        dispatch(getAll('status'))
      }
      dispatch(getAll('contracts'))
    })
  }
    return () => {
      dispatch(clearModelState())
    }
  },[url, id, dispatch])


const handleChangeOnProduct = e => {
  asset['product'][e.target.name] = e.target.value
  setAsset(asset)
}

const handleChange = e => {
  const { value, name } = e.target
  asset[name] = value

  switch(name) {
    case "customer_id" :
      asset.contract_id = null
      dispatch(getContractsForThisCustomer(value))
      break;
    case "contract_id" :
      if(value.length) asset.type = 'rented'
      else asset.type = 'purchased'
      break;
    case "serial_number" :
      asset.serial_numbers = value
        break;
    case "serial_numbers" :
      asset.in_use = false
      asset.user = null
        break;
    case "user" :
      if(value.length > 0) asset.in_use = true
      else asset.in_use = false
        break;
    default:
  }
  setAsset({ ...asset })
}

const dateChange = (type, date ) => {
  asset[type] = date
  setAsset(asset)
};

const setProduct = res => {
  if(res.product) {
    asset['use_icecat'] = res?.product?.type === 'manual' ? false : true
    asset['product'] = res.product
  } else {
    asset['product'] = res
    asset['use_icecat'] = false
  }
  setAsset(asset)
}

const setImage = image => {
  asset['product']['image'] = image
  setAsset(asset)
}

const changeInUse = use => {
  asset.in_use = !use
  setAsset(asset)
}

const handleValidSubmit = () => {
  dispatch(clearModelState())
  editMode ? dispatch(updateModel('asset', asset)) :
  dispatch(createModel('asset', asset))
  .then(res => {
    if(res.type === "CREATE_asset_SUCCESS") history.push(`/assets`, `message.create-asset-success`)
  })
}

    return (
      <CreateContainer
        isFetching={isFetching}
        Model="Asset"
        breadcrumbItem={editMode ? translate('asset.edit') : translate('asset.add')}
        breadcrumbTitle={"Assets"}
        editMode={editMode}
        success={success}
        error={error ?? assetError}
        errors={errors ?? assetErrors}
        >
          <Row>
            <Col lg={12}>
              <AvForm onValidSubmit={handleValidSubmit}>
                <Section title={translate('customer.customer')}>
                  <TypeOfAsset
                    editMode={editMode}
                    asset={asset}
                    contracts={contracts}
                    statuses={statuses}
                    handleChange={handleChange}
                    />
                </Section>
                {!editMode &&
                <>
                  <Section title={translate('product.product')}>
                    <SelectProduct
                      getProduct={setProduct}
                      handleChange={handleChangeOnProduct}
                      product={asset.product}
                      getImage={setImage}
                      editMode={editMode}
                      customer_id={asset.customer_id}
                      />
                  </Section>
                  <Section title={translate('asset.serialnumbers')}>
                    <SerialNumbers handleChange={handleChange} asset={asset}/>
                  </Section>
                </>}

                {editMode ?
                <>
                  <div className="btn btn-info mb-4" id={"product-info"} >{translate('show-product')}</div>
                  <UncontrolledCollapse toggler={"#product-info"}>
                    <Section title={translate('product.product')}>
                      <ProductFields product={asset.product} onAsset />
                    </Section>
                  </UncontrolledCollapse>
                </>
                :null}

                <Section title={translate('asset.details')}>
                  <AssetDetails asset={asset} handleChange={handleChange} />
                  <AssetOrder handleChange={handleChange} asset={asset} dateChange={dateChange} />
                </Section>
                <Row>
                  <Col lg={editMode ? 6 : 12}>
                    <Section title={translate('asset.life-cycle-data')}>
                      <DeliveryDate asset={asset} dateChange={dateChange} />
                    </Section>
                  </Col>
                  {editMode &&
                  <Col lg={6}>
                    <Section title={translate('asset.user')}>
                      <AssetUser handleChange={handleChange} changeInUse={changeInUse} asset={asset}/>
                    </Section>
                  </Col>}
                </Row>

                <Col className="col-lg-12 text-center">
                  <Button type="submit" color="success" onClick={() => handleValidSubmit()}>
                    {editMode ? translate('asset.edit') : translate('asset.add')}
                  </Button>
                </Col>
              </AvForm>
            </Col>
          </Row>
      </CreateContainer>
    );
  }


export default AssetCreateUpdate
