import React from "react";
import { Row, Col } from "reactstrap";
import translate from "helpers/Translation/translate";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const AssetPrice = ({ asset, isCustomer }) => (
  <Row>
    <Col lg={12}>
      <div className="table-responsive">
        <Table className="table">
          <Tbody>
            {asset.type === "purchased" ? (
              <Tr>
                <Th> {translate("asset.price")} </Th>
                <Td className="text-muted">
                  {asset?.price ? asset?.price?.toLocaleString() + " kr" : "-"}
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Th scope="row" width={"220px"}>
                  {" "}
                  {translate("asset.monthly-cost")}
                </Th>
                <Td className="text-muted">
                  {asset?.monthly_cost ? asset?.monthly_cost?.toLocaleString() + " kr" : "-"}
                </Td>
              </Tr>
            )}
            {!isCustomer && (
              <Tr>
                <Th> {translate("asset.emv")}</Th>
                <Td className="text-muted">
                  {asset?.product?.market_value ? asset?.product?.market_value?.toLocaleString() + " kr" :
                    "-"}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </Col>
  </Row>
);

export default AssetPrice;
