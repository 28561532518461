import translate from 'helpers/Translation/translate';
import axios from 'axios';
import { demo, tools } from '../api'
import qs from 'qs';

const RESET_DEMO_REQUEST = "RESET_DEMO_REQUEST"
const RESET_DEMO_SUCCESS = "RESET_DEMO_SUCCESS"
const RESET_DEMO_FAILURE = "RESET_DEMO_FAILURE"

const GET_BILLING_REQUEST = "GET_BILLING_REQUEST"
const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS"
const GET_BILLING_FAILURE = "GET_BILLING_FAILURE"

const SYNC_ITGLUE_REQUEST = "SYNC_ITGLUE_REQUEST"
const SYNC_ITGLUE_SUCCESS = "SYNC_ITGLUE_SUCCESS"
const SYNC_ITGLUE_FAILURE = "SYNC_ITGLUE_FAILURE"

const SYNC_ICECAT_REQUEST = "SYNC_ICECAT_REQUEST"
const SYNC_ICECAT_SUCCESS = "SYNC_ICECAT_SUCCESS"
const SYNC_ICECAT_FAILURE = "SYNC_ICECAT_FAILURE"

const MONTHLY_PDF_REQUEST = "MONTHLY_PDF_REQUEST"
const MONTHLY_PDF_SUCCESS = "MONTHLY_PDF_SUCCESS"
const MONTHLY_PDF_FAILURE = "MONTHLY_PDF_FAILURE"

const GET_DEVICES_REQUEST = "GET_DEVICES_REQUEST"
const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
const GET_DEVICES_FAILURE = "GET_DEVICES_FAILURE"

const CLEAR_STATE = "CLEAR_STATE"


export const clearState = () => { return async dispatch => { dispatch({ type: CLEAR_STATE}) } }


export const resetDemo = () => {
  return async dispatch => {
      dispatch({ type: RESET_DEMO_REQUEST })
        try {
            await demo.get();
            return dispatch ({ type: RESET_DEMO_SUCCESS, message: translate('message.demo-reset-success') });
          } catch (error) {
                return dispatch ({ type: RESET_DEMO_FAILURE, message: translate('message.demo-reset-failure')});
            }
        }
}

export const getBillingInfo = () => {
    return async dispatch => {
      dispatch({ type: GET_BILLING_REQUEST })
        try {
            const customers = await tools.get(`/billing-info`);
            return dispatch ({ type: GET_BILLING_SUCCESS, payload: customers.data });
          } catch (error) {
                return dispatch ({ type: GET_BILLING_FAILURE, message: translate('message.get-reseller-customers-failure')});
            }
        }
}


export const syncItGlue = () => {
    return async (dispatch) => {
      dispatch({ type: SYNC_ITGLUE_REQUEST });
      try {
        const success = await tools.get(`/itglue/sync`);
        if(success) return dispatch({ type: SYNC_ITGLUE_SUCCESS, message: translate('message.sync-it-glue-success')});
      } catch (error) {

          return dispatch({ type: SYNC_ITGLUE_FAILURE, message: error?.response?.data?.error?.message ?? translate('message.sync-it-glue-failure')});
      }
    };
  }

  export const syncIcecat = () => {
    return async (dispatch) => {
      dispatch({ type: SYNC_ICECAT_REQUEST });
      try {
        const success = await tools.get(`/icecat/sync`);
        if(success) return dispatch({ type: SYNC_ICECAT_SUCCESS, message: translate('message.sync-icecat-success')});
      } catch (error) {

          return dispatch({ type: SYNC_ICECAT_FAILURE, message: error?.response?.data?.error?.message ?? translate('message.sync-icecat-failure')});
      }
    };
  }

  export const getManagedDevices = () => {

    return async dispatch => {
      try {
        dispatch({ type: GET_DEVICES_REQUEST })
        const response = await tools.get(`/managed-devices`);
        return dispatch({ type: GET_DEVICES_SUCCESS, payload: response.data.data  })
      } catch (error) {
        if(error?.response?.status === 401) return dispatch({ type: GET_DEVICES_FAILURE, message: error?.response?.data?.message })
        else  return dispatch({ type: GET_DEVICES_FAILURE, message: translate('message.get-devices-failure') })
      }
    }
  };