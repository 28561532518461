import React from "react";

// Redux and actions
import { useSelector } from "react-redux";
import { translate, getContractColumns } from 'helpers/helpers';
import { Alerts } from 'Components/Global';
import { RemotePaginationTable} from "Components/Lists";
import PageContainer from "Containers/PageContainer";


const ContractList = ({ match, location }) => {

  const pagination = useSelector(state => state.Model.contracts)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);
  const warning = useSelector((state) => state.Model.warning);
  const me  = useSelector(state => state.Global.me)

    return (
      <PageContainer
        Model={'Contract'}
        breadcrumbTitle={translate("contract.contracts")}
        breadcrumbItem={translate("contract.list")}
        listHeaderTitle={translate('contract.list')}
        data={pagination?.total ?? []}
        alerts={<Alerts success={success} error={error} location={location} warning={warning} />}
      >
        <RemotePaginationTable
          model={'contracts'}
          pagination={pagination}
          columns={getContractColumns(me?.type , 'all')}
          pageUrl={`${process.env.REACT_APP_API_URL}/contracts`}
          path={match.path}
          can={'Contract'}
          createLink={'/contracts/create'}
          buttonText={translate('contract.create')}
        />
    </PageContainer>
      );
  }


export default ContractList
