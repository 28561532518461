import React from 'react';
import { Button } from 'reactstrap'

const MyExportCSV = ({ onExport }) =>  (
        <Button className="mr-2 mb-3 btn-light " onClick={() => { onExport()}} >
            <i className="mdi mdi-download-outline" style={{ color: 'black'}}></i>
        </Button>
      )
export default MyExportCSV

