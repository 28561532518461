import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { AvField } from "availity-reactstrap-validation";


class TextInputField extends Component {
  render() {
   const { formatMessage } = this.props.intl;
   const {
     placeholder,
     name,
     value,
     errorMessage,
     type,
     onChange,
     min,
     validate,
     defaultValue,
     ref,
     required,
     label,
     readonly,
     max,
     onClick
   } = this.props;

   
   return(
        <AvField
          readOnly={readonly ?? false}
          id={name}
          name={name}
          type={type}
          value={value}
          defaultValue={defaultValue}
          ref={ref}
          className="form-control"
          placeholder={placeholder ? formatMessage({ id: placeholder }) : null}
          onChange={onChange}
          errorMessage={errorMessage ? formatMessage({ id: errorMessage }) : null}
          required={required ?? false}
          validate={validate ?? null}
          label={label ? formatMessage({ id: label }) : null}
          minLength={min ?? null}
          maxLength={max ?? null}
          onClick={onClick}
        />
   )
  }
}

 export default injectIntl(TextInputField);