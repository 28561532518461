import React from "react"
import { Link } from "react-router-dom";
import { Can, translate } from "helpers/helpers";
import { Icons } from 'assets/styles/index';
import NavItem from './NavItem'

const NavCustomers = ({ toggleMenu, layoutType }) => (

            <NavItem
                id={'customer'}
                layoutType={layoutType}
                item={
                <>
                    <i className={Icons.customer}></i>
                    <span className="ml-1">{translate('nav.customers')}</span>
                </>
                }
                menu={
                <ul className="sub-menu ecosystem-list pl-2">
                    <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                        <Link  to="/customers">{translate('nav.customers-list')}</Link>
                    </li>
                    <Can I="create" a="Customer">
                    {() => (
                    <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                        <Link  to="/customers/create">{translate('nav.customers-create')}</Link>
                    </li>
                    )}
                    </Can>
                </ul>
                }
            />
    )


export default NavCustomers