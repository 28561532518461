import React from "react";
import { translate } from "helpers/helpers";
import SectionContainer from "./SectionContainer";
import { Row, Col } from 'reactstrap'
import diplomaImage from 'assets/images/diploma-image.png'
import signature from 'assets/images/signature.png'

const SectionDiploma = ({ report, downloadReport, exportPdf, id }) => {

const homes = report.homes
const name = (report?.results?.reseller_id && report?.customers?.length > 1) ? report.reseller?.name :
report?.results?.reseller_id ? report.reseller?.name :
report.customers[0].name

return (
  <SectionContainer
    report={report}
    downloadReport={downloadReport}
    exportPdf={exportPdf}
    id={id}
    title={translate('reports.diploma-title')}
    filename={'Diploma'}
    body={
      <Col lg={12}>
        <Row>
          <Col lg={7}>
            <h2 className="mt-5">{name }</h2>
            <h5 className="mt-5">
              {name} {translate('reports.diploma-text-1')}
            </h5>
            <h5 className="mt-4 mb-4">
            {name }{translate('reports.diploma-text-2', {year: report.results.year})}
             <strong className="text-success">{Math.round(report.total_co2e).toLocaleString()} kg CO<sub>2</sub>e</strong>
            </h5>
            <p className="mt-5">{translate('reports.comparison-houses', {houses: homes.toFixed().toLocaleString()})}</p>
            <img className="mt-5" src={signature} width="60%" height="auto" alt="signature"/>
          </Col>
          <Col lg={5}>
            <img src={diplomaImage} width="auto" height="500px" alt="graphs and lists"/>
          </Col>
        </Row>
      </Col>
    }
  />

)
  }

export default SectionDiploma

