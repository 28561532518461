import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'


const columns = [
    {
        text: 'Time',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD hh:mm'))
            },
        headerStyle: () => {
            return { width: '150px'};
        },
        headerFormatter: () => {
            return (translate('asset.time'))
        }
    },
    {
        text: 'Product ID',
        dataField: "product_id",
        sort: true,
        formatter: (cell, row) => {
            return (
                ( row.asset?.deleted_at ? <i className="text-muted">{cell}</i> : <a href={`/products/${cell}`}> {cell} </a> )
                )
        }
    },
    {
        text: 'Description',
        dataField: "comment",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('asset.description'))
        }
    }
]

const getProductLogColumns = () => {
    return columns;
}
export default getProductLogColumns;
