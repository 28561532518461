import { map } from "lodash";
import React from "react";
import translate from '../Translation/translate';


const DispositionsColumns = [
    {
        text: 'ID',
        dataField: "id",
        hidden: true,
    },
    {
        text: 'Category',
        dataField: "category",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('asset.category'))
        }
    },
    {
        text: 'Units',
        dataField: "units",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.units'))
        }
    },
    {
        text: 'Disposition',
        dataField: "disposition",
        sort: true,
    },
    {
        text: 'CO2e',
        dataField: "co2e",
        sort: true,
        formatter: (cell, row) => {
            return cell !== 0 ? <strong className= 'text-success'>{parseInt(cell).toLocaleString()} kg</strong> : '-'
        },
        headerStyle: () => {
            return { minWidth: '70px'};
        }
    }
]

export default DispositionsColumns