import React, { useEffect, useState } from "react";
// Redux and actions
import { useSelector, useDispatch } from "react-redux";
import { getAll, importFile } from "store/model/actions";
// Comoponents
import CreateContainer from 'Containers/CreateContainer'
import TableList from 'Components/Lists/TableList';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Alert, Button } from "reactstrap";
import { DesktopTabs, MobileCollapse } from 'Components/Global';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import AssetImportSection from "./Components/Import/AssetImportSection";
import AssetImportErrors from "./Components/Import/AssetImportErrors";
// Functions
import { translate, getAssetColumns, getAssetStatusColumns, getCustomerColumns, getProductColumns, getResellerColumns } from 'helpers/helpers';
// Packages
import fileDownload from 'js-file-download';
import { RemotePaginationTable } from "Components/Lists";
import { CLEAR_STATE } from "store/model/actionTypes";
import { assets } from "store/api";

const AssetImport = () => {

    const [download, setDownload] = useState(false);
    const [selectedIDs, setSelectedIDs] = useState([]);

    const isFetching = useSelector(state => state.Model.isFetching)
    const error = useSelector(state => state.Model.error)
    const warning = useSelector(state => state.Model.warning)
    const errors = useSelector(state => state.Model.errors)
    const newAssets = useSelector(state => state.Model.imported.created)
    const updatedAssets = useSelector(state => state.Model.imported.exists)
    const invalidAssets = useSelector(state => state.Model.imported.invalid)
    const success = useSelector(state => state.Model.success)

    const me = useSelector(state => state.Global.me)
    const isAdmin = useSelector(state => state.Global.isAdmin)
    const isCustomer = useSelector(state => state.Global.isCustomer)

    const customers = useSelector(state => state.Model.customers)
    const statuses = useSelector(state => state.Model.statuses)
    const products = useSelector(state => state.Model.products)
    const resellers = useSelector(state => state.Model.resellers)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAll('statuses'))
      }, [isCustomer, isAdmin])


    const upload = data => {
        dispatch(importFile(data, 'assets'))
    }

    const onClearErrors = () => dispatch({ type: CLEAR_STATE });

    const downloadCSV = () => {
        setDownload(true);
        assets.post(
            `/export`,
            { assets: selectedIDs } // TODO Get all invaliad assets id
          ).then((res) => {
            setDownload(false);
            fileDownload(res.data, 'assets.csv');
          });
      };


        const Tabs = [
            {
                activeTab: 'new',
                translate: 'asset.new',
                displayBadge: newAssets?.length > 0 ? true : false,
                badge: <span className="badge badge-success badge-pill ml-2">{newAssets?.length}</span>,
                component:
                <TableList
                    id={'new'}
                    data={newAssets ?? []}
                    columns={getAssetColumns(me?.type, 'mixed')}
                    title={translate('asset.new')}
                />
            },
            {
                activeTab: 'updated',
                translate: 'asset.updated',
                displayBadge: updatedAssets?.length > 0 ? true : false,
                badge: <span className="badge badge-primary badge-pill ml-2">{updatedAssets?.length}</span>,
                component:
                <TableList
                    id={'updated'}
                    data={updatedAssets ?? []}
                    columns={getAssetColumns(me?.type, 'mixed')}
                    title={translate('asset.updated')}
                />
            },
            {
                activeTab: 'invalid',
                translate: 'asset.invalid',
                displayBadge: invalidAssets?.assets?.length > 0 ? true : false,
                badge: <span className="badge badge-danger badge-pill ml-2">{invalidAssets?.assets?.length}</span>,
                component:
                <div>
                    {invalidAssets?.messages?.map(message => {
                        return <Alert color="danger">{message}</Alert>
                    }) 
                    }
                    <TableList
                        id={'invalid'}
                        data={invalidAssets?.assets ?? []}
                        columns={getAssetColumns(me?.type, 'on-import-error')}
                        title={translate('asset.invalid')}
                        showCSVButton
                        download={download}
                        downloadCSV={downloadCSV}
                        // handleOnSelect={handleOnSelect}
                        // handleOnSelectAll={handleOnSelectAll}
                        // selectedIDs={selectedIDs}
                    />
                </div>
            }
        ]

        const IdTabs = [
            {
                activeTab: 'product-ids',
                translate: 'product.products',
                displayBadge: products?.total > 0 ? true : false,
                badge: <span className="badge badge-primary badge-pill ml-2">{products?.total}</span>,
                component:
                <RemotePaginationTable
                    model={'products'}
                    pagination={products}
                    columns={getProductColumns('ids')}
                    pageUrl={`${process.env.REACT_APP_API_URL}/products`}
                    can={'Asset'}
                />
            },
            {
                activeTab: 'status-ids',
                translate: 'Statuses',
                displayBadge: statuses?.length > 0 ? true : false,
                badge: <span className="badge badge-primary badge-pill ml-2">{statuses?.length}</span>,
                component:
                <TableList
                    id={'sta'}
                    data={statuses ?? []}
                    columns={getAssetStatusColumns('ids')}
                 />
            }
        ]

        if(!isCustomer) {
            IdTabs.push(
                {
                    activeTab: 'customer-ids',
                    translate: 'customer.customers',
                    displayBadge: customers?.total > 0 ? true : false,
                    badge: <span className="badge badge-primary badge-pill ml-2">{customers?.total}</span>,
                    component:
                    <RemotePaginationTable
                        model={'customers'}
                        pagination={customers}
                        columns={getCustomerColumns(me.type, 'ids')}
                        pageUrl={`${process.env.REACT_APP_API_URL}/customers`}
                        can={'Customer'}
                    />
                }
            )
        }
        if(isAdmin) {
            IdTabs.push(
                {
                    activeTab: 'reseller-ids',
                    translate: 'reseller.resellers',
                    displayBadge: resellers?.total > 0 ? true : false,
                    badge: <span className="badge badge-primary badge-pill ml-2">{resellers?.total}</span>,
                    component:
                    <RemotePaginationTable
                        model={'resellers'}
                        pagination={resellers}
                        columns={getResellerColumns('ids')}
                        pageUrl={`${process.env.REACT_APP_API_URL}/resellers`}
                        can={'Reseller'}
                    />
                }
            )
        }

        const hasErrors = !!errors?.errors;
        const hasImported = !!(newAssets?.length || invalidAssets?.length || updatedAssets?.length) || warning;
        return (
            <CreateContainer
                Model={'Asset'}
                breadcrumbItem={translate('asset.import')}
                breadcrumbTitle={""}
                success={success}
                error={error}
                warning={warning}
                >
                    <Row>
                        <Col xl={12}>
                            {!hasImported && <Card>
                                <CardHeader style={ecosystemCardHeader}>
                                <CardTitle>
                                    {translate('asset.import-title')} <i className="mdi mdi-cloud-upload ml-2 font-size-17"></i>
                                </CardTitle>
                                </CardHeader>
                                {!hasErrors && <AssetImportSection
                                    isFetching={isFetching}
                                    upload={upload}
                                    />}
                                {hasErrors && <AssetImportErrors imports={errors} onClearErrors={onClearErrors} />}
                            </Card>}
                            {hasImported ?
                                <Card>
                                    <CardBody>
                                    <CardTitle>{translate('asset.imported-assets')}</CardTitle>
                                        <DesktopTabs Tabs={Tabs} />
                                        <MobileCollapse Tabs={Tabs}/>
                                        <Button className="btn-success col-lg-12 mt-1" onClick={onClearErrors}>
                                            {translate('clear')}
                                        </Button>
                                    </CardBody>
                                </Card>
                            : null}
                            {!hasImported && <Card>
                                <CardBody>
                                    <CardTitle>IDs</CardTitle>
                                        <DesktopTabs Tabs={IdTabs} />
                                        <MobileCollapse Tabs={IdTabs} />
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>
            </CreateContainer>
        )
    }


export default AssetImport