import translate from 'helpers/Translation/translate';

import {
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_FAILURE,
  GET_BLANCCO_REPORT_REQUEST,
  GET_BLANCCO_REPORT_SUCCESS,
  GET_BLANCCO_REPORT_FAILURE,
  GENERATE_REPORT_WARNING,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  DELETE_LOT_REPORT_REQUEST,
  DELETE_LOT_REPORT_SUCCESS,
  DELETE_LOT_REPORT_FAILURE,
  DOWNLOAD_LOTREPORT_EXCEL_REQUEST,
  DOWNLOAD_LOTREPORT_EXCEL_SUCCESS,
  DOWNLOAD_LOTREPORT_EXCEL_FAILURE,
  NOTIFY_EMAIL_REQUEST,
  NOTIFY_EMAIL_SUCCESS,
  NOTIFY_EMAIL_FAILURE,
  GET_ASSET_WITH_REPORT_REQUEST,
  GET_ASSET_WITH_REPORT_SUCCESS,
  GET_ASSET_WITH_REPORT_FAILURE,
  GET_DISK_WITH_REPORT_REQUEST,
  GET_DISK_WITH_REPORT_SUCCESS,
  GET_DISK_WITH_REPORT_FAILURE,
  NO_REPORT,
  CLEAR_STATE
} from "./actionTypes";

import { reports } from '../api'
import qs from 'qs';


export const clearState = () => { return async dispatch => { dispatch({ type: CLEAR_STATE }) } }


export const generateReport = (lotTransactionReport, hardDriveAuditReport, report) => {

  return async (dispatch) => {
    try {
      dispatch({ type: GENERATE_REPORT_REQUEST });

      let formData = new FormData();
      formData.append("lot_transaction_report", lotTransactionReport);
      formData.append("hard_drive_audit_report", hardDriveAuditReport);
      formData.append("customer_id", report.customer_id);
      formData.append("lot_number", report.lot_number);
      formData.append("finalizing_date", report.finalizing_date);
      formData.append("received_date", report.received_date);


      const success = await reports.post('/import', formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if(success.data.data.hdd_audit_missing.length || success.data.data.disposition_missing.length) {
        return dispatch({ type: GENERATE_REPORT_WARNING, payload: success.data.data, message: translate('message.report-generated-with-errors')});
      } else {
        return dispatch({ type: GENERATE_REPORT_SUCCESS, payload: success.data.data, message: translate('message.report-success')});
      }

    } catch (error) {
      if(error?.response?.data?.error?.message['0.performed_security']) {
        return dispatch ({ type: GET_REPORT_FAILURE, message: translate('message.wrong-hdd-file')});
      }
      if(error?.response?.data?.error?.message['0.asset_id']) {
        return dispatch ({ type: GET_REPORT_FAILURE, message: translate('message.wrong-lot-file')});
      }
      if(error?.response?.status === 422) return dispatch ({ type: GENERATE_REPORT_FAILURE, messages: error.response?.data?.error?.message }, error);
      else return dispatch({ type: GENERATE_REPORT_FAILURE,  message: error.response?.data?.error?.message });
    }
  };
};

export const getReport = params => {

  return async dispatch => {
    try {
        dispatch({ type: GET_REPORT_REQUEST })

        const success = await reports.get('/report', { params : params, paramsSerializer: params => { return qs.stringify(params)}});

        return dispatch ({ type: GET_REPORT_SUCCESS, payload: success.data });
      } catch (error) {
          if(error?.response?.status === 404) {
            return dispatch ({ type: NO_REPORT, message: translate('message.no-lot-report'), notFound: true});
          }
          return dispatch ({ type: GET_REPORT_FAILURE, message: translate('message.annual-report-failure')});
        }
    }
}

export const getExcelReport = params => {
  return async dispatch => {
    try {
      dispatch({ type: DOWNLOAD_LOTREPORT_EXCEL_REQUEST })
      const success = await reports.get('/report', {
        params : params, paramsSerializer: params => { return qs.stringify(params)},
        responseType: 'blob'
      });
      return dispatch ({ type: DOWNLOAD_LOTREPORT_EXCEL_SUCCESS, payload: success.data });
    } catch(error) {
      return dispatch ({ type: DOWNLOAD_LOTREPORT_EXCEL_FAILURE, message: translate('message.excel-lot-failure')});
    }
  }
}


export const getAssetWithReport = serial => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ASSET_WITH_REPORT_REQUEST })
      const success = await reports.get(`/asset/${serial}`);
      return dispatch ({ type: GET_ASSET_WITH_REPORT_SUCCESS, payload: success.data.data[0] });
    } catch(error) {
      return dispatch ({ type: GET_ASSET_WITH_REPORT_FAILURE, message: translate('message.asset-with-report-failure')});
    }
  }
}

export const getDiskWithReport = serial => {
  return async dispatch => {
    try {
      dispatch({ type: GET_DISK_WITH_REPORT_REQUEST })
      const success = await reports.get(`/disk/${serial}`);
      return dispatch ({ type: GET_DISK_WITH_REPORT_SUCCESS, payload: success.data.data });
    } catch(error) {
      return dispatch ({ type: GET_DISK_WITH_REPORT_FAILURE, message: translate('message.disk-with-report-failure')});
    }
  }
}

export const getBlanccoReport = (parameters) => {
  return async dispatch => {
    try {
        dispatch({ type: GET_BLANCCO_REPORT_REQUEST })
          const blanccoPDF = await reports.post('/blancco/search', parameters, {
            responseType: 'blob'
          })
          const file = new Blob([blanccoPDF.data], {type: 'application/pdf'})
          return dispatch ({ type: GET_BLANCCO_REPORT_SUCCESS, payload: file });

      } catch (error) {
            return dispatch ({ type: GET_BLANCCO_REPORT_FAILURE, message: translate('message.blancco-report-failure')});
        }
    }
}

export const deleteLotReport = lot => {

  return async dispatch => {
    try {
      dispatch({ type: DELETE_LOT_REPORT_REQUEST })
      await reports.delete(`${lot}`);
      return dispatch ({ type: DELETE_LOT_REPORT_SUCCESS , success: translate(`message.delete-report-success`)});
    } catch (error) {
      return dispatch ({ type: DELETE_LOT_REPORT_FAILURE, message: translate(`message.delete-report-failure`) });
      }
  }
}

export const notifyLotReport = params => {
  return async dispatch => {
    try {
        dispatch({ type: NOTIFY_EMAIL_REQUEST })
        const success = await reports.post(`/mail`, params);
        return dispatch ({ type: NOTIFY_EMAIL_SUCCESS, message: translate('message.mail-sent') });
      } catch (error) {
          return dispatch ({ type: NOTIFY_EMAIL_FAILURE, message: translate('message.something-wrong')});
        }
    }
}
