import {
	GENERATE_REPORT_REQUEST,
	GENERATE_REPORT_SUCCESS,
	GENERATE_REPORT_FAILURE,
	GENERATE_REPORT_WARNING,
	GET_BLANCCO_REPORT_REQUEST,
	GET_BLANCCO_REPORT_SUCCESS,
	GET_BLANCCO_REPORT_FAILURE,
	CLEAR_STATE,
	GET_REPORT_REQUEST,
	GET_REPORT_SUCCESS,
	GET_REPORT_FAILURE,
	DELETE_LOT_REPORT_REQUEST,
	DELETE_LOT_REPORT_SUCCESS,
	DELETE_LOT_REPORT_FAILURE,
	DOWNLOAD_LOTREPORT_EXCEL_REQUEST,
	DOWNLOAD_LOTREPORT_EXCEL_SUCCESS,
	DOWNLOAD_LOTREPORT_EXCEL_FAILURE,
	DOWNLOAD_ANNUAL_REPORT_EXCEL_REQUEST,
	DOWNLOAD_ANNUAL_REPORT_EXCEL_SUCCESS,
	DOWNLOAD_ANNUAL_REPORT_EXCEL_FAILURE,
	NOTIFY_EMAIL_REQUEST,
	NOTIFY_EMAIL_SUCCESS,
	NOTIFY_EMAIL_FAILURE,
	GET_ASSET_WITH_REPORT_REQUEST,
	GET_ASSET_WITH_REPORT_SUCCESS,
	GET_ASSET_WITH_REPORT_FAILURE,
	GET_DISK_WITH_REPORT_REQUEST,
	GET_DISK_WITH_REPORT_SUCCESS,
	GET_DISK_WITH_REPORT_FAILURE,
	NO_REPORT
  } from "./actionTypes";

const INIT_STATE = {
	isFetching: false,
	isMailing: false,
    success: null,
    error: null,
	warning: null,
	errors: [],
	blanccoReport: null,
	assetWithReport: null,
	reportCreated: null,
	years: [],
	notFound: false
 };

const Report = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CLEAR_STATE:
			return {
				...state,
				isFetching: false,
				success: null,
				warning: null,
				error: null,
				errors: [],
				report: null,
				years: [],
				notFound: false,
				reportCreated: null
			};
		case GENERATE_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
	    case GENERATE_REPORT_WARNING:
			return {
				...state,
				isFetching: false,
                warning: action.message,
				reportCreated: action.payload
			};
    	case GENERATE_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
                success: action.message,
				reportCreated: action.payload
			};
        case GENERATE_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
                error: action.message,
				errors: action.messages
			};
		case GET_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				report: action.payload
			};
		case GET_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message,
				notFound: action.notFound
			};
		case GET_BLANCCO_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_BLANCCO_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				blanccoReport: action.payload
			};
		case GET_BLANCCO_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case GET_ASSET_WITH_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_ASSET_WITH_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				assetWithReport: action.payload
			};
		case GET_ASSET_WITH_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case GET_DISK_WITH_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_DISK_WITH_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				diskWithReport: action.payload
			};
		case GET_DISK_WITH_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case DOWNLOAD_LOTREPORT_EXCEL_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case DOWNLOAD_LOTREPORT_EXCEL_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				excel: action.payload
			};
		case DOWNLOAD_LOTREPORT_EXCEL_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case DOWNLOAD_ANNUAL_REPORT_EXCEL_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case DOWNLOAD_ANNUAL_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
				annualExcel: action.payload
			};
		case DOWNLOAD_ANNUAL_REPORT_EXCEL_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case DELETE_LOT_REPORT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case DELETE_LOT_REPORT_SUCCESS:
			return {
				...state,
				isFetching: false,
				success: action.message,
			};
		case DELETE_LOT_REPORT_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.message
			};
		case NO_REPORT:
			return {
				...state,
				isFetching: false,
				error: action.message,
				notFound: true
			};
		case NOTIFY_EMAIL_REQUEST:
			return {
				...state,
				isMailing: true,
			};
		case NOTIFY_EMAIL_SUCCESS:
			return {
				...state,
				isMailing: false,
				success: action.message,
			};
		case NOTIFY_EMAIL_FAILURE:
			return {
				...state,
				isMailing: false,
				error: action.message,
			};
		default:
			return state;
	}
};

export default Report;
