import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import OptionSelect from 'Components/Form/OptionSelect'
import { getCustomersWithBlanccoReportsDropdown, getLotreportsForThisCustomer, getLotreports} from "store/form/actions";

import { Can} from "helpers/helpers";


const BlanccoFilters = ({ filter, onChange }) => {

    const lots = useSelector(state => state.Form.lots)
    const customers = useSelector(state => state.Form.customersDropdown)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCustomersWithBlanccoReportsDropdown())
        dispatch(getLotreports())

        if(filter.customer_id) {
          dispatch(getLotreportsForThisCustomer(filter.customer_id))
        }
      },[filter])

    return (
        <Row >
            <Col lg={6}>
                <FormGroup>
                <select
                    type="select"
                    value={filter.lot_number}
                    name="lot_number"
                    className="form-control select2"
                    onChange={(e) => onChange(e)}
                    >
                    <OptionSelect message={"form.select-lotnumber"} value={''} />
                    {lots?.map(lot => {
                    return <option key={lot.lot_number} value={lot.lot_number}>{lot.lot_number}</option>
                    })}
                </select>
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <Can I="read" a="Customer">
                    {() => (
                        <select
                        type="select"
                        value={filter.customer_id}
                        name="customer_id"
                        className="form-control select2"
                        onChange={(e) => onChange(e)}
                        >
                        <OptionSelect message={"form.select-customer"} value={''} />
                        {customers?.map(customer => {
                            return <option key={customer.id} value={customer.id}>{customer.name}</option>
                        })}
                        </select>
                    )}
                    </Can>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default BlanccoFilters