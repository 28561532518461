import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const CustomerListColumns = [
    {
        text: 'ID',
        dataField: "id",
        sort: true,
        headerStyle: () => {
            return { minWidth: '50px', width: '30px'};
        },
        formatter: (cell, row) => {
            return(
                <a href={`/customers/${row.id}`}>
                    {cell}
                </a>)
        },
    },
    {
        text: 'Customer',
        dataField: "name",
        sort: true,
        headerFormatter: () => {
            return (translate('customer.customer'))
        }
    },
    {
        text: 'Organization number',
        dataField: "org_no",
        sort: true,
        headerFormatter: () => {
            return (translate('form.org-no'))
        }
    },
    {
        text: 'Reseller',
        dataField: "reseller.name",
        sort: true,
        formatter: (cell, row) => {
            return (<a href={`/resellers/${row.reseller?.id }`}>{cell}</a>)
        },
        headerFormatter: () => {
            return (translate('reseller.reseller'))
        }
    },
    {
        text: 'Created at',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment(cell).format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('created-at'))
        }
    },
    {
        text: 'Updated at',
        dataField: "updated_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD HH:mm'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('updated-at'))
        }
    },
]


const getCustomerColumns = (user, list) => {

    var notAvailableFor = {
      reseller: {},
      admin:{},
      customer: {}
    };


    notAvailableFor['reseller']['list'] = [ 'reseller_id', 'reseller.name']
    notAvailableFor['admin']['list'] = [ '']
    notAvailableFor['customer']['list'] = [ '']

    notAvailableFor['reseller']['on-reseller'] = [ 'reseller_id', 'reseller.name']
    notAvailableFor['admin']['on-reseller'] = [ 'reseller.name']

    notAvailableFor['reseller']['ids'] = [ 'reseller_id', 'org_no']
    notAvailableFor['admin']['ids'] = [ 'reseller_id', 'org_no']
    notAvailableFor['customer']['ids'] = [  'reseller_id', 'org_no']


    var filteredColumns = (user && list) ? CustomerListColumns.filter(d => !notAvailableFor[user][list].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getCustomerColumns