import React, {  Component } from "react";
import { Card, CardBody, CardHeader, UncontrolledCollapse } from "reactstrap";
import { translate} from "helpers/helpers";


const GdprInfo = () => {

    const Steps = [
        {
          id: 'person',
          title: translate('gdpr.person-title'),
          icon: 'mdi mdi-account-lock',
          component:
          <>
              <p>{translate('gdpr.person-1')} </p>
              <p>{translate('gdpr.person-2')} </p>
          </>
        },
        {
          id: 'sensitive',
          title: translate('gdpr.sensitive-title'),
          icon: 'mdi mdi-account-alert',
          component:
          <>
          <CardHeader className="p-4 mb-5">
              {translate('gdpr.sensitive-1')}
          </CardHeader>
          <p>{translate('gdpr.sensitive-2')}.
           <a href="https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/introduktion-till-gdpr/personuppgifter/kansliga-personuppgifter/"> {translate('gdpr.sensitive-link')} <i className="mdi mdi-open-in-new"></i></a> 
           </p>
          </>
        },
        {
          id: 'pul',
          title: translate('gdpr.pul-title'),
          icon: 'mdi mdi-account-tie-outline',
          component:
          <>
          <CardHeader className="p-4 mb-5">
              {translate('gdpr.pul-1')}
          </CardHeader>
          <p>{translate('gdpr.pul-2')}</p>
          <p>{translate('gdpr.pul-3')}</p>
          </>
        },
        {
          id: 'resp',
          title: translate('gdpr.resp-title'),
          icon: 'mdi mdi-account-tie-outline',
          component:
          <>
          <p>{translate('gdpr.resp-1')}</p>
          </>
        },
        {
          id: 'gdpr',
          title: translate('gdpr.li-title'),
          icon: 'mdi mdi-shield-check',
          component:
          <>
          <ul>
              <li>{translate('gdpr.li-1')}</li>
              <li>{translate('gdpr.li-2')}</li>
              <li>{translate('gdpr.li-3')}</li>
              <li>{translate('gdpr.li-4')}</li>
              <li>{translate('gdpr.li-5')}</li>
              <li>{translate('gdpr.li-6')}</li>
              <li>{translate('gdpr.li-7')}</li>
          </ul>
          <p>{translate('gdpr.read-more')} <a href="https://www.imy.se/privatperson/dataskydd/introduktion-till-gdpr/">{translate('gdpr.read-more-link')} <i className="mdi mdi-open-in-new"></i></a></p>
          </>
        },
        {
          id: 'more',
          title: translate('gdpr.more-title'),
          icon: 'mdi mdi-server-security',
          component:
          <>
          <p>{translate('gdpr.more-1')}. <a href="https://www.imy.se/">{translate('gdpr.read-more-link')} <i className="mdi mdi-open-in-new"></i></a></p>
          </>
        },
      ]

      return (
        Steps?.map((step, key) =>  (
            <Card lg={10} key={key}>
             <CardBody>
               <CardHeader id={step.id} className={`m-3`} style={{ backgroundColor: 'white', cursor: 'pointer'}}>
                  <h5 className="text-success" style={{ textDecoration: 'underline'}}>{step.title}</h5>
               </CardHeader>
               <UncontrolledCollapse toggler={`#${step.id}`}>
                  <CardBody>
                    <p className="mb-2">{step.text}</p>
                    {step.component}
                  </CardBody>
               </UncontrolledCollapse>
               </CardBody>
             </Card>
            )
          )
      )
}

export default GdprInfo