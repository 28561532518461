import React from "react"
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const NavItem = ({ layoutType, menu, item, id }) => {

    if(layoutType === 'horizontal') {
        return(
        <UncontrolledDropdown>
            <li className={'nav-item"'} id={id}>
            <DropdownToggle caret color="white">
                <Link to="#" className={"nav-link"}>
                    {item}
                </Link>
            </DropdownToggle>
            <DropdownMenu className="p-2">
                {menu}
            </DropdownMenu>
        </li>
        </UncontrolledDropdown>
        )
        } else {
        return (
            <li id={id}>
                <Link to="#" className={`${layoutType === 'horizontal' ? "nav-link" : "has-arrow waves-effect"}`} >
                    {item}
                </Link>
                {menu}
            </li>

        )
    }
}


export default NavItem