import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const UserListColumns = [
    {
        text: '',
        dataField: "id",
        sort: true,
        formatter: (cell, row) => {
            const full_name = row.name.split(" ")
            return(
                <a href={`/users/${row.id}`}>
                    {row.logo ? <img src={row.logo} alt="profile" className="avatar-sm img-thumbnail rounded-circle" />
                     : <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {full_name.length > 1 ?
                            full_name[0].charAt(0) + full_name[1].charAt(0)
                            : row.name.charAt(0)}
                          </span>
                        </div>}
                </a>)
            },
        headerStyle: () => {
            return { minWidth: '70px'};
          }
    },
    {
        text: 'Name',
        dataField: "name",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`/users/${row.id}`}>
                    {cell}
                </a>)
            },
        headerStyle: () => {
            return { minWidth: '170px'};
        },
        headerFormatter: () => {
            return (translate('user.name'))
        }
    },
    {
        text: 'Account',
        dataField: "type",
        sort: true,
        formatter: (cell, row) => {
            return ( 
                <div className="d-flex">
                    <span className="badge badge-soft-primary font-size-11 m-1">
                    {row ?
                        row.role === 'partner_admin' ? 'Partner Admin'
                    :   row.role === 'enduser_admin' ? 'End User Admin'
                    :   row.role === 'partner' ? 'Partner'
                    :   row.role === 'enduser' ? 'End User'
                    :   row.role === 'admin' ? 'Admin'
                    :   row.role : null}
                    </span>
                    {row.key_account_manager ?
                        <span className="badge badge-soft-success font-size-11 m-1">
                            KAM
                        </span>
                    : null}
                </div>

            )
        },
        headerFormatter: () => {
            return (translate('user.account'))
        }
    },
    {
        text: 'Last login',
        dataField: "last_login",
        sort: true,
        formatter: (cell, row) => {
            return (cell ? moment.utc(cell).local().format('YYYY-MM-DD HH:mm') : '-')
        },
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('user.last-login'))
        }
    },
    {
        text: 'Customer',
        dataField: "customer_id",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`customers/${cell}`}>
                    {row.customer ? row.customer.name : null}
                </a>)
            },
        headerStyle: () => {
            return { minWidth: '120px'};
        },
        headerFormatter: () => {
            return (translate('customer.customer'))
        }
    },
    {
        text: 'Reseller',
        dataField: "reseller_id",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`resellers/${cell}`}>
                    {row.reseller ? row.reseller.name : null}
                </a>)
        },
        headerFormatter: () => {
            return (translate('reseller.reseller'))
        }
    },
    {
        text: 'Phone',
        dataField: "telephone",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`tel:${cell}`}>
                    {cell}
                </a>)
            },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('address.phone'))
        }
    },
    {
        text: 'Email',
        dataField: "email",
        sort: true,
        formatter: (cell, row) => {
            return (<a href={`mailto:${cell}`}>{cell}</a>)
        },
        headerFormatter: () => {
            return (translate('address.email'))
        }
    },
    {
        text: 'Created at',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('created-at'))
        }
    },
    {
        text: 'Updated at',
        dataField: "updated_at",
        sort: true,
        hidden: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('updated-at'))
        }
    },
]


const getUserColumns = (user, list) => {
    var notAvailableFor = {
      reseller: {},
      customer: {},
      admin:{}
    };


    notAvailableFor['reseller']['user-list'] = [ 'reseller_id']
    notAvailableFor['customer']['user-list'] = [ 'customer_id', 'reseller_id']
    notAvailableFor['admin']['user-list'] = [ '']

    notAvailableFor['reseller']['on-customer'] = [ 'customer_id', 'reseller_id']
    notAvailableFor['customer']['on-customer'] = [ 'customer_id', 'reseller_id']
    notAvailableFor['admin']['on-customer'] = [ 'customer_id' , 'reseller_id']
    
    notAvailableFor['reseller']['kam'] = [ 'customer_id', 'reseller_id']
    notAvailableFor['customer']['kam'] = [ 'customer_id', 'reseller_id']
    notAvailableFor['admin']['kam'] = [ 'customer_id', 'reseller_id']


    var filteredColumns = ( user && list ) ? UserListColumns.filter(d => !notAvailableFor[user][list].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getUserColumns