import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { translate } from 'helpers/helpers';

class BlanccoGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data : {
            labels: this.props.locale === 'en' ? ['Erased with Blancco', 'Shredded'] : ['Raderade med Blancco', 'Destruerade'],
            datasets: [{
              data: [this.props.blancco, this.props.destroyed ],
              backgroundColor: [
                '#34c38f',
              ],
              hoverOffset: 4
            }]
          }
        }
    }


    render() {

      const { data } = this.state
      const { hdd_assets } = this.props

        return (
          <div className="mt-2 text-center">
            <h5 className="mt-3">{translate('reports.handled-hdd')} {hdd_assets}</h5>
            <Doughnut height={90} data={data} />
          </div>
        );
    }
}

export default BlanccoGraph