import React from "react";
import translate from "helpers/Translation/translate";

const AssetTitle = ({ asset }) => (
    <div className="d-flex col-lg-12 flex-column">
        <h5 className="mb-4 mt-2">{asset?.product?.title ? asset?.product?.title : translate('no-title')}</h5>
        <p className="text-success" style={{marginTop: '-25px'}}>{asset?.product?.product_category}</p>
    </div>
    )

export default AssetTitle
