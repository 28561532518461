export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const MICROSOFT_LOGIN_REQUEST = 'MICROSOFT_LOGIN_REQUEST';
export const MICROSOFT_LOGIN_SUCCESS = 'MICROSOFT_LOGIN_SUCCESS';
export const MICROSOFT_LOGIN_FAILURE = 'MICROSOFT_LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

export const TWOFA_LOGIN_REQUEST = "TWOFA_LOGIN_REQUEST"
export const TWOFA_LOGIN_SUCCESS = "TWOFA_LOGIN_SUCCESS"
export const TWOFA_LOGIN_FAILURE = "TWOFA_LOGIN_FAILURE"

export const DISPLAY_AUTH_MODAL = "DISPLAY_AUTH_MODAL"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"

export const LOST_DEVICE_REQUEST = "LOST_DEVICE_REQUEST"
export const LOST_DEVICE_SUCCESS = "LOST_DEVICE_SUCCESS"
export const LOST_DEVICE_FAILURE = "LOST_DEVICE_FAILURE"


export const CLEAR_STATE = "CLEAR_STATE"


