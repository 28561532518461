import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const ProductListColumns = [
  {
    dataField: "id",
    text: "ID",
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return <a href={`/products/${cell}`}>{cell}</a>
    },
  },
  {
    dataField: "use_icecat",
    text: "Icecat",
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      if(cell) {
        return <span className="badge badge-pill badge-purple-dark" style={{ color: 'white'}}>Icecat</span>
      }
    },
    headerStyle: () => {
      return { minWidth: '50px', width: '30px'};
    },
    headerFormatter: () => {
      return (translate('asset.type'))
    },
  },
  {
    dataField: "name",
    text: 'Product',
    editable: false,
    sort: true,
    headerFormatter: () => {
      return (translate('product.product'))
    },
    headerStyle: () => {
      return { minWidth: '130px'};
    },
  },
  {
    dataField: "supplier",
    text: 'Supplier',
    editable: false,
    sort: true,
    headerFormatter: () => {
      return (translate('product.supplier'))
    }
  },
  {
    dataField: "supplier_code",
    text: 'Supplier code',
    editable: false,
    sort: true,
    headerStyle: () => {
      return { minWidth: '120px'};
    },
    headerFormatter: () => {
      return (translate('product.supplier-code'))
    }
  },
  {
    dataField: "category.name",
    text: 'Category',
    editable: false,
    sort: true,
    headerFormatter: () => {
      return (translate('asset.category'))
    }
  },
  {
    dataField: "customer_id",
    text: 'Customer',
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return (row.customer?.name ?? null)
    },
    headerFormatter: () => {
      return (translate('customer.customer'))
    }
  },
  {
    dataField: "reseller_id",
    text: 'Reseller',
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return (row.reseller?.name ?? null)
    },
    headerFormatter: () => {
      return (translate('reseller.reseller'))
    }
  },
  {
    dataField: "processor",
    text: 'processor',
    sort: true,
    editable: false,
    formatter: (cell, row) => {
      return (cell ?? null)
    },
    headerFormatter: () => {
      return (translate('product.processor'))
    }
  },
  {
    dataField: "hard_drive",
    text: 'hard drive',
    sort: true,
    editable: false,
    formatter: (cell, row) => {
      return (cell ?? null)
    },
    headerFormatter: () => {
      return (translate('product.hard-drive'))
    },
    headerStyle: () => {
      return { minWidth: '120px'};
    },
  },
  {
    dataField: "memory",
    text: 'memory',
    sort: true,
    editable: false,
    formatter: (cell, row) => {
      return (cell ?? null)
    },
    headerFormatter: () => {
      return (translate('product.memory'))
    },
    headerStyle: () => {
      return { minWidth: '100px'};
    },
  },
  {
    dataField: "storage_media",
    text: 'storage media',
    sort: true,
    editable: false,
    formatter: (cell, row) => {
      return (cell ?? null)
    },
    headerFormatter: () => {
      return (translate('product.storage-media'))
    },
    headerStyle: () => {
      return { minWidth: '130px'};
    },
  },
  {
    dataField: "display_size",
    text: 'display',
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return (cell ?? null)
    },
    headerFormatter: () => {
      return (translate('product.display-size'))
    },
    headerStyle: () => {
      return { minWidth: '120px'};
    },
  },
  {
    dataField: "total_assets",
    text: "Assets",
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return (cell?.toLocaleString())
    },
    headerFormatter: () => {
      return 'Assets'
    }
  },
  {
    dataField: "market_value",
    text: 'EMV',
    sort: true,
    editable: true,
    formatter: (cell, row) => {
      return (cell ? cell?.toLocaleString() + ' kr' : null)
    },
    headerStyle: () => {
      return { minWidth: '100px' };
    },
    headerFormatter: () => {
      return 'EMV'
    }
  },
  {
    dataField: "updated_at",
    text: 'Last update',
    editable: false,
    sort: true,
    formatter: (cell, row) => {
      return (moment.utc(cell).local().format('YYYY-MM-DD - HH:mm'))
    },
    headerStyle: () => {
      return { minWidth: '130px', textAlign: 'center' };
    },
    headerFormatter: () => {
      return (translate('product.last-update'))
    }
  },
]


const getProductColumns = (type) => {

  var notAvailableOn= {
    showall: {},
    noticecat: {},
    ids: {}
  };

    notAvailableOn['showall'] = ['customer_id']
    notAvailableOn['noticecat'] = ['product.extra_fields', 'updated_at', 'market_value' ]
    notAvailableOn['ids'] = ['hard_drive', 'memory', 'display_size', 'storage_media', 'processor', 'image', 'product.extra_fields', 'updated_at', 'market_value', 'assets_count', 'customer_id', 'product_category' ]

    var filteredColumns = type ? ProductListColumns.filter(d => !notAvailableOn[type].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getProductColumns