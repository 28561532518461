import React from "react";
import { translate } from 'helpers/helpers';

const PriceSection = ({ warranty, customer_id }) => {

    const assets_without_price = warranty.total?.without_price
    const assets_with_price = (warranty.expired?.total+warranty.six_months?.total)-(warranty.expired.without_price+warranty.six_months.without_price)

    return (
        <>
        <h2 className="mb-0 text-center mt-5">{warranty?.total?.price?.toLocaleString()} kr</h2>
        <p className="text-center mb-0">{translate('warranty.hardware-replacement-title')}</p>
        <p className="f-10 text-muted text-center mb-0"> {translate('warranty.based-on')} {assets_with_price} {translate('warranty.based-on-warranty')}.</p>
        {assets_without_price ? <p className="f-10 text-muted text-center ">
        {translate('warranty.price-missing')} <a href={`/assets?missing_price=true&product_category=${warranty?.total?.title}&customer_id=${customer_id}`}>
           <span className="badge badge-pill badge-danger f-11">{assets_without_price}</span></a> assets</p> : null}
      </>
    )
}

export default PriceSection