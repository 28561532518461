import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
import {  DesktopTabs, MobileCollapse, Alerts  } from "Components/Global";
import { Container} from "reactstrap";
import PageContainer from 'Containers/PageContainer'
import translate from 'helpers/Translation/translate';
import { getBillingInfo } from 'store/tool/actions';
import EcosystemCustomerToCharge from "./Components/CustomerToCharge";
import { NavLinkBadge } from "Components/Global/index";


const Billing = () => {

  const billingInfo = useSelector(state =>  state.Tool.billingInfo)
  const isFetching = useSelector(state =>  state.Tool.isFetching)
  const error = useSelector(state =>  state.Tool.error)
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillingInfo())
  },[])

  const Tabs = [
          {
            activeTab: 'resellers',
            translate: 'reseller.resellers',
            component: <EcosystemCustomerToCharge billingInfo={billingInfo?.resellers} type="reseller"/>,
            badge: billingInfo?.resellers?.length ? <NavLinkBadge color={'badge-primary'} total={billingInfo?.resellers?.length?.toLocaleString()} /> : null
          },
          {
            activeTab: 'customers',
            translate: 'customer.customers',
            component: <EcosystemCustomerToCharge billingInfo={billingInfo?.customers} type="customer"/>,
            badge: billingInfo?.customers?.length ? <NavLinkBadge color={'badge-primary'} total={billingInfo?.customers?.length?.toLocaleString()} /> : null
          }
      ]

    return (
      <PageContainer
        isFetching={isFetching}
        Model={'Reseller'}
        breadcrumbTitle={translate('billing')}
        breadcrumbItem={translate('billing')}
        listHeaderTitle={translate('billing')}
        alerts={<Alerts error={error}/>}
        >
          <Container>
            <DesktopTabs Tabs={Tabs} displayTab="resellers" />
            <MobileCollapse Tabs={Tabs} />
          </Container>
        </PageContainer>
      );
  }

export default Billing

