import React from "react";
import { Row, Col, Label } from "reactstrap";
import { TextInputField } from 'Components/Form/index'
import DatePicker from "react-datepicker";
import moment from "moment";

const AssetDetails = ({ asset, handleChange }) => {

  return (
        <Row>
            <Col sm={6}>
                <TextInputField
                  name={"cost_center"}
                  label={"asset.cost-center"}
                  value={asset?.cost_center ?? ''}
                  placeholder={"form.enter-cost-center"}
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-cost-center"}
                  type={"text"}
                />
            </Col>
            <Col sm={6}>
                <TextInputField
                  name={"sub_supplier_no"}
                  label={"asset.sub-supplier"}
                  value={asset?.sub_supplier_no ?? ''}
                  placeholder={"form.enter-sub-supplier"}
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-sub-supplier"}
                  type={"text"}
                />
            </Col>
            <Col sm={6}>
                <TextInputField
                  name={"installation_address"}
                  label={"asset.installation-address"}
                  value={asset?.installation_address ?? ''}
                  placeholder={"form.enter-installation-address"}
                  errorMessage={"form.enter-installation-address"}
                  onChange={(e) => handleChange(e)}
                  type={"text"}
              />
            </Col>
            <Col sm={6}>
              <TextInputField
                  name={"description"}
                  label={"asset.description"}
                  value={asset?.description ?? ''}
                  placeholder={"form.enter-description"}
                  errorMessage={"form.enter-description"}
                  onChange={(e) => handleChange(e)}
                  type={"text"}
              />
            </Col>
            <Col sm={6}>
                <TextInputField
                  name={"reference"}
                  label={"asset.reference"}
                  value={asset?.reference ?? ''}
                  placeholder={"form.enter-reference"}
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-reference"}
                  type={"text"}
                />
            </Col>
            <Col sm={6}>
              {asset?.type === 'purchased' ?
                  <TextInputField
                    name={"price"}
                    label={"asset.price"}
                    value={asset?.price ?? ''}
                    placeholder={"form.enter-price"}
                    onChange={(e) => handleChange(e)}
                    errorMessage={"form.enter-price"}
                    type={"number"}
                    validation={{ pattern: { value: "[0-9]" } }}
                  />
                :
                  <TextInputField
                    name={"monthly_cost"}
                    label={"asset.monthly"}
                    value={asset?.monthly_cost ?? ''}
                    placeholder={"form.enter-monthly-cost"}
                    onChange={(e) => handleChange(e)}
                    errorMessage={"form.enter-monthly-cost"}
                    type={"number"}
                    validation={{ pattern: { value: "[0-9]" } }}
                    />
                }
              </Col>
              <Col sm={6}>
                <TextInputField
                  label={'asset.computer-name'}
                  name={"computer_name"}
                  id={"computer_name"}
                  value={asset?.computer_name ?? ''}
                  errorMessage={'form.enter-computer-name'}
                  placeholder={'form.enter-computer-name'}
                  onChange={e => handleChange(e)}
                  type={'text'}
                  />
              </Col>
              <Col sm={6}>
                <TextInputField
                  label={'asset.mac-address'}
                  name={"mac_address"}
                  id={"mac_address"}
                  value={asset?.mac_address ?? ''}
                  errorMessage={'form.enter-mac-address'}
                  placeholder={'form.enter-mac-address'}
                  onChange={e => handleChange(e)}
                  type={'text'}
                  />
              </Col>
              <Col sm={6}>
                <TextInputField
                  label={'asset.imei-number'}
                  name={"imei_number"}
                  id={"imei_number"}
                  value={asset?.imei_number ?? ''}
                  errorMessage={'form.enter-imei-number'}
                  placeholder={'form.enter-imei-number'}
                  onChange={e => handleChange(e)}
                  type={'number'}
                  validation={{ pattern: { value: "[0-9]" } }}
                  />
              </Col>
              <Col sm={6}>
                <Label>End of Life</Label>
                <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={asset?.eol_date && new Date(asset.eol_date)}
                    onChange={d => handleChange({ target: { name: 'eol_date', value: d} })}
                  />
              </Col>
           </Row>
    );
  }

export default AssetDetails;
