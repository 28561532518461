export function getCSVHeaders(text, separator) {

    let inQuotes = false, currHeader = "", headers = [];
    text = text.replace(/\r\n/g, '\n');
    let ix = 0;

    for (; ix < text.length && (inQuotes || text[ix] !== '\n'); ix++) {
        const curr = text[ix];
        if (inQuotes) {
            if (curr === '"') {
                inQuotes = false;
            } else {
                currHeader += curr;
            }
        } else if (curr === '"') {
            inQuotes = true;
        } else if (curr === separator) {
            headers.push(currHeader);
            currHeader = "";
        } else {
            currHeader += curr;
        }
    };

    if (currHeader) {
        headers.push(currHeader);
    }

    if (ix === text.length) {
        throw new Error('No headers in file');
    }

    return headers.filter(h => !!h);
}

export function processCSVHeaders(file) {
    const fR = new FileReader();
    return new Promise((resolve, reject) => {
        function onLoad() {
            const text = fR.result;
            try {
                const headers = getCSVHeaders(text, process.env.REACT_APP_CSV_SEPARATOR);
                removeEventListeners();
                resolve(headers);
            } catch (e) {
                return onError(e);
            }

        }

        function onError(info) {
            console.warn(info);
            removeEventListeners();
            reject(info);
        }

        function removeEventListeners() {
            fR.removeEventListener("load", onLoad);
            fR.removeEventListener("error", onError);
        }

        fR.addEventListener('load', onLoad);
        fR.addEventListener('error', onError);
        fR.readAsText(file);
    });
}