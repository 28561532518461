import React from "react";
import { Button, Col, Card, CardTitle, CardBody } from "reactstrap";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";
import translate from 'helpers/Translation/translate';
import { useDispatch } from "react-redux";
import { generateApiToken, deleteApiToken } from "store/user/actions";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ApiSection = ({ user, DemoMode, apiToken, getThisUser, togglemodal }) => {

  const dispatch = useDispatch()

    const generateToken = () => {
      dispatch(generateApiToken())
      .then(res => {
        if(res.type === "GENERATE_API_TOKEN_SUCCESS") {
          togglemodal('api')
        }
      })
    }

    const revokeToken = () => {
      dispatch(deleteApiToken())
      .then(res => {
        if(res.type === "DELETE_API_TOKEN_SUCCESS") {
          getThisUser('api')
        }
      })
    }

    return (
      <Card>
        <CardBody>
          <CardTitle style={ecosystemCardHeader}>{translate('user.api-key')}</CardTitle>
          <div className="table-responsive mt-3">
            <a href="https://api.ecosystemit.com/docs/index.html" target="_blank" rel="noopener noreferrer">{translate('docs')}
              <i className="mdi mdi-open-in-new align-middle ml-2"></i>
            </a>
            <Col lg={10} className="mt-3">
              {user.api_enabled ?
              <>
                {apiToken}
                <Button
                  disabled={DemoMode ? true : false}
                  className="btn btn-danger  col-lg-12"
                  onClick={revokeToken}
                  >{translate('user.revoke-key')}
                </Button>
              </>
              :
                <Button
                  disabled={DemoMode ? true : false}
                  className="btn btn-success  col-lg-12"
                  onClick={generateToken}
                  >{translate('user.get-key')}
                </Button>
              }
            </Col>
          </div>
        </CardBody>
      </Card>
    )
}

export default ApiSection;
