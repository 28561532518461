import CO2e from 'assets/images/co2.png'
import Blancco from "assets/images/blancco.png";
import Icecat from "assets/images/icecat.png";
import ITGlue from "assets/images/itglue.png";


const Icons = {
    dashboard: 'bx bx-bar-chart-alt',
    asset: "mdi mdi-monitor-cellphone-star",
    contract: "mdi mdi-fountain-pen-tip",
    customer: "mdi mdi-star-circle",
    reseller: "mdi mdi-account-tie",
    user: "mdi mdi-account-circle",
    status: "mdi mdi-folder-star-outline",
    kam: 'mdi mdi-account-supervisor-circle',
    warranty: "bx bx-check-shield",
    money: "bx bx-dollar-circle",
    product: 'bx bxs-devices',
    billing: "mdi mdi-receipt",
    report: "mdi mdi-file-certificate",
    CO2e: CO2e,
    blancco: Blancco,
    icecat: Icecat,
    itglue: ITGlue,
    edit: 'bx bx-pencil',
    delete: 'bx bx-trash',
    check: '',
    question: "bx bx-question-mark",
    shield: "mdi mdi-shield-check",
    shield_alert: "mdi mdi-shield-alert",
    support: "bx bx-support"
}


export default Icons