import React from "react";
import { Link } from "react-router-dom";
import { Icons } from 'assets/styles/index';
import { translate } from "helpers/helpers";
import { useSelector } from "react-redux";
// import Azure from 'assets/images/Microsoft_Azure.png'

const NavTools = ({ toggleMenu, layoutType  }) => {

    const isAdmin = useSelector(state => state.Global.isAdmin)
    // const me = useSelector(state => state.Global.me)

    if(isAdmin) {
        return (
                <>
                <li className="menu-title">{translate('nav.tools')}</li>
                <li onClick={toggleMenu}>
                    <Link to="/billing" className={"waves-effect"} >
                        <i className={Icons.billing}></i>
                        <span className="ml-1">{translate('billing')}</span>
                    </Link>
                </li>

                {/* This link is for dev purposes only, POC for listing devices from Azure*/}
                {/* {me.is_microsoft_user &&
                <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                    <Link to="/azure" className={"waves-effect"} >
                        <img src={Azure} width="20px" height="auto"/>
                        <span className="ml-3">Azure AD</span>
                    </Link>
                </li>
                } */}
                </>
                )
            }
        else return null
    }

export default NavTools