import translate from "helpers/Translation/translate";

import {
    GET_WIDGETS_REQUEST,
    GET_WIDGETS_SUCCESS,
    GET_WIDGETS_FAILURE,
    CLEAR_STATE
} from "./actionTypes";

const INIT_STATE = {
    isFetching: false,
    error: null,
    success: null,
    assetCountDiagramData: null,
    opportunityPotential: [],
    adminData : [],
    userCard : []
};

const Dashboard = (state = INIT_STATE, action) => {
	switch (action.type) {
        case GET_WIDGETS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_WIDGETS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                assetCountDiagramData: action.payload.assetCountDiagramData?.data,
                opportunityPotential: action.payload.opportunityPotential?.data,
                adminData: action.payload,
                userCard: action.payload.users?.data,
                assetCard: action.payload.assets?.data,
                productCard: action.payload.products?.data,
                warrantyOverview: action.payload.warrantyOverview,
                warrantySummary: action.payload.warrantySummary,
                customersPurchasedAssets: action.payload.customers_purchased_assets,
                customersContractsExpiresSoon: action.payload.customers_contracts_expires_soon,
                customersContracts: action.payload.customers_contracts

            };
        case GET_WIDGETS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: translate(`${GET_WIDGETS_FAILURE}`),
            };
        case CLEAR_STATE:
            return {
                ...state,
                error: null,
                success: null
            };
		default:
			return state;
	}
};

export default Dashboard;
