import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alerts } from 'Components/Global';
import { SelectProduct } from "../../Form";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Row } from "reactstrap";
import { createModel } from "store/model/actions";

export default function ProductsBody({ onItemClick, context }) {

    const customer_id = context.customer.id;
    const success = useSelector(state => state.Model.success);
    const error = useSelector(state => state.Model.error);
    const errors = useSelector(state => state.Model.errors);
    const [foundProduct, setFoundProduct] = React.useState({ customer_id });
    const dispatch = useDispatch();

    const handleChange = React.useCallback((evt) => {
        setFoundProduct((p) => ({...p, [evt.target.name]: evt.target.value }));
    }, [setFoundProduct]);

    const handleSubmit = React.useCallback(() => {
        if (foundProduct.type !== 'manual') {
            onItemClick(foundProduct);
        } else {
            dispatch(createModel('product', foundProduct)).then((res) => {
                if (res.type === 'CREATE_product_SUCCESS') {
                    onItemClick(res.payload);
                }
            });
        }
    }, [onItemClick, foundProduct]);

    return (
    <div>
        <Alerts success={success} errors={errors} error={error} />
        <AvForm onValidSubmit={handleSubmit}>
            <SelectProduct getProduct={setFoundProduct} product={foundProduct} customer_id={customer_id} handleChange={handleChange} />
            <Row className="mb-5 justify-content-center">
                {foundProduct.type && foundProduct.type !== 'manual' && <Button className="btn btn-success mt-1">
                    Select Product
                </Button>}
                {foundProduct.type === 'manual' && <Button className="btn btn-success mt-1">
                    Create Product
                </Button>}
            </Row>
        </AvForm>
    </div>);
}
