import {
    UPLOAD_LOGO_SUCCESS,
    UPLOAD_LOGO_REQUEST,
    UPLOAD_LOGO_FAILURE,
    DELETE_LOGO_REQUEST,
    DELETE_LOGO_SUCCESS,
    DELETE_LOGO_FAILURE,
    IMPORT_FILE_REQUEST,
    IMPORT_FILE_SUCCESS,
    IMPORT_FILE_FAILURE,
    GET_WARRANTY_INFO_REQUEST,
    GET_WARRANTY_INFO_SUCCESS,
    GET_WARRANTY_INFO_FAILURE,
    CLEAR_STATE,
} from "./actionTypes";

const INIT_STATE = {
    model: {},
    isFetching: false,
    warning: undefined,
    error: undefined,
    errors: [],
    success: undefined,
    notFound: false,
    imported : [],
    warrantyInfo: undefined,
    productImport: undefined,
    pagination: []
};


const Model = (state = INIT_STATE, action) => {
	switch (action.type) {
        case `GET_${action.variable}_REQUEST`:
            return {
                ...state,
                isFetching: true
            };
         case `GET_${action.variable}_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                [action.variable]: action.payload,
            };
        case `GET_${action.variable}_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                notFound: true,
                warning: action.warning
            };
        case `ALL_${action.variable}_REQUEST`:
            return {
                ...state,
                isFetching: true
            };
        case `ALL_${action.variable}_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                [action.variable]: action.payload,
            };
        case `ALL_${action.variable}_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                notFound: true
            };
        case `UPDATE_${action.variable}_REQUEST`:
            return {
                ...state,
                isFetching: true
            };
        case `UPDATE_${action.variable}_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case `UPDATE_${action.variable}_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case `DELETE_${action.variable}_REQUEST`:
            return {
                ...state,
                isFetching: true
            };
        case `DELETE_${action.variable}_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                success: action.success,
            };
        case `DELETE_${action.variable}_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case `CREATE_${action.variable}_REQUEST`:
            return {
                ...state,
                isFetching: true,
                error: null,
                errors: null,
            };
        case `CREATE_${action.variable}_SUCCESS`:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case `CREATE_${action.variable}_FAILURE`:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages,
            }
        case UPLOAD_LOGO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case UPLOAD_LOGO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case UPLOAD_LOGO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages,
            };
        case DELETE_LOGO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_LOGO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message
            };
        case DELETE_LOGO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages,
            };
        case CLEAR_STATE:
            return {
                ...state,
                model: {},
                isFetching: false,
                error: "",
                errors:[],
                success: '',
                notFound: false,
                imported : [],
                warning: null
            };
        case IMPORT_FILE_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
                errors: null,
            };
        case IMPORT_FILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
                warning: action.warning,
                imported: action.payload,
                productImport: action.payload
            };
        case IMPORT_FILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages,
            };
        case GET_WARRANTY_INFO_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null,
                errors: null,
            };
        case GET_WARRANTY_INFO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                // success: action.message,
                warrantyInfo: action.payload
            };
        case GET_WARRANTY_INFO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages,
            };
		default:
			return state;
	}
};

export default Model;
