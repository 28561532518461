import React from "react";
import {  useSelector } from "react-redux";
// Components
import { Card, CardTitle, CardBody, Col, Row } from "reactstrap";
// Functions
import { translate } from 'helpers/helpers';
// Styles
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import ProgressBar from "./ProgressBar";
import PriceSection from "./PriceSection";
import CO2eSection from "./CO2eSection";
import AllAssetsCoveredSection from "./AllAssetsCoveredSection";
import ecosystemCardHeight100 from "assets/styles/EcosystemCardHeight100";

const WarrantyCardCategory = ({ title, warranty, customer_id }) => {

  const totalAssets = warranty.total?.assets
  const expiredAssets = warranty.expired?.total
  const dueSoonAssets = warranty.six_months?.total
  const unknownAssets = warranty.unknown?.total

  const isCustomer = useSelector(state => state.Global.isCustomer)

    return(
      <Card style={ecosystemCardHeight100}>
        <CardBody>
          <a href={`/assets?product_category=${warranty?.total?.title}&customer_id=${customer_id}&type=purchased`}>
            <CardTitle style={ecosystemCardHeader}>
                <Row>
                    <Col xs={9}>
                      {title ?? '-'}
                    </Col>
                    <Col xs={3}>
                      <span className="badge badge-pill bg-light f-14 mb-3">
                      {translate('total')} {totalAssets}
                      </span>
                    </Col>
                </Row>
            </CardTitle>
          </a>

          {/* Dont show price info for customers */}
          {!isCustomer ? <PriceSection warranty={warranty} customer_id={customer_id} /> :

          warranty?.total?.co2e > 0 ? <CO2eSection warranty={warranty} />

          : warranty?.unknown?.total < 0 ? <AllAssetsCoveredSection />

          : null }
          <div className="mt-4">
            <h5 className="text-center">{translate('warranty')}</h5>
            <ProgressBar
              expired={expiredAssets}
              six_months={dueSoonAssets}
              inside={warranty.inside?.total}
              unknown={warranty.unknown?.total}
              total={totalAssets} />
              <div className="mt-2 text-center">
                {expiredAssets > 0 ?
                <a href={`/assets?warranty=expired&customer_id=${customer_id}&product_category=${warranty?.total?.title}&type=purchased`} className="btn btn-danger btn-sm m-1">
                    {expiredAssets} {translate('warranty.expired')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
                {dueSoonAssets  > 0 ?
                <a href={`/assets?warranty=six_months&customer_id=${customer_id}&product_category=${warranty?.total?.title}&type=purchased`} className="btn btn-warning btn-sm m-1">
                    {dueSoonAssets} {translate('warranty.due-soon')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
                {unknownAssets  > 0 ?
                <a href={`/assets?warranty=not_available&customer_id=${customer_id}&product_category=${warranty?.total?.title}&type=purchased`} className="btn btn-light btn-sm m-1">
                    {unknownAssets} {translate('warranty.unknown')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
              </div>
          </div>
        </CardBody>
      </Card>
    )
}

export default WarrantyCardCategory