import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'


const AssetLogColumns = [
    {
        text: 'Time',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD hh:mm'))
            },
        headerStyle: () => {
            return { width: '150px'};
        },
        headerFormatter: () => {
            return (translate('asset.time'))
        }
    },
    {
        text: 'Asset ID',
        dataField: "asset_id",
        sort: true,
        formatter: (cell, row) => {
            return (
                ( row.asset?.deleted_at ? <i className="text-muted">{cell}</i> : <a href={`/assets/${cell}`}> {cell} </a> )
                )
        }
    },
    {
        text: 'Serial number',
        dataField: "asset.serial_number",
        sort: true,
        headerFormatter: () => {
            return (translate('asset.serial'))
        }
    },
    {
        text: 'Description',
        dataField: "comment",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('asset.description'))
        }
    }
]

const getAssetLogColumns = (page) => {

    var notAvailableFor = {
        asset: {},
        history: {}
    };

    notAvailableFor['asset'] = ['asset.id', 'asset.serial_number']
    notAvailableFor['history'] = ['']
    var filteredColumns = page ? AssetLogColumns.filter(d => !notAvailableFor[page].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getAssetLogColumns
