import React, { useState, useEffect } from 'react';
import {  useSelector, useDispatch } from "react-redux";
import { getCustomerDropdown } from 'store/form/actions'

import { Label } from "reactstrap";
import { AvFeedback, AvField, AvGroup} from "availity-reactstrap-validation";
import { translate, Can } from 'helpers/helpers';
import OptionSelect from 'Components/Form/OptionSelect'

const TypeOfAsset = ({ contracts, statuses, asset, editMode, handleChange }) => {

  const dispatch = useDispatch();
  const customers = useSelector(state =>  state.Form.customersDropdown)
  const [ hideType, setHideType ] = useState(false)

  useEffect(() => {
    dispatch(getCustomerDropdown())
  },[])

  const handleChangeInComponent = e => {
    const { value, name } = e.target

    if(name === "contract_id") {
      if(value.length) setHideType(true)
      else setHideType(false)
    }
    handleChange(e)
  }

  return (
            <>
              {!editMode &&
                <Can I="read" a="Customer">
                  {() => (
                    <>
                      <AvGroup className="col-lg-12">
                        <Label className="control-label">{translate('customer.customer')}  <span className="text-success f-10"> {translate('required')}</span></Label>
                        <AvField
                          type="select"
                          required
                          value={asset?.customer_id ?? ''}
                          name="customer_id"
                          className="form-control select2"
                          onChange={e => handleChangeInComponent(e)}
                          errorMessage={translate("form.select-customer")}
                          >
                          <OptionSelect message={"form.select-customer"} value={null} />
                          {customers?.map(customer => {
                            return <option key={customer.id} value={customer.id}>{customer.name}</option>
                          })}
                        </AvField>
                        <AvFeedback></AvFeedback>
                      </AvGroup>
                      {contracts?.length ?
                      <AvGroup className="col-lg-12">
                        <AvField
                          label={translate('contract.contract')}
                          type="select"
                          name="contract_id"
                          value={asset?.contract_id ?? ''}
                          className="form-control select2"
                          onChange={e => handleChangeInComponent(e)}
                          >
                          <OptionSelect message={"form.select-contract"} value={''} />
                          {contracts?.map(contract => {
                              return <option key={contract.id} value={contract.id}>{contract.agreement_no} : {contract.title}</option> }
                          )}
                        </AvField>
                      </AvGroup> : null}
                    </>
                  )}
                </Can>
                }

              {!hideType ?
              <AvGroup className="col-lg-12">
                <Label className="control-label">{translate('asset.type')} <span className="text-success f-10"> {translate('required')}</span></Label>
                  <AvField
                    type="select"
                    name="type"
                    value={asset?.type ?? ''}
                    className="form-control select2"
                    onChange={e => handleChangeInComponent(e)}
                    errorMessage={translate("form.select-asset-type")}
                    required
                    >
                    <OptionSelect message={"form.select-asset-type"} value={null} />
                    <OptionSelect message={"purchased"} value="purchased" />
                    <OptionSelect message={"rented"} value="rented" />
                  </AvField>
              </AvGroup>
              : null}

              {statuses?.length ?
              <AvGroup className="col-lg-12">
                  <AvField
                    type="select"
                    name="status_id"
                    label={'Status'}
                    value={asset?.status_id ?? ''}
                    className="form-control select2"
                    onChange={e => handleChange(e)}
                    >
                    <OptionSelect message={"form.select-status"} value={null}/>
                      {statuses?.map(status => {
                        return <option key={status.id} value={status.id}>{status.status}</option>
                      })}
                  </AvField>
              </AvGroup> : null}
            </>
    )
}

export default TypeOfAsset
