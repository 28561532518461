/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {  Link } from 'react-router-dom';
// Redux
import { useSelector, useDispatch} from "react-redux";
import { clearState } from 'store/auth/actions';
// Components
import { Row, Col, FormGroup, CardBody } from "reactstrap";
import { AvForm } from 'availity-reactstrap-validation';
import { Alerts, GdprLanguage } from 'Components/Global';
import LoginImage from './Components/LoginImage';
import { useAuth0 } from "@auth0/auth0-react";
// Helpers
import translate from 'helpers/Translation/translate';
// Img
import ecosystem from 'assets/images/ecosystem-green.png'

const DemoMode = process.env.REACT_APP_DEMO_MODE
// const lastRelease = new Date(process.env.REACT_APP_LAST_RELEASE);

const Login = () => {

    const error = useSelector(state =>  state.Login.error)
    const success = useSelector(state => state.Login.success)

	const dispatch = useDispatch();

    useEffect(() => {
        return () => {
          dispatch(clearState())
        }
      }, [])
    
	const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

        return (
                <Row className="ecosystem-login-container">
                    <GdprLanguage login/>
                    <Row className="d-flex justify-content-end">
                        <Col lg={5} className="login-form">
                            <Col lg={8} md={8} sm={12} className="float-right">
                                <img src={ecosystem} className="img-fluid mt-3"/>
                            </Col>
                            <Col lg={8} md={8} sm={12} className="float-right">
                                <CardBody>
                                    <AvForm className="form-horizontal">
                                        <Alerts error={error} success={success} />
                                        <FormGroup>
                                            <button
                                                className="btn btn-success btn-block waves-effect waves-light mt-3"
                                                type="button"
                                                onClick={isAuthenticated ? logout : loginWithRedirect}>
                                                {translate('login.login')}
                                            </button>
                                        </FormGroup>

                                        {!DemoMode &&
                                        <div className="text-center ">
                                            <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> {translate('login.forgot')}</Link>
                                        </div>}
                                    </AvForm>
                                </CardBody>
                            </Col>
                        </Col>
                        <Col lg={6} md={6} className="d-none d-lg-block">
                            <LoginImage />
                        </Col>
                    </Row>
                </Row>
        );
    }


export default Login;

