import {
  GET_HISTORY_FOR_ASSET_REQUEST,
  GET_HISTORY_FOR_ASSET_SUCCESS,
  GET_HISTORY_FOR_ASSET_FAILURE,
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAILURE,
  INVENTORY_ASSET_REQUEST,
  INVENTORY_ASSET_SUCCESS,
  INVENTORY_ASSET_FAILURE,
  INVENTORY_ASSETS_REQUEST,
  INVENTORY_ASSETS_SUCCESS,
  INVENTORY_ASSETS_FAILURE,
  CLEAR_STATE,
  GET_ICECAT_DATA_REQUEST,
  GET_ICECAT_DATA_SUCCESS,
  GET_ICECAT_DATA_FAILURE,
} from "./actionTypes"

import translate from "helpers/Translation/translate"
import { assets } from "../api"
import axios from 'axios'


export const getHistoryForAsset = id => {
  return async dispatch => {
    try {
      dispatch({ type: GET_HISTORY_FOR_ASSET_REQUEST })
      const history = await assets.get(`${id}/log`)
      return dispatch({ type: GET_HISTORY_FOR_ASSET_SUCCESS, payload: history.data })
    } catch (error) {
        return dispatch({ type: GET_HISTORY_FOR_ASSET_FAILURE })
    }
  }
}

export const deleteAssets = ids => {
  return async dispatch => {
    try {
      dispatch({ type: DELETE_ASSETS_REQUEST })
      await assets.post('/delete', {assets: ids})
      return dispatch({ type: DELETE_ASSETS_SUCCESS, assets: ids.length })
    } catch (error) {
      return dispatch({ type: DELETE_ASSETS_FAILURE })
    }
  }
}


export const inventoryAsset = id => {
  return async dispatch => {
    try {
      dispatch({ type: INVENTORY_ASSET_REQUEST })
      await assets.post(`${id}/inventory`)
      return dispatch({ type: INVENTORY_ASSET_SUCCESS })
    } catch (error) {
      return dispatch({ type: INVENTORY_ASSET_FAILURE })
    }
  }
}

export const inventoryAssets = ids => {
  return async dispatch => {
    try {
      dispatch({ type: INVENTORY_ASSETS_REQUEST })
      await assets.post(`/inventory`, { assets: ids })
      return dispatch({ type: INVENTORY_ASSETS_SUCCESS, assets: ids.length })
    } catch (error) {
      return dispatch({ type: INVENTORY_ASSETS_FAILURE })
    }
  }
}

export const getIcecatData = request => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ICECAT_DATA_REQUEST })
      const success = await axios.get(`${process.env.REACT_APP_API_URL}/tools/icecat`, {params: request})
      return dispatch({ type: GET_ICECAT_DATA_SUCCESS, payload: success.data })
    } catch (error) {
      switch(error?.response?.status) {
        case 404: return dispatch({ type: GET_ICECAT_DATA_FAILURE, message: error.response.data.error.message, notFound: true })
        case 422: return dispatch({ type: GET_ICECAT_DATA_FAILURE, messages: error?.response?.data?.error?.message })
        case 500: return dispatch({ type: GET_ICECAT_DATA_FAILURE, message: translate(`${GET_ICECAT_DATA_FAILURE}`)})
        default: return error
      }
    }
  }
}

export const clearState = () => { return async dispatch => {dispatch({ type: CLEAR_STATE})}}
export const clearAssetMessages = () => { return async dispatch => {dispatch({ type: CLEAR_STATE})}}
