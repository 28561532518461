import axios from 'axios';

axios.defaults.headers.post["Content-Type"] = "application/json";

const auth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/auth/`,
});

const contracts = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contracts/`,
});

const contractsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contracts/`,
});

const assets = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/`,
});

const assetsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/`,
});

const customers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/customers/`,
});

const forms = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/forms/`,
});

const quotes = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/quotes/`,
});

const resellers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/resellers/`,
});

const resellersUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/resellers/`,
});

const reports = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/moreco-reports/`,
});


const users = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/users/`,
});

const uc = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/io/`,
});

const icecat = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/icecat/`,
});

const financiers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/financiers`,
});

const tariffs = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tariffs`,
});

const suppliers= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/suppliers`,
});

const products= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/products`,
});

const productsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/products`,
});

const dashboard = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/dashboard`,
});

const agreements = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/support-agreements`,
});

const demo = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tools/demo/reset`,
});

const status = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/statuses`,
});


const statusUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/statuses`,
});

const tools = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tools`,
});

function patchAxiosHeaders(token) {
  [
    axios,
    contracts,
    assets,
    assetsUrl,
    customers,
    resellersUrl,
    contractsUrl,
    statusUrl,
    productsUrl,
    forms,
    quotes,
    resellers,
    users,
    uc,
    icecat,
    financiers,
    tariffs,
    suppliers,
    dashboard,
    auth,
    agreements,
    products,
    reports,
    demo,
    status,
    tools
  ].forEach((api) => (api.defaults.headers.common = { 
      Authorization: `Bearer ${token}`,
      'Content-Type': "application/json"
    }));
}

export {
  contracts,
  assets,
  assetsUrl,
  customers,
  resellersUrl,
  contractsUrl,
  statusUrl,
  productsUrl,
  forms,
  quotes,
  resellers,
  users,
  uc,
  icecat,
  financiers,
  tariffs,
  suppliers,
  dashboard,
  auth,
  agreements,
  products,
  reports,
  demo,
  status,
  tools,
  patchAxiosHeaders
};
