import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
// Redux and actions
import { clearModelState } from "store/model/actions";
import { getCategories } from 'store/form/actions'

// Components
import OptionSelect from 'Components/Form/OptionSelect'
import { FormGroup, Col, Row } from "reactstrap";

// Functions
import { translate } from "helpers/helpers";


const HandleEmvFilters = ({ onChange }) => {

  const categories = useSelector(state =>  state.Form.categories)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories({ products: true }))
    return () => {
      dispatch(clearModelState())
    }
  },[])


    return (
      <Col lg={6} className="mb-3">
        <Row>
          <FormGroup className="col-lg-6 col-sm-12">
            <label>EMV</label>
            <select name="market_value" className="form-control select2" onChange={onChange}>
              <OptionSelect message={"include-all"} value={''}/>
              <OptionSelect value={'with'} message={"product.with-fmv"} />
              <OptionSelect value={'without'} message={"product.no-fmv"} />
            </select>
          </FormGroup>
          <FormGroup className="col-lg-6 col-sm-12">
          <label>{translate('asset.category')}</label>
            <select className="form-control select2" name="product_category" onChange={onChange}>
              <OptionSelect message={"include-all-categories"} value={''}/>
                {categories?.map(category=> {
                  return <option key={category.id} value={category.name}>{category.name}</option>
                }
                )}
            </select>
            </FormGroup>
        </Row>
    </Col>
    )
  }

export default HandleEmvFilters;

