import React from "react";
import { Link } from "react-router-dom";
import { Icons } from 'assets/styles/index';
import { UncontrolledDropdown, DropdownToggle } from 'reactstrap';

const NavDashboard = ({ toggleMenu, layoutType }) => {
    if(layoutType === 'horizontal') {
        return(
        <UncontrolledDropdown>
            <li className={'nav-item"'}>
            <DropdownToggle caret color="white">
                <Link to="/dashboard" className={"nav-link"}>
                    <i className={Icons.dashboard}></i>
                    <span className="ml-1">Dashboard</span>
                </Link>
            </DropdownToggle>
            </li>
        </UncontrolledDropdown>
        )
        } else {
        return (
            <li className={`${layoutType === 'horizontal' && 'nav-item"'}`} onClick={toggleMenu}>
                <Link to="/dashboard" className={`${layoutType === 'horizontal' ? "nav-link" : "waves-effect"}`}>
                    <i className={Icons.dashboard}></i>
                    <span className="ml-1">Dashboard</span>
                </Link>
            </li>
            )
        }
}

export default NavDashboard