import React from "react"
import { Link } from "react-router-dom";
import { Can, translate } from "helpers/helpers";
import Icons from 'assets/styles/Icons';
import NavItem from './NavItem'

const NavReports= ({ toggleMenu, layoutType }) => (

        <Can I="read" a="Morecoreport">
            {() => (
            <NavItem
                id={'reports'}
                layoutType={layoutType}
                item={
                <>
                    <i className={Icons.report}></i>
                    <span className="ml-1">{translate('reports')}</span>
                    <span className={`badge badge-primary font-size-10 badge-pill ml-2`}>
                        <strong>
                            Moreco
                        </strong>
                    </span>
                </>
                }
                menu={
                <ul className="sub-menu ecosystem-list pl-2">
                    <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                        <Link  to="/moreco-reports/lots">
                        {translate("list")} {translate("reports.lot")}
                        </Link>
                    </li>
                    <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                        <Link  to="/moreco-reports/blancco">
                            {translate("blancco.list")}
                        </Link>
                    </li>
                    <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu} >
                        <Link to="/moreco-reports/search">
                            {translate("reports.search")}
                        </Link>
                    </li>
                     <Can I="create" a="Morecoreport">
                        {() => (
                             <li className={`${layoutType === 'horizontal' && "p-2"}`}  onClick={toggleMenu}>
                                <Link to="/moreco-reports/generate" >{translate("reports.create")}</Link>
                            </li>
                        )}
                    </Can>
                </ul>
                }
                />
                )}
            </Can>
    )


export default NavReports