import React, {useEffect, useState} from "react";
import { Label, Col } from "reactstrap";
import { FormGroup } from "reactstrap";

import translate from 'helpers/Translation/translate';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import Cookies from "universal-cookie";

registerLocale('sv', sv)
const cookies = new Cookies();
var locale = cookies.get("locale");

const DeliveryDate = ({ dateChange, asset }) => {

  const [ deliveryDate, setDeliveryDate ] = useState(null)
  const [ warrantyDate, setWarrantyDate ] = useState(null)

  useEffect(() => {
    if(asset.delivery_date) {
      setDeliveryDate(new Date(asset.delivery_date))
    }
    if(asset.warranty_end) {
      setWarrantyDate(new Date(asset.warranty_end))
    }
  },[asset.delivery_date, asset.warranty_end])

  const handleChange = (type, date) => {
    if(type === "delivery_date") {
      setDeliveryDate(date)
    } else {
      setWarrantyDate(date)
    }
    dateChange(type, date)
  }

  return (

  <>
    <Col lg={12}>
      <FormGroup>
        <Label htmlFor="delivery_date" style={{ marginBottom: '0px'}}>
          {translate("asset.delivery-date")}
        </Label>
        <div className="mb-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            locale={locale}
            className="form-control mt-2"
            selected={deliveryDate}
            onChange={(e) => handleChange("delivery_date", e)}
          />
        </div>
      </FormGroup>
    </Col>
    {asset?.warranty_lookup === "unavailable" ?
    <Col lg={12}>
      <FormGroup>
        <Label htmlFor="warranty_end">
          {translate("asset.warranty-end")}
        </Label>
        <div className="mb-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            locale={locale}
            className="form-control mt-2"
            selected={warrantyDate}
            onChange={(e) => handleChange("warranty_end", e)}
          />
        </div>
      </FormGroup>
    </Col>
    : null}
  </>
  )
}

export default DeliveryDate
