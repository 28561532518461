import React from "react";
import translate from '../Translation/translate';

const LotReportListColumns = [
    {
        text: 'LOT #',
        dataField: "lot_number",
        sort: true,
        formatter: (cell, row) => {
            return( 
            <a href={`/moreco-reports/lots/${cell}`}>{cell}</a>
            )
        },
        headerStyle: () => {
            return { minWidth: '70px'};
        }
    },
    {
        text: 'Customer',
        dataField: "customer.name",
        sort: true,
        headerFormatter: () => {
            return (translate('customer.customer'))
          }
    },
    {
        text: 'received date',
        dataField: "received",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.received'))
          },
          headerStyle: () => {
            return { minWidth: '150px'};
        }
    },
    {
        text: 'Finalizing date',
        dataField: "finalized",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.finalized'))
          },
          headerStyle: () => {
            return { minWidth: '150px'};
        }
    } 
]

const getLotReportListColumns = (role, page) => {
    var notAvailableOn= {
        admin: {},
        customer: {},
        reseller: {}
    };
  
      notAvailableOn['customer']['all'] = ['customer.name',]
      notAvailableOn['reseller']['all'] = [ ]
      notAvailableOn['admin']['all'] = [ ]

      notAvailableOn['customer']['card'] = ['customer.name',]
      notAvailableOn['reseller']['card'] = ['customer.name', ]
      notAvailableOn['admin']['card'] = ['customer.name', ]
  
      var filteredColumns = (role && page) ? LotReportListColumns.filter(d => !notAvailableOn[role][page].includes(d.dataField)) :['lot_number'];
  
      return filteredColumns;
    }


  export default getLotReportListColumns