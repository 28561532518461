import React from "react";
import { Row, Col } from 'reactstrap'
import GraphReuseRecyling from "./GraphReuseRecyling";
import GraphBlancco from "./GraphBlancco";
import AnnualReportSummaryTable from "./AnnualReportSummaryTable";
import { translate  } from "helpers/helpers";
import SectionContainer from "./SectionContainer";
import { useSelector } from "react-redux";


const SectionSummary = ({ id, report, downloadReport, exportPdf }) => {

  const locale = useSelector(state => state.Global.locale)
  const showEnvironmentData = report.total_co2e > 0
  const homes = report.homes

return (
  <SectionContainer
    report={report}
    downloadReport={downloadReport}
    exportPdf={exportPdf}
    title={translate('Summary') }
    filename={'summary'}
    id={id}
    subTitle={showEnvironmentData ?
      <strong><span className="text-success">{translate('environmental-savings')} {parseInt(report.total_co2e).toLocaleString()} kg C0<sub>2</sub>e</span></strong> : null
    }
    optional={
      <>
      {showEnvironmentData ?
        <h5 className="mt-3 text-success text-center"> {translate('reports.this-is-equal')} <strong>{homes.toFixed().toLocaleString()}</strong> {translate('reports.homes')}! </h5>
      : null}
      <Row className="mt-3">
        {report?.total_assets > 0 ?
        <Col lg={report?.hard_drives?.length > 0 ? 6 : 8} className="mx-auto">
          <GraphReuseRecyling
            resale={report.resale}
            recycled={report.recycled}
            report={report}
            locale={locale}/>
        </Col> : null}
        {report?.hard_drives?.length > 0 ?
        <Col lg={report?.total_assets > 0 ? 6 : 8} className="mx-auto">
          <GraphBlancco
            locale={locale}
            destroyed={(report.shredded?.length ?? 0)}
            blancco={report.blancco_erased_hard_drives?.length ?? 0}
            hdd_assets={report.hard_drives?.length ?? 0}/>
        </Col>
        : null}
      </Row>
      </>
    }
    body={

          <AnnualReportSummaryTable report={report}/>
   }
  />
)
}

export default SectionSummary


