import React, { useEffect, useState } from "react";
import {  useSelector, useDispatch } from "react-redux";
// Redux
import { getCategories, clearState } from 'store/form/actions'
import { updateCategory } from 'store/product/actions'
import {Alerts } from 'Components/Global/index';

// Components
import { FormGroup, Row, Col } from "reactstrap";
import PageContainer from 'Containers/PageContainer'

// Table
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// Functions
import translate from 'helpers/Translation/translate';
import CategoryListFilters from "./Components/CategoryListFilters";

const { SearchBar } = Search;

const IcecatCategoriesList = () => {

  const dispatch = useDispatch();

  const [filter, setFilter ] = useState({ eol_months: null, products: true })
  const [categories, setCategories] = useState([])
  const error = useSelector(state =>  state.Products.error)
  const success = useSelector(state =>  state.Products.success)
  const isFetching = useSelector(state =>   state.Form.isFetching)
  const locale = useSelector(state =>  state.Global.locale)

  const getTheCategories = (filter) => {
    dispatch(getCategories(filter)).then(res => {
      if(res.payload) setCategories(res.payload) })
  }

  const onChange = (event) => {
    const currentFilter = filter;
    currentFilter[event.target.name] = event.target.value;
    setFilter(currentFilter)
    getTheCategories(currentFilter)
  }

  const setActive = active => {
    filter.products = active
    setFilter(filter)
    getTheCategories(filter)
  }

  useEffect(() => {
    getTheCategories(filter)
    return () => {
      dispatch(clearState())
    }
  },[])

  const indication = () => { return translate('asset.no-rows')}

    return (
      <PageContainer
        isFetching={isFetching}
        Model={"Reseller"} // Only admin kan read a Reseller, this page is only for admins. 
        breadcrumbTitle={translate("product.categories")}
        breadcrumbItem={translate("product.categories")}
        listHeaderTitle={translate('product.handle-eol')}
        data={categories ?? []}
        alerts={ <Alerts success={success} error={error} /> }
      >
        <ToolkitProvider
          keyField='id'
          data={categories}
          columns={[
            {
              dataField: "name",
              text: locale === 'sv' ? 'Kategori' : 'Category',
              sort: true,
              editable: false
            },
            {
              dataField: "eol_months",
              text: 'Edit EOL',
              sort: true,
              headerStyle: () => {
                return { width: '200px'};
              }
            }
          ]}
            search >
              {props => (
                <>
                  <Row>
                    <CategoryListFilters onChange={onChange} filter={filter} setActive={setActive} />
                  </Row>
                  <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <FormGroup>
                      <SearchBar {...props.searchProps} className="custom-search-field" />
                    </FormGroup>
                  </Col>
                  </Row>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="id"
                          striped
                          bordered={false}
                          noDataIndication={ indication }
                          cellEdit={
                            cellEditFactory({ mode: "click",
                              afterSaveCell: (oldValue, newValue, row, column) => {
                                dispatch(clearState())
                                dispatch(updateCategory(row, newValue)).then(() => {getTheCategories(filter)})
                              }
                            })}
                          autoSelectText={true}
                          loading={ true }
                          defaultSorted={ [{
                            dataField: 'name',
                            order: 'desc'
                          }]}
                          wrapperClasses="table-responsive"
                          pagination={paginationFactory()}
                      {...props.baseProps}
                      />
                  </div>
                </>
              )}
        </ToolkitProvider>
    </PageContainer>
    );
  }

export default IcecatCategoriesList

