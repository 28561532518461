import {
    GET_CONTRACT_LOG_SUCCESS,
    GET_CONTRACT_LOG_REQUEST,
    GET_CONTRACT_LOG_FAILURE,
    ARCHIVE_CONTRACT_SUCCESS,
    ARCHIVE_CONTRACT_REQUEST,
    ARCHIVE_CONTRACT_FAILURE,
    EXTEND_CONTRACT_SUCCESS,
    EXTEND_CONTRACT_REQUEST,
    EXTEND_CONTRACT_FAILURE,
    REMOVE_ASSETS_SUCCESS,
    REMOVE_ASSETS_REQUEST,
    REMOVE_ASSETS_FAILURE,
    CONNECT_ASSETS_SUCCESS,
    CONNECT_ASSETS_REQUEST,
    CONNECT_ASSETS_FAILURE,
    IMPORT_ASSETS_TO_CONTRACT_REQUEST,
    IMPORT_ASSETS_TO_CONTRACT_SUCCESS,
    IMPORT_ASSETS_TO_CONTRACT_FAILURE,
    CLEAR_STATE
} from "./actionTypes";

import { contracts } from '../api'
import translate from 'helpers/Translation/translate'

export const getContractLog = id => {
    return async dispatch => {
    try {
        dispatch({ type: GET_CONTRACT_LOG_REQUEST })
        const log = await contracts.get(`${id}/log`);
        return dispatch ({ type: GET_CONTRACT_LOG_SUCCESS, payload: log.data});
      } catch (error) {
          dispatch ({ type: GET_CONTRACT_LOG_FAILURE, message: translate('message.contract-log-failure') });
            return error; }
    }
}


export const archiveContract = id => {
    return async dispatch => {
    try {
        dispatch({ type: ARCHIVE_CONTRACT_REQUEST })

        await contracts.get(`${id}/archive`);
        return dispatch ({ type: ARCHIVE_CONTRACT_SUCCESS, message: translate('message.contract-archive-success') });

      } catch (error) {
          dispatch ({ type: ARCHIVE_CONTRACT_FAILURE, message: translate('message.contract-archive-failure') });
            return error; }
    }

}

export const extendContract = (id, date ) => {
  return async dispatch => {

  try {
      dispatch({ type: EXTEND_CONTRACT_REQUEST })

      await contracts.post(`${id}/extensions`, {"end_date" : date });
      return dispatch ({ type: EXTEND_CONTRACT_SUCCESS, message: translate('message.contract-extend-success') });

    } catch (error) {
      if(error?.response?.data?.error?.status === 422) dispatch ({ type: EXTEND_CONTRACT_FAILURE, messages: error.response.data.error.message })
      else  dispatch ({ type: EXTEND_CONTRACT_FAILURE, message: translate('message.contract-extend-failure') });
          return error; }
  }
}

export const removeAssetsFromContract = (id, assets) => {

  return async dispatch => {
  try {
      dispatch({ type: REMOVE_ASSETS_REQUEST })
      const success = await contracts.post(`${id}/assets/remove`, {assets: assets});
      return dispatch ({ type: REMOVE_ASSETS_SUCCESS, payload: success.data, message: success.data.message });
    } catch (error) {
        dispatch ({ type: REMOVE_ASSETS_FAILURE, message: translate('message.assets-removed-failure') });
          return error; }
  }
}

export const connectAssetsToContract = (id, assets) => {
  return async dispatch => {

  try {
      dispatch({ type: CONNECT_ASSETS_REQUEST })

      const success = await contracts.post(`${id}/assets/add`, {assets: assets});
      if (success?.data?.status === 404) {
        dispatch ({ type: CONNECT_ASSETS_FAILURE, message: success.data?.message })
      }
      else dispatch ({ type: CONNECT_ASSETS_SUCCESS, payload: success.data, message: translate('message.assets-connected-success') });

    } catch (error) {
        if(error.response.data.status === 404 ) dispatch ({ type: CONNECT_ASSETS_FAILURE, message: error.response.data.message })
        else dispatch ({ type: CONNECT_ASSETS_FAILURE, message: translate('message.assets-connected-failure') })
          return error; }
  }
}

export const importAssetsToContract = (id, file) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IMPORT_ASSETS_TO_CONTRACT_REQUEST });

      let formData = new FormData();
      formData.append("file", file);

      const result = await contracts.post(`${id}/assets/import`, formData,
        { headers: {
          "Content-Type": "multipart/form-data"
        },
      });

      return dispatch({ type: IMPORT_ASSETS_TO_CONTRACT_SUCCESS, payload: result.data.data, message: translate("message.asset-import-to-contract", { assets: result.data.data.count}) });
    } catch (error) {
      if(error?.response?.data?.error?.status === 422) dispatch ({ type: EXTEND_CONTRACT_FAILURE, messages: error.response.data.error.message })
      else dispatch({ type: IMPORT_ASSETS_TO_CONTRACT_FAILURE,  message: translate("message.asset-import-fail") });
      return error;
    }
  };
};

export const clearState = () => {
    return async dispatch => { dispatch({ type: CLEAR_STATE}) }
  }