import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Redux
import { getById, uploadImage, clearModelState } from "store/model/actions";
// Components
import { Row, Col } from "reactstrap";
import { NavLinkBadge, AssetCount } from "Components/Global/index";
import TableList from "Components/Lists/TableList";
import { UploadFile } from "Components/Form";
import CardContainer from 'Containers/CardContainer'
import ResellerInfo from "Pages/Reseller/Components/ResellerInfo";

// Functions
import { translate, getContractColumns, getUserColumns, getCustomerColumns } from "helpers/helpers";



const ResellerCard = ({ history }) => {

  const me = useSelector(state => state.Global.me)
  const notFound = useSelector(state => state.Model.notFound)
  const success = useSelector(state => state.Model.success)
  const error = useSelector(state => state.Model.error)
  const errors = useSelector(state => state.Model.errors)

  const [ data, setData ] = useState({})
  const [ counted_assets, setCountedAssets ] = useState(undefined)
  const [ periods, setPeriods ] = useState(undefined)

  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
      dispatch(getById(id, 'reseller')).then((res) => {
        const periods = [];
        const counted_assets = [];
        let data = {}
        if (res.payload) {
          data = res.payload;
          data?.asset_count?.map((count) => {
            periods.push(count.period)
            counted_assets.push(count.count)
            return count
          })
          setData(data)
          setPeriods(periods)
          setCountedAssets(counted_assets)
        }
      });
  }, [id, dispatch, location])


  const uploadLogo = (files) => {
    dispatch(clearModelState())
    if(files.length) dispatch(uploadImage(id, files[0], 'reseller'))
  }


    const Tabs = [
        {
          activeTab: 'first',
          translate: 'graph',
          component: (periods && counted_assets) ? <AssetCount series={[{ name: 'Assets', data: counted_assets }]} periods={periods} /> : null},
        {
          activeTab: 'users',
          translate: 'user.users',
          badge: data?.users?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.users.length} /> : null,
          component:
          <TableList
            id={'reseller-users'}
            data={data?.users ?? []}
            title={translate("user.users")}
            columns={getUserColumns(me.type, 'on-customer')}
            dataField={'name'}
            />
        },
        {
          activeTab: 'contracts',
          translate: 'contract.contracts',
          badge: data?.contracts?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.contracts.length} /> : null,
          component:
          <TableList
            id={'reseller-contracts'}
            data={data?.contracts ?? []}
            columns={getContractColumns(me.type, 'on-reseller')}
            dataField={'end_date'}
            />
        },
        {
          activeTab: 'customers',
          translate: 'customer.customers',
          badge: data?.customers?.length  ? <NavLinkBadge color={'badge-primary'} total={data?.customers.length} /> : null,
          component: data?.customers?.length  ?
          <TableList
            id={'customers'}
            data={data?.customers ?? []}
            title={translate("customer.customers")}
            columns={getCustomerColumns(me.type, 'on-reseller')}
            dataField={'name'}
            />
            : null
        }
      ]


    return (
          <CardContainer
            model={'reseller'}
            can={'Reseller'}
            breadcrumbTitle={translate(`reseller.resellers`)}
            breadcrumbItem={translate(`reseller.card`)}
            tabs={Tabs}
            periods={periods}
            data={data}
            image={data.logo ? <img alt="product" src={data.logo} height="auto" width="300px"/> : null}
            history={history}
            notFound={notFound}
            error={error} errors={errors} success={success} location={location}
          >
            <Row>
              <Col lg={data.logo ? 12 : 8}>
                <ResellerInfo reseller={data}/>
              </Col>
              {!data.logo &&
              <Col lg={4}>
                <UploadFile getFiles={uploadLogo} title={translate('upload-logo')} />
              </Col>}
            </Row>
          </CardContainer>
    )
  }


export default ResellerCard;