import React from "react";
import translate from 'helpers/Translation/translate';
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Required } from "Components/Global";

const AssetImportSpecification = () => (
       <Table  className="table mt-5">
            <Tbody>
                <Tr>
                    <Th style={{ width: '40%'}}>{translate('asset.serialnumber')} <Required /></Th>
                    <Td>{translate('info.serialnumber')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('product.product')} ID</Th>
                    <Td>{translate('info.product-id')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('product.supplier-code')} <Required /><span className="text-success">*</span></Th>
                    <Td>{translate('info.supplier-code')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('product.supplier')} <Required /><span className="text-success">*</span></Th>
                    <Td>{translate('info.supplier')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('customer.customer')} ID <Required /></Th>
                    <Td>{translate('info.customer-id')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.type')}<span className="text-success">*</span></Th>
                    <Td>{translate('info.type')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.delivery-date')}</Th>
                    <Td>{translate('info.delivery')} <br />{translate('info.format-date')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('contract.contract')} ID</Th>
                    <Td>{translate('info.contract-id')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.in-use')}</Th>
                    <Td>{translate('info.in-use')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.user')}</Th>
                    <Td>{translate('info.user')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.cost-center')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.computer-name')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.mac-address')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.installation-address')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.invoice-date')}</Th>
                    <Td>{translate('info.format-date')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.invoice-no')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.price')}</Th>
                    <Td>{translate('info.price')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.monthly-cost')}</Th>
                    <Td>{translate('info.monthly-cost')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.reference')}</Th>
                    <Td>{translate('info.reference')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.description')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.additional-info')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.order')}</Th>
                    <Td>{translate('info.order')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.sub-supplier')}</Th>
                    <Td></Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.warranty-end')}</Th>
                    <Td>{translate('info.warranty-end')} <br />{translate('info.format-date')}</Td>
                </Tr>
                <Tr>
                    <Th>{translate('asset.inventoried-at')}</Th>
                    <Td>{translate('info.inventoried-at')} <br /> {translate('info.format-date')}</Td>
                </Tr>
            </Tbody>
        </Table>
  )

export default AssetImportSpecification;
