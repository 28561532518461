import React from "react";

// Redux
import {  useSelector } from "react-redux";

// Component
import { CardBody, FormGroup, Col, Label, Row } from "reactstrap";
import TextInputField from 'Components/Form/TextInputField'
import OptionSelect from 'Components/Form/OptionSelect'
import Select from "react-select";
import { AvField } from "availity-reactstrap-validation";

// Function
import { Can, translate  } from 'helpers/helpers'

const roles = [
  { label: "End User", value: "enduser" },
  { label: "End User Admin", value: "enduser_admin" },
  { label: "Partner", value: "partner" },
  { label: "Partner Admin", value: "partner_admin" },
  { label: "Admin", value: "admin" },
];

const roles_customer = [
  { label: "Basic", value: "enduser" },
  { label: "Admin", value: "enduser_admin" }
]

const roles_reseller = [
  { label: "End User", value: "enduser" },
  { label: "End User Admin", value: "enduser_admin" },
  { label: "Partner", value: "partner" },
  { label: "Partner Admin", value: "partner_admin" },
]

const UserInput = ( { user, customers, resellers, editMode, removeProfilePicture, handleChange, selectChange }) => {

  const isReseller = useSelector(state => state.Global.isReseller)
  const isAdmin = useSelector(state => state.Global.isAdmin)

  return (
    <CardBody>
      <Row>
        {user?.logo &&
        <div className="d-flex flex-column mb-5">
        <div className="avatar-md profile-user-wid mt-5">
            <img
              src={user?.logo}
              alt=""
              className="img-thumbnail rounded-circle"
            />
        </div>
        <button onClick={removeProfilePicture} className="btn-sm btn btn-danger mt-3">{translate('remove-image')}</button>
        </div>}
        <Col lg={12}>
          <FormGroup row>
            <Label htmlFor="name" className="col-form-label col-lg-3" >
              {translate("form.name")}
            </Label>
            <Col lg={9}>
              <TextInputField
                name="name"
                value={user?.name ?? ''}
                placeholder={"form.enter-name"}
                onChange={(e) => handleChange(e)}
                helpMessage={"form.enter-name"}
                errorMessage={"form.enter-name"}
                type={"text"}
                required
                />
            </Col>
          </FormGroup>
        </Col>
      </Row>

    {!editMode &&
    <FormGroup row>
      <label htmlFor="role" className="col-form-label col-lg-3">
        {translate("user.role")}
      </label>
      <Col lg={9}>
        <AvField
          name="role"
          type="select"
          helpMessage={translate("form.select-role")}
          errorMessage={translate("form.select-role")}
          style={{ zIndex: '999'}}
          value={user?.role ?? ''}
          required
          onChange={(e) => selectChange(e, "role")}
          className="form-control select2"
          >
        <OptionSelect message={"form.select-role"} />
          {isAdmin ?
            roles.map((role, i) => {
              return <option key={i} value={role.value}>{role.label}</option>
            })
            : isReseller ?
            roles_reseller.map((role, i) => {
              return <option key={i} value={role.value}>{role.label}</option>
            })
            : roles_customer.map((role, i) => {
              return <option key={i} value={role.value}>{role.label}</option>
            })}
        </AvField>
      </Col>
    </FormGroup>
    }

    {((user?.role === "enduser") || (user?.role === "enduser_admin")) ?

      <Can I="read" a="Customer">
        {() => (
        <FormGroup row>
          <label htmlFor="customer_id" className="col-form-label col-lg-3">
            {translate("customer.customer")}
          </label>
          <Col lg={9}>
            <AvField
              type="select"
              required
              className="form-control select2"
              name="customer_id"
              helpMessage={translate("form.select-customer")}
              errorMessage={translate("form.select-customer")}
              value={user?.customer_id ?? ''}
              onChange={e => handleChange(e, 'customer_id')} >
              <OptionSelect message={"form.select-customer"} />
              {customers?.map(customer => {
                return <option key={customer.id} value={customer.id}>{customer.name}</option>
              })}
            </AvField>
          </Col>
        </FormGroup>
        )}
      </Can>

      : ((user?.role=== "partner") || (user?.role === "partner_admin")) &&
      <Can I="create" a="Reseller">
        {() => (
          <FormGroup row>
            <label htmlFor="reseller_id" className="col-form-label col-lg-3">
              {translate("reseller.resellers")}
            </label>
            <Col lg={9}>
              <AvField
                type="select"
                className="form-control select2"
                name="reseller_id"
                required
                errorMessage={translate("form.select-reseller")}
                helpMessage={translate("form.select-reseller")}
                value={user?.reseller_id ?? ''}
                onChange={e => handleChange(e, 'reseller_id')}>
                <OptionSelect message={"form.select-reseller"} />
                {resellers?.map(reseller => {
                  return <option key={reseller.id} value={reseller.id}>{reseller.name}</option>
                })}
              </AvField>
            </Col>
          </FormGroup>
        )}
      </Can>
    }
 </CardBody>
  )
}

export default UserInput;