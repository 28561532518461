import React from "react";
import { Container, Card, CardBody, CardHeader, } from "reactstrap";
import { Breadcrumbs, LoaderOnPage, Alerts } from 'Components/Global';
import Page403 from '../Pages/Page403';
import { Can } from "helpers/helpers";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";

const CreateContainer = ({ isFetching, Model, breadcrumbItem, breadcrumbTitle, children, editMode, error, errors, success, warning }) => (

        <div className="page-content">
          <Container fluid>
            {isFetching ? <LoaderOnPage/> :
            <>
            <Can I={editMode ? "update" : "create" } a={Model}>
              {() => (
              <>
                <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbItem} />
                <Alerts error={error} success={success} errors={errors} warning={warning}/>
                <Card>
                  <CardHeader style={ecosystemCardHeader}>
                    <h4>
                      {breadcrumbItem}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Container>
                      {children}
                    </Container>
                  </CardBody>
                </Card>
              </>
              )}
            </Can>
            <Can not I={editMode ? "update" : "create" }a={Model}>
              {() => ( <Page403 /> )}
            </Can>
            </>}
          </Container>
        </div>
    )

export default CreateContainer;
