import React from "react";
import { TextInputField } from 'Components/Form/index'
import { AvGroup} from "availity-reactstrap-validation";

const AssetUser = ({ asset, handleChange }) => (
        <AvGroup className="col-lg-12">
          <TextInputField
            label={"asset.user"}
            name={"user"}
            value={asset?.user}
            placeholder={"form.enter-user"}
            onChange={(e) => handleChange(e)}
            error={"form.enter-user"}
            type={"text"}
          />
        </AvGroup>
  )

export default AssetUser;
