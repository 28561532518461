import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Label, FormGroup, FormText } from "reactstrap";
import translate from 'helpers/Translation/translate';


class TextAreaField extends Component {
  render() {

   const { formatMessage } = this.props.intl;
   const  { placeholder, value, name, rows, getChange, label, disabled, helpText } = this.props;

   return(
     <FormGroup>
     {label &&
      <Label for={name}>
        {translate(label)}
      </Label>
     }
      <textarea
        disabled={disabled}
        className="form-control"
        id={name}
        name={name}
        rows={rows}
        value={value}
        placeholder={placeholder ? formatMessage({ id : placeholder }) : null}
        onChange={getChange}
      ></textarea>
      <FormText color="muted">
        {helpText}
      </FormText>
    </FormGroup>
   )
  }
}

 export default injectIntl(TextAreaField);