import React from "react";
import { translate } from "helpers/helpers";
import { Col, Row } from "reactstrap";
import { Table, Tr, Th, Td, Tbody } from 'react-super-responsive-table';


const BlanccoProduct = ({ assetWithReport }) => (
    <Row>
    <Col lg={6}>
      <Table className="table">
        <Tbody>
          <Tr>
            <Th style={{ width : "50%"}}>{translate('reports.product-serial-number')}</Th>
            <Td>{assetWithReport.product_serial_number}</Td>
          </Tr>
          <Tr>
            <Th>{translate('reports.manufacturer')}</Th>
            <Td>{assetWithReport.product_manufacturer}</Td>
          </Tr>
          <Tr>
            <Th>{translate('reports.model')}</Th>
            <Td>{assetWithReport.product_model}</Td>
          </Tr>
          <Tr>
            <Th>{translate('asset.category')}</Th>
            <Td>{assetWithReport.product_category}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Col>
    <Col lg={6}>
      <Table className="table">
        <Tbody>
          <Tr>
            <Th style={{ width : "50%"}}>{translate('reports.lot-number')}</Th>
            <Td>{assetWithReport.lot_number}</Td>
          </Tr>
          <Tr>
            <Th>{translate('reports.cosmetic')}</Th>
            <Td>{assetWithReport.cosmetic}</Td>
          </Tr>
          <Tr>
            <Th>Disposition</Th>
            <Td>{assetWithReport.disposition}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Col>
  </Row>
)

export default BlanccoProduct