import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button, Container, Alert} from 'reactstrap'

// Redux and actions
import { useSelector, useDispatch } from "react-redux";
import { clearModelState } from "store/model/actions";
import { getResellerDropdown, getCustomerDropdown, getLotreports } from "store/form/actions";
import { generateReport, clearState } from "store/report/actions";

import { TextInputField, TranslatedSelect, UploadFile } from "Components/Form/index";
import { AvForm } from "availity-reactstrap-validation";
import { LoaderOnPage } from 'Components/Global';

// Functions
import { translate } from 'helpers/helpers';
import CreateContainer from "Containers/CreateContainer";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import enUsLocale from 'date-fns/locale/en-US'
import Moreco from 'assets/images/logo.png'
import GeneratedReport from "./Components/GeneratedReport";

registerLocale('sv', sv)
registerLocale('en', enUsLocale)

const CreateLotReport = () => {

  const customers = useSelector(state => state.Form.customersDropdown)
  const lots = useSelector(state => state.Form.lots)
  const error = useSelector(state => state.Form.error)
  const locale = useSelector(state => state.Global.locale)

  const reportError = useSelector(state => state.Report.error)
  const errors = useSelector(state => state.Report.errors)
  const success = useSelector(state => state.Report.success)
  const warning = useSelector(state => state.Report.warning)
  const reportCreated = useSelector(state => state.Report.reportCreated)
  const isFetching = useSelector(state => state.Report.isFetching)
  const me = useSelector(state => state.Global.me)

  const [finalizing_date, setDate] = useState(null);
  const [received_date, setreceivedDate] = useState(null);
  const [validation, setValidation] = useState(false)
  const [report, setReport] = useState({ customer_id: null, lot_number: null, finalizing_date: null, received_date: null });
  const [lotTransactionReport, setLotTransactionReport ] = useState(null);
  const [hardDriveAuditReport, sethardDriveAuditReport ] = useState(null);


  const handleChange = e => {
    report[e.target.name] = e.target.value
    var lot_number_exists = lots.filter(lot => (lot.lot_number === report.lot_number))
    if(lot_number_exists.length > 0) setValidation(true)
    else setValidation(false)
  }

  const getLotTransactionReport = files => {
    if(files.length) setLotTransactionReport(files[0])
    else setLotTransactionReport([])
  }

  const getHardDriveAuditReport = files => {
    if(files.length) sethardDriveAuditReport(files[0])
    else sethardDriveAuditReport([])
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getResellerDropdown())
    dispatch(getCustomerDropdown())
    dispatch(getLotreports())
    return () => {
      dispatch(clearModelState())
      dispatch(clearState())
    }
  }, [dispatch])

  const createReport = () => {
    dispatch(clearState())
    dispatch(generateReport(lotTransactionReport, hardDriveAuditReport, report))
  }


    return (
      <CreateContainer
        Model={'Morecoreport'}
        breadcrumbTitle={<img src={Moreco} height="15px" alt="logo"></img>}
        breadcrumbItem={translate("reports.create")}
        editMode={false}
        success={success}
        error={error || reportError}
        errors={errors}
        warning={warning}
      >
      <Card style={{minHeight: '80vh', height: '100%'}}>
        <CardBody style={{ height: '100%'}}>
          <Container>
          {!reportCreated ?
            <AvForm>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Col lg={12}>
                      <Label htmlFor="customer_id" className="col-form-label"> {translate('customer.customer')} </Label>
                      <TranslatedSelect
                        value={report.customer_id}
                        name="customer_id"
                        required
                        onChange={e => handleChange(e)}
                        options={customers ?? []}
                        optionMessage="form.select-customer"
                        errorMessage="form.select-customer"
                        />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col lg={12}>
                    <Label htmlFor="lot_number" className="col-form-label"> LOT # </Label>
                      <TextInputField
                        name="lot_number"
                        onChange={e => handleChange(e)}
                        errorMessage={"reports.enter-lot"}
                        type={"text"}
                        placeholder={'reports.enter-lot'}
                        value={report.lot_number}
                      />
                    {validation && <Alert color="warning">{translate('reports.lot-report-exists')}</Alert>}
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col lg={12}>
                      <Label htmlFor="received_date" className="col-form-label"> {translate('reports.received')} </Label>
                          <DatePicker
                            placeholderText={locale === 'sv' ? 'Mottagen datum' : 'Date received'}
                            dateFormat="yyyy-MM-dd"
                            locale={locale}
                            className="form-control"
                            selected={received_date}
                            onChange={(date) => {
                              report.received_date = date
                              setReport(report)
                              setreceivedDate(date)
                              return date;
                          }}
                          />
                        {received_date > finalizing_date && <span className="text-danger">{translate('reports.error-message')}</span>}
                        </Col>
                    </FormGroup>
                  <FormGroup>
                    <Col lg={12}>
                      <Label htmlFor="finalizing_date" className="col-form-label"> {translate('reports.finalized')} </Label>
                          <DatePicker
                            placeholderText={locale === 'sv' ? 'Slutfört datum' : 'Date finalized'}
                            dateFormat="yyyy-MM-dd"
                            locale={locale}
                            className="form-control"
                            selected={finalizing_date}
                            onChange={(date) => {
                              date.setHours(23, 59, 59);
                              report.finalizing_date = date
                              setReport(report)
                              setDate(date)
                              return date;
                          }}
                          />
                          {received_date > finalizing_date && <span className="text-danger">{translate('reports.error-message')}</span>}
                        </Col>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Col lg={12}>
                    <Label htmlFor="lot_transaction_report" className="col-form-label"> LOT transaction detail report </Label>
                      <UploadFile getFiles={getLotTransactionReport} title={'Upload CSV'} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col lg={12}>
                    <Label htmlFor="hard_drive_audit_report" className="col-form-label"> Audit report hard drives </Label>
                      <UploadFile getFiles={getHardDriveAuditReport} title={'Upload CSV'} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
                 <Row className="text-center mt-3">
                  {isFetching ?
                  <Col lg={12} className="text-center">
                  <LoaderOnPage />
                  <br/>
                  {translate('reports.generation-time')}
                 </Col>
                 :
                    <Button
                      color="success"
                      disabled={(lotTransactionReport && hardDriveAuditReport && report.customer_id && (report.finalizing_date > report.received_date) && report.lot_number) ? false : true}
                      className="mx-auto"
                      onClick={() => createReport(lotTransactionReport && hardDriveAuditReport && report.customer_id && report.finalizing_date && report.lot_number)}>
                        {translate('reports.create')}
                    </Button>}
                  </Row>
            </AvForm>
          : <GeneratedReport data={reportCreated} me={me} />}
          </Container>
        </CardBody>
      </Card>
    </CreateContainer>
    );
}


export default CreateLotReport
