import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const ContractListColumns = [
    {
        text: 'ID',
        dataField: "id",
        sort: true,
        headerStyle: () => {
            return { minWidth: '50px'};
          },
        formatter: (cell, row) => {
            return( <a href={`/contracts/${cell}`}>{cell}</a>)
        },
    },
    {
        text: 'Status',
        dataField: "active",
        sort: true,
        headerStyle: () => {
            return { minWidth: '50px'};
          },
        formatter: (cell, row) => {
            return row.archived ? <span className="badge badge-pill badge-light">{translate('archived')}</span> : cell ? <span className="badge badge-pill badge-success">{translate('active')}</span> : <span className="badge badge-pill badge-danger">{translate('inactive')}</span>
        },
    },
    {
        text: 'Agreement number',
        headerFormatter: () => {
            return (translate('contract.agreement-no'))
        },
        dataField: "agreement_no",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
          }
    },
    {
        text: 'Title',
        headerFormatter: () => {
            return (translate('contract.title'))
        },
        dataField: "title",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
          }
    },
    {
        text: 'Type',
        headerFormatter: () => {
            return (translate('contract.type'))
        },
        dataField: "type",
        sort: true,
        formatter: (cell, row) => {
            return  translate(`${cell}`)
        },
        headerStyle: () => {
            return { minWidth: '100px'};
          }
    },
    {
        text: 'Sub starts',
        headerFormatter: () => {
            return (translate('substarts'))
        },
        dataField: "sub_start_contracts",
        sort: true,
        formatter: (cell, row) => {
            return  cell?.length
        },
        headerStyle: () => {
            return { minWidth: '100px'};
          }
    },
    {
        text: 'Assets',
        dataField: "assets_count",
        sort: true,
        formatter: (cell, row) => {
            return  cell?.toLocaleString()
        },
    },
    {
        text: 'Customer',
        headerFormatter: () => {
            return (translate('customer.customer'))
        },
        dataField: "customer_id",
        sort: true,
        formatter: (cell, row) => {
            return (
                cell ? <a href={`customers/${row.customer_id}`}> {row.customer?.name} </a> : null
                )
        },
        headerStyle: () => {
            return { minWidth: '150px'};
          }
    }
    ,{
        text: 'Monthly cost',
        headerFormatter: () => {
            return (translate('contract.monthly-cost'))
        },
        dataField: "monthly_cost",
        sort: true,
        formatter: (cell, row) => {
            return (
                cell ? cell?.toLocaleString() + ' kr' :
                    row.sum_monthly_cost ? row.sum_monthly_cost.toLocaleString() + ' kr'
                : null)
        },
        headerStyle: () => {
            return { minWidth: '150px'};
        }
    },
    {
        text: 'Start date',
        headerFormatter: () => {
            return (translate('contract.start-date'))
        },
        dataField: "start_date",
        sort: true,
        formatter: (cell, row) => {
            return (moment(cell).format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        }
    },
    {
        text: 'End date',
        headerFormatter: () => {
            return (translate('contract.end-date'))
        },
        dataField: "end_date",
        sort: true,
        formatter: (cell, row) => {
            return (moment(cell).format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
          }
    },
    {
        text: 'Object value',
        headerFormatter: () => {
            return (translate('contract.object-value'))
        },
        dataField: "object_value",
        sort: true,
        formatter: (cell, row) => {
            return (cell?.toLocaleString() + ' kr' ?? null)
            },
        headerStyle: () => {
            return { minWidth: '100px'};
          }
    },
    {
        text: 'Financier',
        headerFormatter: () => {
            return (translate('contract.bank'))
        },
        dataField: "financier",
        sort: true,
    },
    {
        text: 'Created',
        headerFormatter: () => {
            return (translate('created-at'))
        },
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment(cell).format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
          }
    },
    {
        text: 'Updated',
        headerFormatter: () => {
            return (translate('updated-at'))
        },
        dataField: "updated_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD HH:mm'))
        },
        headerStyle: () => {
            return { minWidth: '120px'};
          }
    },
]


const getContractColumns = (user, list) => {

    var notAvailableFor = {
      customer: {},
      reseller: {},
      admin: {}
    };


    notAvailableFor['admin']['all'] = [ '']
    notAvailableFor['reseller']['all'] = [ 'reseller_id', 'reseller']
    notAvailableFor['customer']['all'] = [ 'customer_id', 'customer', 'reseller_id', 'reseller']

    notAvailableFor['admin']['on-reseller'] = [ 'assets_count', 'created_at', 'updated_at']
    notAvailableFor['reseller']['on-reseller'] = [ 'reseller_id', 'reseller', 'assets_count', 'created_at', 'updated_at']

    notAvailableFor['admin']['substarts'] = [ 'customer_id', 'type', 'financier' ]
    notAvailableFor['reseller']['substarts']  = [ 'customer_id', 'type', 'reseller_id', 'reseller' , 'financier']
    notAvailableFor['customer']['substarts']  = [ 'customer_id' , 'type' , 'customer', 'reseller_id', 'reseller' , 'financier']

    var filteredColumns = (user && list) ? ContractListColumns.filter(d => !notAvailableFor[user][list].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getContractColumns