import React from "react"
import { Link } from "react-router-dom";
import { translate } from "helpers/helpers";
import { Icons } from 'assets/styles/index';
import NavItem from './NavItem'

const NavResellers = ({ toggleMenu, layoutType  }) => (

        <NavItem
            id={'reseller'}
            layoutType={layoutType}
            item={
            <>
                <i className={Icons.reseller}></i>
                <span className="ml-1">{translate('nav.resellers')}</span>
            </>
            }
            menu={
                <ul className="sub-menu ecosystem-list pl-2">
                <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                    <Link to="/resellers">{translate('nav.resellers-list')}</Link>
                </li>
                <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                    <Link to="/resellers/create">{translate('nav.resellers-create')}</Link>
                </li>
            </ul>
            }
        />
    )


export default NavResellers