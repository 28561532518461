import React, { useEffect } from "react";
import { Collapse } from "reactstrap";

// MetisMenu
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { translate, Can } from "helpers/helpers";

// Links
import NavTools from './Components/NavTools'
import NavResellers from "./Components/NavResellers";
import NavUsers from './Components/NavUsers'
import NavCustomers from "./Components/NavCustomers";
import NavAssets from "./Components/NavAssets";
import NavDashboard from "./Components/NavDashboard";
import NavContracts from "./Components/NavContracts";
import NavProducts from "./Components/NavProducts";
import NavReports from "./Components/NavReports";

var mm = null;

const NavBarContent = ({ toggleMenu, layoutType }) => {

    return (
        <>
            <NavDashboard toggleMenu={toggleMenu} layoutType={layoutType}/>
            <Can I="read" a="Asset">
                {() => (
                <NavAssets toggleMenu={toggleMenu} layoutType={layoutType}/>
                )}
            </Can>
            <Can I="read" a="Asset">
                {() => (
                <NavProducts toggleMenu={toggleMenu} layoutType={layoutType}/>
                )}
            </Can>
            <Can I="read" a="Contract">
                {() => (<NavContracts toggleMenu={toggleMenu} layoutType={layoutType} />)}
            </Can>
            <Can I="read" a="Reseller">
                {() => (<NavResellers toggleMenu={toggleMenu} layoutType={layoutType}/>)}
            </Can>
            <Can I="read" a="Customer">
                {() => (<NavCustomers toggleMenu={toggleMenu} layoutType={layoutType}/>)}
            </Can>
            <Can I="read" a="User">
                {() => ( <NavUsers toggleMenu={toggleMenu} layoutType={layoutType}/>)}
            </Can>
            <Can I="read" a="Morecoreport">
                {() => ( <NavReports toggleMenu={toggleMenu} layoutType={layoutType}/>)}
            </Can>
            <NavTools toggleMenu={toggleMenu} layoutType={layoutType}/>
        </>
    )
}


const Menu = ({ location, toggleMenuCallback, layoutType, type }) => {

    const activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;
            if (parent2) {
                parent2.classList.add("mm-show");
                const parent3 = parent2.parentElement;
                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };


    useEffect(() => {

        if(layoutType === 'vertical') {
            if(mm) mm.dispose();
            mm = new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var uls = document.getElementsByClassName("sub-menu");
            for (var i = 0; i < uls.length; ++i) {
                var ul = uls[i];
                var items = ul.getElementsByTagName("a");
                for (var j = 0; j < items.length; ++j) {
                    if (location.pathname.indexOf(items[j].pathname)>=0) {
                        matchingMenuItem = items[j];
                        break;
                    }
                }
                if(matchingMenuItem) break;
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
      })


        return (
            type === "condensed" ?
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        <div id="sidebar-menu">
                            <ul className="metismenu list-unstyled" id="side-menu">
                                <li className="menu-title">{translate('nav.menu')}</li>
                                    <NavBarContent toggleMenu={toggleMenuCallback} layoutType={"vertical"}/>
                            </ul>
                        </div>
                    </div>
                </div>
            :
            layoutType === 'vertical' ?
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        <div id="sidebar-menu">
                            <ul className="metismenu list-unstyled" id="side-menu">
                                <li className="menu-title">{translate('nav.menu')}</li>
                                    <SimpleBar style={{ maxHeight: "100%" }}>
                                        <NavBarContent
                                            toggleMenu={toggleMenuCallback}
                                            layoutType={layoutType}
                                            />
                                    </SimpleBar>
                            </ul>
                        </div>
                    </div>
                </div>
            :
                <div className="topnav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse className="navbar-collapse" id="side-menu">
                                <ul className="navbar-nav">
                                    <NavBarContent
                                        toggleMenu={toggleMenuCallback}
                                        layoutType={layoutType}
                                        />
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
        )
    }


export default Menu
