import React from "react";
import { useSelector } from "react-redux";

import { Row, Col } from 'reactstrap'
import GraphBlancco from "./GraphBlancco";
import GraphHardDriveCategories from "./GraphHardDriveCategories"
import SectionContainer from "./SectionContainer";
import { translate, DataErasureColumns } from "helpers/helpers";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const SectionBlancco = ({ report }) => {
  const locale = useSelector(state => state.Global.locale)

 return (

  <SectionContainer
    report={report}
    title={translate('reports.data-security')}
    optional={
      <Row className="mb-5">
        <Col lg={6}>
          {(report.hard_drive_graph_categories?.length && report.hard_drive_graph_units.length) ? 
          <GraphHardDriveCategories categories={report.hard_drive_graph_categories} units={report.hard_drive_graph_units}/>: 
          null}
        </Col>
        <Col lg={6}>
          {(report.hard_drives.length) ? 
              <GraphBlancco
                locale={locale}
                destroyed={(report.shredded?.length ?? 0)}
                blancco={report.blancco_erased_hard_drives?.length ?? 0}
                hdd_assets={report.hard_drives?.length ?? 0}/>
          : null}
        </Col>
      </Row>}
    body={
      <BootstrapTable
        id={'blancco'}
        striped
        bordered={ false }
        condensed
        keyField="id" 
        noDataIndication={ () => { return translate('asset.no-rows') }}
        wrapperClasses="table-responsive f-10"
        defaultSorted={[{
          dataField: 'disk_serial_number',
          order: 'asc'
        }]}
        columns={DataErasureColumns}
        data={report?.hard_drives ?? []}
        pagination={paginationFactory()}
        dataField={"id"}
        order={"desc"}
      />
  }
/>
) }

export default SectionBlancco

