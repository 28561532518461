import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
// Redux and actions
import { clearModelState } from "store/model/actions";
import { getCustomersWithProductsDropdown, getSupplierDropdown, getResellersWithProductsDropdown } from "store/form/actions";

// Components
import OptionSelect from 'Components/Form/OptionSelect'
import { FormGroup } from "reactstrap";

// Functions
import { translate } from "helpers/helpers";


const CategoryListFilters = ({ onChange, filter, setActive }) => {

  return (
          <>
          <FormGroup className="col-lg-4 col-sm-12">
            <label>EOL</label>
            <select name="eol_months" className="form-control select2" onChange={onChange}>
              <OptionSelect message={"include-all"} value={""}/>
              <OptionSelect value={true} message={"product.has-eol"} />
              <OptionSelect value={false} message={"product.no-eol"} />
            </select>
          </FormGroup>
          <FormGroup className="col-lg-2 col-sm-12">
            <label>{translate('product.products')}</label>
              <div className="custom-control custom-checkbox mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="active"
                  checked={filter.products}
                  readOnly
                />
                <label
                  className="custom-control-label"
                  onClick={() => {setActive(!filter.products)}}
                > {filter.products ? 
                  <span className="badge f-14 badge-pill badge-success">{translate('in-system')}</span> :
                  <span className="badge f-14 badge-pill badge-danger">{translate('all-categories')}</span>}
                  </label>
              </div>
          </FormGroup>

          </>
    )
  }

export default CategoryListFilters;

