import React, { useEffect, useState } from "react";
// Redux and actions
import { useSelector, useDispatch } from "react-redux";
// Redux and actions
import { getContractLog, archiveContract, extendContract, clearState } from "store/contract/actions";
import { getById, deleteModel, getWithFilter } from "store/model/actions";
// Components
import { ContractDetails, ContractCustomerDetails, ContractDeliveryAddress, ContractModal, ContractButtons } from "./Components/contract";
import { NavLinkBadge } from "Components/Global/index";
import AssetsTable from "../Asset/Components/AssetList/Table/AssetsTable";
import TableList from 'Components/Lists/TableList';
import CardContainer from 'Containers/CardContainer'
// Functions
import { getContractColumns, getContractLogColumns, translate } from 'helpers/helpers';
import LoaderInsideComponent from "Components/Global/LoaderInsideComponent";


const Contract  = ({ match, location, history }) => {

  const [ modal, setModal ] = useState(false)
  const [ new_end_date, setNewEndDate ] = useState('')
  const [ type, setType ] = useState(null)
  const [ displaySuccess, setDisplaySuccess ] = useState(false)
  const [ isFetching, setFetchContract ] = useState(false)

  const contract = useSelector(state => state.Model.contract)
  const contracts = useSelector(state => state.Model.contracts)
  const notFound = useSelector(state =>  state.Model.notFound)
  const success = useSelector(state =>  state.Model.success)
  const error = useSelector(state =>  state.Model.error)
  const errors = useSelector(state =>  state.Contracts.errors)
  const log = useSelector(state =>  state.Contracts.singleLog)
  const contractSuccess = useSelector(state =>  state.Contracts.success)

  const me = useSelector(state =>  state.Global.me)
  const isCustomer = useSelector(state =>  state.Global.isCustomer)

  const id = match.params.id;
  const dispatch = useDispatch()

  const togglemodal = type => {
    setType(type)
    setModal(!modal)
  };

  const getContract = () => {
    setFetchContract(true)
    dispatch(getById(id, 'contract'))
    .then((res) => {
      if(res.payload) {
        dispatch(getWithFilter('contracts', { filter: { customer_id: res.payload.customer_id, archived: false }}))
      }
      setFetchContract(false)
    })
    dispatch(getContractLog(id))
  }

  const contractDelete = () => {
    dispatch(deleteModel(contract.id, 'contract'))
    .then((res) => {
      if (res.type === "DELETE_contract_SUCCESS") {
        history.push(`/contracts`, 'message.delete-contract-success')
      }
    });
    togglemodal();
  };

  const contractArchive = () => {
    dispatch(archiveContract(contract.id))
    .then((res) => {
      if (res.type === "ARCHIVE_CONTRACT_SUCCESS") {
        history.push(`/contracts`, 'message.archive-contract-success')
      }
    });
    togglemodal();
  };

  const getDate = date => {
    setNewEndDate(date)
  }

  const createExtension = () => {
    dispatch(clearState())
    dispatch(extendContract(contract.id, new_end_date)).then(res => {
      if(res.type === "EXTEND_CONTRACT_SUCCESS") {
        getContract()
        setDisplaySuccess(res.message)
      }
    })
    togglemodal()
  }

  const Tabs = [
      {
          activeTab: "first",
          translate: "asset.assets",
          badge: <NavLinkBadge color={'badge-primary'} total={contract?.assets_count} /> ,
          component: contract ?
            <AssetsTable
              id={'asset'}
              columns={'on-contract'}
              predefinedFilter={{ contract_id: contract?.id }}
              onRemoveFromContract
              onMoveToContract
              updateContractInfo={() => getContract()}
              contracts={contracts?.data?.filter(c => c.id !== contract?.id)}
              /> :
              <LoaderInsideComponent />
              ,
      },
      {
          activeTab: "customer",
          translate: "contract.customer-details",
          component: <ContractCustomerDetails contract={contract} />,
      },
      {
          activeTab: "delivery",
          translate: "contract.delivery-address",
          component: <ContractDeliveryAddress contract={contract} />,
      },
      {
          activeTab: "history",
          translate: "contract.history",
          component:
            <TableList
              id={'history'}
              data={log}
              dataField={'created_at'}
              order={'desc'}
              columns={getContractLogColumns('contract')} />,
      },
    ];

    if(!contract?.parent_id && contract?.type === 'framework') {
        Tabs.push({
          activeTab: "substart",
          translate: "contract.sub-starts",
          badge: contract?.sub_start_contracts?.length ? <NavLinkBadge color={'badge-primary'} total={contract?.sub_start_contracts?.length} /> : null,
          component:
            <TableList
              id={'substart'}
              dataField={'id'}
              data={contract?.sub_start_contracts ?? []}
              columns={getContractColumns(me?.type, 'substarts')} /> ,
        })
    }

  useEffect(() => {
    getContract()
    return () => {
      location.state = null
      dispatch(clearState())
    }
  }, [])


  return (
    <CardContainer
      model={'contract'}
      can={'Contract'}
      breadcrumbTitle={translate("contract.contracts")}
      breadcrumbItem={translate("contract.contract")}
      tabs={Tabs}
      isFetching={isFetching}
      data={contract}
      notFound={notFound}
      error={error}
      errors={errors}
      success={success || displaySuccess}
      history={history}
      location={location}
      customButtons={<ContractButtons contract={contract} togglemodal={togglemodal}/>}
    >
      <ContractDetails contract={contract} isCustomer={isCustomer}/>
      <ContractModal
        success={success || contractSuccess}
        modal={modal}
        type={type}
        contract={contract}
        new_end_date={new_end_date}
        getDate={getDate}
        togglemodal={togglemodal}
        contractDelete={contractDelete}
        contractArchive={contractArchive}
        createExtension={createExtension}
      />
    </CardContainer>
    );
  }

export default Contract
