import React from "react";
import {  useSelector } from "react-redux";
import translate from "helpers/Translation/translate";
import { InfoCard } from "Components/Global";
import ApexRadial from "../../Charts/ApexRadial";
import SyncItGlue from "./SyncItGlue";
import { LoaderInsideComponent } from "Components/Global";

const NewAssetsCard = ({ assetCard }) => {

    const locale = useSelector(state => state.Global.locale)
    const text = locale === 'sv' ? 'Med garanti info' : 'With warranty info'

    return (
    <InfoCard title={'Assets'} total={assetCard?.assetsTotal.toLocaleString()}>
        <p className="text-muted text-center m-0">
            {translate('asset.new-this-month')}: <span className="text-success">
            {assetCard?.newAssetsThisMonth.toLocaleString()}</span>
        </p>
        {!assetCard && <LoaderInsideComponent />}
        <ApexRadial
            color="#34c38f"
            total={assetCard?.assetsWithWarrantyTotal ?? 0}
            text={text}
            id={"assets"}
            percent={assetCard?.assetsWithWarrantyPercent ?? 0}/>
        <SyncItGlue />
    </InfoCard>
    )
}

export default NewAssetsCard;
