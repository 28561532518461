import React from "react";
// Components
import { Card, CardTitle, CardBody, Col, Row } from "reactstrap";
// Functions
import { translate } from 'helpers/helpers';
// Styles
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'
import ProgressBar from "./ProgressBar";
import { EcosystemCardHeight100 } from "assets/styles";

const WarrantyCardCustomer = ({ title, warranty }) => {

  const totalAssets = warranty?.total?.assets
  const expiredAssets = warranty.expired?.total
  const dueSoonAssets = warranty.six_months?.total
  const unknownAssets = warranty.unknown?.total
  const assets_without_price = warranty?.total?.without_price

  const assets_with_price = (warranty.expired?.total+warranty.six_months?.total)-(warranty.expired.without_price+warranty.six_months.without_price)

    return(
      <Card style={EcosystemCardHeight100}>
        <CardBody>
          <a href={`/customers/${warranty?.total?.id}`}>
            <CardTitle style={ecosystemCardHeader}>
                <Row>
                    <Col xs={9}>
                      {title}
                    </Col>
                    <Col xs={3}>
                      <span className="badge badge-pill bg-light f-14 mb-3">
                        {translate('total')} {totalAssets}
                      </span>
                    </Col>
                </Row>
            </CardTitle>
          </a>
          <h2 className="mb-0 text-center mt-5">{warranty?.total?.price?.toLocaleString()} kr</h2>
          <p className="text-center mb-0">{translate('warranty.hardware-replacement-title')}</p>
          <p className="f-10 text-muted text-center mb-0"> {translate('warranty.based-on')} {assets_with_price} assets.</p>
            {assets_without_price ?
              <p className="f-10 text-muted text-center ">
                {translate('warranty.price-missing')} <a href={`/assets?missing_price=true&type=purchased&customer_id=${warranty?.total?.id}`}><span className="badge badge-pill badge-danger f-11">{assets_without_price}</span></a> assets
                </p> : null}
          <div className="mt-4">
            <h5 className="text-center">{translate('warranty')}</h5>
            <ProgressBar
              expired={expiredAssets}
              six_months={dueSoonAssets}
              inside={warranty.inside?.total}
              unknown={warranty.unkown?.total}
              total={totalAssets} />
              <div className="mt-2 text-center">
                {expiredAssets > 0 ?
                <a href={`/assets?warranty=expired&customer_id=${warranty?.total?.id}&type=purchased`} className="btn btn-danger btn-sm m-1">
                    {expiredAssets} {translate('warranty.expired')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
                {dueSoonAssets  > 0 ?
                <a href={`/assets?warranty=six_months&customer_id=${warranty?.total?.id}&type=purchased`} className="btn btn-warning btn-sm m-1">
                    {dueSoonAssets} {translate('warranty.due-soon')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
                {unknownAssets  > 0 ?
                <a href={`/assets?warranty=not_available&customer_id=${warranty?.total?.id}&type=purchased`} className="btn btn-light btn-sm m-1">
                    {unknownAssets} {translate('warranty.unknown')} <i className="mdi mdi-arrow-right"></i>
                </a>
                : null }
              </div>
          </div>
        </CardBody>
      </Card>
    )
}

export default WarrantyCardCustomer