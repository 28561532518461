import React from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "store/auth/actions";

import { Card, CardBody, Button, CardTitle  } from "reactstrap";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";
import { Can, translate } from 'helpers/helpers';

const AccountSecurity = ({ me, user, DemoMode, togglemodal, pathname }) => {

  const dispatch = useDispatch()

  return (
    <Card>
      <CardBody>
      <CardTitle style={ecosystemCardHeader}>{translate('user.account-security')}</CardTitle>
      {(!user?.two_factor_authentication_enabled && me.id === user.id) ?
        <div className="media mb-3">
            <div className="font-size-12 text-muted">
              <h6 className="mt-0 mb-1 text-danger">{translate('notifications.no-2FA')}</h6>
              <p className="mb-1">{translate('notifications.no-2FA-text')}</p>
            </div>
        </div>
      : null }
      {me && pathname === '/users/me' ?
        <>
          <Button
            className="btn btn-warning  col-lg-12 mb-2 "
            onClick={() => togglemodal('change-password')}
            disabled={DemoMode ? true : false}
          >{translate('profile.change-password')}
          </Button>

        {user.two_factor_authentication_enabled ?
            <Button
              className="btn btn-danger col-lg-12 mb-2 "
              onClick={() => togglemodal('disable-2fa')}
              disabled={DemoMode ? true : false}
            >{translate('user.disable-2FA')}
            </Button>
          :
            <Button
              color="success"
              className="btn btn-success col-lg-12 mb-2 "
              onClick={() => togglemodal('enable-2fa')}
              disabled={DemoMode ? true : false}
            >{translate('user.enable-2FA')}
            </Button>}
        </>
      :
      <Can I="create" a="User">
        {() => (
          <Button
              disabled={DemoMode ? true : false}
              className="btn btn-warning col-lg-12 mb-2 "
              onClick={() => dispatch(forgotPassword({email: user.email}))}
            >
            {translate('profile.send-reset-link')}
            </Button>
        )}
      </Can>}
      </CardBody>
    </Card>
  )
}

export default AccountSecurity;
