import React from 'react';
import Base from 'assets/images/Base.png'
import Server from 'assets/images/Server.png'

const LoginImage = () => (

    <div style={{ height: "500px", width: "500px", position: "absolute"}}>
        <img loading="lazy"   alt="login pic" src={Base} data-no-retina style={{position: "absolute", left: "0", top: "0", height: "auto", width: "100%", display: "block", zIndex: 10}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(400px, 310px)", height: "auto", width: "15%", display: "block", zIndex: 9}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(400px, 280px)", height: "auto", width: "15%", display: "block", zIndex: 9}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(400px, 250px)", height: "auto", width: "15%", display: "block", zIndex: 9}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(400px, 220px)", height: "auto", width: "15%", display: "block", zIndex: 9}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(350px, 280px)", height: "auto", width: "15%", display: "block", zIndex: 8}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(350px, 250px)", height: "auto", width: "15%", display: "block", zIndex: 8}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(350px, 220px)", height: "auto", width: "15%", display: "block", zIndex: 8}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(350px, 190px)", height: "auto", width: "15%", display: "block", zIndex: 8}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(300px, 250px)", height: "auto", width: "15%", display: "block", zIndex: 7}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(300px, 220px)", height: "auto", width: "15%", display: "block", zIndex: 7}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(300px, 190px)", height: "auto", width: "15%", display: "block", zIndex: 7}} />
        <img loading="lazy"   alt="login pic" src={Server} data-no-retina style={{position: "absolute", transform: "translate(300px, 160px)", height: "auto", width: "15%", display: "block", zIndex: 7}} />
    </div>
)

export default LoginImage