import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import translate from 'helpers/Translation/translate'


const DesktopTabs = ({ Tabs, displayTab }) => {

    const [activeTab, setActiveTab] = useState(displayTab);
    const toggle = tab => { if(activeTab !== tab) setActiveTab(tab)}

    return (
        <div className="d-none d-md-none d-lg-block" style={{ zIndex: 10}}>
            <Nav tabs className="nav-tabs-custom">
                {Tabs?.map((tab, i) => {
                    if(tab.activeTab === 'current') {
                        return (
                        <NavItem key={i}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                onClick={() => toggle(tab.activeTab)}>
                                 {translate(tab.translate)} {tab.badge}
                            </NavLink>
                        </NavItem>
                        )
                    } else {
                    return(
                        <NavItem key={i}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({ active:  activeTab === tab.activeTab })}
                                onClick={() => toggle(tab.activeTab)}>
                                 {translate(tab.translate)} {tab.badge}
                            </NavLink>
                        </NavItem>
                        )
                    }
                })}
            </Nav>
            <TabContent activeTab={activeTab }>
                {Tabs?.map((tab, i) => (
                    <TabPane key={i}  tabId={tab.activeTab} className="p-3">
                        {tab.component}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    )
}

export default DesktopTabs