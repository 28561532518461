import React,  { useEffect } from "react";
// Redux
import { useSelector, useDispatch} from "react-redux";
import { getWidgets, clearState } from 'store/dashboard/actions'
// Components
import { Col, Card, CardBody, CardTitle, CardHeader, Row } from "reactstrap";
import { AssetCount, LoaderInsideComponent } from "Components/Global";
import NewUsersCard from "./Components/Admin/NewUsersCard";
import NewAssetsCard from "./Components/Admin/NewAssetsCard";
import NewProductsCard from "./Components/Admin/NewProductsCard";
// Styles
import ecosystemCardHeight100 from "assets/styles/EcosystemCardHeight100";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";


const AdminDashboard = () => {
	const dispatch = useDispatch();

  const userCard = useSelector(state => state.Dashboard.userCard)
  const assetCard = useSelector(state => state.Dashboard.assetCard)
  const productCard = useSelector(state => state.Dashboard.productCard)
  const assetCountDiagramData = useSelector(state => state.Dashboard.assetCountDiagramData)

  useEffect(() => {
    dispatch(getWidgets())
    return () => {
      dispatch(clearState())
    }
  },[])

    return (
          <>
            <Col lg={12}>
              <Row>
                <Col lg={4} md={4} sm={12}>
                    <NewAssetsCard assetCard={assetCard} />
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <NewProductsCard productCard={productCard}/>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <NewUsersCard userCard={userCard} />
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Card style={ecosystemCardHeight100} className="ecosystem-dashboard-box-shadow">
                <CardHeader style={ecosystemCardHeader}>
                  <CardTitle> Assets</CardTitle>
                </CardHeader>
                  <CardBody className="text-center">
                    {assetCountDiagramData ? <AssetCount periods={assetCountDiagramData?.xaxis} series={assetCountDiagramData?.series}/>
                    : <LoaderInsideComponent />}
                  </CardBody>
                </Card>
            </Col>
          </>
        )
    }

export default AdminDashboard

