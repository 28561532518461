
export const UPLOAD_LOGO_REQUEST = "UPLOAD_LOGO_REQUEST"
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS"
export const UPLOAD_LOGO_FAILURE = "UPLOAD_LOGO_FAILURE"

export const DELETE_LOGO_REQUEST = "DELETE_CUSTOMER_LOGO_REQUEST"
export const DELETE_LOGO_SUCCESS = "DELETE_CUSTOMER_LOGO_SUCCESS"
export const DELETE_LOGO_FAILURE = "DELETE_CUSTOMER_LOGO_FAILURE"

export const IMPORT_FILE_REQUEST = "IMPORT_FILE_REQUEST"
export const IMPORT_FILE_SUCCESS = "IMPORT_FILE_SUCCESS"
export const IMPORT_FILE_FAILURE = "IMPORT_FILE_FAILURE"

export const GET_WARRANTY_INFO_REQUEST = "GET_WARRANTY_INFO_REQUEST"
export const GET_WARRANTY_INFO_SUCCESS = "GET_WARRANTY_INFO_SUCCESS"
export const GET_WARRANTY_INFO_FAILURE = "GET_WARRANTY_INFO_FAILURE"

export const CLEAR_STATE = "CLEAR_STATE"
