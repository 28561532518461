import React from 'react';
import { Dot } from 'react-animated-dots';
import { Col } from 'reactstrap'


const LoaderInsideComponent = () => (
  <Col lg={12} className="text-center">
    <h4 className="text-success">
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </h4>
  </Col>
)
export default LoaderInsideComponent;