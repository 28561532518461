import React, { Component } from 'react';
import { injectIntl } from 'react-intl';


class OptionSelect extends Component {
  render() {
   const { formatMessage } = this.props.intl;
   const  { message, value, values } = this.props;

    return(<option value={value}>{formatMessage({ id: message }, {values: values })}</option>)
  }
}

 export default injectIntl(OptionSelect);