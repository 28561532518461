export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_ASSET_STATUSES_REQUEST = "GET_ASSET_STATUSES_REQUEST"
export const GET_ASSET_STATUSES_SUCCESS = "GET_ASSET_STATUSES_SUCCESS"
export const GET_ASSET_STATUSES_FAILURE = "GET_ASSET_STATUSES_FAILURE"

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST "
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE"

export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST "
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE"

export const GET_MULTISELECT_CUSTOMERS_FAILURE = "GET_MULTISELECT_CUSTOMERS_FAILURE"
export const GET_MULTISELECT_CUSTOMERS_REQUEST = "GET_MULTISELECT_CUSTOMERS_REQUEST"
export const GET_MULTISELECT_CUSTOMERS_SUCCESS = "GET_MULTISELECT_CUSTOMERS_SUCCESS"

export const GET_PRESELECTED_CUSTOMERS_FAILURE = "GET_PRESELECTED_CUSTOMERS_FAILURE"
export const GET_PRESELECTED_CUSTOMERS_REQUEST = "GET_PRESELECTED_CUSTOMERS_REQUEST"
export const GET_PRESELECTED_CUSTOMERS_SUCCESS = "GET_PRESELECTED_CUSTOMERS_SUCCESS"

export const GET_CUSTOMER_CONTRACTS_REQUEST = "GET_CUSTOMER_CONTRACTS_REQUEST"
export const GET_CUSTOMER_CONTRACTS_SUCCESS = "GET_CUSTOMER_CONTRACTS_SUCCESS "
export const GET_CUSTOMER_CONTRACTS_FAILURE = "GET_CUSTOMER_CONTRACTS_FAILURE"

export const GET_RESELLERS_REQUEST = "GET_RESELLERS_REQUEST "
export const GET_RESELLERS_SUCCESS = "GET_RESELLERS_SUCCESS"
export const GET_RESELLERS_FAILURE = "GET_RESELLERS_FAILURE"

export const GET_RESELLER_CUSTOMERS_REQUEST = "GET_RESELLER_CUSTOMERS_REQUEST "
export const GET_RESELLER_CUSTOMERS_SUCCESS = "GET_RESELLER_CUSTOMERS_SUCCESS"
export const GET_RESELLER_CUSTOMERS_FAILURE = "GET_RESELLER_CUSTOMERS_FAILURE"

export const CLEAR_STATE = "CLEAR_STATE"

export const GET_SUPPLIERS_REQUEST = "GET_SUPPLIERS_REQUEST"
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS"
export const GET_SUPPLIERS_FAILURE = "GET_SUPPLIERS_FAILURE"

export const GET_COMPANY_INFO_REQUEST = "GET_COMPANY_INFO_REQUEST"
export const GET_COMPANY_INFO_SUCCESS = "GET_COMPANY_INFO_SUCCESS"
export const GET_COMPANY_INFO_FAILURE = "GET_COMPANY_INFO_FAILURE"

export const GET_CUSTOMER_STATUSES_REQUEST = "GET_CUSTOMER_STATUSES_REQUEST"
export const GET_CUSTOMER_STATUSES_SUCCESS = "GET_CUSTOMER_STATUSES_SUCCESS"
export const GET_CUSTOMER_STATUSES_FAILURE = "GET_CUSTOMER_STATUSES_FAILURE"

export const GET_RESELLER_STATUSES_REQUEST = "GET_RESELLER_STATUSES_REQUEST"
export const GET_RESELLER_STATUSES_SUCCESS = "GET_RESELLER_STATUSES_SUCCESS"
export const GET_RESELLER_STATUSES_FAILURE = "GET_RESELLER_STATUSES_FAILURE"

export const GET_ANNUAL_YEARS_REQUEST = "GET_ANNUAL_YEARS_REQUEST"
export const GET_ANNUAL_YEARS_SUCCESS = "GET_ANNUAL_YEARS_SUCCESS"
export const GET_ANNUAL_YEARS_FAILURE = "GET_ANNUAL_YEARS_FAILURE"

export const GET_CUSTOMER_LOT_REPORTS_REQUEST = "GET_CUSTOMER_LOT_REPORTS_REQUEST"
export const GET_CUSTOMER_LOT_REPORTS_SUCCESS = "GET_CUSTOMER_LOT_REPORTS_SUCCESS"
export const GET_CUSTOMER_LOT_REPORTS_FAILURE = "GET_CUSTOMER_LOT_REPORTS_FAILURE"

export const GET_LOT_REPORTS_REQUEST = "GET_LOT_REPORTS_REQUEST"
export const GET_LOT_REPORTS_SUCCESS = "GET_LOT_REPORTS_SUCCESS"
export const GET_LOT_REPORTS_FAILURE = "GET_LOT_REPORTS_FAILURE"
