import React, { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { PopUpModal } from "Components/Global";
import axios from "axios";

export default function ReleaseNotes({ onClose }) {
    const [markdown, setMarkdown] = React.useState('');
    useEffect(() => {
        axios.get('/files/release.md').then((resp) => {
            setMarkdown(resp.data);
        }).catch(console.error);
    })
    return <PopUpModal title="New release available" togglemodal={onClose} size='xl' className="embedEntityModal markdown" isOpen>
        <ReactMarkdown>
            {markdown}
        </ReactMarkdown>
    </PopUpModal>
}
