import {
    GET_COMPANY_INFO_REQUEST,
    GET_COMPANY_INFO_SUCCESS,
    GET_COMPANY_INFO_FAILURE,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILURE,
    GET_ASSET_STATUSES_REQUEST,
    GET_ASSET_STATUSES_SUCCESS,
    GET_ASSET_STATUSES_FAILURE,
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAILURE,
    GET_MULTISELECT_CUSTOMERS_FAILURE,
    GET_MULTISELECT_CUSTOMERS_REQUEST,
    GET_MULTISELECT_CUSTOMERS_SUCCESS,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_CUSTOMER_CONTRACTS_REQUEST,
    GET_CUSTOMER_CONTRACTS_SUCCESS,
    GET_CUSTOMER_CONTRACTS_FAILURE,
    GET_CUSTOMER_LOT_REPORTS_REQUEST,
    GET_CUSTOMER_LOT_REPORTS_SUCCESS,
    GET_CUSTOMER_LOT_REPORTS_FAILURE,
    GET_LOT_REPORTS_REQUEST,
    GET_LOT_REPORTS_SUCCESS,
    GET_LOT_REPORTS_FAILURE,
    GET_RESELLERS_REQUEST,
    GET_RESELLERS_SUCCESS,
    GET_RESELLERS_FAILURE,
    GET_RESELLER_CUSTOMERS_REQUEST,
    GET_RESELLER_CUSTOMERS_SUCCESS,
    GET_RESELLER_CUSTOMERS_FAILURE,
    GET_PRESELECTED_CUSTOMERS_FAILURE,
    GET_PRESELECTED_CUSTOMERS_REQUEST,
    GET_PRESELECTED_CUSTOMERS_SUCCESS,
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_FAILURE,
    GET_CUSTOMER_STATUSES_REQUEST,
    GET_CUSTOMER_STATUSES_SUCCESS,
    GET_CUSTOMER_STATUSES_FAILURE,
    GET_RESELLER_STATUSES_REQUEST,
    GET_RESELLER_STATUSES_SUCCESS,
    GET_RESELLER_STATUSES_FAILURE,
    GET_ANNUAL_YEARS_REQUEST,
	GET_ANNUAL_YEARS_SUCCESS,
	GET_ANNUAL_YEARS_FAILURE,
    CLEAR_STATE
} from "./actionTypes";

const INIT_STATE = {
    isFetching: false,
    categories: [],
    lotsDropdown: [],
    customersDropdown: [],
    customersMultiselect: [],
    preselectedCustomers: [],
    assetStatuses: [],
    error: null,
    contracts: [],
    suppliersDropdown: [],
    resellersDropdown: [],
    statuses: [],
    products: [],
    info: null,
    years: []
};

const Form = (state = INIT_STATE, action) => {
	switch (action.type) {
        case CLEAR_STATE:
			return {
				...state,
				error: null
			};
    	case GET_ANNUAL_YEARS_REQUEST:
			return {
				...state,
				isFetching: true
			};
    	case GET_ANNUAL_YEARS_SUCCESS:
			return {
				...state,
				isFetching: false,
                success: action.message,
				years: action.payload
			};
        case GET_ANNUAL_YEARS_FAILURE:
			return {
				...state,
				isFetching: false,
                error: action.message
			};
        case GET_CATEGORIES_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_CATEGORIES_SUCCESS:
			return {
                ...state,
                isFetching: false,
				categories: action.payload
            };
        case GET_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_PRODUCTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                products: action.payload
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_ASSET_STATUSES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_ASSET_STATUSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                assetStatuses: action.payload
            };
        case GET_ASSET_STATUSES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                customersDropdown: action.payload
            };
        case GET_CUSTOMERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_MULTISELECT_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_MULTISELECT_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                customersMultiselect: action.customers
            };
        case GET_MULTISELECT_CUSTOMERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_PRESELECTED_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_PRESELECTED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                preselectedCustomers: action.customers
            };
        case GET_PRESELECTED_CUSTOMERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_CUSTOMER_CONTRACTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CUSTOMER_CONTRACTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                contracts: action.payload
            };
        case GET_CUSTOMER_CONTRACTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_CUSTOMER_LOT_REPORTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CUSTOMER_LOT_REPORTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lots: action.payload
            };
        case GET_CUSTOMER_LOT_REPORTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_LOT_REPORTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_LOT_REPORTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lots: action.payload
            };
        case GET_LOT_REPORTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_RESELLERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_RESELLERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                resellersDropdown: action.payload
            };
        case GET_RESELLERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_RESELLER_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_RESELLER_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                customersDropdown: action.payload
            };
        case GET_RESELLER_CUSTOMERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_COMPANY_INFO_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null
            };
        case GET_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                info: action.payload
            };
        case GET_COMPANY_INFO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_SUPPLIERS_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case GET_SUPPLIERS_SUCCESS:
			return {
                ...state,
                isFetching: false,
				suppliersDropdown: action.payload
            };
        case GET_SUPPLIERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
            case GET_CUSTOMER_STATUSES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CUSTOMER_STATUSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                customerStatuses: action.payload,
            };
        case GET_CUSTOMER_STATUSES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_RESELLER_STATUSES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_RESELLER_STATUSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                resellerStatuses: action.payload
            };
        case GET_RESELLER_STATUSES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
		default:
			return state;
	}
};

export default Form;