import React from "react";
import { Table } from "reactstrap";
import translate from 'helpers/Translation/translate';

const UserPermissions = ({ user, all_permissions }) =>  {
  
  return (
      <div className="table-responsive">
        <Table>
        <tbody>
          <tr>
            <th scope="row"  style={{ width: "40%" }}></th>
            <th className="text-center" style={{ width: "15%" }}>{translate("user.permission-create")}</th>
            <th className="text-center" style={{ width: "15%" }}>{translate("user.permission-read")}</th>
            <th className="text-center" style={{ width: "15%" }}>{translate("user.permission-update")}</th>
            <th className="text-center" style={{ width: "15%" }}>{translate("user.permission-delete")}</th>
          </tr>
          {
            // Map through all the permissions and create a new row on every forth
            all_permissions?.map((p, j) => {
              if(j%4 !== 0) return null;
              p = p.split("_")[1];
                if( (p === 'reseller') && !(user.type === 'admin') ) return null;
                if( (p === 'customer') && !((user.type === 'admin') || (user.type === 'reseller') )) return null;
                if( (p === 'key') && !((user.type === 'admin') || (user.type === 'reseller') )) return null;

                return (
                  <tr key={j/4}>
                    <th>{translate(p+'.'+p)}</th>
                      {all_permissions?.length > 0 && all_permissions?.slice(j , j+4).map((permission, i) => {

                        let has_permission = user?.has_permissions?.includes(permission)
                        return(
                          <td key={i} className="text-center">
                            {has_permission ? <i className="mdi f-20 mdi-check-circle text-success"></i>
                            : <i className="mdi f-20 mdi-check-circle" style={{ color: "#f6f6f6"}}></i>}
                          </td>
                        )
                      })}
                  </tr>
                )
            })}
        </tbody>
      </Table>
    </div>
  )
}

export default UserPermissions;
