import React from "react"
import { useLocation } from "react-router-dom";

// Components
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  Breadcrumbs,
  DesktopTabs,
  HandleDropDown,
  MobileCollapse,
  LoaderOnPage,
  Alerts,
} from "Components/Global/index";
import Page403 from "../Pages/Page403";
import Page404 from "../Pages/Page404";

// Functions
import { Can } from "helpers/helpers";
import ecosystemCardHeight100 from "assets/styles/EcosystemCardHeight100";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";

const CardContainer = ({
  success,
  error,
  errors,
  title,
  image,
  children,
  subtitle,
  customButtons,
  breadcrumbTitle,
  breadcrumbItem,
  isFetching,
  notFound,
  can,
  model,
  data,
  tabs,
  history
 }) =>  {
  const location = useLocation();

  return (

  <div className="page-content">
    <Container fluid>
    {isFetching ? <LoaderOnPage /> :
    <>
    <Can I="read" a={can}>
      {() => (
        <>
        <Breadcrumbs title={breadcrumbTitle}  breadcrumbItem={breadcrumbItem} />
        <Alerts success={success} error={error} errors={errors} location={location} />
        {data && Object.keys(data).length ? <>
          <Card>
            <CardHeader style={ecosystemCardHeader}>
              <Row className="justify-content-between">
                <Col lg={10}>
                  <span>
                    <h4>{data?.name || data.title}</h4>
                    {subtitle}
                  </span>
                  {title}
                </Col>
                {model === 'product' ?
                  <Col lg={2}>{customButtons}</Col>
              :
                <Col lg={2}>
                  <HandleDropDown can={can} model={model} id={data?.id} history={history}>
                    {customButtons}
                  </HandleDropDown>
                </Col>
              }
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={image ? 8 : 12}>
                  {children}
                </Col>
                <Col lg={image ? 4 : 12}>
                  {image}
                </Col>
              </Row>
            </CardBody>
            </Card>
            {tabs?.length &&
              <Card style={ecosystemCardHeight100}>
                <CardBody>
                  <DesktopTabs Tabs={tabs} model={model} displayTab={'first'}/>
                  <MobileCollapse Tabs={tabs} model={model} />
                </CardBody>
              </Card>}
          </> : null}
        {((!isFetching && !data) || notFound) && <Page404 model={`${model}.${model}`} link={`/${model}s`} />}
        </>
        )}
    </Can>
    <Can not I="read" a={can}>
      {() => ( <Page403 /> )}
    </Can>
    </>}
    </Container>
  </div>
  )
}

export default CardContainer;