import React from "react";
import { Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Table as Table2 } from 'reactstrap'

// Functions
import translate from "helpers/Translation/translate";
import moment from "moment";

const ContractDates = ({ contract }) => (

      <div className="mb-5">
        <Table className="table no-border">
        <Tbody>
          <Tr style={{ border: 'none'}}>
            <Th>
            <strong>Status</strong> <br />
              {contract?.archived ?
              <span className="badge badge-pill badge-light">{translate('archived')}</span> : contract?.active ?
              <span className="badge badge-pill badge-success">{translate('active')}</span>:
              <span className="badge badge-pill badge-danger">{translate('inactive')}</span>}
            </Th>
            <Td>
              <strong>{translate("contract.start")}</strong> <br />
              {contract?.start_date && moment(contract?.start_date).format("YYYY-MM-DD")}
            </Td>
            <Td>
              <strong>{translate("contract.end")}</strong> <br />
              {contract?.end_date &&  moment(contract?.end_date).format("YYYY-MM-DD")}
            </Td>
          </Tr>
        </Tbody>
        </Table>
          {contract?.extensions?.length > 0 ?
          <Col lg={12}>
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop text-primary" tag="a">
                <span className="underline float-right">
                  {translate('contract.show-extensions')}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ width:"100%"}} className="mt-4">
                <DropdownItem >
                  <strong>{translate('extensions')}</strong>
                  <Table2 className="table mt-2">
                    <tbody>
                      <tr>
                        <th>{translate('contract.start-date')}</th>
                        <th>{translate('contract.end-date')}</th>
                      </tr>
                    {contract?.extensions.map((extension, i) => {
                      return(
                        <tr key={i}>
                            <td>
                                {moment(extension.start_date).format('YYYY-MM-DD')}
                            </td>
                            <td>
                                {moment(extension.end_date).format('YYYY-MM-DD')}
                            </td>
                          </tr>
                      )
                    })}
                    </tbody>
                  </Table2>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          : null }
      </div>
    )

export default ContractDates