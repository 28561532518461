import React from "react";
import { Button, Col, Row } from "reactstrap";
import { translate } from 'helpers/helpers';
import UploadFile from "Components/Form/UploadFile";
import MultiDropdown from "Components/Form/MultiDropdown";
import { processCSVHeaders } from "helpers/Imports/FileProcessing";

export function CSVUploader({ data = {}, onDataChange, labels, renamesClassName = "" }) {

    const [fileHeaders, setFileHeaders] = React.useState([]);

    const onFileUpload = React.useCallback((files) => {
        const file = files[0];
        const newData = {};
        processCSVHeaders(file).then((headers) => {
            labels.forEach((label) => headers.includes(label) && (newData[label] = label));
            newData.file = file;
            
            setFileHeaders(headers);            
            onDataChange(newData);
        });
    }, [onDataChange]);

    const onDropdownChange = React.useCallback((d) => {
        onDataChange(d);
    }, [onDataChange]);

    return <Row>
        <Col lg={12}>
            <UploadFile title={translate('asset.drop-csv')} getFiles={onFileUpload} />
        </Col>
        {!!fileHeaders.length && <MultiDropdown
            labels={labels}
            options={fileHeaders}
            data={data}
            onDataChange={onDropdownChange}
            className={`mt-5 ${renamesClassName}`}
        />}
    </Row>
}