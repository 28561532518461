import React from "react";
import translate from '../Translation/translate';
import Icons from 'assets/styles/Icons'
import moment from 'moment'
import Cookies from "universal-cookie";
const cookies = new Cookies();

const today = moment().format('YYYY-MM-DD')
const in6Months = moment(today).add(6, 'M').format('YYYY-MM-DD')

const assetListColumns = [
    {
      text: 'Asset',
      dataField: 'id',
      sort: true,
      hidden: false,
      csvText: 'id',
      formatter: (cell, row) => {
        return(
          <a href={`/assets/${cell}`} className="btn btn-sm btn-primary">
            {translate('display')} <i className="mdi mdi-arrow-right"></i>
          </a>
        )
      },
      headerStyle: () => {
        return { minWidth: '80px'};
    },
    },
    {
      text: 'Manufacturer',
      dataField: "product.supplier",
      sort: true,
      hidden: false,
      headerStyle: () => {
          return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('product.supplier'))
      }
    },
    {
      text: 'Supplier code',
      dataField: "product.supplier_code",
      sort: true,
      hidden: false,
      headerStyle: () => {
          return { minWidth: '130px'};
      },
      headerFormatter: () => {
        return (translate('product.supplier-code'))
      }
    },
    {
      text: 'Product',
      dataField: "product.name",
      sort: true,
      hidden: false,
      headerStyle: () => {
          return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('product.product'))
      }
    },
    {
      text: 'Serial number',
      dataField: "serial_number",
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '150px'};
      },
      headerFormatter: () => {
        return (translate('asset.serial'))
      }
    },
    {
      text: 'IMEI',
      dataField: "imei_number",
      sort: false,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '75px'};
      },
      headerFormatter: () => {
        return "IMEI";
      }
    },
    {
      text: 'Category',
      dataField: "product.product_category",
      sort: true,
      hidden: false,
      headerFormatter: () => {
        return (translate('asset.category'))
      }
    },
    {
      text: 'Type',
      dataField: "type",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        if(cell === 'purchased')
         { return <span className="badge badge-pill badge-primary">{cell}</span>
        } else if(cell === 'rented') {
          return <span className="badge badge-pill bg-soft-primary">{cell}</span>
        } else { return null}
        },
      headerFormatter: () => {
          return (translate('asset.type'))
        }
    },
    {
      text: "Status",
      dataField: "status_customer",
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '130px'};
      }
    },
    {
      text: "Admin Status",
      dataField: "status_admin",
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '130px'};
    }
    },
    {
      text: "Reseller Status",
      dataField: "status_reseller",
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '130px'};
      }
    },
    {
      text: 'Contract',
      dataField: "contract_id",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
      return (
        cell ?
          <a href={`/contracts/${cell}`}>
            {row.contract?.agreement_no ?? null}
          </a>
        : '')
      },
      headerFormatter: () => {
        return (translate('contract.contract'))
      }
    },
    {
      text: 'Contract type',
      dataField: "contract.type",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
      return  <span className={`badge-${cell === 'framework' ? 'primary' : cell === 'substart' ? 'light' : 'pink'} badge-pill`}>{translate(`${cell}`)}</span>
      },
      headerFormatter: () => {
        return (translate('contract.type'))
      }
    },
    {
      text: 'Monthly cost',
      dataField: "monthly_cost",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return (cell?.toLocaleString() + ' kr' ?? null)
      },
      headerStyle: () => {
          return { minWidth: '120px'};
      },
      headerFormatter: () => {
        return (translate('asset.monthly-cost'))
      },
    },
    {
      text: 'Start date',
      dataField: "contract.start_date",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return moment(cell).format('YYYY-MM-DD')
      },
      headerFormatter: () => {
        return (translate('contract.start-date'))
      },
      headerStyle: () => {
        return { minWidth: '100px'};
      }
    },
    {
      text: 'End date',
      dataField: "contract.end_date",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return moment(cell).format('YYYY-MM-DD')
      },
      headerFormatter: () => {
        return (translate('contract.end-date'))
      },
      headerStyle: () => {
        return { minWidth: '100px'};
      }
    },
    {
      text: 'Customer',
      dataField: "customer.name",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
      return(
        <a href={`/customers/${row.customer_id}`}>
          {cell}
        </a>)
      },
      headerStyle: () => {
        return { minWidth: '150px'};
      },
      headerFormatter: () => {
        return (translate('customer.customer'))
      }
    },
    {
      text: 'Reseller',
      dataField: "reseller.name",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
      return(
        <a href={`/resellers/${row.reseller_id}`}>
          {cell}
        </a>)
      },
      headerStyle: () => {
        return { minWidth: '150px'};
      },
      headerFormatter: () => {
        return (translate('reseller.reseller'))
      }
    },
    {
      text: 'Order number',
      dataField: "order_no",
      hidden: false,
      headerFormatter: () => {
        return (translate('asset.order'))
      }
    },
    {
      text: 'User',
      dataField: "user",
      sort: true,
      hidden: false,
      headerFormatter: () => {
        return (translate('asset.user'))
      }
    },
    {
      text: 'Price',
      dataField: "price",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return (cell ? cell?.toLocaleString() + ' kr' : null)
      },
      headerStyle: () => {
          return { minWidth: '80px'};
      },
      headerFormatter: () => {
        return (translate('asset.price'))
      }
    },
    {
      text: 'EMV',
      dataField: "product.market_value",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return (cell ? cell?.toLocaleString() + ' kr' : null)
      },
      headerStyle: () => {
          return { minWidth: '80px'};
      },
    },
    {
      text: 'Warranty',
      dataField: "warranty_expires_at",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
          const date = (row?.warranty_expires_at || row.warranty_end) ? moment(row.warranty_expires_at ?? row.warranty_end).format('YYYY-MM-DD') : null
          return (
            !date ? <span className="text-muted"><i className={`text-warning f-18 ${Icons.shield_alert}`}></i> {translate('date-missing')}</span>
            :today >= date ? <span className="text-danger"><i className={`text-danger f-18 ${Icons.shield_alert}`}></i><strong> {moment(date).format('YYYY-MM-DD')}</strong></span>
            :date <= in6Months ? <span className="text-warning"><i className={`text-warning f-18 ${Icons.shield_alert}`}></i><strong> {moment(date).format('YYYY-MM-DD')}</strong></span>
            :date >= in6Months ? <span className="text-success"><i className={`text-success f-18 ${Icons.shield}`}></i><strong> {moment(date).format('YYYY-MM-DD')}</strong></span>
            : null
          )
        },
      headerStyle: () => {
        return { minWidth: '130px', textAlign: 'center' };
      },
      headerFormatter: () => {
        return (translate('asset.warranty'))
      },
    },
    {
      text: "End of Life",
      dataField: "eol_date",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
        return (cell ? moment(cell).format('YYYY-MM-DD') : null)
      },
      headerStyle: () => {
        return { minWidth: '100px', textAlign: 'center' };
      }
    },
    {
      text: 'Additional info',
      dataField: "additional_info",
      hidden: true,
      sort: true,
      headerFormatter: () => {
        return 'Info'
      },
      headerStyle: () => {
        return { minWidth: '100px'};
      },
    },
    {
      text: 'Description',
      dataField: "description",
      hidden: true,
      sort: true,
      headerFormatter: () => {
        return (translate('asset.description'))
      },
      headerStyle: () => {
        return { minWidth: '100px'};
      },
    },
    {
      text: 'Reference',
      dataField: "reference",
      hidden: true,
      sort: true,
      headerFormatter: () => {
        return (translate('asset.reference'))
      },
      headerStyle: () => {
        return { minWidth: '100px'};
      },
    },
    {
      text: 'Delivery date',
      dataField: "delivery_date",
      hidden: true,
      sort: true,
      headerFormatter: () => {
        return (translate('asset.delivery-date'))
      },
      headerStyle: () => {
        return { minWidth: '120px'};
      },
    },
    {
      text: 'Cost center',
      dataField: "cost_center",
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('asset.cost-center'))
      }
    },
    {
      text: 'Installation address',
      dataField: "installation_address",
      csvText: "installation_address",
      hidden: true,
      sort: true,
      headerFormatter: () => {
        return (translate('asset.installation-address'))
      },
      headerStyle: () => {
        return { minWidth: '150px'};
      },
    },
    {
      text: 'Invoice date',
      dataField: "invoice_date",
      csvText: "invoice_date",
      hidden: true,
      sort: true,
      formatter: (cell, row) => {
        return (cell ? moment.utc(cell).local().format('YYYY-MM-DD') : null)
      },
      headerFormatter: () => {
        return (translate('asset.invoice-date'))
      },
      headerStyle: () => {
        return { minWidth: '120px'};
      },
    },
    {
      text: 'Purchase date',
      dataField: "purchased_at",
      sort: true,
      hidden: true,
      formatter: (cell, row) => {
        return (row?.purchased_at ? moment(row.purchased_at).format('YYYY-MM-DD') : null)
      },
      headerStyle: () => {
        return { minWidth: '130px'};
      },
      headerFormatter: () => {
        return (translate('asset.purchase-date'))
      }
    },
    {
      text: 'Created',
      dataField: "created_at",
      sort: true,
      hidden: false,
      formatter: (cell, row) => {
          return (moment(cell).format('YYYY-MM-DD'))
      },
      headerStyle: () => {
          return { minWidth: '100px'};
      },
      headerFormatter: () => {
        return (translate('created-at'))
    },
  },
  {
      text: 'Updated',
      dataField: "updated_at",
      sort: true,
      hidden: true,
      formatter: (cell, row) => {
          return (moment.utc(cell).local().format('YYYY-MM-DD HH:mm'))
      },
      headerStyle: () => {
          return { minWidth: '120px'};
        },
      headerFormatter: () => {
          return (translate('updated-at'))
      },
  },
];

  const getAssetColumns = (user, display) => {

    var notAvailableFor = {
      admin: {},
      customer: {},
      reseller: {}
    };

    


    notAvailableFor['admin']['rented'] = [ 'type', 'order_no', 'warranty_end', 'purchased_at','status_reseller' ]
    notAvailableFor['reseller']['rented'] = [ 'type', 'price', 'reseller.name' , 'order_no' ,'purchased_at', 'warranty_end', 'status_admin']
    notAvailableFor['customer']['rented'] = [ 'type', 'customer_id', 'customer', 'reseller.name', 'order_no', 'purchased_at', 'product.market_value' , 'warranty_end', 'status_admin', 'status_reseller']

    notAvailableFor['admin']['on-contract'] = [ 'updated_at', 'created_at', 'purchased_at', 'reseller.name', 'customer.name', 'eol_date', 'warranty_expires_at', 'contract.start_date', 'contract.end_date','contract.type','type', 'order_no', 'contract_id', 'price', 'warranty_end', 'status_reseller']
    notAvailableFor['reseller']['on-contract'] = [ 'updated_at', 'created_at', 'purchased_at', 'reseller.name', 'customer.name', 'eol_date', 'warranty_expires_at', 'contract.start_date', 'contract.end_date','contract.type', 'type', 'reseller.name' , 'order_no', 'contract_id', 'price', 'warranty_end' , 'status_admin']
    notAvailableFor['customer']['on-contract'] = [ 'updated_at', 'created_at', 'purchased_at', 'reseller.name', 'customer.name', 'eol_date', 'warranty_expires_at', 'contract.start_date', 'contract.end_date','contract.type', 'type', 'customer_id', 'customer', 'reseller.name', 'order_no', 'price', 'contract_id' ,'purchased_at', 'product.market_value', 'eol_date', 'warranty_end', 'status_admin', 'status_reseller'  ]

    notAvailableFor['admin']['purchased'] = [ 'contract.start_date', 'contract.end_date','contract.type', 'type', 'contract_id', 'monthly_cost', 'warranty_end', 'status_reseller']
    notAvailableFor['reseller']['purchased'] = [ 'contract.start_date', 'contract.end_date','contract.type', 'type', 'reseller.name', 'contract_id', 'monthly_cost' , 'warranty_end', 'status_admin']
    notAvailableFor['customer']['purchased'] = [ 'contract.start_date', 'contract.end_date','contract.type', 'type', 'customer_id', 'customer',  'reseller.name', 'contract_id', 'monthly_cost', 'purchased_at', 'product.market_value' ,'warranty_end', 'status_admin', 'status_reseller']

    notAvailableFor['admin']['mixed'] = [ 'contract.start_date', 'contract.type', 'contract_id', 'monthly_cost', 'order_no', 'warranty_end', 'status_reseller']
    notAvailableFor['reseller']['mixed'] = [ 'contract.start_date', 'contract.type', 'reseller.name', 'contract_id', 'monthly_cost', 'order_no', 'warranty_end' , 'status_admin']
    notAvailableFor['customer']['mixed'] = [ 'contract.start_date', 'contract.type', 'customer_id', 'customer', 'customer.name',  'reseller.name', 'contract_id', 'monthly_cost', 'order_no', 'purchased_at', 'product.market_value', 'warranty_end', 'status_admin', 'status_reseller']

    notAvailableFor['admin']['on-import-error'] = [ 'id', 'contract.start_date', 'contract.end_date','contract.type', 'contract_id', 'monthly_cost', 'order_no', 'warranty_end', 'status_reseller']
    notAvailableFor['reseller']['on-import-error'] = [ 'id', 'contract.start_date', 'contract.end_date','contract.type', 'reseller.name', 'contract_id', 'monthly_cost', 'order_no', 'warranty_end' , 'status_admin']
    notAvailableFor['customer']['on-import-error'] = [ 'id', 'contract.start_date', 'contract.end_date','contract.type', 'customer_id', 'customer', 'customer.name',  'reseller.name', 'contract_id', 'monthly_cost', 'order_no', 'purchased_at', 'product.market_value', 'warranty_end', 'status_admin', 'status_reseller']


    var filteredColumns = (user && display) && assetListColumns.filter(d => !notAvailableFor[user][display].includes(d.dataField));

    var AssetToggles = cookies.get("AssetToggles") || {};
    AssetToggles && filteredColumns?.map(column => (
      column.hidden = (column.dataField in AssetToggles) ? !AssetToggles[column.dataField] : false
    ))

    return filteredColumns;
  }

  export default getAssetColumns