import React from "react"
import { Link } from "react-router-dom";
import { Can, translate } from "helpers/helpers";
import NavItem from './NavItem'
import { Icons } from 'assets/styles'

const NavUsers = ({ toggleMenu, layoutType  }) => (

        <NavItem
            id={'user'}
            layoutType={layoutType}
            item={
            <>
                <i className={Icons.user}></i>
                <span className="ml-1">{translate('nav.users')}</span>
            </>
            }
            menu={
                <ul className="sub-menu ecosystem-list pl-2">
                <li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}>
                    <Link to="/users">{translate('nav.users-list')}</Link></li>
                <Can I="create" a="User">
                   {() => (<li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}><Link to="/users/create">{translate('nav.users-create')}</Link></li>)}
                </Can>
                <Can I="read" a="Key">
                    {() => (<li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}><Link to="/users/key-account-managers">{translate('user.list-kam')}</Link></li>)}
                </Can>
                <Can I="create" a="Key">
                    {() => (<li className={`${layoutType === 'horizontal' && "p-2"}`} onClick={toggleMenu}><Link to="/users/key-account-managers/create">{translate('user.create-kam')}</Link></li>)}
                </Can>
            </ul>
            }
        />
    )


export default NavUsers