import React, { useEffect, useState } from 'react';

// Actions and redux
import {  useSelector, useDispatch } from "react-redux";
import { getPredifinedPermissions, TwoFactorAuthSetup, getApiToken, clearState } from "store/user/actions";
import { getById } from "store/model/actions";
import { getMe } from "store/global/actions";

// Components
import { Row, Col, Card, CardBody, CardTitle} from "reactstrap";
import UserInfo from './Components/card/UserInfo';
import { Alerts } from 'Components/Global/index';
import ApiSection from './Components/card/ApiSection';
import UserPermissions from './Components/UserPermissions';
import IntuneIntegration from './Components/IntuneIntegration';
import AccountSecurity from './Components/card/AccountSecurity';
import UserModal from './Components/UserModal';
import NotifyUser from './Components/card/NotifyUser';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader';
import PageContainer from 'Containers/PageContainer';

// Functions
import { translate  } from 'helpers/helpers';

const DemoMode = process.env.REACT_APP_DEMO_MODE

const UserProfile = ({ match, history, location }) => {

  const dispatch = useDispatch()

  const [ modal, setModal ] = useState(false)
  const [ displayModalInfo, setDisplayModalInfo] = useState(undefined)
  const [ user, setUser ] = useState({})

  const error = useSelector(state =>  state.Model.error)
  const isCustomer = useSelector(state =>  state.Global.isCustomer)
  const isFetching = useSelector(state =>  state.Global.isFetching)
  const me = useSelector(state =>  state.Global.me)

  const userError = useSelector(state =>  state.Users.error)
  const all_permissions = useSelector(state =>  state.Users.all_permissions)
  const secret = useSelector(state =>  state.Users.secret)
  const qrcode = useSelector(state =>  state.Users.qrcode)
  const apiToken = useSelector(state =>  state.Users.apiToken)
  const success = useSelector(state =>  state.Users.success)
  const errors = useSelector(state =>  state.Users.errors)
  const AuthSuccess = useSelector(state =>  state.Login.success)

  const url = match.path
  const id = match.params.id


  const getThisUser = () => {
        if(url === '/users/me') {
          dispatch(getMe()).then(res => {
            if(res?.payload) {
              setUser(res.payload)
            }
          })
          dispatch(getApiToken())
        } else {
          dispatch(getById(id, 'user'))
            .then(res => {
              if(res?.payload?.id === parseInt(me.id) ) {
                history.push('/users/me')
              }
              if(res?.payload) {
                setUser(res.payload)
              }
          })
        }
    }

    useEffect(() => {
      dispatch(getPredifinedPermissions())
      .then(() => getThisUser())

      return () => dispatch(clearState())
    }, [dispatch, history?.length ])


    const togglemodal = type => {
      dispatch(clearState())
        if(type === 'enable-2fa') {
          dispatch(TwoFactorAuthSetup()).then(res => {
            if(res.type === "TWOFA_SETUP_SUCCESS") {
              setModal(!modal)
              setDisplayModalInfo(type)
            }
          })
        } else {
          setModal(!modal)
          setDisplayModalInfo(type)
        }
    };


        return (
          <PageContainer
            isFetching={isFetching}
            Model={'User'}
            isProfilePage
            breadcrumbItem={translate('user.profile')}
            breadcrumbTitle={translate('user.user')}
            alerts={<Alerts success={success ?? AuthSuccess} error={ error ?? userError } location={location}/>}
            listHeaderTitle={translate('user.user')}
          >
            <Row>
              <Col xl={9} md={12}>
                <UserInfo
                  user={user}
                  isCustomer={isCustomer}
                  history={history}
                  me={me}
                  />
              </Col>
              <Col lg={3} md={12}>
                <AccountSecurity
                  user={user}
                  me={me}
                  DemoMode={DemoMode}
                  togglemodal={togglemodal}
                  pathname={location.pathname}
                  />
                <NotifyUser
                  me={me}
                  user={user}
                  togglemodal={togglemodal}
                  getThisUser={getThisUser}
                  />
                {user?.id === me?.id &&
                <ApiSection
                  user={user}
                  DemoMode={DemoMode}
                  apiToken={apiToken}
                  getThisUser={getThisUser}
                  togglemodal={togglemodal}
                  />
                }
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle style={{ ...ecosystemCardHeader, border: 'none' }}>{translate('user.permissions')}</CardTitle>
                      <UserPermissions
                        user={user}
                        all_permissions={all_permissions ?? []}
                        me={me}
                        />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle style={ecosystemCardHeader}>{/** TODO: Translate */"Intune Integration"}</CardTitle>
                      <IntuneIntegration
                        user={user}
                        all_permissions={all_permissions ?? []}
                        me={me}
                        />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <UserModal
              modal={modal}
              display={displayModalInfo}
              apiToken={apiToken}
              error={error || userError}
              errors={errors}
              success={success}
              secret={secret}
              qrcode={qrcode}
              togglemodal={togglemodal}
              getThisUser={getThisUser}
            />
          </PageContainer>

        );
    }



export default UserProfile

