import React from "react";

// Components
import { Col, FormGroup, Label, Row, FormText} from "reactstrap";
import { TextInputField, OptionSelect, TextAreaField } from "Components/Form/index";
import AvField from "availity-reactstrap-validation/lib/AvField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Functions
import translate from "helpers/Translation/translate";
import moment from "moment";


const ContractTypeDetails = ({ contract, getChange, editMode, createSubstart, dateChange, displayDateError }) =>
  (
    <>
        <FormGroup className="mt-4" row>
          <Label htmlFor="title" className="col-form-label  col-lg-3">
            {translate("contract.title")}
          </Label>
          <Col lg={9}>
            <TextInputField
              name={"title"}
              value={contract?.title}
              placeholder={"form.enter-title"}
              onChange={(e) => getChange(e)}
              errorMessage={"form.enter-title-message"}
              type={"text"}
              required
            />
          </Col>
        </FormGroup>
        <FormGroup className="mt-4" row>
          <Label htmlFor="agreement_no" className="col-form-label col-lg-3">
            {translate("contract.agreement-no")}
          </Label>
          <Col lg={9}>
            <TextInputField
              name={"agreement_no"}
              value={contract?.agreement_no}
              required
              placeholder={"form.enter-agreement-no"}
              onChange={(e) => getChange(e)}
              errorMessage={"form.enter-agreement-no-message"}
              type={"text"}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mb-5" row>
            <Label htmlFor="description" className="col-form-label col-lg-3"> {translate('contract.description')}</Label>
            <Col lg={9}>
              <TextAreaField
                name={"description"}
                rows={3}
                value={contract?.description}
                placeholder={'form.enter-description'}
                getChange={e => getChange(e)}
              />
            </Col>
          </FormGroup>
        <FormGroup className="mb-5" row>
            <Label htmlFor="special_conditions" className="col-form-label col-lg-3"> {translate('contract.special-conditions')}</Label>
            <Col lg={9}>
              <TextAreaField
                name={"special_conditions"}
                rows={3}
                value={contract?.special_conditions}
                placeholder={'form.enter-special-conditions'}
                getChange={e => getChange(e)}
              />
            </Col>
          </FormGroup>
        {!createSubstart ?
        <FormGroup className="mb-4" row>
          <Label htmlFor="type" className="col-form-label col-lg-3">
            {translate("contract.type")}
          </Label>
          <Col lg={9}>
            <AvField
              name="type"
              type="select"
              required
              errorMessage={translate("form.select-type")}
              className="form-control select2"
              value={contract?.type}
              onChange={(e) => getChange(e)}
            >
              <OptionSelect message={"form.select-type"} />
              <OptionSelect value={"framework"} message={"contract.framework"} />
              <OptionSelect value={"direct"} message={"contract.direct"} />
            </AvField> 
          </Col>
        </FormGroup>
        : null}
        <div>
          <FormGroup className="mb-4" row>
            <Label className="col-form-label  col-lg-3">
              {translate("contract.start-date")}
            </Label>
            <Col lg={9}>
              <Row>
                <Col md={12}>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={editMode || createSubstart ? moment(contract?.start_date).toDate() : contract?.start_date}
                    onChange={(e) => dateChange('start_date', e)}
                  />
                </Col>
              </Row>
            {displayDateError && !contract?.start_date && <FormText color="danger">{translate('form.select-start-date-warning')}</FormText>}
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label  col-lg-3">
              {translate("contract.end-date")}
            </Label>
            <Col lg={9}>
              <Row>
                <Col md={12}>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={ editMode || createSubstart ? moment(contract?.end_date).toDate(): contract?.end_date}
                    onChange={(e) => dateChange( 'end_date' , e)}
                    minDate={contract?.start_date}
                  />
                </Col>
              </Row>
              {displayDateError && !contract?.end_date && <FormText color="danger">{translate('form.select-end-date-warning')}</FormText>}
            </Col>
          </FormGroup>
        </div>
    </>
)

export default ContractTypeDetails;
