import React from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import Layout from "./AdminPanel";
import NonAuthLayout from "./AdminPanel/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { I18nProvider } from './helpers/Translation/index';
import { useAuth0 } from "@auth0/auth0-react";
import { patchAxiosHeaders } from "store/api";
import { getMe } from "store/actions";


const App = () => {
	const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
	const locale = useSelector(state => state.Global.locale);
	const { me, isFetching } = useSelector(state => state.Global);
	const dispatch = useDispatch();

	if (!me && !isFetching && !isLoading) {
		getAccessTokenSilently()
			.then(patchAxiosHeaders)
			.then(() => dispatch(getMe()));
	}

	return (
			<Router>
				<I18nProvider locale={locale}>
					<Switch>
						{!!me && authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						)).concat(isAuthenticated || isLoading ? [] : [<AppRoute
							path="/"
							layout={NonAuthLayout} 
							component={() => <Redirect to="/login" />}
							isAuthProtected={false}
						/>])}
					</Switch>
				</I18nProvider>
			</Router>
		);
}

export default App;
