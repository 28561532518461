import React from "react";
import { Col } from "reactstrap";

const Footer = () => (
      <footer className="footer">
            <Col lg={12}>
              <div className="text-sm-right d-sm-block">
                {new Date().getFullYear()} © Ecosystem Software.
              </div>
            </Col>
      </footer>
  );

export default Footer;
