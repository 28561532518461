import React from "react";

// Components
import { Button } from "reactstrap";
import TextInputField from "Components/Form/TextInputField";
import { AvForm } from "availity-reactstrap-validation";

// Functions
import { translate } from 'helpers/helpers';

const ChangePassword = ({ changePassword, response = null }) => response
  ? <a href={response.ticket}>Click here to change your password</a>
  : (
    <AvForm onValidSubmit={changePassword}>
             <TextInputField
              name="old_password"
              id="old_password"
              value=""
              placeholder={"form.enter-old-password"}
              errorMessage={"form.enter-old-password"}
              type="password"
              required={true}
              label={"form.old-password"}
              min={6}
            />
            <Button type="submit" className="" color="success">
              {translate("user.change-password")}
            </Button>
        </AvForm>
    )

export default ChangePassword
