import React from "react";
import {
  Media,
  UncontrolledCollapse,
  TabPane,
} from "reactstrap";
import translate from 'helpers/Translation/translate';


const TabAsset = () => (
    <TabPane tabId="assets">
      <Media className="faq-box mb-4">
        <div className="faq-icon mr-3">
          <i className="bx bx-help-circle font-size-20 text-success"></i>
        </div>
        <Media body>
          <h5 className="font-size-15">{translate('faq.assets-how')} <span href="#" style={{ cursor: 'pointer', fontSize: '12px'}}className="text-primary font-8" id="new-assets">
              {translate('read-more')}
            </span> </h5>
            <UncontrolledCollapse toggler="#new-assets">
              <p className="text-muted mt-4 mb-4">
                {translate('faq.assets-1')}
              </p>
              <ul className="mt-3">
                <li className="mb-5">
                  <strong>
                    {translate('faq.assets-import-title')}
                  </strong>
                  <br />
                  <p className="mt-2">
                    {translate('faq.assets-import-text')}
                    <a
                      href="/files/example.csv"
                      target="_blank"
                      download
                      className="ml-2 "
                    >
                      <i className="bx bx-download mr-1"></i>
                      {translate("asset.download-file")}
                    </a>
                  </p>
                  <a
                    href="/assets/import"
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    {translate("faq.assets-import-link")} <i className="mdi mdi-arrow-right ml-1"></i>
                  </a>
                </li>
                <li className="mb-5">
                  <strong>
                    {translate('faq.assets-manual-title')}
                  </strong>
                  <p className="mt-2">
                    {translate('faq.assets-manual-text')}
                  </p>
                  <a
                    href="/assets/create"
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    {translate('faq.assets-manual-link')}
                    <i className="mdi mdi-arrow-right ml-1"></i>
                  </a>
                </li>
                <li className="mb-5">
                  <strong >
                    {translate('faq.assets-api-title')}
                  </strong>
                  <p className="mt-2">
                    {translate('faq.assets-api-text')}
                  </p>
                  <a
                    href="/users/me"
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    {translate('faq.assets-api-link')}<i className="mdi mdi-arrow-right ml-1"></i>
                  </a>
                </li>
              </ul>
            </UncontrolledCollapse>
        </Media>
      </Media>
      <Media className="faq-box mb-4">
        <div className="faq-icon mr-3">
          <i className="bx bx-help-circle font-size-20 text-success"></i>
        </div>
        <Media body>
          <h5 className="font-size-15"> {translate('faq.assets-serial-title')}
          <span href="#" style={{ cursor: 'pointer', fontSize: '12px'}}className="text-primary font-8 ml-1" id="sn">{translate('read-more')}</span></h5>
          <UncontrolledCollapse toggler="#sn">
            {translate('faq.assets-serial-text')}
        </UncontrolledCollapse>
        </Media>
      </Media>
      <Media className="faq-box mb-4">
        <div className="faq-icon mr-3">
          <i className="bx bx-help-circle font-size-20 text-success"></i>
        </div>
        <Media body>
          <h5 className="font-size-15">{translate('faq.assets-eol-title')} <span href="#" style={{ cursor: 'pointer', fontSize: '12px'}}className="text-primary font-8" id="eol">{translate('read-more')}</span></h5>
          <UncontrolledCollapse toggler="#eol">
            {translate('faq.assets-eol-text')}
        </UncontrolledCollapse>
        </Media>
      </Media>
    </TabPane>
);

export default TabAsset;
