import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { LoaderOnPage } from "Components/Global";

// Functions
import { translate } from 'helpers/helpers';
import { Row, Col } from "reactstrap";
// Styles
import Microsoft from 'assets/images/microsoft-authenticator-app.png'
import Google from 'assets/images/google-2fa.png'
import ReactCodeInput from 'react-verification-code-input';


const Enable2FA = ({ qrcode, secret, enableTwoFA }) => (

    <AvForm className="text-center" >
    <div className="p-3 bg-light">
      <h5>1. {translate('user.setup-2FA-step1-title')}</h5>
      <p>{translate('user.setup-2FA-step1-text')}</p>
    </div>

    <div className="p-3">
        <h5 className="mt-4">2. {translate('user.setup-2FA-step2-title')}</h5>
        <p> {translate('user.setup-2FA-step2-text')}</p>
        {qrcode ?
          <img src={qrcode} alt="QRCode for two factory auth setup" height="100px" className="mb-2"></img>
        : <LoaderOnPage />}
        <p>Secret: {secret}</p>
    </div>

    <div className="p-3">
        <h5 className="mt-4">3. {translate('user.setup-2FA-step3-title')}</h5>
        <p> {translate('user.setup-2FA-step3-text')}</p>
        <ReactCodeInput onComplete={enableTwoFA}/>
     </div>
    </AvForm>
    )

export default Enable2FA
