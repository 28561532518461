import React from "react";
// falgs
import { LOCALES } from 'helpers/Translation/locales';
import {  useSelector, useDispatch } from "react-redux";
import { changeLocale } from "store/global/actions";
import Colors from 'assets/styles/GreenColors'

const SwitchLanguage = () => {

  const locale = useSelector(state => state.Global.locale)
  const dispatch = useDispatch()

    return (
          <div className="d-flex" style={{ alignItems: 'baseline', cursor: 'pointer' }}>
            <div className="avatar-xs mr-1" onClick={() => dispatch(changeLocale(LOCALES.ENGLISH))} >
                <span className="avatar-title rounded-circle" style={ locale === 'en' ? { background: Colors.dark } : { background: Colors.light}}>
                  En
                </span>
            </div>
            <div className="avatar-xs ml-1" onClick={() => dispatch(changeLocale(LOCALES.SWEDISH))}>
                <span className="avatar-title rounded-circle" style={ locale === 'sv' ? { background: Colors.dark } : { background: Colors.light}}>
                  Sv
                </span>
            </div>
          </div>
    );
  }


export default SwitchLanguage
