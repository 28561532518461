import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
// Redux and actions
import { clearModelState } from "store/model/actions";
import { getCustomersWithProductsDropdown, getResellersWithProductsDropdown, getSupplierDropdown, getYears } from "store/form/actions";

// Components
import OptionSelect from 'Components/Form/OptionSelect'
import { FormGroup } from "reactstrap";

// Functions
import { translate, Can} from "helpers/helpers";


const ProductListFilters = ({ onChange, filter }) => {

  const me = useSelector(state =>  state.Global.me)
  const customersDropdown = useSelector(state =>  state.Form.customersDropdown)
  const resellersDropdown = useSelector(state =>  state.Form.resellersDropdown)
  const suppliersDropdown = useSelector(state =>  state.Form.suppliersDropdown)

  const dispatch = useDispatch();

  useEffect(() => {
    if(me.type !== 'customer') dispatch(getCustomersWithProductsDropdown())
    if(me.type === 'admin') dispatch(getResellersWithProductsDropdown())
    dispatch(getSupplierDropdown())

    return () => {
      dispatch(clearModelState())
    }
  }, [])


    return (
          <>
          <FormGroup className="col-lg-3 col-sm-12 pr-0">
              <label>{translate('product.supplier')}</label>
                <select className="form-control select2" name="supplier" value={filter?.supplier} onChange={e => onChange(e)}>
                  <OptionSelect message={"include-all-suppliers"} value={""}/>
                    {suppliersDropdown?.map((supplier, i) => {
                      return <option key={i} value={supplier}>{supplier}</option>
                    }
                    )}
                </select>
            </FormGroup>
            <Can I="read" a="Customer">
              {() => (
                <FormGroup className="col-lg-3 col-sm-12 pr-0">
                    <label>{translate('customer.customer')}</label>
                    <select className="form-control select2" name="customer_id" value={filter?.customer_id} onChange={e => onChange(e)}>
                      <OptionSelect message={"include-all-customers"} value={""}/>
                        {customersDropdown?.map(customer => {
                          return <option key={customer.id} value={customer.id}>{customer.name}</option>
                        }
                        )}
                    </select>
                </FormGroup>
              )}
            </Can>
            <Can I="read" a="Reseller">
              {() => (
                <FormGroup className="col-lg-3 col-sm-12 pr-0">
                    <label>{translate('reseller.reseller')}</label>
                    <select className="form-control select2" name="reseller_id" value={filter?.reseller_id} onChange={e => onChange(e)}>
                      <OptionSelect message={"include-all-resellers"} value={""}/>
                        {resellersDropdown?.map(reseller => {
                          return <option key={reseller.id} value={reseller.id}>{reseller.name}</option>
                        }
                        )}
                    </select>
                </FormGroup>
              )}
            </Can>
            <FormGroup className="col-lg-3 col-sm-12 pr-0">
               <label>{translate('type')}</label>
                <select className="form-control select2" name="use_icecat" value={filter?.use_icecat} onChange={e => onChange(e)}>
                  <OptionSelect message={"include-all"} value={""}/>
                  <OptionSelect message={"icecat-products"} value={true}/>
                  <OptionSelect message={"manual-products"} value={false}/>
                </select>
            </FormGroup>
          </>
    )
  }

export default ProductListFilters;

