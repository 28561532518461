import React from "react";
import { AvForm } from "availity-reactstrap-validation";

// Functions
import { translate } from 'helpers/helpers';
import { Button, Col } from "reactstrap";

const Disable2FA = ({ disableTwoFA }) =>  (

    <AvForm onValidSubmit={disableTwoFA} >
    {translate('user.disable-2FA-text')}
    <Col lg={12} className="m-4 text-center">
     <Button type="submit" className="" color="danger">{translate('user.disable-2FA')}</Button>
    </Col>
   </AvForm>
  )

export default Disable2FA
