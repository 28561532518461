import React from "react";
import { CardHeader, CardTitle } from 'reactstrap';
import ecosystemCardHeader from 'assets/styles/EcosystemCardHeader'

const ListHeader = ({ data, title }) =>  (
    <CardHeader style={ecosystemCardHeader} >
        <CardTitle>
         {title}
         <span className="badge badge-pill badge-light ml-1">{data?.length?.toLocaleString() || data?.toLocaleString()}</span>
         </CardTitle>
    </CardHeader>
  )
export default ListHeader;