import translate from '../Translation/translate';
import moment from 'moment'

const AssetStatusColumns = [
  {
    dataField: "id",
    text: "ID",
    editable: false,
    sort: true,
    headerStyle: () => {
      return { minWidth: '50px', width: '30px'};
    }
  },
  {
    dataField: "status",
    text: translate('Status'),
    editable: false,
    sort: true
  },
  {
    text: translate('created-at'),
    dataField: "created_at",
    sort: true,
    formatter: (cell, row) => {
        return (moment(cell).format('YYYY-MM-DD'))
    },
    headerStyle: () => {
        return { minWidth: '100px'};
      }
},
{
    text: translate('updated-at'),
    dataField: "updated_at",
    sort: true,
    formatter: (cell, row) => {
        return (moment(cell).format('YYYY-MM-DD'))
    },
    headerStyle: () => {
        return { minWidth: '100px'};
      }
},
]


const getAssetStatusColumns = (type) => {

  var notAvailableOn= {};

    notAvailableOn['ids'] = ['']

    var filteredColumns = type ? AssetStatusColumns.filter(d => !notAvailableOn[type].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

export default getAssetStatusColumns