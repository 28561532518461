import { getAssetLogColumns, getUrl } from 'helpers/helpers';
import { TableList } from '../Lists';
import React, { useEffect, useState } from 'react';

export function ObjectLog({ id, objectType, columns }) {

    const [log, setLog] = useState([])

    useEffect(() => {
        getUrl(objectType).get(`${id}/log`).then(({ data: { logs } }) => setLog(logs));
    }, []);

    return <TableList
                data={log}
                columns={columns}
                dataField={'created_at'}
                order={'desc'} />
}
