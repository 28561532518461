import React from "react";
import Breadcrumbs from "Components/Global/Breadcrumb";
import { Container } from "reactstrap";
import { translate} from "helpers/helpers";
import { GdprLanguage } from "Components/Global";
import GdprInfo from "./Gdpr-info";

const Gdpr = () =>  (

            <div className="page-content">
              <GdprLanguage />
              <Container fluid>
                <Breadcrumbs title='GDPR' breadcrumbItem="" />
                    <div className="mx-auto text-center p-3">
                      <h2> {translate('gdpr.title')}</h2>
                    </div>
                    <div className="mx-auto col-lg-8 text-center mb-5">
                      <p>{translate('gdpr.sub-title')} </p>
                    </div>
                    <div className="col-lg-8 mx-auto">
                      <GdprInfo />
                    </div>
              </Container>
            </div>
          )

export default Gdpr
