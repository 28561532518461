import React from 'react';
import {UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import NavLinkBadge from "./NavLinkBadge";
import translate from 'helpers/Translation/translate'


const MobileCollapse = ({ Tabs }) => (

    <div className="d-lg-none d-block">
        {Tabs?.map((tab, i) => {
            return (
                <div key={i}>
                    <Button color="primary" className=" col-12" id={tab.activeTab} style={{ marginBottom: '1rem' }}>
                        {translate(tab.translate)} {tab.displayBadge ? tab.badge : null}
                        {tab.array?.length > 0 && <NavLinkBadge color={'badge-light'} model={tab.array} /> }
                        {tab.total && <NavLinkBadge color={'badge-light'} model={tab.total} /> }
                    </Button>
                    <UncontrolledCollapse toggler={`#${tab.activeTab}`}>
                        <Card>
                            <CardBody>
                                {tab.component}
                            </CardBody>
                        </Card>
                    </UncontrolledCollapse>
                </div>
            )}
        )}
    </div>
)

export default MobileCollapse