import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    MICROSOFT_LOGIN_REQUEST,
    MICROSOFT_LOGIN_SUCCESS,
    MICROSOFT_LOGIN_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    TWOFA_LOGIN_REQUEST,
    TWOFA_LOGIN_SUCCESS,
    TWOFA_LOGIN_FAILURE,
    LOST_DEVICE_REQUEST,
    LOST_DEVICE_SUCCESS,
    LOST_DEVICE_FAILURE,
    DISPLAY_AUTH_MODAL,
    LOGOUT_REQUEST,
    CLEAR_STATE
 } from './actionTypes';

import translate from 'helpers/Translation/translate';
import { auth } from "../api";


export const forgotPassword = email => {
  return async dispatch => {

    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });

      const res = await auth.post(`${process.env.REACT_APP_API_URL}/reset`, email);
      if (res.status === 200) return dispatch({ type: FORGOT_PASSWORD_SUCCESS, message: translate('email.sent') });
      if (res.status === 422) return dispatch({ type: FORGOT_PASSWORD_FAILURE, message: translate('message.email-not-existing') });
      if (res.status === 404) return dispatch({ type: FORGOT_PASSWORD_FAILURE, message: translate('message.email-not-existing') });

    } catch ( error ) {
      return dispatch ({ type: FORGOT_PASSWORD_FAILURE, message: translate('email.something-wrong')})
    }

  }
};

export const clearState = () => { return async dispatch => { return dispatch({ type: CLEAR_STATE}) }}

