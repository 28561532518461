import React from 'react';
import { UncontrolledAlert } from "reactstrap";
import { translate } from 'helpers/helpers';

const Alerts = ({ success, error, errors, location, warning }) => {

    const displayMessages = () => {
        return(
          Object.entries(errors).map(([key]) =>
          <UncontrolledAlert key={key} color="danger" className="mt-3"> <i className="mdi mdi-alert-circle f-20" style={{ color: '#8e071f4f'}}></i>  {errors[key]} </UncontrolledAlert>
          ))
      }

    return (
    <>
       {warning && <UncontrolledAlert color="warning"> <i className="mdi mdi-alert-circle f-20" style={{ color: '#8e071f4f'}}></i> {warning}</UncontrolledAlert>}
       {error && <UncontrolledAlert color="danger"> <i className="mdi mdi-alert-circle f-20" style={{ color: '#8e071f4f'}}></i> {error}</UncontrolledAlert>}
       {errors && displayMessages()}
       {success && <UncontrolledAlert color="success"> <i className="mdi mdi-check-circle f-20" style={{ color: '#0c650b4a'}}></i> {success}</UncontrolledAlert>}
       {location?.state?.length  && <UncontrolledAlert color="success" className="mt-3"> <i className="mdi mdi-check-circle f-20" style={{ color: '#0c650b4a'}}></i> {translate(`${location?.state}`)}</UncontrolledAlert>}
    </>
    )
}

export default Alerts;