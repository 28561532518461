import React from "react";
import { Button } from "reactstrap";
import translate from 'helpers/Translation/translate'

const DemoMessage = ({ isAdmin, resetDemo, isResetting }) =>  (

    <div className="d-md-flex align-items-baseline justify-content-between">
        <p className="text-danger f-12">{translate('message.demo')}</p>
        {isAdmin && <Button onClick={resetDemo} color="danger" className=" ml-2">{translate('message.demo-reset')}
        {isResetting && <i className="bx bx-loader bx-spin font-size-18 align-middle ml-2"></i>}</Button>}
    </div>
)

export default DemoMessage;