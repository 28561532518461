import React from "react";
import { Progress } from "reactstrap";

const ProgressBar = ({ expired, six_months, inside, unknown, total }) => {

    const inPercent = (value, total) => {
        return (value/total)*100
    }

    return (
        <Progress multi>
            <Progress bar color="danger" value={inPercent(expired, total)} />
            <Progress bar color="warning" value={inPercent(six_months, total)} />
            <Progress bar color="success" value={inPercent(inside, total)} />
            <Progress bar color="light" value={inPercent(unknown, total)} />
        </Progress>
    )
}

export default ProgressBar