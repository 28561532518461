// @flow
import {
    GET_CONTRACTS_REQUEST,
    GET_CONTRACTS_SUCCESS,
    GET_CONTRACTS_FAILURE,
    GET_CONTRACT_LOG_REQUEST,
    GET_CONTRACT_LOG_SUCCESS,
    GET_CONTRACT_LOG_FAILURE,
    DELETE_CONTRACT_REQUEST,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILURE,
    ARCHIVE_CONTRACT_REQUEST,
    ARCHIVE_CONTRACT_SUCCESS,
    ARCHIVE_CONTRACT_FAILURE,
    CREATE_CONTRACT_REQUEST,
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILURE,
    EXTEND_CONTRACT_SUCCESS,
    EXTEND_CONTRACT_REQUEST,
    EXTEND_CONTRACT_FAILURE,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_REQUEST,
    UPDATE_CONTRACT_FAILURE,
    REMOVE_ASSETS_SUCCESS,
    REMOVE_ASSETS_REQUEST,
    REMOVE_ASSETS_FAILURE,
    CONNECT_ASSETS_SUCCESS,
    CONNECT_ASSETS_REQUEST,
    CONNECT_ASSETS_FAILURE,
    IMPORT_ASSETS_TO_CONTRACT_REQUEST,
    IMPORT_ASSETS_TO_CONTRACT_SUCCESS,
    IMPORT_ASSETS_TO_CONTRACT_FAILURE,
    CLEAR_STATE
} from "../contract/actionTypes";

const INIT_STATE = {
    assets : [],
    newAssets: [],
    updatedAssets: [],
    invalidAssets: [],
    contracts: [],
    activeContracts: [],
    inactiveContracts: [],
    archivedContracts: [],
    contractsLog: [],
    singleLog: [],
    isFetching: false,
    notFound: false,
    error: null,
    errors: [],
    logErrorMessage: null
};

const Contracts = (state = INIT_STATE, action) => {
	switch (action.type) {
        case GET_CONTRACTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                contracts: action.contracts,
            };
        case GET_CONTRACTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case CREATE_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.payload,
                contract: action.payload,
            };
        case CREATE_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case GET_CONTRACT_LOG_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_CONTRACT_LOG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                singleLog: action.payload.logs
            };
        case GET_CONTRACT_LOG_FAILURE:
            return {
                ...state,
                isFetching: false,
                logErrorMessage: action.message
            };
        case DELETE_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.payload,
            };
        case DELETE_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case EXTEND_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case EXTEND_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case EXTEND_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages

            };
        case UPDATE_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case UPDATE_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case ARCHIVE_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ARCHIVE_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case ARCHIVE_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case REMOVE_ASSETS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case REMOVE_ASSETS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case REMOVE_ASSETS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case CONNECT_ASSETS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case CONNECT_ASSETS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                success: action.message,
            };
        case CONNECT_ASSETS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case IMPORT_ASSETS_TO_CONTRACT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case IMPORT_ASSETS_TO_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                newAssets: action.payload.created,
                updatedAssets: action.payload.exists,
                invalidAssets: action.payload.invalid,
                success: action.message,
            };
        case IMPORT_ASSETS_TO_CONTRACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message,
                errors: action.messages
            };
        case CLEAR_STATE:
            return {
                ...state,
                error: null,
                errors: [],
                success: null
            };
		default:
			return state;
	}
};

export default Contracts;
