import React from "react";
import { FormGroup, Label, Col, Row, Table } from "reactstrap";
import PopUpModal from 'Components/Global/PopUpModal'
import translate from "helpers/Translation/translate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ContractModal = ({
  modal,
  error,
  success,
  contractDelete,
  contractArchive,
  createExtension,
  togglemodal,
  contract,
  type,
  new_end_date,
  getDate,
}) => {

  const endDate = new Date(contract?.end_date);
  const minDate = endDate.setDate(endDate.getDate() + 1);

  return (
    <PopUpModal
      isOpen={modal}
      togglemodal={togglemodal}
      title={
        type === "delete"
          ? translate(`contract.delete`)
          : type === "archive"
          ? translate(`contract.archive`)
          : type === "extend"
          ? translate(`contract.extend`)
          : null
      }
      success={success}
      error={error}
      action={
        type === "delete"
          ? contractDelete
          : type === "archive"
          ? contractArchive
          : type === "extend"
          ? createExtension
          : null
      }
      buttonText={
        type === "delete"
          ? translate(`contract.delete`)
          : type === "archive"
          ? translate(`contract.archive`)
          : type === "extend"
          ? translate(`contract.extend`)
          : null
      }
    >
      <p className="mb-2">
        {type === "delete"
          ? translate(`contract.delete-help`)
          : type === "archive"
          ? translate(`contract.archive-help`)
          : type === "extend"
          ? translate(`contract.extend-help`)
          : null}
      </p>

      {type === "extend" ? (
        <Row>
          <Col lg={6} className="mt-5">
            <Table className="table">
              <tbody>
                <tr>
                  <th scope="row" style={{ width: "200px" }}>
                    <strong>{translate("contract.start")}</strong>
                  </th>
                  <td>
                    {contract?.start_date &&
                      moment(contract?.start_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>{translate("contract.end")}</strong>
                  </th>
                  <td>
                    {contract?.end_date &&
                      moment(contract?.end_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
              </tbody>
            </Table>
            <FormGroup className="mb-4 mt-5" row>
              <Label className="col-form-label  col-lg-4">
                {translate("contract.new-end-date")}
              </Label>
              <Col lg={9}>
                <Row>
                  <Col md={12}>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      selected={new_end_date}
                      onChange={(e) => getDate(e)}
                      minDate={minDate}
                    />
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Col>
          <Col lg={6} className="mt-5">
            <h5>{translate("extensions")}</h5>
            <Table className="table">
              <tbody>
                {contract?.extensions?.map((extension) => {
                  return (
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>
                        {moment(extension.start_date).format("YYYY-MM-DD")}
                      </th>
                      <td>
                        {" "}
                        <strong>
                          {moment(extension.end_date).format("YYYY-MM-DD")}
                        </strong>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : null}
    </PopUpModal>
  );
};

export default ContractModal