import translate from "helpers/Translation/translate";

import {
  GET_HISTORY_FOR_ASSET_REQUEST,
  GET_HISTORY_FOR_ASSET_SUCCESS,
  GET_HISTORY_FOR_ASSET_FAILURE,
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAILURE,
  INVENTORY_ASSET_REQUEST,
  INVENTORY_ASSET_SUCCESS,
  INVENTORY_ASSET_FAILURE,
  INVENTORY_ASSETS_REQUEST,
  INVENTORY_ASSETS_SUCCESS,
  INVENTORY_ASSETS_FAILURE,
  CLEAR_STATE,
  GET_ICECAT_DATA_REQUEST,
  GET_ICECAT_DATA_SUCCESS,
  GET_ICECAT_DATA_FAILURE,
} from "./actionTypes";

const INIT_STATE = {
  history: [],
  isFetching: false,
  isFetchingIcecat: false,
  error: null,
  errors: [],
  success: null,
  icecatData: null,
  notFound: false,
  productType: null,
};


const Assets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        error: null,
        errors: [],
        success: null,
        notFound: false,
        icecatData: null
      };
      case GET_HISTORY_FOR_ASSET_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
    case GET_HISTORY_FOR_ASSET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        history: action.payload.logs,
      };
    case GET_HISTORY_FOR_ASSET_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case INVENTORY_ASSET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case INVENTORY_ASSET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: translate(`${INVENTORY_ASSET_SUCCESS}`),
      };
    case INVENTORY_ASSET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: translate(`${INVENTORY_ASSET_FAILURE}`),
      };
    case INVENTORY_ASSETS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case INVENTORY_ASSETS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: translate(`${INVENTORY_ASSETS_SUCCESS}`, {assets: action.assets }),
      };
    case INVENTORY_ASSETS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: translate(`${INVENTORY_ASSETS_FAILURE}`),
      };
    case DELETE_ASSETS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_ASSETS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: translate(`${DELETE_ASSETS_SUCCESS}`, {assets: action.assets }),
      };
    case DELETE_ASSETS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: translate(`${DELETE_ASSETS_FAILURE}`),
      };
    case GET_ICECAT_DATA_REQUEST:
      return {
        ...state,
        isFetchingIcecat: true,
      };
    case GET_ICECAT_DATA_SUCCESS:
      return {
        ...state,
        isFetchingIcecat: false,
        icecatData: action.payload.data,
        productType: action.payload.data.type
      };
    case GET_ICECAT_DATA_FAILURE:
      return {
        ...state,
        isFetchingIcecat: false,
        error: action.message,
        errors: action.messages,
        notFound: action.notFound
      };
    default:
      return state;
  }
};

export default Assets;
