import React from "react";
import ApexRadial from "../../Charts/ApexRadial";
import translate from "helpers/Translation/translate";
import { useSelector } from "react-redux";
import { InfoCard, LoaderInsideComponent } from "Components/Global";
import { Link } from "react-router-dom";

const NewUsersCard = ({ userCard })=> {

    const locale = useSelector(state => state.Global.locale)
    const text = locale === 'sv' ? 'Aktiva användare senaste veckan' : 'Active users last week'

        return (
            <InfoCard title={translate('user.users')} total={userCard?.usersTotal?.toLocaleString()}>
                <p className="text-muted text-center m-0">
                    {translate('user.new-this-month')}:
                    <span className="text-success">
                    {userCard?.newUsersThisMonth?.toLocaleString()}</span>
                </p>
                {!userCard.usersTotal && <LoaderInsideComponent />}
                <ApexRadial
                    color="#34c38f"
                    total={userCard?.activeUsersTotal ?? 0}
                    text={text}
                    id="users"
                    percent={userCard?.activeUsersPercent ?? 0}/>
                <Link className="btn btn-success col-lg-12" to={'users/create'}>{translate('user.create')}</Link>
            </InfoCard>
        );
}

export default NewUsersCard;
