import {
	CHANGE_LOCALE,
	GET_ME_REQUEST,
	GET_ME_SUCCESS,
} from "./actionTypes";
import { users } from "../api";

export function saveToStorage(key, value) {
	localStorage.setItem(key, value);
}

export function getFromStorage(key) {
	return localStorage.getItem(key);
}

export function removeFromStorage(key) {	
	localStorage.removeItem(key);
}

export const changeLocale = lang => {
	const locale = getFromStorage('lang');
	if(locale) removeFromStorage('lang')
	saveToStorage('lang', lang)
	return async dispatch => {
		try { dispatch({type: CHANGE_LOCALE, payload: lang})
		} catch (error) {
			return error;
		}
	}
}


export const getMe = () => {
	return async (dispatch) => {
		dispatch({ type: GET_ME_REQUEST });

		const user = await users.get(`me`);
		return dispatch({ type: GET_ME_SUCCESS, payload: user.data });
	};
};