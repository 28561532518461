import React from "react";
import { FormGroup, Col, Label } from "reactstrap";
import translate from 'helpers/Translation/translate';
import TextInputField from '../Form/TextInputField'


const ContactSection = ({ model, handleChange, onUser }) =>  (

    <>
      <FormGroup className="mb-4" row>
        <Label htmlFor="address" className="col-form-label col-lg-3" > {translate("contact.address")} </Label>
        <Col lg={9}>
          <TextInputField
            name={"address"}
            value={model?.address ?? ''}
            placeholder={"form.enter-address"}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-address"}
            type={"text"}
            label={""}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="zip" className="col-form-label col-lg-3" > {translate("contact.zip")} </Label>
        <Col lg={9}>
          <TextInputField
            name={"zip"}
            value={model?.zip ?? ''}
            placeholder={"form.enter-zip"}
            onChange={(e) => handleChange(e)}
            errorMessage={"form.enter-zip"}
            type={"number"}
            label={""}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="city" className="col-form-label col-lg-3" > {translate("contact.city")} </Label>
          <Col lg={9}>
            <TextInputField
              name={"city"}
              value={model?.city ?? ''}
              placeholder={"form.enter-city"}
              onChange={(e) => handleChange(e)}
              errorMessage={"form.enter-city"}
              type={"text"}
              label={""}
            />
          </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="telephone" className="col-form-label col-lg-3"> {translate("contact.phone")} </Label>
          <Col lg={9}>
            <TextInputField
              name={"telephone"}
              value={model?.telephone ?? ''}
              placeholder={"form.enter-phone"}
              onChange={(e) => handleChange(e)}
              errorMessage={"form.enter-phone"}
              type={"number"}
              label={""}
            />
          </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="email" className="col-form-label col-lg-3" > {translate("contact.mail")} </Label>
          <Col lg={9}>
            <TextInputField
              name={"email"}
              value={model?.email ?? ''}
              placeholder={"form.enter-email"}
              onChange={(e) => handleChange(e)}
              errorMessage={"form.enter-email"}
              type={"email"}
              label={""}
              required={onUser ? true : false}
            />
          </Col>
      </FormGroup>
  </>
  )

export default ContactSection;