import React from "react";
import { Table, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import translate from "helpers/Translation/translate";

const ProductAttributes = ({ features }) => (
        <Table className="table">
            <Tbody>
            {features.map((attribute, j) => (
                <Tr key={j}>
                  <Td>{attribute.name}</Td>
                  <Th>{attribute.formatted_value}</Th>
                </Tr>
            ))}
          {features?.length < 1 &&
            <Tr key={'no-feature'}>
              <Th>{translate('info-not-avaiable')}</Th>
              <Td></Td>
            </Tr>
          }
        </Tbody>
      </Table>
    )

export default ProductAttributes