import React from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../Pages/Asset/Components/AssetList/Table/Pagination';


const RemotePagination = ({
  keyField,
  dataField,
  order,
  data,
  props,
  page,
  sizePerPage,
  onTableChange,
  perPageChange,
  pageChange,
  totalSize,
  indication,
  columns,
  selectRow,
}) => (
  <PaginationProvider
    pagination={paginationFactory({
      custom: true,
      page,
      sizePerPage,
      totalSize,
    })}
  >
    {({ paginationProps, paginationTableProps }) => (
      <>
        <BootstrapTable
          remote
          keyField={keyField ?? "id"}
          data={data ?? []}
          columns={columns}
          onTableChange={onTableChange}
          striped
          bordered={false}
          hover
          condensed
          noDataIndication={indication}
          defaultSorted={[
            { dataField: dataField ?? "id",
              order: order ?? "asc" },
          ]}
          wrapperClasses="table-responsive sticky-header"
          selectRow={selectRow}
          {...paginationTableProps}
          {...props.baseProps}
        />
        <Pagination
          sizePerPage={sizePerPage}
          perPageChange={perPageChange}
          paginationProps={paginationProps}
          from={data?.from}
          to={data?.to}
          totalSize={data?.total}
          links={data?.links}
          pageChange={pageChange}
        />
      </>
    )}
  </PaginationProvider>
);

  export default RemotePagination