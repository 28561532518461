import React from "react";
import translate from '../Translation/translate';
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import moment from 'moment'

const KamListColumns = [
    {
        text: '',
        dataField: "id",
        sort: true,
        formatter: (cell, row) => {
            const full_name = row.name.split(" ")
            return(
                <a href={`users/${row.id}`}>
                    {row.logo ? <img src={row.logo} alt="profile" className="avatar-sm img-thumbnail rounded-circle" />
                     : <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary">
                          {full_name.length > 1 ?
                            full_name[0].charAt(0) + full_name[1].charAt(0)
                            : row.name.charAt(0)}                          </span>
                        </div>}
                </a>)
            },
        headerStyle: () => {
                return { width: '80px'};
        }
    },
    {
        text: 'Name',
        dataField: "name",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`/users/${row.id}`}>
                    {cell}
                </a>)
        },
        headerFormatter: () => {
            return (translate('user.name'))
        }
    },
    {
        text: 'Customers',
        dataField: "customer",
        sort: true,
        formatter: (cell, row) => {
            return (
                <ul className="ecosystem-list">
                {row?.customers?.map((customer, idx) => {
                    return (
                      <li key={idx} className="pb-3">
                        <Link
                          key={customer.id}
                          to={`/customers/${customer.id}`}
                          className="team-member d-inline-block mr-2"
                          id={`customer_${customer.id}`}
                        >
                        {customer.name}
                        </Link>
                      </li>
                    );
                    })
                }
              </ul>
            )
        },
        headerFormatter: () => {
            return (translate('customer.customers'))
        }
    },
    {
        text: 'Reseller',
        dataField: "reseller_id",
        sort: true,
        formatter: (cell, row) => {
            return(
                <a href={`resellers/${cell}`}>
                    {row.reseller ? row.reseller.name : null}
                </a>)
        },
        headerFormatter: () => {
            return (translate('reseller.reseller'))
        }
    },
    {
        text: 'Created at',
        dataField: "created_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment(cell).format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('created-at'))
        }
    },
    {
        text: 'Update at',
        dataField: "updated_at",
        sort: true,
        formatter: (cell, row) => {
            return (moment.utc(cell).local().format('YYYY-MM-DD'))
        },
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('updated-at'))
        }
    }
    ,
    {
        text: 'Handle',
        dataField: "handle",
        formatter: (cell, row) => {
            return(
                <a href={`/users/${row.id}/key-account-manager/edit`}>
                    <Button color="info" className="btn-sm ">{translate('handle')}</Button>
                </a>)
        },
        headerFormatter: () => {
            return (translate('handle'))
        }
    }
]


const getKamColumns = (user, type) => {
    var notAvailableFor = {
      reseller: {},
      customer: {},
      admin:{}
    };


    notAvailableFor['reseller'] = [ 'reseller_id']
    notAvailableFor['admin'] = ['handle']
    notAvailableFor['customer'] = ['handle', 'reseller_id', 'customer', 'name' ]


    // notAvailableFor['reseller']['kam'] = [ 'reseller_id', 'customer_id']
    // notAvailableFor['customer'][] = [ 'customer_id', 'reseller_id']


    var filteredColumns = user ? KamListColumns.filter(d => !notAvailableFor[user].includes(d.dataField)) :['id'];

    return filteredColumns;
  }

  export default getKamColumns