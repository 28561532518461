import React from "react";
import { CardTitle, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import classnames from "classnames";
import TabAsset from "./TabAsset";
import { translate } from "helpers/helpers";

const GeneralQuestions = props => (
  <>
    <CardTitle className="mb-5">
      {translate('faq.general')}
      <Nav tabs className="nav-tabs-custom mt-5">
        <NavItem>
          <NavLink
            className={classnames({
              active: props.nestedActiveTab === "assets",
            })}
            onClick={() => {
              props.toggleNestedTab("assets");
            }}
          >
            <p className="font-weight-bold mb-4">Assets</p>
          </NavLink>
        </NavItem>
      </Nav>
    </CardTitle>
    <TabContent activeTab={props.nestedActiveTab}>
      <TabAsset />
    </TabContent>
  </>
);

export default GeneralQuestions;
