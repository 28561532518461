import React from "react";
import { toHeaderText } from "./HeaderProcessor";
function getRuleMessage(ruleAndDeps, cell) {
    const { rule, deps } = ruleAndDeps;
    const sanitized = `"${cell ?? ""}"`;

    switch(rule) {
        case 'required':
            return "This field is required";
        case 'numeric':
            return `This field should be numeric, but ${sanitized} was provided`;
        case 'exists':
            return `Value ${sanitized} doesn't exist`;
        case 'required_without':
        case 'required_without_all':
            return `This field is required if no ${toHeaderText(deps)} is provided`;
    }
    return rule;
}

export function getErrorForCell(error, cell) {
    const errors = [];
    error.forEach((ruleAndDeps) => {
        const [rule, deps] = ruleAndDeps.split("|");
        if (rule.startsWith('required_without')) {
            const fields = deps.replace(/\d+\./g, '')
                .split('/')
                .map((field) => field.trim().replace(' ', '_'));
            fields.forEach((field) => errors.push({ rule, deps: field }));
        } else {
            errors.push({ rule, deps });
        }
    })
    return <ul>
        {errors.map((ruleAndDeps, i) => <li key={i}>{getRuleMessage(ruleAndDeps, cell)}</li>)}
    </ul>
}