import React, { useEffect, useState } from "react";
// Redux
import { getBlanccoReport, getDiskWithReport, getAssetWithReport } from 'store/report/actions';
import { useSelector, useDispatch } from "react-redux";
// Components
import {CardBody, Button, Col, Row } from "reactstrap";
import blanccologo from "assets/images/blancco.png";
import BlanccoProduct from "./Components/BlanccoProduct";
import BlanccoDisk from "./Components/BlanccoDisk";
import PageContainer from "Containers/PageContainer";

import { DesktopTabs, MobileCollapse, } from 'Components/Global/index';
import { translate } from "helpers/helpers";

const  BlanccoReport  = ({ match }) => {

  const [ is_disk, setIsDisk ] = useState(false)

  const assetWithReport = useSelector(state => state.Report.assetWithReport)
  const diskWithReport = useSelector(state => state.Report.diskWithReport)
  const isFetching = useSelector(state => state.Report.isFetching)
  const serial_number = match.params.id

  const dispatch = useDispatch()

  const openReport = () => {
    dispatch(getBlanccoReport({serial : serial_number, type : is_disk ? 'disk' : 'asset', 'server' : 'cloud', 'format': 'pdf'})).then(res => {
      if(res.type === 'GET_BLANCCO_REPORT_SUCCESS') {
          const fileURL = URL.createObjectURL(res.payload)
          window.open(fileURL)
      }
    })
  }


  useEffect(() => {
    if(match.path.indexOf('asset') > 0) {
      dispatch(getAssetWithReport(serial_number))
    } else {
      setIsDisk(true)
      dispatch(getDiskWithReport(serial_number))
    }
  }, [dispatch, serial_number, match])


  const Tabs = []
  const displayDisks = () => {

    assetWithReport?.hard_drives.map(drive => (
      Tabs.push({
          activeTab: drive.service_tracking_id,
          translate: drive.disk_serial_number,
          component: <BlanccoDisk drive={drive} />
        })
    ))
  }


  return (
    <PageContainer
      breadcrumbTitle={'Blancco Report'}
      breadcrumbItem={match.params.id}
      Model="Morecoreport"
      isFetching={isFetching}
      listHeaderTitle={          <>
        <img src={blanccologo} alt="blancco logo" width="30px" />
        Blancco
     </>}
      >
            <CardBody>
              <Row className="justify-content-end">
                <Button color="light" onClick={openReport}>
                  <img src={blanccologo} alt="blancco logo" width="30px" />
                  Blancco PDF
                </Button>
              </Row>
            </CardBody>
              {assetWithReport ?
              <>
                <CardBody>
                  <Row className="mt-3">
                    <Col lg={12}>
                      <h5 className="text-primary">{translate('product.product')}</h5>
                    </Col>
                  </Row>
                  <BlanccoProduct assetWithReport={assetWithReport} />
                </CardBody>
               <CardBody>
                <Row className="mt-3">
                  <Col lg={12}>
                    <h5>{translate('reports.hard-drives')}</h5>
                  </Col>
                </Row>
                {assetWithReport?.hard_drives?.length ? displayDisks() : null}
                {Tabs.length ?
                  <>
                  <DesktopTabs Tabs={Tabs} displayTab={Tabs[0].activeTab}/>
                  <MobileCollapse Tabs={Tabs} />
                  </> : null}
                  </CardBody>
              </>
               : null}
              {diskWithReport ?
                <CardBody>
                  <BlanccoDisk drive={diskWithReport}/>
                </CardBody>
              : null}
    </PageContainer>
  )

}


export default BlanccoReport

