import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { Col, Row } from "reactstrap";
import OptionSelect from "./OptionSelect";

function MultiDropdown ({ options = [], labels = [], data, onDataChange, className }) {

    const changeSelect = React.useCallback((e) => onDataChange({ ...data, [e.target.name]: e.target.value }), [data, onDataChange]);

    const optionElements = React.useMemo(() => options.map((option) => {
        return (
            <option key={option} value={option}>
                {option}
            </option>
        );
        }), [options]);

    const renderSelect = React.useCallback((label) => {
        return <AvField
            type="select"
            name={label}
            value={data[label]}
            onChange={changeSelect}
            className="form-control select2"
            >
            <OptionSelect message={"form.leave"} value="" />
            {optionElements}
        </AvField>
    }, [onDataChange, options, data]);

    return <Row lg={12} className={className}>
            {
                labels.map((l) => {
                    return <>
                        <Col lg={6}>
                            {l}
                        </Col>
                        <Col lg={6}>
                            {renderSelect(l)}
                        </Col>
                    </>
                })
            }
    </Row>
}

export default React.memo(MultiDropdown);
