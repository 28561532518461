import React from "react";
import { Row, Button, FormGroup, Col, Label } from "reactstrap";
import translate from "helpers/Translation/translate";
import TextInputField from '../Form/TextInputField'

const UcLookupSection = ({ model, handleChange, allaForetagFetch }) =>  (
    <>
      <FormGroup className="mb-4" row>
        <Label htmlFor="org_no" className="col-form-label col-lg-3"> {translate("reseller.org-no")} </Label>
        <Col lg={9}>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <TextInputField
                  name={"org_no"}
                  value={model?.org_no}
                  placeholder={"form.enter-orgno-message"}
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-orgno-message"}
                  type={"text"}
                  label={""}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label htmlFor="name" className="col-form-label col-lg-3"> {translate("reseller.name")} </Label>
        <Col lg={9}>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <TextInputField
                  name={"name"}
                  value={model?.name}
                  placeholder={"form.enter-name"}
                  onChange={(e) => handleChange(e)}
                  errorMessage={"form.enter-name"}
                  type={"text"}
                  label={""}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </FormGroup>
    </>
  );


export default UcLookupSection;
