import React, { useEffect, useState  } from "react";
import { useSelector, useDispatch } from "react-redux";
// Redux and actions
import {  getReport, clearState } from "store/report/actions";
// Components
import { Row, Col, Button, FormGroup, CardHeader, Label } from 'reactstrap'
import { OptionSelect } from 'Components/Form/index';
// Functions
import {  Can, translate } from "helpers/helpers";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import enUsLocale from 'date-fns/locale/en-US'

registerLocale('sv', sv)
registerLocale('en', enUsLocale)

const SectionSelect = ({ getCustomer, getYear, getReseller, getStartDate, getEndDate }) => {

  const me = useSelector(state => state.Global.me)
  const locale = useSelector(state => state.Global.locale)
  const isCustomer = useSelector(state => state.Global.isCustomer)
  const isReseller = useSelector(state => state.Global.isReseller)
  const resellers = useSelector(state => state.Form.resellersDropdown)
  const customers = useSelector(state => state.Form.customersDropdown)
  const years = useSelector(state => state.Form.years)

    const [filter, setFilter ] = useState({
      customer_id : "",
      reseller_id: '',
      year: '',
      lot_number: '',
      start_date: '',
      end_date: '',
      excel: false
    })

    const dispatch = useDispatch()

    useEffect(() => {
        if(isCustomer) {
          filter.customer_id = me?.customer_id
          setFilter(filter)
        }
        if(isReseller) {
          filter.reseller_id = me?.reseller_id
          setFilter(filter)
          }
      }, [filter, isCustomer, isReseller])


    const getTheReport = () => {
        dispatch(clearState())
        dispatch(getReport({ filter }))
    }

    const selectYear = (year) => {
        filter.year = year
        getYear(year)
        setFilter(filter)
    }

    const selectCustomer = (customer) => {
        filter.customer_id = customer
        getCustomer(customer)
        setFilter(filter)
    }

    const selectReseller = (reseller) => {
      filter.reseller_id = reseller
      getReseller(reseller)
      setFilter(filter)
    }

    const canFetch = (filter.customer_id || filter.reseller_id) && (filter.year || filter.start_date && filter.end_date);
    return(
    <>
    <Row >
      <Can I="read" a="Customer">
        {() => (
          <Col lg={6}>
            <CardHeader>
              <h4>{translate('customer.customer')}</h4>
              <Col lg={12}>
                <Row className="justify-content-center">
                {translate('reports.yearly-text-for-reseller')}
                  <FormGroup className="col-lg-12 mt-3">
                    <select name="year" className="form-control select2" value={filter.customer_id} onChange={(e) => selectCustomer(e.target.value)}>
                      <OptionSelect value={""} message={"form.select-customer"} />
                      <OptionSelect value={"all"} message={"all"} />
                      {customers.map((customer, i) => (
                        <option key={i} value={customer.id}>{customer.name}</option>
                        ))}
                    </select>
                  </FormGroup>
                </Row>
              </Col>
              <Can I="read" a="Reseller">
                    {() => (
                      <>
                      <Col lg={12}>
                        {translate('reports.yearly-text-for-admin')}
                      </Col>
                        <FormGroup className="col-lg-12">
                        <select name="year" className="form-control select2" value={filter.reseller_id} onChange={(e) => selectReseller(e.target.value)}>
                          <OptionSelect message={"form.select-reseller"} />
                          {resellers.map((reseller, i) => (
                            <option key={i} value={reseller.id}>{reseller.name}</option>
                            ))}
                        </select>
                      </FormGroup>
                      </>
                    )}
                  </Can>
            </CardHeader>
          </Col>
        )}
      </Can>
      <Col lg={isCustomer ? 12 : 6}>
        <CardHeader>
          <h4>{translate('reports.summary')}</h4>
          <Can not I="read" a="Customer">
            {() => (
              translate('reports.yearly-text-for-customer')
            )}
          </Can>
          <FormGroup className="col-lg-12">
            <Label htmlFor="start_date" className="col-form-label"> {translate('contract.start-date')} </Label>
                <DatePicker
                  placeholderText={locale === 'sv' ? 'Start datum' : 'Start date'}
                  dateFormat="yyyy-MM-dd"
                  locale={locale}
                  className="form-control"
                  selected={filter.start_date}
                  onChange={(date) => {
                    filter.start_date = date
                    getStartDate(date)
                    setFilter(filter)
                    return date;
                }}
                />
          </FormGroup>
          <FormGroup className="col-lg-12">
            <Label htmlFor="end_date" className="col-form-label"> {translate('contract.end-date')} </Label>
                <DatePicker
                  placeholderText={locale === 'sv' ? 'Slutdatum' : 'End date'}
                  dateFormat="yyyy-MM-dd"
                  locale={locale}
                  className="form-control"
                  selected={filter.end_date}
                  onChange={(date) => {
                    filter.end_date = date
                    getEndDate(date)
                    setFilter(filter)
                    return date;
                }}
                />
          </FormGroup>
        </CardHeader>
        
        <CardHeader>
        <h4>{translate('reports.annual')}</h4>
        <FormGroup className="col-lg-12">
            <Label className="col-form-label">{translate('form.select-year')}</Label>
            <select name="year" className="form-control select2" value={filter.year} onChange={(e) => selectYear(e.target.value)}>
            <OptionSelect value="" message={"form.select-year"} />
              {years.length ? years.map((year, i) => (
                <option key={i} value={year}>{year}</option>
                ))
              : <option value={filter.year}>{filter.year}</option>}
            </select>
          </FormGroup>
        </CardHeader>
      </Col>
      <Col lg={12} className="text-center mt-4">
        <FormGroup>
          <Button disabled={!canFetch} color="success" onClick={() => getTheReport()}>
            {translate('fetch')}
          </Button>
        </FormGroup>
      </Col>
    </Row>
</>)
}

export default SectionSelect