import React from "react";
import translate from '../Translation/translate';
import moment from 'moment'

const AssetsOnReportColumns = [
    {
        text: 'Lot number',
        dataField: "lot_number",
        sort: true,
        headerFormatter: () => {
            return (translate('reports.lot-number'))
        }
    },
    {
        text: 'Parent S/N',
        dataField: "product_serial_number",
        sort: true,
        headerStyle: () => {
            return { minWidth: '150px'};
        },
        headerFormatter: () => {
            return (translate('reports.product-serial-number'))
        }
    },
    {
        text: 'Mnf',
        dataField: "product_manufacturer",
        sort: true
        ,
        headerFormatter: () => {
            return (translate('reports.manufacturer'))
        }
    },
    {
        text: 'Model',
        dataField: "product_model",
        sort: true,
        headerStyle: () => {
            return { minWidth: '80px'};
        },
        headerFormatter: () => {
            return (translate('reports.model'))
        }
    },
    {
        text: 'Category',
        dataField: "product_category",
        sort: true,
        headerStyle: () => {
            return { minWidth: '80px'};
        },
        headerFormatter: () => {
            return (translate('asset.category'))
        }
    },
    {
        text: 'Processor',
        dataField: "processor",
        sort: true,
    },
    {
        text: 'HDD GB',
        dataField: "hdd_gb",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        }
    },
    {
        text: 'Functional',
        dataField: "functional",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('reports.functional'))
        }
    },
    {
        text: 'Cosmetic',
        dataField: "cosmetic",
        sort: true,
        headerStyle: () => {
            return { minWidth: '100px'};
        },
        headerFormatter: () => {
            return (translate('reports.cosmetic'))
        }
    },
    {
        text: 'Disposition',
        dataField: "disposition",
        sort: true,
    },
    {
        text: 'Buy price SEK',
        dataField: "buy_price",
        sort: true,
        formatter: (cell, row) => {
            return (cell?.toLocaleString() + ' kr' ?? null)
          },
    },
]

const getAssetsOnReportColumns = (user, section) => {
    var notAvailableOn= {
        admin: {},
        customer: {},
        reseller: {}
    };

      notAvailableOn['customer']['specification'] = [ 'product_category', 'buy_price']
      notAvailableOn['reseller']['specification']  = [ 'product_category', 'buy_price']
      notAvailableOn['admin']['specification']  = ['product_category']

      notAvailableOn['customer']['all'] = ['buy_price']
      notAvailableOn['reseller']['all']  = ['buy_price']
      notAvailableOn['admin']['all']  = [ ]

      notAvailableOn['customer']['destruction'] = ['buy_price']
      notAvailableOn['reseller']['destruction']  = ['buy_price']
      notAvailableOn['admin']['destruction']  = ['buy_price']

      

      var filteredColumns = (user && section) ? AssetsOnReportColumns.filter(d => !notAvailableOn[user][section].includes(d.dataField)) :['id'];

      return filteredColumns;
    }


  export default getAssetsOnReportColumns