import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Can, translate } from 'helpers/helpers';
import { LoaderInsideComponent } from 'Components/Global'


const AssetTablesActions = ({ download, downloadCSV, selectedIDs, togglemodal, modal, onAddToContract, onRemoveFromContract, onMoveToContract }) => (

              <div className="mb-2">
                <Can I="create" a="Asset">
                  {() => (
                    <>
                    <button
                      id="csv"
                      className="btn btn-light mr-1  btn-sm"
                      onClick={downloadCSV}>
                       {download ?
                       <LoaderInsideComponent />
                        :
                        <>
                        <i className="mdi mdi-download "></i> CSV
                       </>}
                    </button>
                    <UncontrolledTooltip placement="top" target="csv">
                      {translate('csv-download')}
                    </UncontrolledTooltip>
                    </>
                  )}
                </Can>
                <Can I="update" a="Asset">
                  {() => (
                    <>
                    <button
                      disabled={selectedIDs.length < 1 ?? false}
                      className="btn btn-success mr-1  btn-sm" onClick={() => togglemodal('inventory', !modal)}>
                      {translate('assets.inventory')}
                    </button>
                    </>
                  )}
                </Can>


                {onAddToContract &&
                  <Can I="update" a="Contract" >
                    {() => (
                      <>
                      <button
                        id="add"
                        disabled={selectedIDs.length < 1 ?? false}
                        className="btn btn-success mr-1  btn-sm" onClick={() => togglemodal('add', !modal)}>
                      {translate('button.assets-add')}
                      </button>
                      <UncontrolledTooltip placement="top" target="add">
                      {translate('button.assets-add')}
                    </UncontrolledTooltip>
                      </>
                    )}
                  </Can>
                }

              {onRemoveFromContract &&
                <Can I="update" a="Contract" >
                  {() => (
                    <>
                    <button
                      id="remove"
                      disabled={selectedIDs.length < 1 ?? false}
                      className="btn btn-warning mr-1  btn-sm" onClick={() => togglemodal('remove', !modal)}>
                      {translate('button.assets-remove', { assets: selectedIDs.length })}
                    </button>
                    <UncontrolledTooltip placement="top" target="remove">
                      {translate('button.assets-remove', { assets: selectedIDs.length,})}
                    </UncontrolledTooltip>
                    </>
                  )}
                </Can>
              }

              {onMoveToContract &&
                <Can I="update" a="Contract" >
                  {() => (
                    <>
                    <button
                      id="move"
                      disabled={selectedIDs.length < 1 ?? false}
                      className="btn btn-warning mr-1  btn-sm" onClick={() => togglemodal('move', !modal)}>
                      {translate('button.assets-move')}
                    </button>
                    <UncontrolledTooltip placement="top" target="move">
                      {translate('button.assets-move')}
                    </UncontrolledTooltip>
                    </>
                  )}
                </Can>
              }

              <Can I="delete" a="Asset">
                {() => (
                  <>
                    <button
                      id="delete-assets"
                      disabled={selectedIDs.length < 1 ?? false}
                      className="btn btn-danger  btn-sm" onClick={() => togglemodal('delete', !modal)}>
                      {translate('assets.delete')}
                    </button>
                    <UncontrolledTooltip placement="top" target="delete-assets">
                      {translate('assets.delete')}
                    </UncontrolledTooltip>
                  </>
                )}
              </Can>
        </div>
    )

export default AssetTablesActions