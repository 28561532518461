import React from "react";
import { translate } from 'helpers/helpers';

const CO2eSection = ({ warranty }) => {

    return (
        <p>{translate('warranty.co2e-text')} <strong className="text-success f-14">{warranty?.total?.co2e} kg</strong>.</p>

    )
}

export default CO2eSection