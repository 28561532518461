import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    MICROSOFT_LOGIN_REQUEST,
    MICROSOFT_LOGIN_SUCCESS,
    MICROSOFT_LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    TWOFA_LOGIN_REQUEST,
    TWOFA_LOGIN_SUCCESS,
    TWOFA_LOGIN_FAILURE,
    DISPLAY_AUTH_MODAL,
    LOGOUT_REQUEST,
    CLEAR_STATE,
    LOST_DEVICE_REQUEST,
    LOST_DEVICE_SUCCESS,
    LOST_DEVICE_FAILURE,
}
    from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    me: {},
    email: "",
    existing: false,
    isFetching: false,
    text: '',
    success: null,
    token: null,
    displayModal: false
}

const login = (state = initialState, action) => {
        switch (action.type) {
          case LOGOUT_REQUEST:
            return {
              ...state,
              isFetching: false,
              success: action.message
            };
        case LOGIN_REQUEST:
          return {
            ...state,
            isFetching: true,
            displayModal: false
          };
        case LOGIN_SUCCESS:
          return {
            ...state,
            isFetching: false,
            me: action.payload,
          };
        case LOGIN_FAILURE:
          return {
            ...state,
            isFetching: false,
            error: action.message
          };
        case MICROSOFT_LOGIN_REQUEST:
            return {
              ...state,
              isFetching: true,
              displayModal: false
            };
        case MICROSOFT_LOGIN_SUCCESS:
            return {
              ...state,
              isFetching: false,
              me: action.payload,
            };
          case MICROSOFT_LOGIN_FAILURE:
            return {
              ...state,
              isFetching: false,
              error: action.message
            };
        case TWOFA_LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case TWOFA_LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                me: action.payload,
                success: action.message,
            };
        case TWOFA_LOGIN_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message
            };
        case LOGOUT_SUCCESS:
          return {
            ...state,
            isFetching: false,
          };
        case FORGOT_PASSWORD_REQUEST:
          return {
            ...state,
            isFetching: true,
          };
        case FORGOT_PASSWORD_SUCCESS:
          return {
            ...state,
            isFetching: false,
            success: action.message
          };
        case FORGOT_PASSWORD_FAILURE:
          return {
            ...state,
            isFetching: false,
            error: action.message
          };
        case CHANGE_PASSWORD_REQUEST:
          return {
            ...state,
            isFetching: false,
          };
        case CHANGE_PASSWORD_SUCCESS:
          return {
            ...state,
            isFetching: false,
            success: action.message
          };
        case CHANGE_PASSWORD_FAILURE:
          return {
            ...state,
            isFetching: false,
            error: action.message
          };
        case LOST_DEVICE_REQUEST:
            return {
              ...state,
              isFetching: true,
            };
          case LOST_DEVICE_SUCCESS:
            return {
              ...state,
              isFetching: false,
              success: action.message
            };
          case LOST_DEVICE_FAILURE:
            return {
              ...state,
              isFetching: false,
              error: action.message
            };
        case DISPLAY_AUTH_MODAL:
          return {
            ...state,
            displayModal: true
          }
        case CLEAR_STATE:
          return {
              ...state,
              isFetching: false,
              error: null,
              success: null
          };
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;