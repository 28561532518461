import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Redux and actions
import { useSelector, useDispatch } from "react-redux";
import { getPredifinedPermissions, dontShowTourAgain } from "store/user/actions";
import { clearModelState } from "store/model/actions";
// Global Components
import PageContainer from "Containers/PageContainer";
import ImportExampleFile from "Components/Global/ImportExampleFile";
import VerticalTabs from "Components/Global/VerticalTabs";
import VerticalTabsInfo from "Components/Global/VerticalTabsInfo";
import { Row, Col, FormGroup, Button} from "reactstrap";
import Select from "react-select";
import { Icons } from 'assets/styles'
// User Components
import UserPermissions from "../User/Components/UserPermissions";
// Functions
import { translate, Can } from "helpers/helpers";

const GetStarted = ({ history }) => {

  const all_permissions = useSelector(state => state.Users.all_permissions)
  const predifined_permissions = useSelector(state => state.Users.predifined_permissions)
  const [ user, setUser ] = useState({ has_permissions : [], role: 'enduser' })

  const me = useSelector(state => state.Global.me)
  const isAdmin = useSelector(state => state.Global.isAdmin)
  const isReseller = useSelector(state => state.Global.isReseller)
  const isCustomer = useSelector(state => state.Global.isCustomer)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPredifinedPermissions())
    return () => {
      dispatch(clearModelState())
    }
  }, [])

  const selectChange = (e, change) => {
      const role = e.value;
      const filter = predifined_permissions.filter(permission => permission.role === role)
      const permissions = filter[0]['permissions']

      setUser(user => ({ ...user, has_permissions: permissions , role: role}))
  };

  const endTour = () => {
    dispatch(dontShowTourAgain())
    .then(res => {
      if (res?.type === "DONT_SHOW_TOUR_SUCCESS") history.push(`/dashboard`)
    })
  }


  const roles_customer = [
    { label: "Basic", value: "enduser" },
    { label: "Admin", value: "enduser_admin" }
  ]

  const roles_reseller = [
    { label: "End User", value: "enduser" },
    { label: "End User Admin", value: "enduser_admin" },
    { label: "Partner", value: "partner" },
    { label: "Partner Admin", value: "partner_admin" },
  ]

  const roles = [
    { label: "End User", value: "enduser" },
    { label: "End User Admin", value: "enduser_admin" },
    { label: "Partner", value: "partner" },
    { label: "Partner Admin", value: "partner_admin" },
    { label: "Admin", value: "admin" },
  ];


    const Tabs = [
      {
        activeTab : "Contract",
        translate: 'start.step-contract',
        icon : Icons.contract,
        title: translate('contract.contract'),
        component:
        <VerticalTabsInfo
          content={{
            buttons:
            <Col lg={12}>
              <Row>
                <Can I="read" a="Contract">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/contracts"}>{translate('contract.list')}</Link>
                    </FormGroup>
                  )}
                </Can>
                <Can I="create" a="Contract">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/contracts/create"}>{translate('contract.create')}</Link>
                    </FormGroup>
                  )}
                </Can>
              </Row>
          </Col>,
            title: translate('contract.contract'),
            media : [
          {
            subtitle: translate('start.step-contract'),
            text: translate('start.step-contract-text')
          },
          {
            subtitle: translate('extensions'),
            text: translate('start.step-contract-extensions'),
          },
          {
            subtitle: translate('substarts'),
            text: translate('start.step-contract-substarts'),
          },
          {
            subtitle: translate('contract.history'),
            text: translate('start.step-contract-history'),
          },
          {
            subtitle: 'Assets',
            text: translate('start.step-contract-assets'),
          },
          {
            subtitle: translate('contract.archive'),
            text: translate('start.step-contract-archive'),
          }
            ]
        }} />
      },
      {
        activeTab : "Asset",
        translate: 'start.step-asset',
        icon: Icons.asset,
        title: "Asset",
        component:
        <VerticalTabsInfo
          content={{
            title: "Asset",
            media : [
          {
            subtitle: translate('start.step-asset'),
            text: translate('start.step-asset-text'),
            component:
            <Row>
              <Col lg={4} className="d-flec justify-content-center">
                <Link className="btn btn-primary btn-sm" to={'/assets/import'}> {translate('asset.import')} </Link>
                <br /><br />
                {translate('asset.import-text1')}
                <br /><br />
                <p>{translate('asset.help-text')}</p> <ImportExampleFile />
              </Col>
              <Col lg={4}>
                <Link className="btn btn-primary btn-sm" to={'/assets/create'}> {translate('asset.create-manually')} </Link>
                <br /><br />
                {translate('start.step-assets-manually-text')}
              </Col>
              <Col lg={4}>
                <Link className="btn btn-primary btn-sm" to={'/users/me'}> {translate('asset.use-API')} </Link>
                <br /><br />
                {translate('asset.create-with-API')}
              </Col>
            </Row>
          },
          {
            subtitle: translate('user.user'),
            text: translate('start.step-asset-user'),
          },
          {
            subtitle:translate('asset.warranty'),
            text: translate('start.step-asset-warranty'),
          },
          {
            subtitle: "EOL",
            text: translate('start.step-asset-eol'),
          },
          {
            subtitle: 'CO2e',
            text: translate('start.step-asset-co2'),
          },
          {
            subtitle: translate('asset.delivery-date'),
            text: translate('start.step-asset-delivery'),
          },
          {
            subtitle: translate('product.product'),
            text: translate('start.step-asset-product')
          }
            ]
          }} />
      },
      {
        activeTab : "Status",
        translate: 'start.step-status',
        icon: Icons.status,
        title: "Status",
        component:
        <VerticalTabsInfo
          content={{
            buttons:
            <Col lg={12}>
              <Can I="create" a="Assetstatus">
                {() => (
                  <FormGroup className="col-sm-12 col-lg-3">
                    <Link className="btn btn-success col-lg-12" to={"/assets/status"}>{translate('asset.status-handle')}</Link>
                  </FormGroup>
                )}
              </Can>
            </Col>,
            title: "Status",
            media : [
          {
            subtitle: translate('start.step-status'),
            text: isReseller ? translate('start.step-status-text-reseller') : isCustomer ? translate('start.step-status-text-customer') : translate('start.step-status-text-admin')
          }
            ]
          }} />
      },
      {
        activeTab : "User",
        translate: 'start.step-user',
        icon: Icons.user,
        title: translate("user.user"),
        component:
        <VerticalTabsInfo
          content={{
            buttons:
            <Col lg={12}>
              <Row>
                <Can I="read" a="User">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/users"}>{translate('user.list')}</Link>
                    </FormGroup>
                  )}
                </Can>
                <Can I="create" a="User">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/users/create"}>{translate('user.create')}</Link>
                    </FormGroup>
                  )}
                </Can>
              </Row>
            </Col>,
            title: translate("user.role"),
            media : [
          {
            text: isReseller ? translate('start.step-user-text-reseller') : isCustomer ? translate('start.step-user-text-customer') : translate('start.step-user-text-admin'),
            component:
            <div className="d-none d-sm-none d-lg-block">
            <FormGroup>
              <Select
                style={{ zIndex: '999'}}
                required
                value={{label : user.role}}
                onChange={(e) => selectChange(e, "role")}
                options={
                    isAdmin ? roles
                  : isReseller ? roles_reseller
                  : roles_customer}
                classNamePrefix="select2-selection col-3"
              />
          </FormGroup>
          <UserPermissions all_permissions={all_permissions} user={user} />
          </div>
          }]
          }} />
      }
    ]

    if(!isCustomer) {
      Tabs.push({
        activeTab : "KAM",
        translate: 'start.step-kam',
        icon: Icons.kam,
        title: "KAM",
        component:
        <VerticalTabsInfo
          content={{
            buttons:
            <Col lg={12}>
              <Row>
                <Can I="read" a="Key">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/users/key-account-managers"}>{translate('user.list-kam')}</Link>
                    </FormGroup>
                  )}
                </Can>
                <Can I="create" a="Key">
                  {() => (
                    <FormGroup className="col-sm-12 col-md-6">
                      <Link className="btn btn-success col-sm-12" to={"/users/key-account-managers/create"}>{translate('user.create-kam')}</Link>
                    </FormGroup>
                  )}
                </Can>
              </Row>
            </Col>,
            title: "KAM",
            media : [
            {
              subtitle: translate('start.step-kam'),
              text: translate('start.step-kam-text'),
              text2: translate('start.step-kam-text-2')
            }
            ]
          }} />
      })
      Tabs.unshift({
        activeTab : "Customer",
        translate: 'start.step-customer',
        icon : Icons.customer,
        title: translate('customer.customer'),
        component:
        <VerticalTabsInfo
          content={{
            buttons:
            <Col lg={12}>
              <Row>
                <Can I="read" a="Customer">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-sm-12" to={"/customers"}>{translate('customer.list')}</Link>
                    </FormGroup>
                  )}
                </Can>
                <Can I="create" a="Customer">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success" to={"/customers/create"}>{translate('customer.create')}</Link>
                    </FormGroup>
                  )}
                </Can>
              </Row>
          </Col>,
            title: translate('customer.customer'),
            media : [
            {
              subtitle: translate('start.step-customer'),
              text: translate('start.step-customer-text')
            },
            {
              subtitle: 'Assets',
              text: translate('start.step-customer-assets-1'),
              text2: translate('start.step-customer-assets-2')
            },
            {
              subtitle: translate('contract.contracts'),
              text: translate('start.step-customer-contracts'),
            },
            {
            subtitle: translate('user.users'),
            text: translate('start.step-user-text')
            }
            ],
          }} />
      })
    }

    if(isAdmin) {
      Tabs.unshift({
        activeTab : "Reseller",
        translate: 'start.step-reseller',
        icon : Icons.reseller,
        title: translate('reseller.reseller'),
        component: <VerticalTabsInfo
          content = {{
            buttons:
            <Col lg={12}>
              <Row>
                <Can I="read" a="Reseller">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-lg-12" to={"/resellers"}>{translate('reseller.list')}</Link>
                    </FormGroup>
                  )}
                </Can>
                <Can I="create" a="Reseller">
                  {() => (
                    <FormGroup className="col-sm-12 col-lg-3">
                      <Link className="btn btn-success col-lg-12" to={"/resellers/create"}>{translate('reseller.create')}</Link>
                    </FormGroup>
                  )}
                </Can>
              </Row>
            </Col>,
            title: translate('reseller.reseller'),
            media : [{
              subtitle: translate('start.step-reseller'),
              text: translate('start.step-reseller-text')
            }],
          }}
        />
      })
    }

    return (
      <PageContainer
        breadcrumbTitle={translate('start.title')}
        breadcrumbItem=""
        Model="Asset"
        listHeaderTitle={me?.getting_started ?
          <Col lg={12} className="d-flex justify-content-center">
            <Button className="btn-sm btn-danger" onClick={endTour}>
               <i className="mdi mdi-close align-middle f-20"></i> {translate('start.dont-show')}
            </Button>
          </Col>
        : null}
      >
          <div className="mx-auto col-lg-12 text-center p-3">
            <h2 >{translate('start.title')}</h2>
          </div>
          <VerticalTabs Tabs={Tabs} />
      </PageContainer>
    );
}

export default GetStarted
