import React from "react";
import {  useSelector } from "react-redux";

// Components
import Breadcrumbs from "Components/Global/Breadcrumb";
import { Container, Row} from "reactstrap";
import AdminDashboard from "./AdminDashboard";
import ResellerDashboard from './ResellerDashboard'
import CustomerDashboard from "./CustomerDashboard";
import ReleaseNotes from "./Components/ReleaseNotes";

const Dashboard = ({ history, location }) => {


    const isAdmin = useSelector(state => state.Global.isAdmin)
    const isReseller = useSelector(state => state.Global.isReseller)
    const isCustomer = useSelector(state => state.Global.isCustomer)

    const showReleaseNotes = location.pathname.endsWith('/release-notes');
    const onCloseReleaseNotes = () => history.push('/dashboard');
    
    return (
        <div className="page-content">
          {showReleaseNotes && <ReleaseNotes onClose={onCloseReleaseNotes} />}
          <Container fluid>
            <Breadcrumbs title="Ecosystem" breadcrumbItem="Dashboard" />
            <Row>
              {isAdmin && <AdminDashboard />}
              {isReseller && <ResellerDashboard />}
              {isCustomer && <CustomerDashboard />}
            </Row>
          </Container>
        </div>
    );
}


export default Dashboard;

