import React, { useEffect } from "react";
import {  useSelector, useDispatch } from "react-redux";
// Redux and actions
import { clearModelState } from "store/model/actions";
import { getCustomerDropdown, getYears } from "store/form/actions";

// Components
import OptionSelect from 'Components/Form/OptionSelect'
import { FormGroup, Row, Col, Button } from "reactstrap";

// Functions
import { translate, Can} from "helpers/helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const ReportFilters = ({ filter, clearFilter, setDate, onChange }) => {

  const me = useSelector(state =>  state.Global.me)
  const years = useSelector(state => state.Form.years)
  const locale = useSelector(state => state.Global.locale)
  const customers = useSelector(state => state.Form.customersDropdown)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getYears())
    if(me.type !== 'customer') dispatch(getCustomerDropdown())
    return () => {
      dispatch(clearModelState())
    }
  },[me, me.type])


    return (
            <Row>
                <Col lg={6} >
                    <Row className="align-items-baseline pl-3">
                        <p className="mr-1"><strong>{translate('quick-select')}</strong></p>
                        {years?.map((year, i )=> (
                          <Button key={i} color="" className="mr-1" onClick={() => setDate(year, 'year')}>{year}</Button>
                        ))}
                        <Button color="" className="mr-1" onClick={() => setDate(null, 'year')}>{translate('all')}</Button>
                    </Row>
                </Col>
                <Col lg={6}>
                  <Row className=" align-items-end">
                    <FormGroup className="col-sm-12 col-md-4 pr-0">
                      <label>{translate('report.start-date')}</label>
                      <DatePicker
                        placeholderText={locale === 'sv' ? 'Start datum' : 'Start date'}
                        dateFormat="yyyy-MM-dd"
                        name="start_date"
                        locale={locale}
                        selected={filter.start_date}
                        className="form-control"
                        onChange={(e) => setDate(e, 'start_date')}
                        />
                    </FormGroup>
                    <FormGroup className="col-sm-12 col-md-4 pr-0">
                      <label>{translate('report.end-date')}</label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={locale === 'sv' ? 'Slut datum' : 'End date'}
                        locale={locale}
                        selected={filter.end_date}
                        className="form-control"
                        onChange={(e) => setDate(e, 'end_date')}
                        />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Button color="light" className="col-12" onClick={() => clearFilter()}>
                        {translate('clear')} filter
                      </Button>
                    </FormGroup>
                  </Row>
                </Col>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <Can I="read" a="Customer">
                    {() => (
                      <FormGroup>
                        <select
                          type="select"
                          value={filter.customer_id}
                          name="customer_id"
                          className="form-control select2"
                          onChange={(e) => onChange(e)}
                          >
                          <OptionSelect message={"form.select-customer"} value={null} />
                          {customers?.map(customer => {
                            return <option key={customer.id} value={customer.id}>{customer.name}</option>
                          })}
                        </select>
                      </FormGroup>
                    )}
                  </Can>
                </Col>
            </Row>
    )
  }

export default ReportFilters;

