import React from "react";
// Redux and actions
import {  useSelector } from "react-redux";
// Functions
import { translate, getResellerColumns } from 'helpers/helpers';
import { Alerts } from 'Components/Global';

import PageContainer from "Containers/PageContainer";
import { RemotePaginationTable } from "Components/Lists";


const ResellerList = ({ match, location }) => {

  const pagination = useSelector(state => state.Model.resellers)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);

    return (
      <PageContainer
        Model={'Reseller'}
        breadcrumbTitle={translate("reseller.resellers")}
        breadcrumbItem={translate("reseller.list")}
        listHeaderTitle={translate("reseller.list")}
        data={pagination?.total ?? []}
        alerts={<Alerts success={success} error={error} location={location} />}
      >
        <RemotePaginationTable
          model={'resellers'}
          pagination={pagination}
          columns={getResellerColumns('all')}
          createLink={'/resellers/create'}
          buttonText={translate("reseller.create")}
          pageUrl={`${process.env.REACT_APP_API_URL}/resellers`}
          path={match.path}
          can={'Reseller'}
          keyField={'id'}
          dataField={'name'}
        />
    </PageContainer>
  );
}


export default ResellerList;
