import React from "react";

// Components
import { Col, FormGroup, Label, CardHeader } from "reactstrap";
import { TextInputField, TextAreaField, TranslatedSelect } from "Components/Form/index";
import {  AvGroup, AvInput } from "availity-reactstrap-validation";

// Functions
import  { Can, translate }from 'helpers/helpers';


const ContractCustomerDetails = ({ createSubstart, contract, resellers, getChange, customers, deliveryAddress,  getDeliveryAddress, editMode }) => (

    <>
      {!editMode &&
      <>
        {!createSubstart &&
        <>
        <Can I="read" a="Reseller">
          {() => (
            <FormGroup className="mb-4" row>
              <Label htmlFor="reseller_id" className="col-form-label col-lg-3"> {translate('reseller.reseller')} </Label>
              <Col lg={9}>
                <TranslatedSelect
                  name="reseller_id"
                  value={contract?.reseller_id}
                  required
                  onChange={e => getChange(e)}
                  options={resellers ?? []}
                  optionMessage="form.select-reseller"
                  errorMessage="form.select-reseller"
                  />
              </Col>
            </FormGroup>
          )}
        </Can>
        <Can I="read" a="Customer">
          {() => (
            <FormGroup className="mb-4" row>
              <Label htmlFor="customer_id" className="col-form-label col-lg-3"> {translate('customer.customer')}  </Label>
              <Col lg={9}>
                <TranslatedSelect
                  name="customer_id"
                  required
                  value={contract?.customer_id}
                  onChange={e => getChange(e)}
                  options={customers ?? []}
                  optionMessage={"form.select-customer" }
                  errorMessage="form.select-customer"
                  />
                </Col>
            </FormGroup>
          )}
        </Can>
        </>}

        <FormGroup className="mb-4" row>
          <Label htmlFor="add_delivery_address" className="col-form-label col-lg-3">
            {translate('contract.delivery-address')}
          </Label>
          <Col lg={9}>
            <AvGroup check onClick={() => { getDeliveryAddress(!deliveryAddress); }}>
                <AvInput className="mt-1" type="checkbox" name="deliveryAddress" checked={deliveryAddress}/>
                <Label className="mt-1" check for="deliveryAddress">{translate('contract.add-delivery-address')}</Label>
            </AvGroup>
          </Col>
        </FormGroup>

        {deliveryAddress &&
        <CardHeader>
          <FormGroup className="mb-4" row>
            <Label htmlFor="delivery_address" className="col-form-label col-lg-3"> {translate('contract.delivery-address')}</Label>
            <Col lg={9}>
              <TextInputField
                name={"delivery_address"}
                value={contract?.delivery_address}
                placeholder={'form.delivery-address'}
                onChange={e => getChange(e)}
                errorMessage={'form.enter-delivery-address'}
                type={"text"}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label htmlFor="delivery_zip" className="col-form-label col-lg-3"> {translate('contract.delivery-zip')}</Label>
            <Col lg={9}>
              <TextInputField
                  name={"delivery_zip"}
                  value={contract?.delivery_zip}
                  placeholder={'form.delivery-zip'}
                  onChange={e => getChange(e)}
                  errorMessage={'form.enter-delivery-zip'}
                  type={"number"}
                  validation={{
                    pattern: {
                      value: "^[0-9]+$",
                      error: "Only Numbers"
                    }
                  }}
                />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label htmlFor="delivery_city" className="col-form-label col-lg-3"> {translate('contract.delivery-city')}</Label>
            <Col lg={9}>
              <TextInputField
                  name={"delivery_city"}
                  value={contract?.delivery_city}
                  placeholder={'form.delivery-city'}
                  onChange={e => getChange(e)}
                  errorMessage={'form.enter-delivery-city'}
                  type={"text"}
                />
            </Col>
          </FormGroup>
        </CardHeader>}
      </>
      }
      <FormGroup className="mb-5 mt-5" row>
        <Label htmlFor="comment" className="col-form-label col-lg-3"> {translate('contract.comment')}</Label>
        <Col lg={9}>
          <TextAreaField
            name={"comment"}
            rows={3}
            value={contract?.comment}
            placeholder={'form.enter-comment'}
            getChange={e => getChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup className="mb-4 mt-4" row>
        <Label htmlFor="supplier" className="col-form-label col-lg-3"> {translate('supplier.supplier')} </Label>
        <Col lg={9}>
          <TextInputField
            name={"supplier"}
            value={contract?.supplier}
            placeholder={'form.enter-contract-supplier'}
            onChange={e => getChange(e)}
            errorMessage={'form.enter-contract-supplier'}
            type={"text"}
            />
        </Col>
      </FormGroup>
    </>
  )


export default ContractCustomerDetails;
