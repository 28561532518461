import React, { useState } from "react";
import {  useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// Redux
import { importFile } from 'store/model/actions'
// Components
import { Row, Col, Card, CardBody, Form,  CardTitle, Button } from "reactstrap";
import  UploadFile from 'Components/Form/UploadFile'
import CreateContainer from 'Containers/CreateContainer'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// Functions
import { getProductColumns, translate } from 'helpers/helpers';


const ProductEMVImport = () => {

    const products = useSelector(state => state.Model.imported)
    const isFetching = useSelector(state => state.Model.isFetching)
    const error = useSelector(state => state.Model.error)
    const [selectedFiles, setSelectedFiles ] = useState([])
    const dispatch = useDispatch();

    const getFiles = files => {
        setSelectedFiles(files)
    }

    const upload = e => {
        dispatch(importFile(selectedFiles[0], 'products'))
        setSelectedFiles([])
    }


    const indication = () => { return translate('asset.no-rows')}

        return (
            <CreateContainer
                Model={'Reseller'}
                isFetching={isFetching}
                breadcrumbItem={translate('product.products-fmv-import')}
                breadcrumbTitle={translate('product.products')}
                success={products.length ? translate('file-imported') : null}
                error={error}
                >
                    <Row>
                        <Col xl={12}>
                            <CardBody className="col-lg-8" style={{ 'margin' : 'auto'}}>
                                <CardTitle>{translate('product.import-title')}</CardTitle>
                                <Form>
                                    <div className="mb-4">
                                        <Link to="/files/fmv.xlsx" target="_blank"  download className="mb-2"> {translate('asset.download-file')}</Link>
                                    </div>
                                    <UploadFile getFiles={getFiles} title={translate('upload-file')}/>
                                    <Row>
                                        <Col lg={3} className="float-right">
                                        {selectedFiles.length > 0 ?
                                            isFetching ?
                                            <Button color="success" className="text-center  col-lg-12 mt-1">
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                                {translate('uploading')}
                                            </Button> :
                                            <Button color="success" className="col-lg-12 mt-1 " onClick={e => upload(e)}>
                                                {translate('upload')}
                                            </Button> : null}
                                        </Col>
                                    </Row>
                                 </Form>
                            </CardBody>
                            {products &&
                            <Card>
                                <CardBody>
                                    <CardTitle>{translate('product.imported-values')} <span className="badge badge-pill bg-soft-success text-success ml-1">{products?.count}</span></CardTitle>
                                    <ToolkitProvider
                                        keyField='id' 
                                        data={products?.updated ?? []}
                                        columns={getProductColumns('showall')}
                                        search 
                                        remote>
                                       {props => (
                                           <div className="table-responsive">
                                             <BootstrapTable
                                               keyField="id"
                                               striped
                                               bordered={false}
                                               noDataIndication={ indication }
                                               autoSelectText={true}
                                               loading={ true }
                                               defaultSorted={ [{
                                                 dataField: 'name',
                                                 order: 'desc'
                                               }]}
                                               wrapperClasses="table-responsive"
                                               pagination={paginationFactory()}
                                               {...props.baseProps}
                                               />
                                           </div>
                                       )}
                                     </ToolkitProvider>
                                </CardBody>
                            </Card> }
                        </Col>
                    </Row>
            </CreateContainer>
        )
    }


export default ProductEMVImport