import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Redux and actions
import { useSelector, useDispatch } from "react-redux";
import { importAssetsToContract } from 'store/contract/actions'
import { getById, clearModelState } from 'store/model/actions'
// Components
import { Card, CardBody, CardTitle } from "reactstrap";
import AssetsTable from '../Asset/Components/AssetList/Table/AssetsTable'
import AssetImportSection from "../Asset/Components/Import/AssetImportSection";
import { Alerts, DesktopTabs, MobileCollapse } from 'Components/Global';
import TableList from 'Components/Lists/TableList';
import PageContainer from "Containers/PageContainer";
// Functions
import { getAssetColumns, translate } from 'helpers/helpers';


const HandleAssetsOnContract  = ({ location }) => {

    const [selectedFile, setSelectedFile ] = useState(null)

    const isFetching = useSelector(state => state.Assets.isFetching)
    const error = useSelector(state => state.Assets.error)
    const errors = useSelector(state => state.Contracts.errors)
    const contract = useSelector(state => state.Model.contract)
    const me = useSelector(state => state.Global.me)

    const contractErrorMessage = useSelector(state => state.Contracts.error)
    const success = useSelector(state => state.Contracts.success)
    const warning = useSelector(state => state.Contracts.warning)
    const newAssets = useSelector(state => state.Contracts.newAssets)
    const updatedAssets = useSelector(state => state.Contracts.updatedAssets)
    const invalidAssets = useSelector(state => state.Contracts.invalidAssets)

    const dispatch = useDispatch()
    const { id } = useParams();

    const getFiles = files => {
        setSelectedFile(files[0]);
    }

    const upload = e => {
        dispatch(importAssetsToContract(id, selectedFile))
    }

    useEffect(() => {
        dispatch(getById(id, 'contract'))
        return () => {
          location.state = null
          dispatch(clearModelState())
        }
      }, [])

    const Tabs = [
            {
                activeTab: 'import',
                translate: 'contract.import-with-csv',
                component:
                <AssetImportSection
                    isFetching={isFetching}
                    getFiles={getFiles}
                    upload={upload}
                    selectedFile={selectedFile} />
            },
            {
                activeTab: 'select',
                translate: 'contract.select-from-list',
                component: contract &&
                <AssetsTable predefinedFilter={{ not_on_contract: true, customer_id: contract?.customer_id }} onAddToContract />
            },
        ]


    const Tabs2 = [
            {
                activeTab: 'new',
                translate: 'asset.new',
                displayBadge: newAssets.length > 0 ? true : false,
                badge: <span className="badge badge-success badge-pill ml-2">{newAssets?.length?.toLocaleString()}</span>,
                component:
                <TableList
                    data={newAssets}
                    columns={getAssetColumns(me?.type, 'mixed')}
                    title={translate('asset.new')}
                />
            },
            {
                activeTab: 'updated',
                translate: 'asset.updated',
                displayBadge: updatedAssets.length > 0 ? true : false,
                badge: <span className="badge badge-primary badge-pill ml-2">{updatedAssets?.length?.toLocaleString()}</span>,
                component:
                <TableList
                    data={updatedAssets}
                    columns={getAssetColumns(me?.type, 'mixed')}
                    title={translate('asset.updated')}
                />
            },
            {
                activeTab: 'invalid',
                translate: 'asset.invalid',
                displayBadge: invalidAssets.length > 0 ? true : false,
                badge: <span className="badge badge-danger badge-pill ml-2">{invalidAssets?.length?.toLocaleString()}</span>,
                component:
                <TableList
                    data={invalidAssets}
                    columns={getAssetColumns(me?.type, 'mixed')}
                    title={translate('asset.invalid')}
                />
            }
        ]

        return (
            <PageContainer
                isFetching={isFetching}
                Model={"Contract"}
                can={'update'}
                breadcrumbTitle={""}
                breadcrumbItem={translate('contract.add-assets')}
                listHeaderTitle={translate('contract.add-assets')}
                alerts={<Alerts error={error || contractErrorMessage} errors={errors} success={success} warning={warning}/>}
                >
                    <CardBody>
                        <MobileCollapse Tabs={Tabs}/>
                        <DesktopTabs Tabs={Tabs} displayTab={'import'}/>
                    </CardBody>
                    {success ?
                    <Card>
                        <CardBody>
                        <CardTitle>{translate('asset.imported-assets')}</CardTitle>
                            <MobileCollapse Tabs={Tabs2} />
                            <DesktopTabs Tabs={Tabs2} />
                        </CardBody>
                    </Card>
                    : null}
            </PageContainer>
        )
    }

export default HandleAssetsOnContract