import React from "react";
import {Col} from 'reactstrap'
import Home from 'assets/images/home.png'
import Recycle from 'assets/images/recycle.png'
import { translate, DispositionsColumns } from "helpers/helpers";
import SectionContainer from "./SectionContainer";
import BootstrapTable from 'react-bootstrap-table-next';

const SectionEnvironment = ({ id, report, downloadReport, exportPdf }) => {
  const showCO2eData = report.total_co2e > 0
  const homes = report.homes

  return (
    <SectionContainer
      report={report}
      downloadReport={downloadReport}
      exportPdf={exportPdf}
      title={<span className="text-success">{translate('reports.environment')}</span>}
      filename={'environment'}
      id={id}
      subTitle={showCO2eData ? <><strong className="text-success">{translate('environmental-savings')} {Math.round(report.total_co2e).toLocaleString()} kg C0<sub>2</sub>e</strong></> : null}
      optional={
      <>
        <div className="avatar-lg mr-2 mx-auto">
           <div className="avatar-title rounded-circle bg-soft-success">
            <img src={showCO2eData ? Home : Recycle} width="60px" alt="home icon"/>
          </div>
        </div>
        {showCO2eData ? 
        <h5 className="mt-3 text-success text-center"> {translate('reports.this-is-equal')} <strong>{homes.toFixed().toLocaleString()}</strong> {translate('reports.homes')}! </h5>
        : 
        <Col lg={12} className="text-align-left mt-3 text-center">
          <h5 className="text-success">{translate('reports.recycle-title')}</h5>
          <ul className="ecosystem-list">
            <li>{translate('reports.recycle1')}</li>
            <li>{translate('reports.recycle2')}</li>
            <li>{translate('reports.recycle3')}</li>
            <li>{translate('reports.recycle4')}</li>
            <li>{translate('reports.recycle5')}!</li>
          </ul>
        </Col>
        }
      </>
      }
      body={
        <BootstrapTable
          id={'env'}
          striped
          bordered={ false }
          condensed
          keyField="id"
          noDataIndication={ () => { return translate('asset.no-rows') }}
          wrapperClasses="table-responsive f-11"
          defaultSorted={[{ dataField: 'co2e', order: 'desc' }]}
          data={report.dispositions}
          columns={DispositionsColumns}
          />}
    />
  )
    }

export default SectionEnvironment

