
export const GET_CONTRACTS_REQUEST = "GET_CONTRACTS_REQUEST";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_FAILURE = "GET_CONTRACTS_FAILURE";

export const GET_CONTRACT_LOG_REQUEST = "GET_CONTRACT_LOG_REQUEST";
export const GET_CONTRACT_LOG_SUCCESS = "GET_CONTRACT_LOG_SUCCESS";
export const GET_CONTRACT_LOG_FAILURE = "GET_CONTRACT_LOG_FAILURE";

export const DELETE_CONTRACT_REQUEST = "DELETE_CONTRACT_REQUEST";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_FAILURE = "DELETE_CONTRACT_FAILURE";

export const ARCHIVE_CONTRACT_REQUEST = "ARCHIVE_CONTRACT_REQUEST";
export const ARCHIVE_CONTRACT_SUCCESS = "ARCHIVE_CONTRACT_SUCCESS";
export const ARCHIVE_CONTRACT_FAILURE = "ARCHIVE_CONTRACT_FAILURE";

export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS"
export const CREATE_CONTRACT_REQUEST = "CREATE_CONTRACT_REQUEST"
export const CREATE_CONTRACT_FAILURE = "CREATE_CONTRACT_FAILURE"

export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS"
export const UPDATE_CONTRACT_REQUEST = "UPDATE_CONTRACT_REQUEST "
export const UPDATE_CONTRACT_FAILURE = "UPDATE_CONTRACT_FAILURE"

export const EXTEND_CONTRACT_SUCCESS = "EXTEND_CONTRACT_SUCCESS"
export const EXTEND_CONTRACT_REQUEST = "EXTEND_CONTRACT_REQUEST"
export const EXTEND_CONTRACT_FAILURE = "EXTEND_CONTRACT_FAILURE"

export const REMOVE_ASSETS_SUCCESS = "REMOVE_ASSETS_SUCCESS"
export const REMOVE_ASSETS_REQUEST = "REMOVE_ASSETS_REQUEST"
export const REMOVE_ASSETS_FAILURE = "REMOVE_ASSETS_FAILURE"

export const CONNECT_ASSETS_SUCCESS = "CONNECT_ASSETS_SUCCESS"
export const CONNECT_ASSETS_REQUEST = "CONNECT_ASSETS_REQUEST"
export const CONNECT_ASSETS_FAILURE = "CONNECT_ASSETS_FAILURE"

export const IMPORT_ASSETS_TO_CONTRACT_REQUEST = "IMPORT_ASSETS_TO_CONTRACT_REQUEST"
export const IMPORT_ASSETS_TO_CONTRACT_SUCCESS = "IMPORT_ASSETS_TO_CONTRACT_SUCCESS"
export const IMPORT_ASSETS_TO_CONTRACT_FAILURE = "IMPORT_ASSETS_TO_CONTRACT_FAILURE"

export const CLEAR_STATE = 'CLEAR_STATE'