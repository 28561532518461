
import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { translate } from 'helpers/helpers';

class GraphHardDriveCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data : {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#34c38f',
              ],
              hoverOffset: 4
            }]
          },
         }
    }

    componentDidMount = () => {

      const { data  } = this.state

      if(data.labels !== this.props.categories) {

        function rand(min, max) {
          return min + Math.random() * (max - min);
        }

        const colors = []
        this.props.categories.map((category, i ) => {
          var h = rand(1, 360);
          var s = rand(50, 80);
          var l = rand(50, 90);
          return colors.push('hsl(' + h + ',' + s + '%,' + l + '%)')
        })

        data.labels = this.props.categories
        data.datasets[0].data = this.props.units
        data.datasets[0].backgroundColor = colors

        this.setState({ data })
        return true
      } else {
        return false
      }
    }

    componentWillUnmount = () => {
      this.setState({ data : {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [ '#34c38f'],
          hoverOffset: 4
        }]
      }})
    }

    render() {

      const { data } = this.state

        return (
          <div className="mt-2 text-center">
            <h5 className="mt-3">{translate('categories')}</h5>
            <Doughnut height={90} data={data} />
          </div>
        );
    }
}

export default GraphHardDriveCategories