import React from "react";
import translate from "../Translation/translate";
import moment from "moment";
const today = moment().format('YYYY-MM-DD')

const ContractListDashboardColumns = [
  {
    text: "Status",
    dataField: "id",
    formatter: (cell, row) => {
      const end_date = moment(row.end_date).format("YYYY-MM-DD");
      return today < end_date ? (
        <a className="btn btn-sm btn-warning" href={`/contracts/${cell}`}>
          Due soon
        </a>
      ) : (
        <a className="btn btn-sm btn-danger" href={`/contracts/${cell}`}>
          Expired
        </a>
      );
    },
    headerStyle: () => {
      return { minWidth: "100px" };
    },
  },
  {
    text: "End date",
    headerFormatter: () => {
      return translate("contract.end-date");
    },
    dataField: "end_date",
    sort: true,
    formatter: (cell, row) => {
      return moment(cell).format("YYYY-MM-DD");
    },
    headerStyle: () => {
      return { minWidth: "100px" };
    },
  },
  {
    text: "Customer",
    headerFormatter: () => {
      return translate("customer.customer");
    },
    dataField: "customer_id",
    sort: true,
    formatter: (cell, row) => {
      return cell ? (
        <a href={`customers/${row.customer_id}`}> {row.customer_name} </a>
      ) : null;
    },
    headerStyle: () => {
      return { minWidth: "150px" };
    },
  },
  {
    text: "Object value",
    headerFormatter: () => {
      return translate("contract.object-value");
    },
    dataField: "object_value",
    sort: true,
    formatter: (cell, row) => {
      return cell.toLocaleString() + " kr" ?? null;
    },
    headerStyle: () => {
      return { minWidth: "100px" };
    },
  },
  {
    text: "Monthly cost",
    headerFormatter: () => {
      return translate("contract.monthly-cost");
    },
    dataField: "monthly_cost",
    sort: true,
    formatter: (cell, row) => {
      return cell?.toLocaleString() + " kr" ?? null;
    },
    headerStyle: () => {
      return { minWidth: "150px" };
    },
  },
  {
    text: "Agreement number",
    headerFormatter: () => {
      return translate("contract.agreement-no");
    },
    dataField: "agreement_no",
    sort: true,
    headerStyle: () => {
      return { minWidth: "150px" };
    },
  },
  {
    text: "Title",
    headerFormatter: () => {
      return translate("contract.title");
    },
    dataField: "title",
    sort: true,
    headerStyle: () => {
      return { minWidth: "150px" };
    },
  },
  {
    text: "Assets",
    dataField: "assets_count",
    sort: true,
    formatter: (cell, row) => {
      return cell?.toLocaleString();
    },
  },
];

export default ContractListDashboardColumns;
