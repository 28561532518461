import {
    GET_COMPANY_INFO_REQUEST,
    GET_COMPANY_INFO_SUCCESS,
    GET_COMPANY_INFO_FAILURE,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILURE,
    GET_ASSET_STATUSES_REQUEST,
    GET_ASSET_STATUSES_SUCCESS,
    GET_ASSET_STATUSES_FAILURE,
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAILURE,
    GET_MULTISELECT_CUSTOMERS_FAILURE,
    GET_MULTISELECT_CUSTOMERS_REQUEST,
    GET_MULTISELECT_CUSTOMERS_SUCCESS,
    GET_PRESELECTED_CUSTOMERS_FAILURE,
    GET_PRESELECTED_CUSTOMERS_REQUEST,
    GET_PRESELECTED_CUSTOMERS_SUCCESS,
    GET_CUSTOMER_CONTRACTS_REQUEST,
    GET_CUSTOMER_CONTRACTS_SUCCESS,
    GET_CUSTOMER_CONTRACTS_FAILURE,
    GET_CUSTOMER_LOT_REPORTS_REQUEST,
    GET_CUSTOMER_LOT_REPORTS_SUCCESS,
    GET_CUSTOMER_LOT_REPORTS_FAILURE,
    GET_LOT_REPORTS_REQUEST,
    GET_LOT_REPORTS_SUCCESS,
    GET_LOT_REPORTS_FAILURE,
    GET_RESELLERS_REQUEST,
    GET_RESELLERS_SUCCESS,
    GET_RESELLERS_FAILURE,
    GET_RESELLER_CUSTOMERS_REQUEST,
    GET_RESELLER_CUSTOMERS_SUCCESS,
    GET_RESELLER_CUSTOMERS_FAILURE,
    GET_SUPPLIERS_REQUEST,
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_FAILURE,
    GET_RESELLER_STATUSES_REQUEST,
    GET_RESELLER_STATUSES_SUCCESS,
    GET_RESELLER_STATUSES_FAILURE,
    GET_ANNUAL_YEARS_REQUEST,
    GET_ANNUAL_YEARS_SUCCESS,
    GET_ANNUAL_YEARS_FAILURE,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    CLEAR_STATE
} from "./actionTypes";

import { forms, uc, reports } from '../api'
import translate from "helpers/Translation/translate";
import axios from 'axios';

export const clearState = () => { return async dispatch => { dispatch({ type: CLEAR_STATE}) } }


export const getCategories = filters => {
    return async dispatch => {
        try {
            dispatch({ type: GET_CATEGORIES_REQUEST })
                const categories = await forms.post(`categories`, { filters });
                return dispatch ({ type: GET_CATEGORIES_SUCCESS, payload: categories.data });
          } catch (error) {
                return dispatch ({ type: GET_CATEGORIES_FAILURE, message: translate('message.categories-failure')});
            }
        }
}

export const getProducts = filters => {
    return async dispatch => {
        try {
            dispatch({ type: GET_PRODUCTS_REQUEST })
                const products = await forms.post(`products`, { filters });
                return dispatch ({ type: GET_PRODUCTS_SUCCESS, payload: products.data });
          } catch (error) {
                return dispatch ({ type: GET_PRODUCTS_FAILURE, message: translate('message.products-failure')});
            }
        }
}

export const getSupplierDropdown = () => {
    return async dispatch => {
        try {
            dispatch({ type: GET_SUPPLIERS_REQUEST })
            const products = await forms.get(`products/suppliers`);
            return dispatch ({ type: GET_SUPPLIERS_SUCCESS, payload: products.data });
          } catch (error) {
                return dispatch ({ type: GET_SUPPLIERS_FAILURE, message: translate('message.products-failure')});
            }
        }
}

export const getCustomerDropdown = () => {
    return async dispatch => {

        try {
            dispatch({ type: GET_CUSTOMERS_REQUEST })
            const customers = await forms.get(`customers`);
            return dispatch ({ type: GET_CUSTOMERS_SUCCESS, payload: customers.data });
          } catch (error) {
                return dispatch ({ type: GET_CUSTOMERS_FAILURE, message: translate('message.customers-failure')});
            }
        }
}

export const getCustomersWithBlanccoReportsDropdown = () => {
    return async dispatch => {

        try {
            dispatch({ type: GET_CUSTOMERS_REQUEST })
            const customers = await forms.get(`customers/with-blancco-reports`);
            return dispatch ({ type: GET_CUSTOMERS_SUCCESS, payload: customers.data });
          } catch (error) {
                return dispatch ({ type: GET_CUSTOMERS_FAILURE, message: translate('message.customers-failure')});
            }
        }
}

export const getCustomersWithProductsDropdown = () => {
    return async dispatch => {

        try {
            dispatch({ type: GET_CUSTOMERS_REQUEST })
            const customers = await forms.get(`customers/with-products`);
            return dispatch ({ type: GET_CUSTOMERS_SUCCESS, payload: customers.data });
          } catch (error) {
                return dispatch ({ type: GET_CUSTOMERS_FAILURE, message: translate('message.customers-failure')});
            }
        }
}

export const getResellersWithProductsDropdown = () => {
    return async dispatch => {

        try {
            dispatch({ type: GET_RESELLERS_REQUEST })
            const resellers = await forms.get(`resellers/with-products`);
            return dispatch ({ type: GET_RESELLERS_SUCCESS, payload: resellers.data });
          } catch (error) {
                return dispatch ({ type: GET_RESELLERS_FAILURE, message: translate('message.resellers-failure')});
            }
        }
}


export const getCustomersMultiSelect = () => {
    return async dispatch => {
        try {
            dispatch({ type: GET_MULTISELECT_CUSTOMERS_REQUEST })
            const customers = await forms.get(`/multiselect/customers`);
            return dispatch ({ type: GET_MULTISELECT_CUSTOMERS_SUCCESS, customers: customers.data.customers });
          } catch (error) {
              return dispatch ({ type: GET_MULTISELECT_CUSTOMERS_FAILURE, message: translate('message.customers-failure')});
            }
    }
}

export const getpredfinedCustomers = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_PRESELECTED_CUSTOMERS_REQUEST })
            const customers = await forms.get(`/multiselect/${id}/customers`);
            return dispatch ({ type: GET_PRESELECTED_CUSTOMERS_SUCCESS, customers: customers.data.customers });
          } catch (error) {
              return dispatch ({ type: GET_PRESELECTED_CUSTOMERS_FAILURE, message: translate('message.customers-failure')});
            }
    }
}


export const getContractsForThisCustomer = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_CUSTOMER_CONTRACTS_REQUEST })
            const contracts = await forms.get(`customers/${id}/contracts`);
            return dispatch ({ type: GET_CUSTOMER_CONTRACTS_SUCCESS, payload: contracts.data });
          } catch (error) {
                return dispatch ({ type: GET_CUSTOMER_CONTRACTS_FAILURE, message: translate('message.contracts-failure')});
            }
        }
}

export const getStatusesForThisCustomer = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_ASSET_STATUSES_REQUEST })
            const statuses = await forms.get(`customers/${id}/asset-statuses`);
            return dispatch ({ type: GET_ASSET_STATUSES_SUCCESS, payload: statuses.data });
          } catch (error) {
                return dispatch ({ type: GET_ASSET_STATUSES_FAILURE, message: translate('message.get-status-failure')});
            }
        }
}


  export const getStatusesForThisReseller = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_RESELLER_STATUSES_REQUEST })
            const success = await axios.get(`${process.env.REACT_APP_API_URL}/forms/resellers/${id}/asset-statuses`);
            return dispatch ({ type: GET_RESELLER_STATUSES_SUCCESS, payload: success.data});
          } catch (error) {
             return dispatch ({
                  type:  GET_RESELLER_STATUSES_FAILURE,
                  message: translate('message.fetch-status-failure') });
             }
        }
}

export const getResellerDropdown = () => {
    return async dispatch => {
        try {
            dispatch({ type: GET_RESELLERS_REQUEST })
            const resellers = await forms.get(`resellers`);
            return dispatch ({ type: GET_RESELLERS_SUCCESS, payload: resellers.data });
          } catch (error) {
                return dispatch ({ type: GET_RESELLERS_FAILURE, message: translate('message.resellers-failure')});
            }
        }
}

export const getCustomersForThisReseller = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_RESELLER_CUSTOMERS_REQUEST })
            const customers = await forms.get(`resellers/${id}/customers`);
            return dispatch ({ type: GET_RESELLER_CUSTOMERS_SUCCESS, payload: customers.data });
          } catch (error) {
                return dispatch ({ type: GET_RESELLER_CUSTOMERS_FAILURE, message: translate('message.get-reseller-customers-failure')});
            }
        }
}



export const fetchInfoFromAllaForetag = request => {
    return async dispatch => {
        try {
            dispatch({ type: GET_COMPANY_INFO_REQUEST })
            const info = await uc.post('lookup', request);
            return dispatch ({ type: GET_COMPANY_INFO_SUCCESS, payload: info.data });
          } catch (error) {
              if(error?.response?.status === 404) return dispatch ({ type: GET_COMPANY_INFO_FAILURE, message: error.response?.data?.error?.message})
              else  return dispatch ({ type: GET_COMPANY_INFO_FAILURE, message: translate('message.uc-lookup-failure')});
            }
        }
}

export const getYears = () => {
    return async dispatch => {
      try {
          dispatch({ type: GET_ANNUAL_YEARS_REQUEST })
          const years = await reports.get(`/years`);
          return dispatch ({ type: GET_ANNUAL_YEARS_SUCCESS, payload: Object.values(years.data) });
        } catch (error) {
              return dispatch ({ type: GET_ANNUAL_YEARS_FAILURE, message: translate('message.years-failure')});
          }
      }
  }

  export const getLotreportsForThisCustomer = id => {
    return async dispatch => {
        try {
            dispatch({ type: GET_CUSTOMER_LOT_REPORTS_REQUEST })
            const reports = await forms.get(`customers/${id}/lot-reports`);
            return dispatch ({ type: GET_CUSTOMER_LOT_REPORTS_SUCCESS, payload: reports.data });
          } catch (error) {
                return dispatch ({ type: GET_CUSTOMER_LOT_REPORTS_FAILURE, message: translate('message.lot-reports-failure')});
            }
        }
}

export const getLotreports = () => {
    return async dispatch => {
        try {
            dispatch({ type: GET_LOT_REPORTS_REQUEST })
            const reports = await forms.get(`/lot-reports`);
            return dispatch ({ type: GET_LOT_REPORTS_SUCCESS, payload: reports.data });
          } catch (error) {
                return dispatch ({ type: GET_LOT_REPORTS_FAILURE, message: translate('message.lot-reports-failure')});
            }
        }
}


