import React from "react";
import { Row, Col } from "reactstrap";
import { translate } from "helpers/helpers";
import { Loader } from "Components/Global";

const CalculatingWarranty = () => {

    return (
        <Row>
            <Col lg={12} className="text-center">
                <h5>{translate('warranty.calculating')}</h5>
                <p className="text-muted">{translate('warranty.calculating-text')}</p>
                <i className="bx bx-loader text-primary bx-spin font-size-18 align-middle"></i>
            </Col>
        </Row>
    )
}

export default CalculatingWarranty