import React from "react";
import { Col, Row} from 'reactstrap'
import { Table, Tr, Th, Td, Tbody } from 'react-super-responsive-table';
import { translate } from "helpers/helpers";


const AnnualReportSummaryTable = ({ report }) =>  {

  return (
    <Row className="mt-5">
      {report.total_assets > 0 ?
      <Col lg={6} className="mx-auto">
        <Table className="table f-10">
          <Tbody>
            <Tr>
              <Th > {translate('resale')} </Th>
              <Td >
                <strong>{report.resale?.toLocaleString()}</strong> assets
              </Td>
              </Tr>
            <Tr>
              <Th style={{ width: '70%'}}> {translate('recycle')}</Th>
              <Td >
                <strong>{report.recycled?.toLocaleString()}</strong> assets
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Col>
      : null}
      {report.hard_drives.length > 0 ?
      <Col lg={6} className="d-flex flex-column justify-content-around mx-auto">
        <Table className="table f-10" >
          <Tbody>
            <Tr>
              <Th style={{ width: '70%'}}> {translate('blancco-erased')} </Th>
              <Td >
                <strong>{report.blancco_erased_hard_drives?.length?.toLocaleString()}</strong> assets
              </Td>
            </Tr>
            <Tr>
              <Th > {translate('shredded')} </Th>
              <Td >
                <strong>{report.shredded?.length?.toLocaleString()}</strong> assets
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Col>
      : null}
    </Row>
  )
}

export default AnnualReportSummaryTable

