import React, { useEffect, useState } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, FormGroup, Col } from "reactstrap";
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { createModel, deleteModel, updateModel, getAll, clearModelState } from 'store/model/actions';
import { Can, translate } from 'helpers/helpers';
import { PopUpModal } from "Components/Global";
import { Icons } from 'assets/styles/index';


const Statuses = () => {

  const dispatch = useDispatch()

  const [ newStatus, setNewStatus ] = useState('')
  const [ updateStatus, setUpdateStatus ] = useState('')
  const [ update, setUpdate ] = useState(false)
  const [ status_id, setStatusId ] = useState(false)
  const [ modal, setModal ] = useState(false)

  const statuses = useSelector(state =>  state.Model.status)
  const error = useSelector(state =>  state.Model.error)
  const errors = useSelector(state =>  state.Model.errors)
  const success = useSelector(state =>  state.Model.success)

    useEffect(() => {
      dispatch(getAll('status'))
      return () => {
        dispatch(clearModelState())
      }
    },[])

    const togglemodal = id => {
      setModal(!modal)
      setStatusId(id)
    }

    const openModal = (status, update) => {
      dispatch(clearModelState())
      togglemodal(status.id)
      setUpdateStatus(status)
      setUpdate(update)
    }

    const handleChange = e => {
      let value = e.target.value
      setUpdateStatus(updateStatus => ({ ...updateStatus, status: value }))
    };

    const handleNewStatus = () => {
      dispatch(clearModelState())
      dispatch(createModel('assets/statuse', {status: newStatus}))
        .then(() => {
          dispatch(getAll('status'))
          setNewStatus('')
        })
    }

    const updateThisStatus = () => {
      dispatch(clearModelState())
      dispatch(updateModel('assets/statuse', updateStatus))
      .then(() => {
        dispatch(getAll('status'))
      })
      togglemodal()
    }

    const deleteThisStatus = () => {
      dispatch(clearModelState())
      dispatch(deleteModel(status_id, 'status'))
      .then(() => {
        dispatch(getAll('status'))
      })
        togglemodal()
    }

      return (
        <div className="table-responsive">
          <Table className="table">
            <Tbody className="without-border">
              {statuses?.map((status) => (
                <Tr key={status.id}>
                  <Td>{status.status}</Td>
                  <Td>
                    <Can I="update" a="Assetstatus">
                      {() => (
                        <button
                          className="btn btn-small btn-warning m-1"
                          onClick={() => openModal(status, true)}
                          >
                          <i className={Icons.edit}></i>
                        </button>
                      )}
                    </Can>
                    <Can I="delete" a="Assetstatus">
                      {() => (
                        <button className="btn btn-small btn-danger m-1" onClick={() => openModal(status)}>
                          <i className={Icons.delete}></i>
                        </button>
                      )}
                    </Can>
                  </Td>
                </Tr>
              ))}
              <Can I="create" a="Assetstatus">
                {() => (
                  <Tr>
                    <Td>
                      <Input
                        type="text"
                        value={newStatus ?? ''}
                        onChange={(e) => setNewStatus(e.target.value)}
                        name="new_status"
                        className="mt-1"
                      />
                    </Td>
                    <Td>
                        <Button
                          disabled={newStatus?.length ? false : true }
                          onClick={() => handleNewStatus()} color="success" className=" m-1">
                          {translate('status.add-new')}
                        </Button>
                    </Td>
                  </Tr>
                )}
              </Can>
            </Tbody>
          </Table>

          <PopUpModal
            isOpen={modal}
            togglemodal={togglemodal}
            title={update ? translate('status.update-sure') : translate('status.delete-sure')}
            success={success}
            error={error}
            errors={errors}
            >
              <p className="mb-2"> {update ? translate('status.update-sure') : translate('status.delete-sure')}</p>
              {update &&
                <FormGroup row>
                  <Col lg={10}>
                    <Input
                      type="text"
                      value={updateStatus.status ?? ''}
                      onChange={e => handleChange(e)}
                      name="update_status"
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      disabled={updateStatus?.length ??  false}
                      type="button"
                      color="warning"
                      className=""
                      onClick={updateThisStatus}>
                        {translate('status.update')}
                    </Button>
                  </Col>
                </FormGroup>}
                {!update &&
                <Col lg={12} className="d-flex">
                  <Button
                    type="button"
                    color="danger"
                    className=" mx-auto mt-3"
                    onClick={deleteThisStatus}>
                      {translate('status.delete')}
                  </Button>
                </Col>}
          </PopUpModal>
      </div>
      );
    };

 export default Statuses
