import React from 'react';
import { UncontrolledCollapse, Card, CardBody, Table } from "reactstrap";
import BillingTable from './BillingTable';
import { translate } from 'helpers/helpers';


const EcosystemCustomerToCharge = ({ billingInfo, type }) => (

    <div className="table-responsive mt-3">
        <Table className="table">
            <thead>
                <tr>
                    <th>
                        {translate('ecosystem-customer')}
                    </th>
                    <th>
                        {translate('period')}
                    </th>
                    <th>
                        Assets
                    </th>
                </tr>
            </thead>
            <tbody>
                {billingInfo?.map((customer, i ) => {
                    return (
                        <>
                        <tr key={i} >
                            <td style={{ display: 'flex', alignItems: 'baseline'}}>
                                {customer.name}
                                {type === "reseller" &&
                                    <p className="text-info ml-2" id={`b${customer.id}`} style={{ fontSize: '11px', cursor: 'pointer' }}>
                                        {translate('details')}
                                    </p>
                                }
                            </td>
                            <td>
                                {customer.asset_count[0]?.period}
                            </td>
                            <td>
                                {customer.asset_count[0]?.count.toLocaleString()}
                            </td>
                        </tr>
                        <tr key={'_'+i}>
                            <td style={{ borderTop: 'none'}}></td>
                            <td style={{ borderTop: 'none'}}>
                                {customer.asset_count[1] ? <>  <span className="text-muted mr-2">{translate('previous')}</span> {customer.asset_count[1].period} </> : null}
                            </td>
                            <td style={{ borderTop: 'none'}}>
                                {customer.asset_count[1]?.count.toLocaleString()}
                            </td>
                        </tr>
                        <tr key={'__'+i} >
                            <td colSpan={3} className="p-0">
                                {type === "reseller" ?
                                    <UncontrolledCollapse toggler={`#b${customer.id}`} style={{ width: "100%"}}>
                                        <Card>
                                            <CardBody>
                                                <BillingTable data={customer.customers} />
                                            </CardBody>
                                        </Card>
                                    </UncontrolledCollapse>
                                : null}
                            </td>
                        </tr>
                    </>
                    )
                })}
            </tbody>
        </Table>
    </div>
    )

export default EcosystemCustomerToCharge