import React from "react";
import {  useSelector } from "react-redux";

// Functions
import { translate, getProductColumns } from "helpers/helpers";
import { Alerts } from 'Components/Global';
import PageContainer from "Containers/PageContainer";
import { RemotePaginationTable } from "Components/Lists";


const ProductList = ({ match, location }) => {

  const pagination = useSelector(state => state.Model.products)
  const error = useSelector((state) => state.Model.error);
  const success = useSelector((state) => state.Model.success);
  const warning = useSelector((state) => state.Model.warning);

    return (
      <PageContainer
        Model={'Asset'}
        breadcrumbTitle={translate("product.list")}
        breadcrumbItem={translate("product.list")}
        listHeaderTitle={translate("product.list")}
        data={pagination?.total ?? []}
        alerts={<Alerts success={success} error={error} warning={warning} location={location} />}
      >
        <RemotePaginationTable
          model={'products'}
          pagination={pagination}
          columns={getProductColumns('noticecat')}
          pageUrl={`${process.env.REACT_APP_API_URL}/products`}
          path={match.path}
          can={'Asset'}
          keyField={'name'}
        />
    </PageContainer>
     );
  }

export default ProductList;

