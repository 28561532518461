import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearModelState } from 'store/model/actions'
import { getCustomerDropdown } from 'store/form/actions'

import { FormGroup, Row, Col } from "reactstrap";
import { OptionSelect } from 'Components/Form/index'
// Functions
import { translate, Can } from 'helpers/helpers';


const ContractListfilter = ({ filter, onChange }) => {

  const dispatch = useDispatch();
  const me  = useSelector(state => state.Global.me)
  const customersDropdown  = useSelector(state => state.Form.customersDropdown)

  useEffect(() => {
    if(me.type === 'admin' || me.type === 'reseller') dispatch(getCustomerDropdown())
    return () => {
      dispatch(clearModelState())
      // location.state = null
    }
  }, [filter])

  return (
    <Col lg={12}>
      <Row>
        <Col lg={4}>
          <Can I="read" a="Customer">
            {() => (
              <FormGroup>
                <label>{translate('customer.customers')}</label>
                <select
                  value={filter?.customer_id}
                  name="customer_id"
                  className="form-control select2 col-lg-12"
                  onChange={onChange}
                  >
                  <OptionSelect message={"include-all"} value={""}/>
                  {customersDropdown?.map(customer => {
                    return <option key={customer.id} value={customer.id}>{customer.name}</option>
                  }
                  )}
                </select>
              </FormGroup>
            )}
          </Can>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <label>Status</label>
            <select
              value={filter?.status}
              name="status"
              className="form-control select2 col-lg-12"
              onChange={onChange}
              >
              <OptionSelect message={"include-all"} value={""}/>
              <OptionSelect message={"contract.active"} value={'active'}/>
              <OptionSelect message={"contract.inactive"} value={'inactive'}/>
              <OptionSelect message={"contract.archived"} value={'archived'}/>
            </select>
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <label>{translate('contract.type')}</label>
            <select
              name="type"
              value={filter?.type}
              className="col-lg-12"
              onChange={onChange}>
              <OptionSelect message={"include-all"} value={""}/>
              <OptionSelect message={'contract.direct'} value={'direct'}/>
              <OptionSelect message={'contract.framework'} value={'framework'}/>
              <OptionSelect message={'contract.substart'} value={'substart'}/>
            </select>
          </FormGroup>
        </Col>
      </Row>
  </Col>
 )
}

export default ContractListfilter
