import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import EcosystemCardHeight100 from 'assets/styles/EcosystemCardHeight100';

import { HandleDropDown } from "Components/Global";
import { Table, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { translate } from 'helpers/helpers';
import moment from 'moment'

const UserInfo = ({ user, isCustomer, history, me }) =>  (

  <Card style={EcosystemCardHeight100}>
    <CardBody>
      <HandleDropDown can="User" model={'user'} id={user?.id} history={history} me={me}></HandleDropDown>
      <Col lg={12}>
        <div className="table-responsive">
            {user.logo && (
              <div className="avatar-md mb-3">
                <img src={user.logo} alt="" className="img-thumbnail rounded-circle" />
              </div>
              )}
              <div className="d-flex mb-3">
                <h5>{user.name}</h5>
                <div>
                  <span className="badge badge-soft-primary ml-1">
                    {isCustomer && user.role === "enduser" && "Basic" }
                    {isCustomer && user.role === "enduser_admin" && "Admin"}
                    {!isCustomer && user.role === "enduser" && "End User" }
                    {!isCustomer && user.role === "enduser_admin" && "End User Admin"}
                    {user.role === "partner" && "Partner" }
                    {user.role === "partner_admin" && "Partner Admin"}
                    {user.role === "admin" && "Admin"}
                  </span>
                  {user.key_account_manager && <span className="badge badge-soft-success"> KAM </span>}
                </div>
              </div>
              <p><span className="text-muted">{translate('user.last-login')}: </span> {user.last_login ? moment.utc(user.last_login).local().format('YYYY-MM-DD HH:mm') : '-'}</p>
              <hr />
          <Table className="table no-border mt-5">
            <Tbody>
              <Tr>
                <Th scope="row">{translate("contact.phone")}</Th>
                <Td>
                  <a href={`tel:${user.email}`}>{user.telephone}</a>
                </Td>
              </Tr>
              <Tr>
                <Th scope="row">Email</Th>
                <Td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </Td>
              </Tr>
              <Tr>
                <Th scope="row">{translate("contact.address")}</Th>
                <Td>{user.address}</Td>
              </Tr>
              <Tr>
                <Th scope="row">{translate('profile.joined')}</Th>
                <Td>{user.created_at ? moment(user.created_at).format('YYYY-MM-DD') : "-"}</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        </Col>
      </CardBody>
    </Card>
  )


export default UserInfo;
