import React from "react";
import { Row, Col } from "reactstrap";
import { Can, translate } from 'helpers/helpers';

const CustomerInfo = ({ customer }) =>  (
      <Row>
        <Col lg={6}>
          <p><strong className="mr-1">{translate('address.address')}</strong>: {customer.address}</p>
          <p><strong className="mr-1">{translate('address.zip')}</strong>: {customer.zip} </p>
          <p><strong className="mr-1">{translate('address.city')}</strong>: {customer.city}</p>

          <p><strong className="mr-1">{translate('address.email')}</strong>: <a href={`mailto:${customer.email}`}>{customer.email}</a></p>
          <p><strong className="mr-1">{translate('address.phone')}</strong>: <a href={`tel:${customer.telephone}`}>{customer.telephone}</a></p>
        </Col>
        <Col lg={6}>
          <p><strong className="mr-1">{translate('reseller.org-no')}</strong>: {customer.org_no} </p>
          <p><strong className="mr-1">Ecosystem ID</strong>: {customer.id} </p>
          <p><strong className="mr-1">{translate('form.comments')}</strong>: {customer.comment}</p>

          <Can I="read" a="Reseller">
            {() =>  <p><strong className="mr-1">{translate('reseller.reseller')}</strong>: {customer.reseller ? <a href={`/resellers/${customer.reseller.id}`}>{customer.reseller.name}</a> : null}</p>}
          </Can>
           <p><strong className="mr-1">{translate('user.key-account-managers')}</strong>: </p>
           <ul className="ecosystem-list">
            {customer.key_account_managers?.map(manager => {
              return <li><a href={`/users/${manager.id}`}>{manager.name}</a></li>
            })}
           </ul>
        </Col>
      </Row>
)

export default CustomerInfo;
