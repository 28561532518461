import React from "react";
import moment from 'moment'
import { FormattedMessage }  from 'react-intl';


const displayEOL = ( asset ) => {

    const today = moment()
    const eol_date = asset.eol_date ? moment(asset.eol_date) : null
    const product_category = asset.product_category

    if (eol_date && (today > eol_date)) return <span className="text-danger">{moment(asset.eol_date).format('YYYY-MM-DD')} <strong className="ml-1"><FormattedMessage id={'asset.warranty-overdue'}/></strong></span>
    if (eol_date && (today < eol_date)) return moment(asset.eol_date).format('YYYY-MM-DD')
    if (!product_category) return <span className="text-danger"><FormattedMessage id={'asset.no-category'}/></span>
    if (product_category && !eol_date) return <span><FormattedMessage id={'asset.category-no-eol'}/></span>

}

export default displayEOL
