import React, { useState, useEffect  } from 'react';

import { Container, Row, Col } from "reactstrap";
import { LoaderOnPage } from '../Components/Global';
import { translate } from 'helpers/helpers'
//Import Images
import image from "assets/images/verification-img.png";
import background from "assets/images/LoginBackground.png";

const delay = 1;


const Page403 = () => {
    const [show, setShow] = useState(false);

    useEffect(
        () => {
          let timer1 = setTimeout(() => setShow(true), delay * 1000);
          return () => {
            clearTimeout(timer1);
          };
        },
        []
      );

        return (
            show ?
                <div className="account-pages" style={{ backgroundImage: `url(${background})`}}>
                    <Container>
                        <Row>
                            <Col lg="6" className="center-center">
                                <h3 className="display-2 font-weight-medium">403</h3>
                                <h4> {translate('no-auth')}</h4>
                                <p> {translate('no-auth-description')} </p>
                            </Col>
                            <Col lg="6" className="center-center">
                                <img src={image} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            : <LoaderOnPage />
        );
}

export default Page403;