import React, { useEffect, useState  } from "react";
import { useSelector, useDispatch } from "react-redux";

// Redux and actions
import { getReport, clearState} from "store/report/actions";
import { getYears, getCustomerDropdown, getResellerDropdown } from "store/form/actions";

// Components
import { Row, Col } from 'reactstrap'
import PageContainer from 'Containers/PageContainer'
import {
  SectionSummary,
  SectionEnvironment,
  SectionBlancco,
  SectionSpecification,
  SectionSelect,
  SectionReportInfo,
  SectionModal,
  AdminButtons,
  ReportButtons,
  SectionDiploma,
} from "./Components";
import { Alerts} from "Components/Global/index"
import Moreco from 'assets/images/logo.png'
import Page404 from "Pages/Page404";

// Functions
import { translate } from "helpers/helpers";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const Report = ({ match }) => {

  const dispatch = useDispatch()

  const error = useSelector(state => state.Report.error)
  const success = useSelector(state => state.Report.success)
  const isFetching = useSelector(state => state.Report.isFetching)

  const isAdmin = useSelector(state => state.Global.isAdmin)
  const isCustomer = useSelector(state => state.Global.isCustomer)
  const me = useSelector(state => state.Global.me)
  const locale = useSelector(state => state.Global.locale)
  const report = useSelector(state => state.Report.report)
  const notFound = useSelector(state => state.Report.notFound)

  const [isLotReport, setLotReport ] = useState(false)
  const [downloadReport, setdownloadReport ] = useState(false)
  const [downloadDiploma, setdownloadDiploma ] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const lot_number = match?.params?.id ?? ''
  const [reseller, setReseller] = useState('')
  const [customer, setCustomer] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [year, setYear] = useState('')

  const [filter, setFilter] = useState({
      customer_id : '',
      reseller_id: null,
      year: '',
      lot_number: '',
      start_date: '',
      end_date: '',
      excel: 'false',
  })

  const getFilter = filter => {
    setFilter(filter)
  }

  useEffect(() => {
    if(lot_number) {
      filter.lot_number = lot_number
      setFilter(filter)
      setLotReport(true)
      dispatch(getReport({ filter: filter }))
    }
      return () => {
        dispatch(clearState())
      }
  }, [dispatch, lot_number])

  useEffect(() => {
    if(!lot_number) {
      if(me.customer_id) {
        filter.customer_id = me?.customer_id
        setFilter(filter)
      }
      if(me.reseller_id) {
        filter.reseller_id = me?.reseller_id
        setFilter(filter)
      }
        dispatch(getYears())
        dispatch(getCustomerDropdown())
        dispatch(getResellerDropdown())
        return () => {
          dispatch(clearState())
        }
    }
  }, [dispatch, filter, lot_number, me?.customer_id, me?.reseller_id])


  var pdf;
  const addCanvas2pdf = (canvas, imgWidth, pageHeight, margin) => {

    var myImage = new Image()
    myImage.src = canvas.toDataURL('image/jpeg', 0.6);
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var position = 10;

    pdf.addImage(myImage, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    pdf.setDrawColor(255, 255, 255);
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, pageHeight+margin, imgWidth+2*margin, pageHeight, "FD");
    pdf.setDrawColor(255, 255, 255);
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, imgWidth+2*margin, margin, "FD");

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight + margin;
        pdf.addPage();
        pdf.addImage(myImage, 'PNG', margin, position, imgWidth, imgHeight);
        pdf.setDrawColor(255, 255, 255);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, imgWidth+2*margin, margin, "FD");
        pdf.setDrawColor(255, 255, 255);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, pageHeight+margin, imgWidth+2*margin, pageHeight, "FD");
        heightLeft -= pageHeight;
    }
    return pdf;
  }
  const exportPdf = async (id, filename) => {

    setdownloadReport(true)
    pdf = new jsPDF('l', 'px', 'a4', );
    var margin = 10;
    var imgWidth = pdf.internal.pageSize.getWidth() - 2*margin;
    var pageHeight = pdf.internal.pageSize.getHeight() - 2*margin;
    var sections = document.getElementById(id).children;
    var canvas;

    for(var i=0; i<sections.length; i++) {
      if(i>0) pdf.addPage();
      canvas = await html2canvas(sections[i]);
      addCanvas2pdf(canvas, imgWidth, pageHeight, margin);
    }

    // Adding footer to every page
    const pageCount = pdf.internal.getNumberOfPages();
    for(let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      const pageSize = pdf.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      const footer = locale === 'sv'? `Sida ${i} av ${pageCount}`: `Page ${i} of ${pageCount}`;
      pdf.setFont("Helvetica");
      pdf.setFontSize('8')
      pdf.text(footer, pageWidth-45, pageHeight-5);
    }
    pdf.save(`${filename}.pdf`);
    setdownloadReport(false)
  }

  const exportDiploma = () => {
    setdownloadDiploma(true)
    exportPdf('pdf-diploma', 'Diploma')
    setdownloadDiploma(false)
  }

  const deleteReport = () => {
    setDeleteModal(true)
    setIsOpen(true)
  }

    return (
      <PageContainer
        isFetching={isFetching}
        reportGeneration={isFetching}
        Model={"Morecoreport"}
        breadcrumbTitle={<img src={Moreco} height="15px" alt="logo"></img>}
        breadcrumbItem={isLotReport ? 'LOT ' + lot_number : translate('reports.report')}
        listHeaderTitle={isLotReport? 'LOT ' + lot_number : translate('reports.report')}
        alerts={<Alerts error={error} success={success}/>}
        >
        {!report && !lot_number &&
          <SectionSelect
            getFilter={getFilter}
            getCustomer={setCustomer}
            getYear={setYear}
            getReseller={setReseller}
            getStartDate={setStartDate}
            getEndDate={setEndDate}/>}
          {report ?
          <Row>
            <Col lg={12}>
              <Row className="justify-content-end">
                <AdminButtons
                  deleteReport={deleteReport}
                  setIsOpen={setIsOpen}
                  isAdmin={isAdmin}
                  lot_number={lot_number}/>
                <ReportButtons
                  report={report}
                  exportPdf={exportPdf}
                  downloadReport={downloadReport}
                  downloadDiploma={downloadDiploma}
                  />
              </Row>
            </Col>
            <Col lg={12}>
              <div id="pdf">
                {report?.results?.year ?
                <SectionDiploma
                  id={'pdf-diploma'}
                  report={report}
                  exportPdf={exportPdf}
                  exportDiploma={exportDiploma}
                  /> : null}
                <SectionReportInfo
                  id={'overview'}
                  downloadReport={downloadReport}
                  report={report}
                  exportPdf={exportPdf}/>
                <SectionSummary
                  id={'summary'}
                  report={report}
                  downloadReport={downloadReport}
                  exportPdf={exportPdf}/>
                <SectionEnvironment
                  id={'environment'}
                  report={report}
                  downloadReport={downloadReport}
                  exportPdf={exportPdf}/>
              </div>
              {report.hard_drives?.length ?
                <SectionBlancco report={report} downloadReport={downloadReport} me={me}/>
              : null}
              {report.total_assets > 0 ?
                <SectionSpecification report={report} me={me} downloadReport={downloadReport}/>
              : null}
            </Col>
          </Row>
        : notFound && <Page404 model={`reports.report`}/>}
        <SectionModal
          customer_emails={report?.lots[0]?.customer_emails}
          reseller_emails={report?.lots[0]?.reseller_emails}
          lot_number={lot_number}
          error={error}
          success={success}
          deleteModal={deleteModal}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </PageContainer>
    )
  }

export default Report;